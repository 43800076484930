import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

const TooltipComponent = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '370px',
  },
});

export default TooltipComponent;
