import { Avatar, Box, Typography, useTheme } from '@mui/material';

const CommentAvatarComponent = ({
  avatarUrl,
  abbreviation,
}: {
  avatarUrl?: string;
  abbreviation?: string;
}) => {
  const theme = useTheme();
  return (
    <Avatar
      sx={{
        backgroundColor: theme.palette.primary.main,
      }}
    >
      {avatarUrl && (
        <Box
          component="img"
          src={avatarUrl}
          sx={{
            height: '30px',
            width: '30px',
            objectFit: 'contain',
          }}
        ></Box>
      )}
      {abbreviation && (
        <Typography
          sx={{
            color: '#FFFFFF',
          }}
        >
          {abbreviation}
        </Typography>
      )}
    </Avatar>
  );
};

export default CommentAvatarComponent;
