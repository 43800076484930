import { makeStyles } from '@mui/styles';
import { useEffect, useRef, useState } from 'react';
import { Alert } from 'reactstrap';
import { delay, Subject, takeUntil, tap } from 'rxjs';
import { ToastPropsModel } from '../../../models/toast/toast-props.model';
import { ToastType } from '../../../models/toast/toast-type.model';
import { ToastModel } from '../../../models/toast/toast.model';
import { ToastService } from '../../../services/toasts/toast.service';

const defaultToastProps: ToastPropsModel = {
  id: 'default-toast',
  fade: true,
};

const useStyles = makeStyles({
  topToast: {
    top: '10px',
    position: 'fixed',
    width: '350px',
    height: '50px',
    zIndex: 50,
    textAlign: 'center',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

const ToastComponent = () => {
  const [toasts, setToasts] = useState<ToastModel[]>([]);
  const toastsRef = useRef(toasts);
  toastsRef.current = toasts;

  const removeToast = (toast: ToastModel) => {
    const remainingToasts = toastsRef.current.filter((t) => t.id !== toast.id);
    setToasts(remainingToasts);
  };

  useEffect(() => {
    const destroy$ = new Subject<void>();

    ToastService.onToast()
      .pipe(
        takeUntil(destroy$),
        tap((toast) => {
          // clear toast if no message received
          if (!toast.message) {
            const remainingToasts = toasts.filter(
              (currentToast) => currentToast.keepAfterRouteChange
            );

            remainingToasts.forEach(
              (remainingToast) => delete remainingToast.keepAfterRouteChange
            );

            setToasts(() => remainingToasts);
          }

          setToasts((t) => t.concat([toast]));
        }),
        delay(5000),
        tap((toast) => {
          if (toast.autoClose) {
            removeToast(toast);
          }
        })
      )
      .subscribe();

    return () => {
      destroy$.next();
      destroy$.complete();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getToast = (
    toastType: string,
    toastMessage: string,
    fade: boolean,
    uniqueKey: number
  ): JSX.Element => {
    switch (toastType) {
      case ToastType.success:
        return successToast(toastMessage, fade, uniqueKey);
      case ToastType.error:
        return errorToast(toastMessage, fade, uniqueKey);
      case ToastType.warning:
        return warningToast(toastMessage, fade, uniqueKey);
      case ToastType.info:
        return infoToast(toastMessage, fade, uniqueKey);
      default:
        return <></>;
    }
  };

  const successToast = (
    message: string,
    fade: boolean,
    uniqueKey: number
  ): JSX.Element => {
    return (
      <Alert key={uniqueKey} color="success" fade={fade}>
        {message}
      </Alert>
    );
  };

  const errorToast = (
    message: string,
    fade: boolean,
    uniqueKey: number
  ): JSX.Element => {
    return (
      <Alert key={uniqueKey} color="danger" fade={fade}>
        {message}
      </Alert>
    );
  };

  const warningToast = (
    message: string,
    fade: boolean,
    uniqueKey: number
  ): JSX.Element => {
    return (
      <Alert key={uniqueKey} color="warning" fade={fade}>
        <span className="alert-inner--icon">
          <i className="ni ni-like-2" />
        </span>{' '}
        <span className="alert-inner--text">
          <strong>Warning!</strong> {message}
        </span>
      </Alert>
    );
  };

  const infoToast = (
    message: string,
    fade: boolean,
    uniqueKey: number
  ): JSX.Element => {
    return (
      <Alert key={uniqueKey} color="info" fade={fade}>
        <span className="alert-inner--icon">
          <i className="ni ni-like-2" />
        </span>{' '}
        <span className="alert-inner--text">
          <strong>Info!</strong> {message}
        </span>
      </Alert>
    );
  };

  const styles = useStyles();

  return toasts.length > 0 ? (
    <div className={styles.topToast}>
      {toasts.map((toast, index) =>
        getToast(toast.type, toast.message ?? '', toast.fade ?? true, index)
      )}
    </div>
  ) : (
    <></>
  );
};

ToastComponent.defaultProps = defaultToastProps;

export default ToastComponent;
