export const TransactionType = {
  BankDeposit: 'BANK_DEPOSIT',
  BankWithdrawal: 'BANK_WITHDRAWAL',
  CardDeposit: 'CARD_DEPOSIT',
  CardWithdrawal: 'CARD_WITHDRAWAL',
  BoughtPropertyShares: 'BOUGHT_PROPERTY_SHARES',
  SoldPropertyShares: 'SOLD_PROPERTY_SHARES',
  PaidPropertyDividend: 'PAID_PROPERTY_DIVIDEND',
  StripeCardDeposit: 'STRIPE_CARD_DEPOSIT',
  StripeCardWithdrawal: 'STRIPE_CARD_WITHDRAWAL',
  DividendReceived: 'DIVIDEND_RECEIVED',
};
