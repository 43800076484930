import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import usePagination from '../../../hooks/use-pagination.hook';
import { PaginationProps } from '../../../models/table/pagination-props.model';
import { TablePaginationProps } from '../../../models/table/table-pagination-props';

const TablePaginationComponent = ({
  count,
  page,
  onPageChange,
  rowsPerPage,
  rowsPerPageOptions,
}: TablePaginationProps) => {
  const paginationProps: PaginationProps = {
    count,
    page,
    rowsPerPage,
    rowsPerPageOptions,
  };

  const pagination = usePagination(paginationProps);

  if (!pagination.showPages) {
    return <></>;
  }

  const onNext = () => {
    onPageChange(page + 1);
  };

  const onPrevious = () => {
    onPageChange(page - 1);
  };

  const getPrevious = (): JSX.Element => {
    return (
      <PaginationItem className={pagination.showPrevious ? '' : 'disabled'}>
        <PaginationLink onClick={onPrevious}>
          <i className="fa fa-angle-left" />
          <span className="sr-only">Previous</span>
        </PaginationLink>
      </PaginationItem>
    );
  };

  const getPagination = (): JSX.Element => {
    return (
      <>
        {pagination.pages.map((pageNumber: number) => {
          return (
            <PaginationItem
              key={pageNumber}
              className={page === pageNumber ? 'active' : ''}
            >
              <PaginationLink onClick={() => onPageChange(pageNumber)}>
                {pageNumber}
              </PaginationLink>
            </PaginationItem>
          );
        })}
      </>
    );
  };

  const getNext = (): JSX.Element => {
    return (
      <PaginationItem className={pagination.showNext ? '' : 'disabled'}>
        <PaginationLink onClick={onNext}>
          <i className="fa fa-angle-right" />
          <span className="sr-only">Next</span>
        </PaginationLink>
      </PaginationItem>
    );
  };

  return (
    <>
      <Pagination
        className="pagination justify-content-center"
        listClassName="justify-content-end"
      >
        {getPrevious()}
        {getPagination()}
        {getNext()}
      </Pagination>
    </>
  );
};

export default TablePaginationComponent;
