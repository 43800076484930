import { Theme } from '@mui/material';
import { PropertyStatusType } from '../constants/property.constants';
import {
  GYM_TYPE,
  HOSPITAL_TYPE,
  PARK_TYPE,
  PUBLIC_TRANSPORT_STATION_TYPE,
  PropertyAmenityType,
  RESTAURANT_TYPE,
  SCHOOL_TYPE,
  SUPERMARKET_TYPE,
} from '../constants/property-details/property-amenity-type.constants';

export const GetPropertyStatusTranslationId = (
  propertyStatus: string
): string => {
  switch (propertyStatus) {
    case PropertyStatusType.Financing.key:
      return PropertyStatusType.Financing.value;
    case PropertyStatusType.Financed.key:
      return PropertyStatusType.Financed.value;
    case PropertyStatusType.Suspended.key:
      return PropertyStatusType.Suspended.value;
    default:
      return '';
  }
};

export const GetPropertyStatusColor = (
  propertyStatus: string,
  theme: Theme
): string => {
  switch (propertyStatus) {
    case PropertyStatusType.Financing.key:
      return theme.palette.success.main;
    case PropertyStatusType.Financed.key:
      return theme.palette.info.main;
    case PropertyStatusType.Suspended.key:
      return theme.palette.secondary.dark;
    default:
      return theme.palette.primary.main;
  }
};

export const GetPropertyAmenityDefaultImage = (
  amenityType: PropertyAmenityType
): string => {
  switch (amenityType) {
    case RESTAURANT_TYPE:
      return 'https://as1.ftcdn.net/v2/jpg/04/10/84/56/1000_F_410845622_Ikv3NiJFSZodpjUJdqpbbBJqGJgKTOUc.jpg';
    case HOSPITAL_TYPE:
      return 'https://as1.ftcdn.net/v2/jpg/03/17/53/66/1000_F_317536689_18HQMpWnpnjyrUaJCWTCRxVLgxLa9Lx1.jpg';
    case SCHOOL_TYPE:
      return 'https://as2.ftcdn.net/v2/jpg/02/43/38/87/1000_F_243388799_tO67sbXtn47URdzMMVKvfiyMohxQYheI.jpg';
    case SUPERMARKET_TYPE:
      return 'https://as1.ftcdn.net/v2/jpg/01/12/73/74/1000_F_112737470_1GDKiI6Lky32Tqk9Jp0mrb7FvW8vYXOQ.jpg';
    case GYM_TYPE:
      return 'https://as1.ftcdn.net/v2/jpg/02/73/96/74/1000_F_273967497_nJw1XHa7q7IipcCLIapkUd6Ec9tZVoH8.jpg';
    case PARK_TYPE:
      return 'https://as1.ftcdn.net/v2/jpg/03/81/52/02/1000_F_381520294_Zl8FeUtaJ1UzyA6Ti9bb6IF1f8gEyKpN.jpg';
    case PUBLIC_TRANSPORT_STATION_TYPE:
      return 'https://as1.ftcdn.net/v2/jpg/01/71/82/86/1000_F_171828628_C2WkSTIXAuSptiZIQx6Km9DbGmEcws9z.jpg';
    default:
      return '';
  }
};
