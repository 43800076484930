import { Box, FormHelperText, TextFieldProps } from '@mui/material';
import { isNil } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import { FormInputTextProps } from '../../../models/common/form/form-input-text-props.model';
import TextFieldComponent from '../text-field/text-field.component';

const FormInputTextComponent = ({
  name,
  endAdornment,
  ...props
}: FormInputTextProps & TextFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <>
          <Box>
            <TextFieldComponent
              id={name}
              {...field}
              {...props}
              error={!!errors[name]}
              InputProps={{
                ...props?.InputProps,
                endAdornment: isNil(endAdornment)
                  ? props?.inputProps?.endAdornment
                  : endAdornment,
                sx: {
                  color: (theme) => theme.palette.text.primary,
                  fontWeight: 580,
                },
              }}
              InputLabelProps={{
                sx: {
                  color: (theme) => theme.palette.text.primary,
                  fontWeight: 580,
                },
              }}
            />
            <FormHelperText
              id={name}
              sx={{
                color: (theme) => theme.palette.error.main,
                fontWeight: 580,
              }}
            >
              {errors[name] ? (errors[name]?.message as unknown as string) : ''}
            </FormHelperText>
          </Box>
        </>
      )}
    />
  );
};

export default FormInputTextComponent;
