import { Box, Chip, Typography, useTheme } from '@mui/material';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ChipsProps } from '../../../models/common/chips/chips-props.model';

const ChipsComponent = ({
  chips,
  selectedChip,
  chipsDescription,
  wrapChips = true,
  chipsGap,
  centerChips = false,
  onChipClick,
}: ChipsProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box sx={{ width: '100%' }}>
      {!isNil(chipsDescription) && (
        <Typography
          color="text"
          fontSize="14px"
          paddingBottom={theme.spacing(0.5)}
        >
          {chipsDescription}
        </Typography>
      )}

      <Box
        sx={{
          width: '100%',
          overflowY: 'auto',
          overflow: wrapChips ? 'hidden' : 'auto',
          display: 'flex',
          flexWrap: wrapChips ? 'wrap' : 'nowrap',
          justifyContent: { xs: 'start', sm: centerChips ? 'center' : 'start' },
          alignItems: centerChips ? 'center' : 'start',
          paddingBottom: theme.spacing(1),
          paddingTop: theme.spacing(1),
          gap: isNil(chipsGap) ? theme.spacing(1) : chipsGap,
        }}
      >
        {chips.map((chip, index) => (
          <Chip
            key={index}
            label={t(chip.labelTranslationId)}
            onClick={() => onChipClick(chip)}
            variant={chip.id === selectedChip.id ? 'filled' : 'outlined'}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ChipsComponent;
