import { CardExpiryElement } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { FormStripeInputProps } from '../../../models/common/form/form-stripe-input-props.model';
import FormStripeInputComponent from '../forms/form-stripe-input.component';

const StripeExpiryComponent = (
  props: FormStripeInputProps<typeof CardExpiryElement>
) => {
  const { t } = useTranslation();

  return (
    <FormStripeInputComponent
      label={t('stripe.expiry.label')}
      stripeElement={CardExpiryElement}
      inputProps={{
        options: {
          placeholder: t('stripe.expiry.placeholder'),
        },
      }}
      {...props}
    />
  );
};

export default StripeExpiryComponent;
