import { OrderTableModel } from '../../models/order/order-table.model';
import ColumnDefinitionModel from '../../models/table/column-definition.model';
import { ColumnType } from './column-type.constants';

export const OrderColumnsDefinition: ColumnDefinitionModel<
  OrderTableModel,
  keyof OrderTableModel
>[] = [
  {
    key: 'id',
    headerTranslationId: 'history_page.orders_table.id',
    type: ColumnType.Text,
  },
  {
    key: 'propertyName',
    headerTranslationId: 'history_page.orders_table.property_name',
    type: ColumnType.Text,
  },
  {
    key: 'value',
    headerTranslationId: 'history_page.orders_table.order_value',
    type: ColumnType.Currency,
  },
  {
    key: 'quantity',
    headerTranslationId: 'history_page.orders_table.order_quantity',
    type: ColumnType.Text,
  },
  {
    key: 'direction',
    headerTranslationId: 'history_page.orders_table.order_type',
    type: ColumnType.Text,
  },
  {
    key: 'createdAt',
    headerTranslationId: 'history_page.orders_table.order_date',
    type: ColumnType.Date,
  },
];
