import { ColumnType } from '../../constants/table/column-type.constants';
import { ButtonOptionsModel } from '../../models/table/button-options.model';
import { ColumnOptions } from '../../models/table/column-definition.model';
import { CurrencyOptionsModel } from '../../models/table/currency-options.model';
import { DateOptionsModel } from '../../models/table/date-options.model';
import { ProgressBarOptionsModel } from '../../models/table/progress-bar-options.model';
import TableButtonComponent from '../../components/common/table/table-elements/table-button.component';
import TableImageComponent from '../../components/common/table/table-elements/table-image.component';
import { ImageOptionsModel } from '../../models/table/image-options.model';
import TableDateComponent from '../../components/common/table/table-elements/table-date.component';
import TableProgressBarComponent from '../../components/common/table/table-elements/table-progress-bar.component';
import TableCurrencyComponent from '../../components/common/table/table-elements/table-currency.component';
import { IconOptionsModel } from '../../models/table/icon-options.model';
import TableIconComponent from '../../components/common/table/table-elements/table-icon.component';

export const TableColumnProvider = {
  renderCustomElement(options: ColumnOptions, type: ColumnType): JSX.Element {
    switch (type) {
      case ColumnType.Button:
        return <TableButtonComponent options={options as ButtonOptionsModel} />;
      case ColumnType.Image:
        return <TableImageComponent options={options as ImageOptionsModel} />;
      case ColumnType.ProgressBar:
        return (
          <TableProgressBarComponent
            options={options as ProgressBarOptionsModel}
          />
        );
      case ColumnType.Date:
        return <TableDateComponent options={options as DateOptionsModel} />;
      case ColumnType.Currency:
        return (
          <TableCurrencyComponent options={options as CurrencyOptionsModel} />
        );
      case ColumnType.Icon:
        return <TableIconComponent options={options as IconOptionsModel} />;
      default:
        return <></>;
    }
  },
};
