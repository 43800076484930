import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Container, useTheme } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TypeOf } from 'zod';
import CardDepositSchema from '../../../../schemas/deposit/payment.schema';
import ButtonComponent from '../../../common/button/button.component';
import FormInputTextComponent from '../../../common/forms/form-input-text.component';

type ICardDepositForm = TypeOf<typeof CardDepositSchema>;

const CardDepositInputComponent = ({
  submitDepositAmount,
}: {
  submitDepositAmount: (amount: number) => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const defaultCardDepositFormValues: ICardDepositForm = {
    amount: '100',
  };

  const methods = useForm<ICardDepositForm>({
    resolver: zodResolver(CardDepositSchema),
    defaultValues: defaultCardDepositFormValues,
  });

  const submitForm: SubmitHandler<ICardDepositForm> = (
    cardDepositForm: ICardDepositForm
  ) => {
    submitDepositAmount(Number(cardDepositForm.amount));
  };
  return (
    <Box>
      <Container sx={{ height: '100%' }}>
        <FormProvider {...methods}>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={methods.handleSubmit(submitForm)}
            display="flex"
            flexDirection="column"
            paddingTop={theme.spacing(2)}
          >
            <FormInputTextComponent
              label={t('card_deposit.input.fields.amount')}
              type="text"
              name="amount"
              variant="filled"
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: theme.spacing(2),
              }}
            >
              <ButtonComponent
                sx={{
                  width: '200px',
                  height: '50px',
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
                type="submit"
              >
                {t('card_deposit.input.next_button')}
              </ButtonComponent>
            </Box>
          </Box>
        </FormProvider>
      </Container>
    </Box>
  );
};

export default CardDepositInputComponent;
