import { Box, FormHelperText } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { isNil } from 'lodash';
import moment, { Moment } from 'moment';
import { Controller, useFormContext } from 'react-hook-form';
import TextFieldComponent from '../text-field/text-field.component';

const FormCalendarComponent = ({
  name,
  label,
}: {
  name: string;
  label: string;
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { onChange, value } }) => (
        <>
          <Box>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label={label}
                renderInput={(params) => (
                  <TextFieldComponent
                    {...params}
                    error={!isNil(errors[name]?.message)}
                    variant="filled"
                  />
                )}
                value={value}
                onChange={(value) => onChange(moment(value as Moment).toDate())}
                inputFormat="DD.MM.YYYY"
                PopperProps={{
                  sx: {
                    backgroundColor: '#FFFFFF',
                    '& .Mui-selected': {
                      color: '#FFFFFF',
                    },
                  },
                }}
                DialogProps={{
                  sx: {
                    '& .Mui-selected': {
                      color: '#FFFFFF',
                    },
                    '& .MuiPaper-root': {
                      backgroundColor: '#FFFFFF',
                    },
                  },
                }}
              />
            </LocalizationProvider>
            <FormHelperText
              id={name}
              sx={{
                color: (theme) => theme.palette.error.main,
                fontWeight: 580,
              }}
            >
              {errors[name] ? (errors[name]?.message as unknown as string) : ''}
            </FormHelperText>
          </Box>
        </>
      )}
    />
  );
};

export default FormCalendarComponent;
