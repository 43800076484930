import { Box, Typography, useTheme } from '@mui/material';
import { t } from 'i18next';
import { ReactNode, useState } from 'react';
import { CurrencyFormatter } from '../../../../utilities/currency.utility';
import ButtonComponent from '../../../common/button/button.component';
import LoadingSpinnerComponent from '../../../common/loading-spinner/loading-spinner.component';

const InvestDialogPreviewComponent = ({
  investmentAmount,
  invest,
}: {
  investmentAmount: number;
  invest: () => void;
}) => {
  const theme = useTheme();

  const [isInvestmentAttempted, setIsInvestmentAttempted] = useState(false);
  const [investButtonSpinnerColor, setInvestButtonSpinnerColor] =
    useState('#FFFFFF');
  const createInvestment = () => {
    setIsInvestmentAttempted(true);
    invest();
  };

  const RenderPreviewRow = (
    title: string,
    value: string,
    bottomBorderWidth = 1
  ): ReactNode => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: { xs: 'center', sm: 'space-between' },
          alignItems: 'center',
          borderBottom: `${bottomBorderWidth}px solid ${theme.palette.secondary.dark}`,
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
          height: { xs: '55px', sm: '45px' },
        }}
      >
        <Typography
          sx={{
            fontSize: '12px',
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: theme.spacing(1),
          }}
        >
          <Typography
            color="primary"
            sx={{
              fontSize: '18px',
              fontWeight: 'bold',
            }}
          >
            {value}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          border: `1px solid ${theme.palette.secondary.dark}`,
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          width: { xs: '180px', sm: '360px' },
        }}
      >
        {RenderPreviewRow(
          t('invest_dialog.preview.investment_amount'),
          CurrencyFormatter(investmentAmount),
          0
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: theme.spacing(2),
        }}
      >
        <ButtonComponent
          sx={{
            width: { xs: '180px', sm: '200px' },
            height: '50px',
            fontSize: '12px',
            fontWeight: 'bold',
          }}
          onClick={createInvestment}
          onMouseEnter={() =>
            setInvestButtonSpinnerColor(theme.palette.primary.main)
          }
          onMouseLeave={() => setInvestButtonSpinnerColor('#FFFFFF')}
        >
          {isInvestmentAttempted ? (
            <LoadingSpinnerComponent spinnerColor={investButtonSpinnerColor} />
          ) : (
            t('invest_dialog.preview.invest_button')
          )}
        </ButtonComponent>
      </Box>
    </Box>
  );
};

export default InvestDialogPreviewComponent;
