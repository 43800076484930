import { DialogProps } from '../../../models/common/dialogs/dialog-props.model';
import DialogComponent from './dialog.component';
import FailureDialogContentComponent from './failure-dialog-content.component';

const FailureDialogComponent = ({
  handleClose,
  message,
  confirmButtonLabel,
}: {
  message: string;
  confirmButtonLabel: string;
} & DialogProps) => {
  return (
    <DialogComponent open={true} handleClose={handleClose} width={'360px'}>
      <FailureDialogContentComponent
        message={message}
        confirmButtonLabel={confirmButtonLabel}
        close={handleClose}
      />
    </DialogComponent>
  );
};

export default FailureDialogComponent;
