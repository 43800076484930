import { ReactNode, useEffect, useMemo, useState } from 'react';
import StripeService from '../../../services/payments/stripe/stripe.service';
import CardDepositCardSelectComponent from './deposit/card-deposit-card-select.component';
import CardDepositInputComponent from './deposit/card-deposit-input.component';
import * as Stripe from '@stripe/stripe-js';
import useObservable from '../../../hooks/use-observable.hook';
import LoadingComponent from '../../common/loading-spinner/loading.component';
import { Elements } from '@stripe/react-stripe-js';
import CardDepositNewCardPaymentComponent from './deposit/card-deposit-new-card-payment.component';
import CardDepositExistingCardPaymentComponent from './deposit/card-deposit-existing-card-payment.component';
import CardDepositFailedPaymentComponent from './deposit/card-deposit-failed-payment.component';
import CardDepositSuccessfulPaymentComponent from './deposit/card-deposit-successful-payment.component';
import { StepModel } from '../../../models/common/stepper/step.model';
import StepperComponent from '../../common/stepper/stepper.component';
import {
  DEPOSIT_STEPS,
  PaymentStatus,
} from '../../../constants/deposit/deposit-steps.constants';

const CardPaymentComponent = ({
  connectedAccountId,
  handleClose,
}: {
  connectedAccountId: string;
  handleClose: () => void;
}) => {
  const [paymentStatus, setPaymentStatus] = useState(PaymentStatus.InputAmount);
  const [depositAmount, setDepositAmount] = useState(0);
  const depositCurrency = 'bgn';

  const [paymentMethodId, setPaymentMethodId] = useState<string>('');
  const [lastFourDigits, setLastFourDigits] = useState<string>('');

  const goToSelectCard = () => {
    setPaymentStatus(PaymentStatus.SelectCard);
  };

  const goToNewCardPayment = () => {
    setPaymentStatus(PaymentStatus.NewCardPayment);
  };

  const goToExistingCardPayment = (
    paymentMethodId: string,
    lastFourDigits: string
  ) => {
    setPaymentMethodId(paymentMethodId);
    setLastFourDigits(lastFourDigits);
    setPaymentStatus(PaymentStatus.ExistingCardPayment);
  };

  const goToPaymentSuccessful = () => {
    setPaymentStatus(PaymentStatus.PaymentSuccessful);
  };

  const goToPaymentFailed = () => {
    setPaymentStatus(PaymentStatus.PaymentFailed);
  };

  const stripeConfiguration$ = useMemo(
    () => StripeService.GetConfig(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [stripeConfiguration, , isConfigurationLoading] =
    useObservable(stripeConfiguration$);

  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe.Stripe | null> | null>(null);

  useEffect(() => {
    if (!isConfigurationLoading) {
      setStripePromise(
        Stripe.loadStripe(stripeConfiguration?.publishableKey ?? '', {
          stripeAccount: connectedAccountId,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfigurationLoading]);

  const getPaymentView = (): ReactNode => {
    switch (paymentStatus) {
      case PaymentStatus.InputAmount:
        const submitDepositAmount = (amount: number) => {
          setDepositAmount(amount);
          goToSelectCard();
        };

        return (
          <CardDepositInputComponent
            submitDepositAmount={submitDepositAmount}
          />
        );
      case PaymentStatus.SelectCard:
        return (
          <CardDepositCardSelectComponent
            goToNewCardPayment={goToNewCardPayment}
            goToExistingCardPayment={goToExistingCardPayment}
          />
        );
      case PaymentStatus.NewCardPayment:
        return (
          <CardDepositNewCardPaymentComponent
            depositAmount={depositAmount}
            depositCurrency={depositCurrency}
            onPaymentFail={goToPaymentFailed}
            onPaymentSuccess={goToPaymentSuccessful}
          />
        );
      case PaymentStatus.ExistingCardPayment:
        return (
          <CardDepositExistingCardPaymentComponent
            paymentMethodId={paymentMethodId}
            lastFourDigits={lastFourDigits}
            depositAmount={depositAmount}
            depositCurrency={depositCurrency}
            onPaymentFail={goToPaymentFailed}
            onPaymentSuccess={goToPaymentSuccessful}
          />
        );
      case PaymentStatus.PaymentSuccessful:
        return (
          <CardDepositSuccessfulPaymentComponent
            close={handleClose}
            paymentAmount={depositAmount}
          />
        );
      case PaymentStatus.PaymentFailed:
        return <CardDepositFailedPaymentComponent close={handleClose} />;
      default:
        return <></>;
    }
  };

  const returnToStep = (step: StepModel) => {
    setPaymentStatus(step.id);
  };

  return (
    <>
      <StepperComponent
        steps={DEPOSIT_STEPS}
        nextTrigger={paymentStatus}
        returnToStep={returnToStep}
      />
      <LoadingComponent isLoading={isConfigurationLoading}>
        <Elements stripe={stripePromise}>{getPaymentView()}</Elements>
      </LoadingComponent>
    </>
  );
};

export default CardPaymentComponent;
