import { StepModel } from '../../models/common/stepper/step.model';

export enum WithdrawSteps {
  InputWithdrawAmount,
  SelectPaymentMethod,
  PreviewWithdrawRequest,
  SuccessfulWithdrawRequest,
  FailedWithdrawRequest,
}

export const WITHDRAWAL_STEPS: StepModel[] = [
  {
    id: WithdrawSteps.InputWithdrawAmount,
    labelId: 'withdraw_dialog.steps.input_amount',
  },
  {
    id: WithdrawSteps.SelectPaymentMethod,
    labelId: 'withdraw_dialog.steps.select_payment_method',
  },
  {
    id: WithdrawSteps.PreviewWithdrawRequest,
    labelId: 'withdraw_dialog.steps.preview_withdrawl',
  },
];
