import { isNil } from 'lodash';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { take } from 'rxjs';
import { OrderDirection } from '../../../constants/order-direction.constants';
import { DialogProps } from '../../../models/common/dialogs/dialog-props.model';
import { OrderModel } from '../../../models/order/order.model';
import { OrderService } from '../../../services/orders/order.service';
import SharedDataService from '../../../services/shared-data/shared-data.service';
import DialogComponent from '../../common/dialog/dialog.component';
import InvestDialogAmountInputComponent from './invest-dialog-steps/invest-dialog-amount-input.component';
import InvestDialogPreviewComponent from './invest-dialog-steps/invest-dialog-preview.component';
import { CurrencyFormatter } from '../../../utilities/currency.utility';
import SuccessDialogContentComponent from '../../common/dialog/success-dialog-content.component';
import FailureDialogContentComponent from '../../common/dialog/failure-dialog-content.component';

enum InvestStatus {
  InputAmount,
  PreviewInvestment,
  SuccessfulInvestment,
  FailedInvestment,
}

const InvestDialogComponent = ({
  open,
  customer,
  handleClose,
  propertyId,
}: DialogProps & { propertyId: number }) => {
  const { t } = useTranslation();

  const [investStatus, setInvestStatus] = useState(InvestStatus.InputAmount);
  const [investmentAmount, setInvestmentAmount] = useState(0);

  const goToPreview = () => {
    setInvestStatus(InvestStatus.PreviewInvestment);
  };

  const goToSuccessfulInvestment = () => {
    setInvestStatus(InvestStatus.SuccessfulInvestment);
  };

  const goToFailedInvestment = () => {
    setInvestStatus(InvestStatus.FailedInvestment);
  };

  const onInvestmentSet = (investmentAmount: number) => {
    setInvestmentAmount(investmentAmount);
    goToPreview();
  };

  const createInvestmentOrder = () => {
    if (isNil(customer)) {
      return;
    }

    OrderService.PlaceValueOrder(
      propertyId,
      investmentAmount,
      OrderDirection.Buy
    )
      .pipe(take(1))
      .subscribe(
        (order: OrderModel | null) => {
          if (isNil(order)) {
            goToFailedInvestment();
          } else {
            SharedDataService.TriggerUpdatePortfolioMetricsEvent();
            SharedDataService.TriggerUpdateCustomerInformationEvent();
            SharedDataService.TriggerUpdatePropertyEvent();
            goToSuccessfulInvestment();
          }
        },
        () => goToFailedInvestment()
      );
  };

  const getTitle = (): string => {
    switch (investStatus) {
      case InvestStatus.InputAmount:
        return t('invest_dialog.input.title');
      case InvestStatus.PreviewInvestment:
        return t('invest_dialog.preview.title');
      case InvestStatus.SuccessfulInvestment:
      case InvestStatus.FailedInvestment:
      default:
        return '';
    }
  };

  const renderInvestmentView = (): ReactNode => {
    switch (investStatus) {
      case InvestStatus.InputAmount:
        return (
          <InvestDialogAmountInputComponent
            customerCash={customer?.cash ?? 0}
            setInvestmentAmount={onInvestmentSet}
          />
        );
      case InvestStatus.PreviewInvestment:
        return (
          <InvestDialogPreviewComponent
            investmentAmount={investmentAmount}
            invest={createInvestmentOrder}
          />
        );
      case InvestStatus.SuccessfulInvestment:
        const message = `${t(
          'invest_dialog.successful_investment.description'
        )} ${CurrencyFormatter(investmentAmount)}`;
        const confirmButtonLabel = t(
          'invest_dialog.successful_investment.close_button'
        );
        return (
          <SuccessDialogContentComponent
            message={message}
            confirmButtonLabel={confirmButtonLabel}
            close={handleClose}
          />
        );
      case InvestStatus.FailedInvestment:
        const failedInvestmentMessage = t(
          'invest_dialog.failed_investment.description'
        );
        const failedInvestmentConfirmButtonLabel = t(
          'invest_dialog.failed_investment.close_button'
        );
        return (
          <FailureDialogContentComponent
            message={failedInvestmentMessage}
            confirmButtonLabel={failedInvestmentConfirmButtonLabel}
            close={handleClose}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <DialogComponent
      open={open}
      handleClose={handleClose}
      dialogTitle={getTitle()}
      width={{ sm: '400px', md: '500px' }}
    >
      {renderInvestmentView()}
    </DialogComponent>
  );
};

export default InvestDialogComponent;
