import { Box, Paper, useTheme } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PROPERTY_DETAILS_TABS from '../../constants/property-details/property-details-tabs.constants';
import { PropertyDetailsTabType } from '../../enums/property-details/property-details-tab-type.enum';
import { PropertyModel } from '../../models/property/property.model';
import TabComponent from '../common/tabs/tab.component';
import PropertyDetailsAmenitiesTabComponent from './property-details-tabs/property-details-amenities-tab.component';
import PropertyDetailsCommentsTabComponent from './property-details-tabs/property-details-comments-tab.component';
import PropertyDetailsDividendsTabComponent from './property-details-tabs/property-details-dividends-tab.component';
import PropertyDetailsHistoryTabComponent from './property-details-tabs/property-details-history-tab.component';
import PropertyDetailsInformationTabComponent from './property-details-tabs/property-details-information-tab.component';
import PropertyDetailsLocationTabComponent from './property-details-tabs/property-details-location-tab.component';
import HorizontalMenuComponent from '../common/horizontal-menu/horizontal-menu.component';

const PropertyDetailsTabsComponent = ({
  property,
}: {
  property: PropertyModel;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState(
    PropertyDetailsTabType.Information
  );

  const renderTab = (): JSX.Element => {
    switch (selectedTab) {
      case PropertyDetailsTabType.Information:
        return <PropertyDetailsInformationTabComponent property={property} />;
      case PropertyDetailsTabType.Comments:
        return <PropertyDetailsCommentsTabComponent property={property} />;
      case PropertyDetailsTabType.Location:
        return <PropertyDetailsLocationTabComponent property={property} />;
      case PropertyDetailsTabType.Amenities:
        return <PropertyDetailsAmenitiesTabComponent property={property} />;
      case PropertyDetailsTabType.Dividends:
        return <PropertyDetailsDividendsTabComponent property={property} />;
      case PropertyDetailsTabType.History:
        return <PropertyDetailsHistoryTabComponent property={property} />;
      default:
        return <></>;
    }
  };

  const renderTabMenuItem = (
    tab: {
      nameTranslationId: string;
      type: PropertyDetailsTabType;
    },
    tabIndex: number
  ): JSX.Element => {
    return (
      <Box
        sx={{
          marginRight:
            tab.type == PropertyDetailsTabType.History ? 0 : theme.spacing(1),
          minWidth: '160px',
        }}
      >
        <TabComponent
          name={t(tab.nameTranslationId)}
          tabType={tab.type}
          index={tabIndex}
          selectedTabType={selectedTab}
          key={tabIndex}
        />
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box id={'property_details_tabs'}>
        <HorizontalMenuComponent
          items={PROPERTY_DETAILS_TABS}
          renderItem={renderTabMenuItem}
          onItemClick={(item) => setSelectedTab(item.type)}
        />
      </Box>

      <Paper elevation={2}>{renderTab()}</Paper>
    </Box>
  );
};

export default PropertyDetailsTabsComponent;
