import { t } from 'i18next';

const StripeErrorCode = {
  invalidCardNumber: 'invalid_number',
  invalidExpiryYearPast: 'invalid_expiry_year_past',
};

export const GetStripeErrorMessage = (errorCode: string): string => {
  switch (errorCode) {
    case StripeErrorCode.invalidCardNumber:
      return t('errors_stripe.invalid_card_number');
    case StripeErrorCode.invalidExpiryYearPast:
      return t('errors_stripe.invalid_expiry_year_past');
    default:
      return '';
  }
};
