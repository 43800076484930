import { Observable } from 'rxjs';
import { DividendDistributionEventByProperty } from '../../models/dividend/dividend-distribution-event-by-property.model';
import ApiProvider from '../../providers/api/api.provider';

const DividendsService = {
  GetDividendDistributionEventsByProperty(
    propertyId: number
  ): Observable<DividendDistributionEventByProperty[]> {
    const queryParams = {
      propertyId: propertyId,
    };
    return ApiProvider.get<DividendDistributionEventByProperty[]>(
      '/dividends/events',
      queryParams
    );
  },
};

export default DividendsService;
