import { createContext } from 'react';
import { CustomerModel } from '../../models/customer/customer.model';

const CustomerContext = createContext<{
  customer: CustomerModel | undefined;
  isCustomerLoading: boolean;
}>({ customer: undefined, isCustomerLoading: false });

export const CustomerProvider = CustomerContext.Provider;
export default CustomerContext;
