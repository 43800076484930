import { useEffect, useState } from 'react';
import { Observable } from 'rxjs';

const useObservable = <T>(
  observable: Observable<T>
): [T | undefined, Error | undefined, boolean] => {
  const [value, setValue] = useState<T>();
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const subscription = observable.subscribe({
      next: (value: T) => {
        setValue(value);
        setLoading(false);
      },
      error: (error: Error) => {
        setError(error);
        setLoading(false);
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [observable]);

  return [value, error, loading];
};

export default useObservable;
