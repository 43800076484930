import { DialogProps } from '../../../models/common/dialogs/dialog-props.model';
import DialogComponent from './dialog.component';
import SuccessDialogContentComponent from './success-dialog-content.component';

const SuccessDialogComponent = ({
  handleClose,
  message,
  confirmButtonLabel,
}: {
  message: string;
  confirmButtonLabel: string;
} & DialogProps) => {
  return (
    <DialogComponent open={true} handleClose={handleClose} width={'360px'}>
      <SuccessDialogContentComponent
        message={message}
        confirmButtonLabel={confirmButtonLabel}
        close={handleClose}
      />
    </DialogComponent>
  );
};

export default SuccessDialogComponent;
