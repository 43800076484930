import { ThemeOptions } from '@mui/material';
export const themeOptions: ThemeOptions = {
  palette: {
    background: {
      default: '',
      paper: '',
    },
    text: {
      primary: '#3d3d3d',
      secondary: '',
      disabled: '',
    },
    primary: {
      main: '#423f96',
      light: '',
      dark: '',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#e0e2e5',
      light: '',
      dark: '#707070',
      contrastText: '',
    },
    error: {
      main: '#dc143c',
      light: '',
      dark: '',
      contrastText: '',
    },
    warning: {
      main: '#876a41',
      light: '',
      dark: '',
      contrastText: '',
    },
    info: {
      main: '#1e96fc',
      light: '',
      dark: '',
      contrastText: '',
    },
    success: {
      main: '#6da34d',
      light: '',
      dark: '',
      contrastText: '',
    },
  },
  typography: {
    fontFamily: ['Manrope', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(
      ','
    ),
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        outlined: ({ theme }) => ({
          backgroundColor: 'transparent',
          border: `1px solid ${theme.palette.primary.main}`,
          color: theme.palette.primary.main,
          transition: 'all .2s ease',
          borderRadius: '8px',
          '&: hover': {
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: '#FFFFFF',
          },
        }),
        filled: ({ theme }) => ({
          backgroundColor: theme.palette.primary.main,
          border: `1px solid ${theme.palette.primary.main}`,
          color: '#FFFFFF',
          borderRadius: '8px',
          '&: hover': {
            backgroundColor: `${theme.palette.primary.main} !important`,
          },
        }),
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: '#707070 #707070',
          '&::-webkit-scrollbar': {
            backgroundColor: '#e0e2e5',
            width: '5px',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: '#707070',
          },
          '&::-webkit-scrollbar-thumb:focus': {
            backgroundColor: '#423f96',
          },
          '&::-webkit-scrollbar-thumb:active': {
            backgroundColor: '#423f96',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#423f96',
          },
          '&::-webkit-scrollbar-corner': {
            backgroundColor: '#707070',
          },
        },
      },
    },
  },
};
