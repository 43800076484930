import { object, string } from 'zod';
import i18n from '../../translations/i18n';

const CustomerProfileChangePasswordSchema = object({
  oldPassword: string()
    .regex(
      new RegExp('.*[A-Z].*'),
      i18n.t('reset_password.errors.password_uppercase_letter')
    )
    .regex(
      new RegExp('.*[a-z].*'),
      i18n.t('reset_password.errors.password_lowercase_letter')
    )
    .regex(
      new RegExp('.*\\d.*'),
      i18n.t('reset_password.errors.password_number')
    )
    .min(8, i18n.t('reset_password.errors.password_min_length')),
  newPassword: string()
    .regex(
      new RegExp('.*[A-Z].*'),
      i18n.t('reset_password.errors.password_uppercase_letter')
    )
    .regex(
      new RegExp('.*[a-z].*'),
      i18n.t('reset_password.errors.password_lowercase_letter')
    )
    .regex(
      new RegExp('.*\\d.*'),
      i18n.t('reset_password.errors.password_number')
    )
    .min(8, i18n.t('reset_password.errors.password_min_length')),
  confirmNewPassword: string().min(
    8,
    i18n.t('reset_password.errors.confirm_password_min_length')
  ),
}).superRefine(({ newPassword, confirmNewPassword }, ctx) => {
  if (newPassword !== confirmNewPassword) {
    ctx.addIssue({
      code: 'custom',
      message: i18n.t('reset_password.errors.confirm_password_not_match'),
      path: ['confirmNewPassword'],
    });
  }
});

export default CustomerProfileChangePasswordSchema;
