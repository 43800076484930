import { Box, useTheme } from '@mui/material';
import { isNil } from 'lodash';
import { StripeAcceptableVerificationDocument } from '../../models/payments/stripe/stripe-acceptable-verification-document.model';
import ButtonComponent from '../common/button/button.component';
import DropzoneComponent from '../common/dropzone/dropzone.component';
import VerificationAcceptableDocuments from './verification-acceptable-documents.component';

const VerificationDropzoneComponent = ({
  acceptableDocumentsDescription,
  acceptableDocuments,
  buttonActionText,
  buttonAction,
  setFile,
  idKey,
}: {
  acceptableDocumentsDescription?: string;
  acceptableDocuments?: StripeAcceptableVerificationDocument[];
  buttonActionText?: string;
  buttonAction?: () => void;
  setFile: (file: File) => void;
  idKey: string;
}) => {
  const theme = useTheme();

  const dropzoneSetFiles = (files: File[]) => {
    if (files.length === 0) {
      return;
    }

    const file = files[0];
    setFile(file);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
      }}
      key={idKey}
    >
      {!isNil(acceptableDocuments) && acceptableDocuments.length > 0 && (
        <VerificationAcceptableDocuments
          acceptableDocuments={acceptableDocuments}
          acceptableDocumentsDescription={acceptableDocumentsDescription ?? ''}
        />
      )}
      <DropzoneComponent maxFiles={1} setFiles={dropzoneSetFiles} />
      {!isNil(buttonActionText) && !isNil(buttonAction) && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ButtonComponent
            onClick={buttonAction}
            sx={{
              width: '200px',
              height: '50px',
            }}
          >
            {buttonActionText}
          </ButtonComponent>
        </Box>
      )}
    </Box>
  );
};

export default VerificationDropzoneComponent;
