import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { ReactNode, useState } from 'react';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
import { CustomerModel } from '../../../models/customer/customer.model';

const BankDepositComponent = ({ customer }: { customer: CustomerModel }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [companyName] = useState('Ландсера България АД');
  const [companyIBAN] = useState('BG55STSA93001527562860');
  const [companyBankBIC] = useState('STSABGSF');

  const [copySuccessfulText] = useState('Copied');
  const [copySuccessfulIndex, setCopySuccessfulIndex] = useState(-1);

  const copyValueToClipboard = (value: string, index: number) => {
    copy(value);

    setCopySuccessfulIndex(index);

    setTimeout(() => setCopySuccessfulIndex(-1), 2000);
  };

  const getCopySuccessfulTooltipText = (index: number): string => {
    return index == copySuccessfulIndex ? copySuccessfulText : '';
  };

  const BankDepositInformation = (
    title: string,
    value: string,
    index: number,
    bottomBorderWidth = 1
  ): ReactNode => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: { xs: 'center', sm: 'space-between' },
          alignItems: 'center',
          borderBottom: `${bottomBorderWidth}px solid ${theme.palette.secondary.dark}`,
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
          height: { xs: '55px', sm: '45px' },
        }}
      >
        <Typography
          sx={{
            fontSize: '12px',
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: theme.spacing(1),
          }}
        >
          <Typography
            color="primary"
            sx={{
              fontSize: '14px',
              fontWeight: 'bold',
            }}
          >
            {value}
          </Typography>

          <Box
            sx={{
              backgroundColor: theme.palette.primary.main,
              width: '24px',
              height: '24px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '5px',
            }}
          >
            <Tooltip
              title={getCopySuccessfulTooltipText(index)}
              placement="top"
              arrow
              disableFocusListener
              disableHoverListener
              disableTouchListener
              open={true}
            >
              <ContentCopyOutlinedIcon
                sx={{
                  color: '#FFFFFF',
                  fontSize: '16px',
                  cursor: 'pointer',
                }}
                onClick={() => copyValueToClipboard(value, index)}
              />
            </Tooltip>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          border: `1px solid ${theme.palette.secondary.dark}`,
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {BankDepositInformation(t('bank_deposit.recipient'), companyName, 1)}
        {BankDepositInformation(t('bank_deposit.iban'), companyIBAN, 2)}
        {BankDepositInformation(
          t('bank_deposit.reference'),
          isNil(customer) ? 'Loading Reference...' : customer.id.toString(),
          3
        )}
        {BankDepositInformation(
          t('bank_deposit.bic_code'),
          companyBankBIC,
          4,
          0
        )}
      </Box>

      <Typography
        color="primary"
        fontSize="12px"
        textAlign="center"
        fontWeight="600"
        sx={{
          paddingTop: theme.spacing(2),
        }}
      >
        {t('bank_deposit.tax_warning')}
      </Typography>
    </Box>
  );
};

export default BankDepositComponent;
