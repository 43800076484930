import { Box } from '@mui/material';
import { RouteProps } from 'react-router-dom';

const ImageBottomTextGradientComponent = ({ children }: RouteProps) => {
  return (
    <Box
      sx={{
        background:
          'linear-gradient(0deg, rgba(0,0,0,0.8547794117647058) 0%, rgba(131,129,134,0) 65%, rgba(197,196,198,0) 100%)',
        height: '100%',
        width: '100%',
        borderRadius: 'inherit',
      }}
    >
      {children}
    </Box>
  );
};

export default ImageBottomTextGradientComponent;
