import { PaymentMethodModel } from '../payment-methods/payment-method.model';

export type DepositType = 'BANK' | 'CARD';

export const DepositTypeBank = 'BANK';
export const DepositTypeCard = 'CARD';

export type DepositModel = {
  id: number;
  customerId: number;
  amount: number;
  createdAt: Date;
  paymentMethod: PaymentMethodModel;
};
