import { ProgressBarOptionsModel } from '../../../../models/table/progress-bar-options.model';
import ProgressBarComponent from '../../progress-bar/progress-bar.component';

const TableProressBarComponent = ({
  options,
}: {
  options: ProgressBarOptionsModel;
}) => {
  return <ProgressBarComponent min={0} max={100} value={options.value} />;
};

export default TableProressBarComponent;
