import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Container, useTheme } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TypeOf } from 'zod';
import CommentSchema from '../../../schemas/comment-section/comment.schema';
import ButtonComponent from '../button/button.component';
import FormInputTextComponent from '../forms/form-input-text.component';
import CommentAvatarComponent from './comment-avatar.component';

type ICommentForm = TypeOf<typeof CommentSchema>;

const CommentTextareaComponent = ({
  addComment,
  avatarAbbreviation,
  isReply,
  maxLength = 2048,
}: {
  addComment: (content: string) => void;
  avatarAbbreviation: string;
  isReply: boolean;
  maxLength: number;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const defaultCommentFormValues: ICommentForm = {
    content: '',
  };

  const methods = useForm<ICommentForm>({
    resolver: zodResolver(CommentSchema),
    defaultValues: defaultCommentFormValues,
  });

  const submitForm: SubmitHandler<ICommentForm> = (
    commentForm: ICommentForm
  ) => {
    addComment(commentForm.content);
    methods.reset();
  };

  return (
    <Box>
      <Container
        sx={{
          height: '100%',
          padding: '0px !important',
          maxWidth: '100% !important',
        }}
      >
        <FormProvider {...methods}>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={methods.handleSubmit(submitForm)}
            display="flex"
            flexDirection="column"
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: theme.spacing(1),
              }}
            >
              <CommentAvatarComponent abbreviation={avatarAbbreviation} />
              <Box
                sx={{
                  width: '100%',
                }}
              >
                <FormInputTextComponent
                  label={
                    isReply
                      ? t('comment_section.comment.input_label_reply')
                      : t('comment_section.comment.input_label_comment')
                  }
                  type="text"
                  name="content"
                  variant="filled"
                  placeholder={
                    isReply
                      ? t('comment_section.comment.input_placeholder_reply')
                      : t('comment_section.comment.input_placeholder_comment')
                  }
                  multiline
                  inputProps={{
                    maxLength: maxLength,
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                paddingTop: theme.spacing(1),
              }}
            >
              <ButtonComponent
                sx={{
                  width: { xs: '100%', sm: '200px' },
                  height: '50px',
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
                type="submit"
              >
                {isReply
                  ? t('comment_section.comment.reply_button')
                  : t('comment_section.comment.comment_button')}
              </ButtonComponent>
            </Box>
          </Box>
        </FormProvider>
      </Container>
    </Box>
  );
};

export default CommentTextareaComponent;
