import { Tooltip } from '@mui/material';
import { IconOptionsModel } from '../../../../models/table/icon-options.model';

const TableIconComponent = ({ options }: { options: IconOptionsModel }) => {
  return (
    <Tooltip title={options.tooltipLabel ?? ''}>
      <options.Icon sx={{ color: options.color ?? 'primary' }} />
    </Tooltip>
  );
};

export default TableIconComponent;
