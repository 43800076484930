export enum NotifiableEventConstants {
  BoughtPropertyShares = 'BOUGHT_PROPERTY_SHARES',
  SoldPropertyShares = 'SOLD_PROPERTY_SHARES',
  DividendReceived = 'DIVIDEND_RECEIVED',
  CardFundSuccess = 'CARD_FUND_SUCCESS',
  BankFundSuccess = 'BANK_FUND_SUCCESS',
  CardWithdrawSuccess = 'CARD_WITHDRAW_SUCCESS',
  BankWithdrawSuccess = 'BANK_WITHDRAW_SUCCESS',
}

export type NotifiableEvent =
  | 'BOUGHT_PROPERTY_SHARES'
  | 'SOLD_PROPERTY_SHARES'
  | 'DIVIDEND_RECEIVED'
  | 'CARD_FUND_SUCCESS'
  | 'BANK_FUND_SUCCESS'
  | 'CARD_WITHDRAW_SUCCESS'
  | 'BANK_WITHDRAW_SUCCESS';
