import { Observable } from 'rxjs';
import { MerchantCategoryCodeModel } from '../../models/merchant-category-code/merchant-category-code.model';
import ApiProvider from '../../providers/api/api.provider';

const MerchantCategoryCodeService = {
  GetAll(): Observable<MerchantCategoryCodeModel[]> {
    return ApiProvider.get<MerchantCategoryCodeModel[]>(
      '/merchant-category-codes'
    );
  },
};

export default MerchantCategoryCodeService;
