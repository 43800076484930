import { isNil } from 'lodash';
import { LocalStorageItems } from '../constants/local-storage.constants';
import { User } from '../models/user/user.model';

export const AuthenticationHeader = () => {
  const isUserAuthenticated = IsUserAuthenticated();
  if (isUserAuthenticated) {
    const token = GetCurrentToken();

    if (isNil(token)) {
      return {};
    }

    return { Authorization: token };
  } else {
    return {};
  }
};

export const GetCurrentToken = (): string | null => {
  return localStorage.getItem(LocalStorageItems.AuthenticationToken);
};

export const GetCurrentTokenExpiryDate = (): number | null => {
  const tokenExpiryDate = localStorage.getItem(
    LocalStorageItems.AuthenticationTokenExpiryDate
  );

  if (!isNil(tokenExpiryDate)) {
    return parseInt(tokenExpiryDate);
  }

  return null;
};

export const IsUserAuthenticated = (): boolean => {
  const token = GetCurrentToken();
  const tokenExpiryDate = GetCurrentTokenExpiryDate();

  return (
    !isNil(token) && !isNil(tokenExpiryDate) && tokenExpiryDate > Date.now()
  );
};

export const GetAuthenticatedUser = (): User | null => {
  const localUser = localStorage.getItem(LocalStorageItems.AuthenticationUser);

  if (isNil(localUser)) {
    return null;
  }

  return JSON.parse(localUser);
};
