import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PropertyModel } from '../../../models/property/property.model';
import InformationFieldComponent from '../../common/information-field/information-field.component';
import MapComponent from '../../common/map/map.component';

const PropertyDetailsLocationTabComponent = ({
  property,
}: {
  property: PropertyModel;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const centerPosition: [number, number] = [
    property.latitude,
    property.longitude,
  ];

  const zoomLevel = 17;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: theme.spacing(2),
          gap: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            color="primary"
            sx={{
              fontSize: '24px',
              fontWeight: 'bold',
            }}
          >
            {t('property_details.location_tab.title')}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            gap: theme.spacing(2),
          }}
        >
          <InformationFieldComponent
            fieldLabel={t('property_details.location_tab.property_country')}
            fieldValue={'България'}
          />
          <InformationFieldComponent
            fieldLabel={t('property_details.location_tab.property_region')}
            fieldValue={property.province.name}
          />
          <InformationFieldComponent
            fieldLabel={t('property_details.location_tab.property_city')}
            fieldValue={property.city.name}
          />
          <InformationFieldComponent
            fieldLabel={t('property_details.location_tab.property_district')}
            fieldValue={property.district.name}
          />
          <InformationFieldComponent
            fieldLabel={t('property_details.location_tab.address')}
            fieldValue={property.address}
          />
          <InformationFieldComponent
            fieldLabel={t('property_details.location_tab.floor')}
            fieldValue={property.floor.toString()}
          />
        </Box>

        <MapComponent
          centerPosition={centerPosition}
          zoomLevel={zoomLevel}
          schrollWheelZoom={true}
          markerLabel={property.name}
          mapHeight="700px"
        />
      </Box>
    </>
  );
};

export default PropertyDetailsLocationTabComponent;
