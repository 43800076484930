import { t } from 'i18next';
import { object, string } from 'zod';
import { MAX_DEPOSIT_AMOUNT } from '../../constants/deposit/deposit-steps.constants';
import { CurrencyFormatter } from '../../utilities/currency.utility';

const CardDepositSchema = object({
  amount: string()
    .min(1, t('card_deposit.input.errors.min_amount'))
    .regex(
      /^[1-9]+[0-9]*$/i,
      t('card_deposit.input.errors.amount_number_validation')
    ),
}).superRefine(({ amount }, ctx) => {
  if (MAX_DEPOSIT_AMOUNT < +amount) {
    ctx.addIssue({
      code: 'custom',
      message: `${t(
        'card_deposit.input.errors.max_amount'
      )} ${CurrencyFormatter(MAX_DEPOSIT_AMOUNT)}`,
      path: ['amount'],
    });
  }

  /* This is the way to handle also numbers like 10.5 but the backend currently does not support it. Once we implement it on there
  we can remove this comment and also remove regex from the upper configuration
  if (!/^[+]?\d+([.]\d+)?$/.test(amount)) {
    ctx.addIssue({
      code: 'custom',
      message: t('card_deposit.input.errors.amount_number_validation'),
      path: ['amount'],
    });
  }*/
});

export default CardDepositSchema;
