import { TableBody, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TableCellComponent from './table-cell.component';

const TableEmptyRowsComponent = () => {
  const { t } = useTranslation();

  return (
    <TableBody>
      <TableRow>
        <TableCellComponent sx={{ textAlign: 'left !important' }}>
          <Typography
            sx={{
              fontSize: '18px',
            }}
          >
            {t('information.no_records')}
          </Typography>
        </TableCellComponent>
      </TableRow>
    </TableBody>
  );
};

export default TableEmptyRowsComponent;
