import { useContext, useMemo, useState } from 'react';
import { combineLatest, map, of } from 'rxjs';
import { WithdrawalColumnsDefinition } from '../../../constants/table/withdrawal-columns.constants';
import useObservable from '../../../hooks/use-observable.hook';
import { WithdrawalTableModel } from '../../../models/withdrawal/withdrawal-table.model';
import { WithdrawalModel } from '../../../models/withdrawal/withdrawal.model';
import { WithdrawalService } from '../../../services/withdrawals/withdrawal.service';
import {
  GetExecutionStrategy,
  GetWithdrawalStatus,
} from '../../../utilities/withdrawal.utility';
import TableComponent from '../../common/table/table.component';
import CustomerContext from '../../../providers/customer/customer.provider';
import { isNil } from 'lodash';
import { GetCustomerId } from '../../../utilities/customer.utility';
import { FormatCardLastFour } from '../../../utilities/payment-methods.utility';
import { PaymentMethodType } from '../../../models/payment-methods/payment-method.model';

const HistoryWithdrawalsTabComponent = () => {
  const columns = WithdrawalColumnsDefinition;

  const rowsPerPage = 10;
  const [page, setPage] = useState(1);

  const { customer } = useContext(CustomerContext);

  const getTableWithdrawal = (
    withdrawal: WithdrawalModel
  ): WithdrawalTableModel => {
    const tableModel: WithdrawalTableModel = {
      id: withdrawal.id,
      status: GetWithdrawalStatus(withdrawal.status),
      amount: {
        value: withdrawal.amount,
      },
      createdAt: {
        value: withdrawal.createdAt,
      },
      executionStrategy: GetExecutionStrategy(withdrawal.executionStrategy),
      paymentMethodSubMethod: withdrawal.paymentMethod.subMethod,
      paymentMethodDescription:
        withdrawal.paymentMethod.type === PaymentMethodType.Card
          ? FormatCardLastFour(withdrawal.paymentMethod.description)
          : withdrawal.paymentMethod.description,
    };

    return tableModel;
  };

  const withdrawals$ = useMemo(
    () => {
      if (isNil(customer)) {
        return of([]);
      }

      const customerId = GetCustomerId(customer);

      const executedWithdrawals = WithdrawalService.GetExecutedWithdrawals(
        customerId,
        page,
        rowsPerPage
      );

      const failedWithdrawals = WithdrawalService.GetFailedWithdrawals(
        customerId,
        page,
        rowsPerPage
      );

      return combineLatest([executedWithdrawals, failedWithdrawals]).pipe(
        map((lists) => {
          const arrayToReturn = [];
          for (const list of lists) {
            arrayToReturn.push(...list);
          }

          return arrayToReturn;
        }),
        map<WithdrawalModel[], WithdrawalTableModel[]>(
          (withdrawals: WithdrawalModel[]) => {
            return withdrawals.map((withdrawal) => {
              return getTableWithdrawal(withdrawal);
            });
          }
        ),
        map((withdrawals) =>
          withdrawals.sort((withdrawal) => withdrawal.id).reverse()
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, customer]
  );

  const [withdrawals, , loading] = useObservable(withdrawals$);

  const getWithdrawals = (): WithdrawalTableModel[] => {
    return withdrawals ?? [];
  };

  return (
    <TableComponent
      data={getWithdrawals()}
      columns={columns}
      totalRows={getWithdrawals().length}
      loading={loading}
      onPageChange={setPage}
      rowsPerPage={rowsPerPage}
    />
  );
};

export default HistoryWithdrawalsTabComponent;
