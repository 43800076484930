import { Box, Typography, useTheme } from '@mui/material';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import DefaultProfilePicture from '../../assets/img/icons/default-profile-picture.png';
import { CustomerModel } from '../../models/customer/customer.model';

const CustomerProfileSummaryComponent = ({
  customer,
}: {
  customer: CustomerModel;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: '200px',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography
        color="primary"
        sx={{
          fontSize: '22px',
          fontWeight: 'bold',
          paddingBottom: theme.spacing(2),
        }}
      >
        {t('customer_profile_summary.title')}
      </Typography>
      <Box
        component="img"
        src={DefaultProfilePicture}
        sx={{
          width: '140px',
          height: '140px',
          objectFit: 'contain',
          backgroundColor: theme.palette.primary.main,
          borderRadius: '10px',
        }}
      ></Box>

      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: theme.spacing(2),
          }}
        >
          <Typography
            color="primary"
            sx={{
              fontSize: '22px',
              fontWeight: 'bold',
            }}
          >
            {`${customer.firstName} ${customer.lastName}`}
          </Typography>
          <Typography
            color="primary"
            sx={{
              fontSize: '16px',
            }}
          >
            {isNil(customer.city)
              ? t('country.bulgaria')
              : `${customer.city} ${t('country.bulgaria')}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerProfileSummaryComponent;
