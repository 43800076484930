import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const ButtonOutlinedComponent = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: 'transparent',
  borderRadius: '8px',
  border: `solid 2px ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  fontSize: '12px',
  fontWeight: 'bold',
  transition: 'all .2s ease',
  '&: hover': {
    backgroundColor: theme.palette.primary.main,
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
}));

export default ButtonOutlinedComponent;
