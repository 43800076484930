import { Box, Typography, useTheme } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../../../common/button/button.component';

const CardDepositFailedPaymentComponent = ({
  close,
}: {
  close: () => void;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(2),
        paddingTop: theme.spacing(2),
      }}
    >
      <Box>
        <CancelOutlinedIcon
          sx={{
            fontSize: '100px',
            color: theme.palette.error.main,
          }}
        />
      </Box>

      <Box>
        <Typography fontSize="14px" textAlign="center">
          {t('card_deposit.payment_failed.description')}
        </Typography>
      </Box>

      <ButtonComponent
        sx={{
          width: '200px',
          height: '50px',
        }}
        onClick={close}
      >
        {t('card_deposit.payment_failed.close_button')}
      </ButtonComponent>
    </Box>
  );
};

export default CardDepositFailedPaymentComponent;
