export type StripeProofOfAddressDocumentsVerificationStatusCodeType =
  | 'DOCUMENT_CORRUPT'
  | 'DOCUMENT_COUNTRY_NOT_SUPPORTED'
  | 'DOCUMENT_EXPIRED'
  | 'DOCUMENT_FAILED_COPY'
  | 'DOCUMENT_FAILED_OTHER'
  | 'DOCUMENT_FAILED_TEST_MODE'
  | 'DOCUMENT_FRAUDULENT'
  | 'DOCUMENT_FAILED_GREYSCALE'
  | 'DOCUMENT_INCOMPLETE'
  | 'DOCUMENT_INVALID'
  | 'DOCUMENT_MANIPULATED'
  | 'DOCUMENT_MISSING_BACK'
  | 'DOCUMENT_MISSING_FRONT'
  | 'DOCUMENT_NOT_READABLE'
  | 'DOCUMENT_NOT_UPLOADED'
  | 'DOCUMENT_PHOTO_MISMATCH'
  | 'DOCUMENT_TOO_LARGE'
  | 'DOCUMENT_TYPE_NOT_SUPPORTED'
  | 'SUCCESS';

export const StripeProofOfAddressDocumentsVerificationStatusCode = {
  DocumentCorrupt: 'DOCUMENT_CORRUPT',
  DocumentCountryNotSupported: 'DOCUMENT_COUNTRY_NOT_SUPPORTED',
  DocumentExpired: 'DOCUMENT_EXPIRED',
  DocumentFailedCopy: 'DOCUMENT_FAILED_COPY',
  DocumentFailedOther: 'DOCUMENT_FAILED_OTHER',
  DocumentFailedTestMode: 'DOCUMENT_FAILED_TEST_MODE',
  DocumentFraudulent: 'DOCUMENT_FRAUDULENT',
  DocumentFailedGreyscale: 'DOCUMENT_FAILED_GREYSCALE',
  DocumentIncomplete: 'DOCUMENT_INCOMPLETE',
  DocumentInvalid: 'DOCUMENT_INVALID',
  DocumentManipulated: 'DOCUMENT_MANIPULATED',
  DocumentMissingBack: 'DOCUMENT_MISSING_BACK',
  DocumentMissingFront: 'DOCUMENT_MISSING_FRONT',
  DocumentNotReadable: 'DOCUMENT_NOT_READABLE',
  DocumentNotUploaded: 'DOCUMENT_NOT_UPLOADED',
  DocumentPhotoMismatch: 'DOCUMENT_PHOTO_MISMATCH',
  DocumentTooLarge: 'DOCUMENT_TOO_LARGE',
  DocumentTypeNotSupported: 'DOCUMENT_TYPE_NOT_SUPPORTED',
  Success: 'SUCCESS',
};
