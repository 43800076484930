import { useContext, useMemo, useState } from 'react';
import { map, of } from 'rxjs';
import { TransactionColumnsDefinition } from '../../../constants/table/transaction-columns.constants';
import useObservable from '../../../hooks/use-observable.hook';
import { TransactionTableModel } from '../../../models/transaction/transaction-table.model';
import { TransactionModel } from '../../../models/transaction/transaction.model';
import { GetTransactionType } from '../../../utilities/transactions.utility';
import TableComponent from '../../common/table/table.component';
import CustomerService from '../../../services/customer/customer.service';
import CustomerContext from '../../../providers/customer/customer.provider';
import { GetCustomerId } from '../../../utilities/customer.utility';
import { isNil } from 'lodash';

const HistoryTransactionsTabComponent = () => {
  const columns = TransactionColumnsDefinition;

  const { customer } = useContext(CustomerContext);

  const rowsPerPage = 10;
  const [page, setPage] = useState(1);

  const getTableTransaction = (
    transaction: TransactionModel
  ): TransactionTableModel => {
    const tableModel: TransactionTableModel = {
      id: transaction.id,
      amount: {
        value: transaction.amount,
      },
      transactionType: GetTransactionType(transaction.type),
      createdAt: {
        value: new Date(transaction.createdAt),
      },
    };

    return tableModel;
  };

  const transactions$ = useMemo(
    () => {
      if (isNil(customer)) {
        return of([]);
      }

      return CustomerService.GetTransactions(
        GetCustomerId(customer),
        page,
        rowsPerPage
      ).pipe(
        map<TransactionModel[], TransactionTableModel[]>(
          (transactions: TransactionModel[]) => {
            return transactions.map((transaction) => {
              return getTableTransaction(transaction);
            });
          }
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, customer]
  );

  const [transactions, , loading] = useObservable(transactions$);

  const getTransactions = (): TransactionTableModel[] => {
    return transactions ?? [];
  };

  return (
    <TableComponent
      data={getTransactions()}
      columns={columns}
      totalRows={getTransactions().length}
      loading={loading}
      onPageChange={setPage}
      rowsPerPage={rowsPerPage}
    />
  );
};

export default HistoryTransactionsTabComponent;
