import { Box, Typography, useTheme } from '@mui/material';
import { StripeAcceptableVerificationDocument } from '../../models/payments/stripe/stripe-acceptable-verification-document.model';
import ButtonComponent from '../common/button/button.component';
import VerificationAcceptableDocuments from './verification-acceptable-documents.component';
import VerificationDropzoneComponent from './verification-dropzone.component';
import { SyntheticEvent } from 'react';
import AccordionSummaryComponent from '../common/accordion/accordion-summary.component';
import AccordionComponent from '../common/accordion/accordion.component';
import AccordionDetailsComponent from '../common/accordion/accordion-details.component';

const VerificationFileSelectionComponent = ({
  setDocumentBack,
  setDocumentFront,
  acceptableDocuments,
  acceptableDocumentsDescription,
  nextButtonAction,
  nextButtonLabel,
  expandedPanel,
  expandedPanelFront,
  expandedPanelBack,
  setExpandedPanel,
  documentBackName,
  documentFrontName,
  documentBackLabel,
  documentFrontLabel,
}: {
  setDocumentBack: (file: File) => void;
  setDocumentFront: (file: File) => void;
  acceptableDocuments: StripeAcceptableVerificationDocument[];
  acceptableDocumentsDescription: string;
  nextButtonAction: () => void;
  nextButtonLabel: string;
  expandedPanel: string;
  expandedPanelFront: string;
  expandedPanelBack: string;
  setExpandedPanel: (expandedPanel: string) => void;
  documentBackName: string;
  documentFrontName: string;
  documentBackLabel: string;
  documentFrontLabel: string;
}) => {
  const theme = useTheme();

  const handleChange =
    (panel: string) =>
    (_event: SyntheticEvent<Element, Event>, isExpanded: boolean) => {
      setExpandedPanel(isExpanded ? panel : '');
    };

  const setFrontFile = (file: File) => {
    setDocumentFront(file);
    setExpandedPanel(expandedPanelBack);
  };

  const setBackFile = (file: File) => {
    setDocumentBack(file);
    setExpandedPanel('');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
      }}
    >
      <VerificationAcceptableDocuments
        acceptableDocuments={acceptableDocuments}
        acceptableDocumentsDescription={acceptableDocumentsDescription}
      />

      <AccordionComponent
        expanded={expandedPanel === expandedPanelFront}
        onChange={handleChange(expandedPanelFront)}
        elevation={3}
      >
        <AccordionSummaryComponent
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography fontSize="18px" fontWeight="bold" color="primary">
              {documentFrontLabel}
            </Typography>
            <Typography
              color="primary"
              fontSize="14px"
              paddingLeft={theme.spacing(2)}
            >
              {documentFrontName}
            </Typography>
          </Box>
        </AccordionSummaryComponent>
        <AccordionDetailsComponent>
          <VerificationDropzoneComponent
            setFile={setFrontFile}
            key={'documentFront'}
            idKey={'documentFront'}
          />
        </AccordionDetailsComponent>
      </AccordionComponent>

      <AccordionComponent
        expanded={expandedPanel === expandedPanelBack}
        onChange={handleChange(expandedPanelBack)}
      >
        <AccordionSummaryComponent>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography fontSize="18px" fontWeight="bold" color="primary">
              {documentBackLabel}
            </Typography>
            <Typography
              color="primary"
              fontSize="14px"
              paddingLeft={theme.spacing(2)}
            >
              {documentBackName}
            </Typography>
          </Box>
        </AccordionSummaryComponent>
        <AccordionDetailsComponent>
          <VerificationDropzoneComponent
            setFile={setBackFile}
            key={'documentBack'}
            idKey={'documentBack'}
          />
        </AccordionDetailsComponent>
      </AccordionComponent>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <ButtonComponent
          onClick={nextButtonAction}
          sx={{
            width: '200px',
            height: '50px',
          }}
        >
          {nextButtonLabel}
        </ButtonComponent>
      </Box>
    </Box>
  );
};

export default VerificationFileSelectionComponent;
