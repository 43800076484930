import { t } from 'i18next';
import { CurrencyOptionsModel } from '../models/table/currency-options.model';

export const CurrencyFormatter = (
  value: number,
  options?: CurrencyOptionsModel
): string => {
  const locale = options?.locale ?? 'hu';
  const currency = options?.currency ?? 'BGN';
  const minimumFractionDigits = options?.minimumFractionDigits ?? 0;
  const maximumFractionDigits = options?.maximumFractionDigits ?? 2;
  const showZero = options?.showZero ?? true;

  if (value === 0 && !showZero) {
    return '';
  } else {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits,
    })
      .format(value)
      .replace(',', '.') // quick fix for consistency
      .replace('BGN', t('currency.bgn.text'));
  }
};

export const GetCurrencyDisplay = (currency: string) => {
  switch (currency) {
    case 'BGN':
      return t('currency.bgn.sign');
    default:
      return '';
  }
};
