import { InputAdornment, Tooltip } from '@mui/material';
import { CardCvcElement } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { FormStripeInputProps } from '../../../models/common/form/form-stripe-input-props.model';
import FormStripeInputComponent from '../forms/form-stripe-input.component';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

const StripeCvcComponent = (
  props: FormStripeInputProps<typeof CardCvcElement>
) => {
  const { t } = useTranslation();

  return (
    <FormStripeInputComponent
      label={t('stripe.cvc.label')}
      stripeElement={CardCvcElement}
      endAdornment={
        <InputAdornment position="end">
          <Tooltip title={t('stripe.cvc.tooltip')}>
            <HelpOutlineOutlinedIcon />
          </Tooltip>
        </InputAdornment>
      }
      inputProps={{
        options: {
          placeholder: t('stripe.cvc.placeholder'),
        },
      }}
      {...props}
    />
  );
};

export default StripeCvcComponent;
