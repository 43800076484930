import { t } from 'i18next';

export const timeAgo = (timeInMileSec: number) => {
  const seconds = parseInt((timeInMileSec / 1000).toFixed(0));
  const minutes = parseInt((timeInMileSec / (1000 * 60)).toFixed(0));
  const hours = parseInt((timeInMileSec / (1000 * 60 * 60)).toFixed(0));
  const days = parseInt((timeInMileSec / (1000 * 60 * 60 * 24)).toFixed(0));
  const weeks = parseInt(
    (timeInMileSec / (1000 * 60 * 60 * 24 * 7)).toFixed(0)
  );
  const months = parseInt(
    (timeInMileSec / (1000 * 60 * 60 * 24 * 31)).toFixed(0)
  );
  const years = parseInt(
    (timeInMileSec / (1000 * 60 * 60 * 24 * 12)).toFixed(0)
  );

  if (seconds < 60) {
    return `${seconds} ${t('time.seconds')}`;
  } else if (minutes < 60) {
    if (minutes === 1) {
      return `${minutes} ${t('time.minute')}`;
    }
    return `${minutes} ${t('time.minutes')}`;
  } else if (hours < 24) {
    if (hours === 1) {
      return `${hours} ${t('time.hour')}`;
    }
    return `${hours} ${t('time.hours')}`;
  } else if (days < 7) {
    if (days === 1) {
      return `${days} ${t('time.day')}`;
    }
    return `${days} ${t('time.days')}`;
  } else if (weeks < 4) {
    if (weeks === 1) {
      return `${weeks} ${t('time.week')}`;
    }
    return `${weeks} ${t('time.weeks')}`;
  } else if (months < 12) {
    if (months === 1) {
      return `${months} ${t('time.month')}`;
    }
    return `${months} ${t('time.months')}`;
  } else {
    if (years === 1) {
      return `${years} ${t('time.year')}`;
    }
    return `${years} ${t('time.years')}`;
  }
};
