import { LanguageSettingsModel } from '../../models/settings/language-settings.model';

export const SUPPORTED_LANGUAGES: LanguageSettingsModel[] = [
  {
    display: 'English',
    id: 'en',
  },
  {
    display: 'Български',
    id: 'bg',
  },
];
