import { Box, Typography, useTheme } from '@mui/material';

const InformationFieldComponent = ({
  fieldLabel,
  fieldValue,
}: {
  fieldLabel: string;
  fieldValue: string;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(0.5),
      }}
    >
      <Typography
        sx={{
          fontSize: { md: '18px', lg: '20px', xl: '22px' },
          fontWeight: 'bold',
          color: theme.palette.primary.main,
        }}
      >
        {fieldLabel}
      </Typography>

      <Typography
        sx={{
          fontSize: { md: '18px', lg: '20px', xl: '22px' },
          color: theme.palette.secondary.dark,
        }}
      >
        {fieldValue}
      </Typography>
    </Box>
  );
};

export default InformationFieldComponent;
