export type PortfolioValue = {
  date: Date;
  value: number;
};

export class ChartPortfolioData {
  snapshotDate: string;
  portfolioValue: number;

  constructor(snapshotDate: string, portfolioValue: number) {
    this.snapshotDate = snapshotDate;
    this.portfolioValue = portfolioValue;
  }
}
