import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import ImageGradientComponent from '../common/gradient/image-gradient.component';
import Logo from '../../assets/img/icons/landsera-blue-logo.png';
import ForgotPasswordCover from '../../assets/img/brand/forgot-password-cover.jpg';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import FormInputTextComponent from '../common/forms/form-input-text.component';
import ButtonComponent from '../common/button/button.component';
import { isNil } from 'lodash';
import ResetPasswordSchema from '../../schemas/authentication/reset-password.schema';
import { TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import CustomerService from '../../services/customer/customer.service';
import { take } from 'rxjs';
import { RoutesConstants } from '../../constants/routes.constants';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import PasswordTooltipComponent from './password-tooltip.component';
import { DialogType } from '../../services/dialog-manager/dialog-manager.service';
import withDialogs from '../../providers/dialog/with-dialog';
import LoadingSpinnerComponent from '../common/loading-spinner/loading-spinner.component';
import { DialogManagerProps } from '../../models/common/dialogs/dialog-manager-props.model';
import SuccessDialogComponent from '../common/dialog/success-dialog.component';
import FailureDialogComponent from '../common/dialog/failure-dialog.component';
type IResetPasswordForm = TypeOf<typeof ResetPasswordSchema>;

const ResetPasswordComponent = ({
  dialogManagerService,
}: DialogManagerProps) => {
  const TOKEN_SEARCH_PARAM = 'token';

  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [formErrorMessage, setFormErrorMessage] = useState('');

  const [searchParams] = useSearchParams();

  const [resetPasswordCode, setResetPasswordCode] = useState<string | null>(
    null
  );
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);
  const [isResetPasswordExecuting, setIsResetPasswordExecuting] =
    useState(false);
  const [resetPasswordButtonSpinnerColor, setResetPasswordButtonSpinnerColor] =
    useState('#FFFFFF');

  const defaultResetPasswordFormValues: IResetPasswordForm = {
    password: '',
    confirmPassword: '',
  };

  const methods = useForm<IResetPasswordForm>({
    resolver: zodResolver(ResetPasswordSchema),
    defaultValues: defaultResetPasswordFormValues,
  });

  useEffect(() => {
    setResetPasswordCode(searchParams.get(TOKEN_SEARCH_PARAM));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const onLoginRedirectClick = () => {
    navigate(RoutesConstants.Login);
  };

  const onResetPassowrdClick: SubmitHandler<IResetPasswordForm> = (
    resetPasswordParameters: IResetPasswordForm
  ) => {
    if (!isNil(resetPasswordCode)) {
      setFormErrorMessage('');
      setIsResetPasswordExecuting(true);
      CustomerService.UpdatePasswordWithToken(
        resetPasswordParameters.password,
        resetPasswordCode
      )
        .pipe(take(1))
        .subscribe({
          complete: () => openResetPasswordSuccessfulDialog(),
          error: () => openResetPasswordFailureDialog(),
        });
    } else {
      setFormErrorMessage(t('reset_password.errors.missing_code'));
    }
  };

  const openResetPasswordSuccessfulDialog = () => {
    const message = t('password_reset_successful.message');
    const confirmButtonLabel = t('password_reset_successful.confirmation');

    const resetPasswordSuccessfulDialog: DialogType = {
      component: SuccessDialogComponent,
      props: {
        handleClose: () => navigate(RoutesConstants.Login),
        message,
        confirmButtonLabel,
      },
    };

    dialogManagerService.Open(resetPasswordSuccessfulDialog);
  };

  const openResetPasswordFailureDialog = () => {
    const message = t('password_reset_failure.message');
    const confirmButtonLabel = t('password_reset_failure.confirmation');

    const resetPasswordFailureDialog: DialogType = {
      component: FailureDialogComponent,
      props: {
        message,
        confirmButtonLabel,
      },
    };

    dialogManagerService.Open(resetPasswordFailureDialog);
  };

  return (
    <>
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        <Box
          sx={{
            flex: 1,
            minWidth: '370px',
            height: '100%',
          }}
        >
          <Container sx={{ height: '100%' }}>
            <FormProvider {...methods}>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: theme.spacing(10),
                  }}
                >
                  <Box
                    component="img"
                    src={Logo}
                    sx={{
                      height: '70px',
                      width: '340px',
                      objectFit: 'contain',
                    }}
                  ></Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    component="form"
                    noValidate
                    autoComplete="off"
                    onSubmit={methods.handleSubmit(onResetPassowrdClick)}
                    sx={{
                      width: '75%',
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          width: '100%',
                          height: '70px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography
                          color="primary"
                          sx={{ fontSize: '24px', fontWeight: 'bold' }}
                        >
                          {t('reset_password.title')}
                        </Typography>
                      </Box>

                      <Tooltip
                        title={<PasswordTooltipComponent />}
                        placement="top-start"
                      >
                        <Box
                          sx={{
                            marginBottom: theme.spacing(2),
                          }}
                        >
                          <FormInputTextComponent
                            label={t('reset_password.fields.password')}
                            type={isPasswordVisible ? 'text' : 'password'}
                            name="password"
                            variant="filled"
                            autoComplete="on"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onMouseDown={(event) => {
                                    event.preventDefault();
                                    setIsPasswordVisible(true);
                                  }}
                                  onMouseUp={(event) => {
                                    event.preventDefault();
                                    setIsPasswordVisible(false);
                                  }}
                                  edge="end"
                                  tabIndex={-1}
                                >
                                  {isPasswordVisible ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            sx={{
                              width: '50px',
                            }}
                          />
                        </Box>
                      </Tooltip>

                      <Box
                        sx={{
                          marginBottom: (theme) => theme.spacing(2),
                        }}
                      >
                        <FormInputTextComponent
                          label={t('reset_password.fields.confirm_password')}
                          type={isConfirmPasswordVisible ? 'text' : 'password'}
                          name="confirmPassword"
                          variant="filled"
                          autoComplete="on"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onMouseDown={(event) => {
                                  event.preventDefault();
                                  setIsConfirmPasswordVisible(true);
                                }}
                                onMouseUp={(event) => {
                                  event.preventDefault();
                                  setIsConfirmPasswordVisible(false);
                                }}
                                edge="end"
                                tabIndex={-1}
                              >
                                {isConfirmPasswordVisible ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          sx={{
                            width: '50px',
                          }}
                        />
                      </Box>

                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          marginTop: theme.spacing(3),
                        }}
                      >
                        <ButtonComponent
                          sx={{
                            width: '220px',
                            height: '50px',
                            fontSize: '18px',
                          }}
                          type="submit"
                          onMouseEnter={() =>
                            setResetPasswordButtonSpinnerColor(
                              theme.palette.primary.main
                            )
                          }
                          onMouseLeave={() =>
                            setResetPasswordButtonSpinnerColor('#FFFFFF')
                          }
                        >
                          {isResetPasswordExecuting ? (
                            <LoadingSpinnerComponent
                              spinnerColor={resetPasswordButtonSpinnerColor}
                            />
                          ) : (
                            t('reset_password.reset_password_button')
                          )}
                        </ButtonComponent>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                          marginTop: theme.spacing(2),
                        }}
                      >
                        {!isNil(formErrorMessage) && (
                          <Typography color="error">
                            {formErrorMessage}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: theme.spacing(5),
                  }}
                >
                  <Typography>
                    {t('reset_password.already_registered')}
                  </Typography>

                  <Typography
                    color="primary"
                    sx={{
                      fontWeight: 'bold',
                      cursor: 'pointer',
                      marginLeft: theme.spacing(0.5),
                    }}
                    onClick={onLoginRedirectClick}
                  >
                    {t('reset_password.login_redirect')}
                  </Typography>
                </Box>
              </Box>
            </FormProvider>
          </Container>
        </Box>

        <Box
          sx={{
            backgroundImage: `url(${ForgotPasswordCover})`,
            backgroundSize: 'cover',
            backgroundPosition: 'right',
            flex: 1.8,
            minWidth: '340px',
            '@media (max-width: 800px)': {
              display: 'none',
            },
          }}
        >
          <ImageGradientComponent />
        </Box>
      </Box>
    </>
  );
};

const WrappedResetPasswordComponent = withDialogs(ResetPasswordComponent);
export default WrappedResetPasswordComponent;
