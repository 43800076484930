import { useState } from 'react';
import { RouteProps } from 'react-router-dom';
import { DialogManagerService } from '../../services/dialog-manager/dialog-manager.service';
import DialogWrapper from './dialog-wrapper';
import DialogContext from './dialog.context';

const DialogProvider = (props: RouteProps) => {
  const [dialogManagerService] = useState(new DialogManagerService());
  return (
    <DialogContext.Provider
      value={{ dialogManagerService: dialogManagerService }}
    >
      <DialogWrapper dialogManagerService={dialogManagerService} />
      {props.children}
    </DialogContext.Provider>
  );
};

export default DialogProvider;
