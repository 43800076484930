import { ButtonOptionsModel } from '../../../../models/table/button-options.model';
import ButtonComponent from '../../button/button.component';

const TableButtonComponent = ({ options }: { options: ButtonOptionsModel }) => {
  return (
    <ButtonComponent
      color="primary"
      type="button"
      onClick={options.onClick}
      sx={{
        width: '160px',
        height: '50px',
      }}
    >
      {options.name}
    </ButtonComponent>
  );
};

export default TableButtonComponent;
