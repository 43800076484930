// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./manrope-v13-latin_cyrillic-regular.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./manrope-v13-latin_cyrillic-regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./manrope-v13-latin_cyrillic-regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./manrope-v13-latin_cyrillic-regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./manrope-v13-latin_cyrillic-regular.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "#Manrope" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'Manrope';\n  font-style: normal;\n  font-weight: 400;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); /* IE9 Compat Modes */\n  src: local(''),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")\n      format('embedded-opentype'),\n    /* IE6-IE8 */ url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")\n      format('woff2'),\n    /* Super Modern Browsers */ url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")\n      format('woff'),\n    /* Modern Browsers */ url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")\n      format('truetype'),\n    /* Safari, Android, iOS */\n      url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format('svg'); /* Legacy iOS */\n}\n", "",{"version":3,"sources":["webpack://./src/assets/fonts/manrope-font.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,4CAAoD,EAAE,qBAAqB;EAC3E;;;;;;;;;;2DAUyE,EAAE,eAAe;AAC5F","sourcesContent":["@font-face {\n  font-family: 'Manrope';\n  font-style: normal;\n  font-weight: 400;\n  src: url('./manrope-v13-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */\n  src: local(''),\n    url('./manrope-v13-latin_cyrillic-regular.eot?#iefix')\n      format('embedded-opentype'),\n    /* IE6-IE8 */ url('./manrope-v13-latin_cyrillic-regular.woff2')\n      format('woff2'),\n    /* Super Modern Browsers */ url('./manrope-v13-latin_cyrillic-regular.woff')\n      format('woff'),\n    /* Modern Browsers */ url('./manrope-v13-latin_cyrillic-regular.ttf')\n      format('truetype'),\n    /* Safari, Android, iOS */\n      url('./manrope-v13-latin_cyrillic-regular.svg#Manrope') format('svg'); /* Legacy iOS */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
