import { Box, Typography, useTheme } from '@mui/material';
import { ProgressBarProps } from '../../../models/common/progress-bar-props.model';
import { Line } from 'rc-progress';
import { useEffect, useState } from 'react';
import { CurrencyFormatter } from '../../../utilities/currency.utility';
import { isNil } from 'lodash';
import { RoundDecimal } from '../../../utilities/number.utility';

const ProgressBarComponent = ({
  min,
  max,
  value,
  percentageLabel,
  remainingLabel,
  color,
  strokeWidth = 8,
  trailWidth = 8,
  labelColor,
}: ProgressBarProps) => {
  const [progressPercentage, setProgressPercentage] = useState(0);

  const theme = useTheme();

  useEffect(() => {
    const range = max - min;
    const correctedStartValue = value - min;
    const percentage = (correctedStartValue * 100) / range;

    setProgressPercentage(percentage);
  }, [min, max, value]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Line
          percent={progressPercentage}
          strokeWidth={strokeWidth}
          strokeColor={color ?? theme.palette.primary.main}
          trailWidth={trailWidth}
          strokeLinecap="butt"
          style={{
            borderRadius: '5px',
            width: '100%',
          }}
        />

        {(!isNil(percentageLabel) || !isNil(remainingLabel)) && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: (theme) => theme.spacing(0.2),
            }}
          >
            <Typography
              sx={{
                textAlign: 'start',
                fontSize: '14px',
                fontWeight: 'bold',
                color: labelColor ?? theme.palette.secondary.dark,
              }}
            >
              {RoundDecimal(progressPercentage).toFixed(2)}
              {percentageLabel}
            </Typography>

            <Typography
              sx={{
                textAlign: 'end',
                fontSize: '14px',
                fontWeight: 'bold',
                color: labelColor ?? theme.palette.secondary.dark,
              }}
            >
              {CurrencyFormatter(Math.ceil(max - value))}
              {` ${remainingLabel}`}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ProgressBarComponent;
