import { Box } from '@mui/material';
import { useMemo, useState } from 'react';
import useObservable from '../../hooks/use-observable.hook';
import { PropertyModel } from '../../models/property/property.model';
import { PropertyService } from '../../services/properties/property.service';
import CardPropertyComponent from '../common/card/card-property.component';
import GridComponent from '../common/grid/grid.component';

const PropertiesComponent = () => {
  const propertiesPerPage = 15;
  const [page, setPage] = useState(1);

  const properties$ = useMemo(
    () => PropertyService.GetProperties(page, propertiesPerPage),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page]
  );

  const [properties, , loading] = useObservable(properties$);

  return (
    <Box
      sx={{
        padding: (theme) => theme.spacing(4),
      }}
    >
      <GridComponent
        style={{ height: '100%' }}
        loading={loading}
        onPageChange={setPage}
        totalElements={5}
        elementsPerPage={propertiesPerPage}
        elementsPerPageOptions={[]}
      >
        {properties?.map((property: PropertyModel, propertyIndex: number) => {
          return (
            <CardPropertyComponent
              key={propertyIndex}
              property={property}
            ></CardPropertyComponent>
          );
        }) ?? <></>}
      </GridComponent>
    </Box>
  );
};

export default PropertiesComponent;
