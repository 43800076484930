import { GridProps } from '../../../models/common/grid-props.model';
import { useState } from 'react';
import TablePaginationComponent from '../table/table-pagination.component';
import EmptyStateComponent from '../empty-state/empty-state.component';
import LoadingComponent from '../loading-spinner/loading.component';
import { Box } from '@mui/material';

const GridComponent = ({
  children,
  loading,
  totalElements,
  elementsPerPage = 15,
  elementsPerPageOptions = [15],
  onPageChange,
}: GridProps) => {
  const [currentPage, setCurrentPage] = useState(1);

  const onGridPageChange = (page: number) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  return (
    <LoadingComponent isLoading={loading}>
      {totalElements === 0 ? (
        <EmptyStateComponent />
      ) : (
        <>
          <Box
            sx={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: {
                xs: 'repeat(auto-fit, minmax(350px, 1fr))',
                sm: 'repeat(auto-fit, minmax(350px, 1fr))',
                md: 'repeat(auto-fit, minmax(350px, 1fr))',
              },
              gap: (theme) => theme.spacing(4),
              justifyContent: 'center',
            }}
          >
            {children}
          </Box>

          <TablePaginationComponent
            count={totalElements}
            page={currentPage}
            onPageChange={onGridPageChange}
            rowsPerPage={elementsPerPage}
            rowsPerPageOptions={elementsPerPageOptions}
          />
        </>
      )}
    </LoadingComponent>
  );
};

export default GridComponent;
