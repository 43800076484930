import { Typography } from '@mui/material';
import { CurrencyOptionsModel } from '../../../../models/table/currency-options.model';
import { CurrencyFormatter } from '../../../../utilities/currency.utility';

const TableCurrencyComponent = ({
  options,
}: {
  options: CurrencyOptionsModel;
}) => {
  return <Typography>{CurrencyFormatter(options.value, options)}</Typography>;
};

export default TableCurrencyComponent;
