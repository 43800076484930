import { Box, Divider, Typography, useTheme } from '@mui/material';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart, Pie, Cell, ResponsiveContainer, Sector } from 'recharts';
import { CustomerBalanceProps } from '../../models/customer-balance/customer-balance-props.model';
import CustomerContext from '../../providers/customer/customer.provider';
import PortfolioMetricsContext from '../../providers/portfolio-metrics/portfolio-metrics.provider';
import { CurrencyFormatter } from '../../utilities/currency.utility';
import LoadingComponent from '../common/loading-spinner/loading.component';
import { isNil } from 'lodash';

const CustomerBalanceComponent = ({
  chartInnerRadius = 100,
  chartOuterRadius = 120,
  isTitleVisible = true,
}: CustomerBalanceProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [chartData, setChartData] = useState<
    {
      name: string;
      value: number;
    }[]
  >([]);

  const theme = useTheme();
  const { t } = useTranslation();

  const { customer, isCustomerLoading } = useContext(CustomerContext);
  const { portfolioMetrics, arePortfolioMetricsLoading } = useContext(
    PortfolioMetricsContext
  );

  const [colors, setColors] = useState<string[]>([
    theme.palette.success.main,
    theme.palette.primary.main,
  ]);

  useEffect(() => {
    if (!isNil(customer) && !isNil(portfolioMetrics)) {
      const data = [
        {
          name: t('customer_balance.available'),
          value: customer.cash === 0 ? 1e-10 : customer.cash,
        },
        {
          name: t('customer_balance.invested'),
          value: portfolioMetrics.investedCash,
        },
      ];

      if (customer.cash + portfolioMetrics.investedCash === 0) {
        setColors([theme.palette.secondary.dark, theme.palette.primary.main]);
      }

      setChartData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, portfolioMetrics]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderActiveShape = (props: any): any => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text
          fontFamily="Manrope,Roboto,Helvetica,Arial,sans-serif"
          fontWeight="400"
          fontSize="1rem"
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fill={fill}
        >
          {payload.name}
        </text>

        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />

        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />

        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />

        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />

        <text
          fontFamily="Manrope,Roboto,Helvetica,Arial,sans-serif"
          fontWeight="400"
          fontSize="1rem"
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >
          {CurrencyFormatter(value)}
        </text>

        <text
          fontFamily="Manrope,Roboto,Helvetica,Arial,sans-serif"
          fontWeight="400"
          fontSize="1rem"
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`(${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  const getBalanceChart = (): ReactNode => {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={chartData}
            innerRadius={chartInnerRadius}
            outerRadius={chartOuterRadius}
            fill="#8884d8"
            cx="50%"
            cy="50%"
            startAngle={270}
            endAngle={-90}
            paddingAngle={5}
            dataKey="value"
            onMouseEnter={onPieEnter}
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
          >
            {chartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  };

  return (
    <>
      <LoadingComponent
        isLoading={isCustomerLoading || arePortfolioMetricsLoading}
      >
        {
          <>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  fontSize: '20px',
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}
              >
                {isTitleVisible && (
                  <Typography>{t('customer_balance.balance_title')}</Typography>
                )}
                <Typography
                  sx={{
                    color: (theme) => theme.palette.secondary.dark,
                    fontWeight: 'bold',
                    fontSize: '32px',
                  }}
                >
                  {CurrencyFormatter(
                    (customer?.cash ?? 0) +
                      (portfolioMetrics?.investedCash ?? 0)
                  )}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ width: '100%', height: '350px', margin: 0 }}>
              {getBalanceChart()}
            </Box>

            <Divider />

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                fontSize: '20px',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                gap: theme.spacing(2),
                padding: (theme) => theme.spacing(1),
              }}
            >
              <Box
                sx={{
                  textAlign: 'center',
                }}
              >
                <Typography>{t('customer_balance.available')}</Typography>
                <Typography
                  sx={{
                    color: (theme) => theme.palette.success.main,
                    fontWeight: 'bold',
                    fontSize: '32px',
                  }}
                >
                  {CurrencyFormatter(customer?.cash ?? 0)}
                </Typography>
              </Box>

              <Box
                sx={{
                  textAlign: 'center',
                }}
              >
                <Typography>{t('customer_balance.invested')}</Typography>
                <Typography
                  sx={{
                    color: (theme) => theme.palette.primary.main,
                    fontWeight: 'bold',
                    fontSize: '32px',
                  }}
                >
                  {CurrencyFormatter(portfolioMetrics?.investedCash ?? 0)}
                </Typography>
              </Box>
            </Box>
          </>
        }
      </LoadingComponent>
    </>
  );
};

export default CustomerBalanceComponent;
