import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
  },
  image: {
    width: '100%',
    paddingLeft: '30%',
    paddingRight: '30%',
  },
  text: {
    width: '100%',
    textAlign: 'center',
    paddingBottom: '2rem',
    paddingTop: '2rem',
    color: '#993e9b',
  },
});

const EmptyStateComponent = () => {
  const styles = useStyles();

  return (
    <>
      <div className={styles.wrapper}>
        <h2 className={styles.text}>Не са намерени данни</h2>
        <img
          alt=""
          src={require('../../../assets/img/brand/empty-state.jpg')}
          className={styles.image}
        ></img>
      </div>
    </>
  );
};

export default EmptyStateComponent;
