import { TableHeaderProps } from '../../../models/table/table-header-props.model';
import { ColumnType } from '../../../constants/table/column-type.constants';
import { TableHead, TableRow } from '@mui/material';
import TableCellComponent from './table-cell.component';
import { useTranslation } from 'react-i18next';

const TableHeaderComponent = <T, K extends keyof T>({
  columns,
}: TableHeaderProps<T, K>) => {
  const { t } = useTranslation();

  const isColumnVisible = (columnType: ColumnType): boolean => {
    return columnType !== ColumnType.RowHighlight;
  };

  const headers = columns.map((column, index) => {
    const style = {
      width: column.width ?? 100,
      padding: '0px',
    };

    return (
      isColumnVisible(column.type) && (
        <TableCellComponent key={`headCell-${index}`} style={style} scope="col">
          {t(column.headerTranslationId)}
        </TableCellComponent>
      )
    );
  });

  return (
    <TableHead
      sx={{
        height: '40px',
      }}
    >
      <TableRow>{headers}</TableRow>
    </TableHead>
  );
};

export default TableHeaderComponent;
