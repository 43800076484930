import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AllPropertyAmenityTypes,
  Amenities,
  GetPropertyAmenityIcon,
  GetPropertyAmenityType,
  PropertyAmenityType,
} from '../../../constants/property-details/property-amenity-type.constants';
import useObservable from '../../../hooks/use-observable.hook';
import { ChipModel } from '../../../models/common/chips/chip.model';
import { PropertyAmenityModel } from '../../../models/property/property-amenity.model';
import { PropertyModel } from '../../../models/property/property.model';
import { PropertyService } from '../../../services/properties/property.service';
import ChipsMultiSelectComponent from '../../common/chips/chips-multi-select.component';
import ImageBottomTextGradientComponent from '../../common/gradient/image-bottom-text-gradient.component';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import SelectedGradientComponent from '../../common/gradient/selected-gradient.component';
import MapComponent from '../../common/map/map.component';
import { renderToStaticMarkup } from 'react-dom/server';
import { MapMarkerModel } from '../../../models/common/map-marker.model';
import HorizontalMenuComponent from '../../common/horizontal-menu/horizontal-menu.component';
import { GetPropertyAmenityDefaultImage } from '../../../utilities/property.utility';

const PropertyDetailsAmenitiesTabComponent = ({
  property,
}: {
  property: PropertyModel;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const centerPosition: [number, number] = [
    property.latitude,
    property.longitude,
  ];

  const zoomLevel = 15;

  const [amenitiesChips] = useState<ChipModel[]>(
    Amenities.map<ChipModel>(
      (amenity) =>
        ({
          id: amenity.key,
          labelTranslationId: amenity.value,
        } as ChipModel)
    )
  );
  const [selectedAmenitiesChips, setSelectedAmenitiesChips] = useState<
    ChipModel[]
  >([{ id: AllPropertyAmenityTypes[0], labelTranslationId: '' }]);
  const [selectedAmenities, setSelectedAmenities] = useState<
    PropertyAmenityModel[]
  >([]);

  const toggleSelectedAmenity = (amenity: PropertyAmenityModel) => {
    const selectedAmenityIndex = selectedAmenities.findIndex(
      (selectedAmenity) => selectedAmenity.id === amenity.id
    );

    if (selectedAmenityIndex > -1) {
      const remainingSelectedAmenities = selectedAmenities.filter(
        (selectedAmenity) => selectedAmenity.id !== amenity.id
      );
      setSelectedAmenities(remainingSelectedAmenities);
    } else {
      setSelectedAmenities(selectedAmenities.concat([amenity]));
    }
  };

  const toggleChip = (amenityChip: ChipModel) => {
    const selectedAmenityChipIndex = selectedAmenitiesChips.findIndex(
      (selectedChip) => selectedChip.id === amenityChip.id
    );

    if (selectedAmenityChipIndex > -1) {
      const remainingSelectedChips = selectedAmenitiesChips.filter(
        (selectedChip) => selectedChip.id !== amenityChip.id
      );
      setSelectedAmenitiesChips(remainingSelectedChips);
    } else {
      setSelectedAmenitiesChips(selectedAmenitiesChips.concat([amenityChip]));
    }
  };

  const propertyClosestAmenities$ = useMemo(
    () => PropertyService.GetPropertyClosestAmenities(property.id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [property]
  );
  const [propertyClosestAmenities, , loadingPropertyClosestAmenities] =
    useObservable(propertyClosestAmenities$);

  const [propertyAmenitiesGroupedByType, setPropertyAmenitiesGroupedByType] =
    useState(new Map<PropertyAmenityType, PropertyAmenityModel[]>());

  useEffect(() => {
    const groupedAmenities = new Map<
      PropertyAmenityType,
      PropertyAmenityModel[]
    >();
    AllPropertyAmenityTypes.forEach((propertyAmenityType) => {
      const amenities = propertyClosestAmenities?.filter(
        (propertyAmenity) => propertyAmenity.type === propertyAmenityType
      );
      groupedAmenities.set(propertyAmenityType, amenities ?? []);
    });
    setPropertyAmenitiesGroupedByType(groupedAmenities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingPropertyClosestAmenities]);

  const getAmenityMapMarker = (
    amenity: PropertyAmenityModel
  ): MapMarkerModel => {
    const AmenityIcon = GetPropertyAmenityIcon(amenity.type);
    const iconMarkup = renderToStaticMarkup(
      <Avatar sx={{ backgroundColor: theme.palette.primary.main }}>
        <AmenityIcon sx={{ color: '#FFFFFF' }} />
      </Avatar>
    );

    return {
      position: [amenity.latitude, amenity.longitude],
      label: `${amenity.name} - ${Math.ceil(amenity.distanceInMeters)} ${t(
        'meters'
      )}`,
      iconMarkup: iconMarkup,
    };
  };

  const renderAmenity = (amenity: PropertyAmenityModel): JSX.Element => {
    const isSelected =
      selectedAmenities.findIndex(
        (selectedAmenity) => selectedAmenity.id === amenity.id
      ) > -1;

    const renderAmenityInformation = (): JSX.Element => {
      return (
        <Box
          key={amenity.id}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'end',
            height: '100%',
          }}
        >
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 'bold',
              color: '#FFFFFF',
              paddingBottom: theme.spacing(1),
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
            }}
          >
            {amenity.name}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              paddingBottom: theme.spacing(2),
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
            }}
          >
            <PlaceOutlinedIcon
              sx={{
                fontSize: '28px',
                color: '#FFFFFF',
                height: '100%',
                paddingRight: theme.spacing(1),
              }}
            />
            <Typography
              sx={{
                fontSize: '18px',
                color: '#FFFFFF',
              }}
            >
              {`${Math.ceil(amenity.distanceInMeters)} ${t('meters')}`}
            </Typography>
          </Box>
        </Box>
      );
    };
    return (
      <Box
        key={amenity.id}
        sx={{
          backgroundImage: `url(${GetPropertyAmenityDefaultImage(
            amenity.type
          )})`,
          backgroundSize: 'cover',
          objectFit: 'cover',
          borderRadius: '8px',
          height: '180px',
          cursor: 'pointer',
          width: { xs: '240px', sm: '320px' },
          marginRight: theme.spacing(1),
        }}
      >
        {isSelected ? (
          <SelectedGradientComponent>
            {renderAmenityInformation()}
          </SelectedGradientComponent>
        ) : (
          <ImageBottomTextGradientComponent>
            {renderAmenityInformation()}
          </ImageBottomTextGradientComponent>
        )}
      </Box>
    );
  };

  const renderAmenitiesByType = (
    amenityGroup: PropertyAmenityModel[],
    amenityType: PropertyAmenityType
  ): JSX.Element => {
    const AmenityIcon = GetPropertyAmenityIcon(amenityType);

    amenityGroup = amenityGroup.sort(
      (left, right) => left.distanceInMeters - right.distanceInMeters
    );

    return (
      <Box
        key={amenityType}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1),
          paddingBottom: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: theme.spacing(1),
          }}
        >
          <AmenityIcon color="primary" />
          <Typography
            color="primary"
            sx={{
              fontSize: '18px',
              fontWeight: 'bold',
            }}
          >
            {t(GetPropertyAmenityType(amenityType))}
          </Typography>
        </Box>

        <HorizontalMenuComponent
          items={amenityGroup}
          renderItem={renderAmenity}
          onItemClick={(amenity) => toggleSelectedAmenity(amenity)}
          isLeftArrowVisible
          isRightArrowVisible
          defaultNoRecordsMessage={t(
            'property_details.amenities_tab.no_amenities_records'
          )}
        />
      </Box>
    );
  };

  const renderAmenities = (): JSX.Element => {
    const generatedAmenities: JSX.Element[] = [];

    propertyAmenitiesGroupedByType.forEach((amenityGroup, amenityType) => {
      const selectedAmenityIndex = selectedAmenitiesChips.findIndex(
        (selectedAmenity) => selectedAmenity.id === amenityType
      );
      const isAmenitySelected = selectedAmenityIndex > -1;
      if (isEmpty(amenityGroup) || !isAmenitySelected) {
        return;
      }

      generatedAmenities.push(renderAmenitiesByType(amenityGroup, amenityType));
    });

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {generatedAmenities}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        gap: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          color="primary"
          sx={{
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          {t('property_details.amenities_tab.title')}
        </Typography>
      </Box>

      <Box>
        <ChipsMultiSelectComponent
          chips={amenitiesChips}
          selectedChips={selectedAmenitiesChips}
          onChipClick={toggleChip}
        />
      </Box>

      <Box>{renderAmenities()}</Box>

      <MapComponent
        centerPosition={centerPosition}
        zoomLevel={zoomLevel}
        schrollWheelZoom={true}
        markerLabel={property.name}
        mapHeight="700px"
        additionalMarkers={selectedAmenities.map((amenity) =>
          getAmenityMapMarker(amenity)
        )}
      />
    </Box>
  );
};

export default PropertyDetailsAmenitiesTabComponent;
