import { t } from 'i18next';
import { number, object, string } from 'zod';
import { IsValidNumber } from '../../utilities/number.utility';

const PropertyInvestmentSchema = object({
  availableCustomerCash: number(),
  investmentAmount: string()
    .min(1, t('invest_dialog.input.errors.min_amount'))
    .refine(
      (value) => IsValidNumber(value),
      t('invest_dialog.input.errors.not_valid_number')
    )
    .refine(
      (value) => +value > 0,
      t('invest_dialog.input.errors.not_valid_amount')
    ),
}).superRefine(({ availableCustomerCash, investmentAmount }, ctx) => {
  if (availableCustomerCash < +investmentAmount) {
    ctx.addIssue({
      code: 'custom',
      message: t('invest_dialog.input.errors.not_enough_cash'),
      path: ['investmentAmount'],
    });
  }
});

export default PropertyInvestmentSchema;
