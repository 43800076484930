import React from 'react';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import { Box } from '@mui/material';

const HorizontalMenuArrowComponent = ({
  children,
  disabled,
  onClick,
}: {
  children: React.ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
}) => {
  return (
    <Box
      sx={{
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        right: '1%',
        opacity: disabled ? '0' : '1',
        userSelect: 'none',
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

export const HorizontalMenuLeftArrowComponent = () => {
  const { isFirstItemVisible, scrollPrev, visibleElements, initComplete } =
    React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  React.useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  return (
    <HorizontalMenuArrowComponent
      disabled={disabled}
      onClick={() => scrollPrev()}
    >
      <ChevronLeftOutlinedIcon />
    </HorizontalMenuArrowComponent>
  );
};

export const HorizontalMenuRightArrowComponent = () => {
  const { isLastItemVisible, scrollNext, visibleElements } =
    React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !visibleElements.length && isLastItemVisible
  );
  React.useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  return (
    <HorizontalMenuArrowComponent
      disabled={disabled}
      onClick={() => scrollNext()}
    >
      <ChevronRightOutlinedIcon />
    </HorizontalMenuArrowComponent>
  );
};
