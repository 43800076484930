import { KeyValueModel } from '../models/common/key-value.model';

const Financing: KeyValueModel<string, string> = {
  key: 'FINANCING',
  value: 'property_status.financing',
};

const Financed: KeyValueModel<string, string> = {
  key: 'FINANCED',
  value: 'property_status.financed',
};

const Suspended: KeyValueModel<string, string> = {
  key: 'SUSPENDED',
  value: 'property_status.suspended',
};

export const PropertyStatusType = {
  Financing: Financing,
  Financed: Financed,
  Suspended: Suspended,
};
