import ColumnDefinitionModel from '../../models/table/column-definition.model';
import { WithdrawalRequestTableModel } from '../../models/withdrawal/withdrawal-request-table.model';
import { ColumnType } from './column-type.constants';

export const PendingWithdrawalRequestsColumnsDefinition: ColumnDefinitionModel<
  WithdrawalRequestTableModel,
  keyof WithdrawalRequestTableModel
>[] = [
  {
    key: 'id',
    headerTranslationId: 'withdrawal_requests_dialog.table_columns.id',
    type: ColumnType.Text,
    width: 150,
  },
  {
    key: 'paymentMethodSubMethod',
    headerTranslationId:
      'withdrawal_requests_dialog.table_columns.payment_method_sub_method',
    type: ColumnType.Text,
    width: 150,
  },
  {
    key: 'paymentMethodDescription',
    headerTranslationId:
      'withdrawal_requests_dialog.table_columns.payment_method_description',
    type: ColumnType.Text,
    width: 240,
  },
  {
    key: 'amount',
    headerTranslationId:
      'withdrawal_requests_dialog.table_columns.withdrawal_request_amount',
    type: ColumnType.Currency,
  },
  {
    key: 'executionStrategy',
    headerTranslationId:
      'withdrawal_requests_dialog.table_columns.withdrawal_request_execution_strategy',
    type: ColumnType.Text,
    width: 150,
  },
  {
    key: 'status',
    headerTranslationId:
      'withdrawal_requests_dialog.table_columns.withdrawal_request_status',
    type: ColumnType.Text,
    width: 150,
  },
  {
    key: 'updatedAt',
    headerTranslationId:
      'withdrawal_requests_dialog.table_columns.withdrawal_request_updated_at',
    type: ColumnType.Date,
    width: 170,
  },
];
