import { t } from 'i18next';
import { TransactionType } from '../constants/transaction-types.constants';

export const GetTransactionType = (transactionType: string) => {
  switch (transactionType) {
    case TransactionType.BankDeposit: {
      return t('transactions.bank_deposit');
    }
    case TransactionType.BankWithdrawal: {
      return t('transactions.bank_withdrawal');
    }
    case TransactionType.CardDeposit: {
      return t('transactions.card_deposit');
    }
    case TransactionType.CardWithdrawal: {
      return t('transactions.card_withdrawal');
    }
    case TransactionType.BoughtPropertyShares: {
      return t('transactions.property_share_bought');
    }
    case TransactionType.SoldPropertyShares: {
      return t('transactions.property_share_sold');
    }
    case TransactionType.PaidPropertyDividend: {
      return t('transactions.property_dividend_paid');
    }
    case TransactionType.StripeCardDeposit: {
      return t('transactions.stripe_card_deposit');
    }
    case TransactionType.StripeCardWithdrawal: {
      return t('transactions.stripe_card_withdrawal');
    }
    case TransactionType.DividendReceived: {
      return t('transactions.dividend_received');
    }
    default: {
      return '';
    }
  }
};
