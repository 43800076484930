import { Box, useTheme } from '@mui/material';
import { RouteProps } from 'react-router-dom';

const PreviewBoxComponent = ({ children }: RouteProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.secondary.dark}`,
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      {children}
    </Box>
  );
};

export default PreviewBoxComponent;
