import { Box, useTheme } from '@mui/material';
import { isNil } from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { defaultIfEmpty, forkJoin, map, mergeMap, of } from 'rxjs';
import { RoutesConstants } from '../../constants/routes.constants';
import { InvestmentsColumnsDefinition } from '../../constants/table/investments-columns.constants';
import useObservable from '../../hooks/use-observable.hook';
import { InvestmentModel } from '../../models/investments/investment.model';
import { InvestmentsTableModel } from '../../models/investments/investments-table.model';
import { PropertyModel } from '../../models/property/property.model';
import { PropertyService } from '../../services/properties/property.service';
import GetPlatformRoute from '../../utilities/route.utility';
import TableComponent from '../common/table/table.component';
import TitledBoxComponent from '../common/titled-box/titled-box.component';
import CustomerBalanceComponent from '../customer-balance/customer-balance.component';
import CustomerService from '../../services/customer/customer.service';
import CustomerContext from '../../providers/customer/customer.provider';
import { GetCustomerId } from '../../utilities/customer.utility';
import PortfolioChartComponent from '../portfolio-chart/portfolio-chart.component';

const InvestmentsComponent = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { customer } = useContext(CustomerContext);

  const columns = InvestmentsColumnsDefinition;

  const rowsPerPage = 10;
  const [page, setPage] = useState(1);

  const getTableInvestments = (
    property: PropertyModel,
    investment: InvestmentModel
  ): InvestmentsTableModel => {
    const tableModel: InvestmentsTableModel = {
      propertyName: property.name,
      propertyType: property.type.name,
      propertyPrice: {
        value: property.value,
      },
      quantity: investment.quantity,
      details: {
        name: t('investments.property_details_button'),
        onClick: () => {
          const params = new URLSearchParams({
            id: property.id.toString(),
          });
          navigate(
            `${GetPlatformRoute(RoutesConstants.PropertyDetails)}?${params}`
          );
        },
      },
      propertyImage: {
        src:
          !isNil(property.imageUrls) && property.imageUrls.length > 0
            ? property.imageUrls[0]
            : undefined,
      },
      investmentValue: {
        value: investment.value,
      },
    };

    return tableModel;
  };

  const investments$ = useMemo(
    () => {
      if (isNil(customer)) {
        return of([]);
      }

      return CustomerService.GetInvestments(
        GetCustomerId(customer),
        page,
        rowsPerPage
      ).pipe(
        map((investments) =>
          investments.map((investment) =>
            PropertyService.GetProperty(investment.propertyId).pipe(
              map((property) => getTableInvestments(property, investment))
            )
          )
        ),

        mergeMap((investmentTableModels) =>
          forkJoin(investmentTableModels).pipe(defaultIfEmpty([]))
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, customer]
  );

  const [investments, , investmentsLoading] = useObservable(investments$);

  const getInvestments = (): InvestmentsTableModel[] => {
    return investments ?? [];
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: {
            xs: theme.spacing(2),
            sm: theme.spacing(2),
            md: theme.spacing(4),
          },
          gap: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'column', md: 'row' },
            gap: theme.spacing(2),
          }}
        >
          <Box
            sx={{
              flex: '1 1 0px',
              width: { xs: '100%', sm: '100%', md: '0px' },
            }}
          >
            <TitledBoxComponent title={t('investments.balance_title')}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  minHeight: '300px',
                }}
              >
                <CustomerBalanceComponent isTitleVisible={false} />
              </Box>
            </TitledBoxComponent>
          </Box>

          <Box
            sx={{
              flex: '1 1 0px',
              width: { xs: '100%', sm: '100%', md: '0px' },
            }}
          >
            <TitledBoxComponent title={t('investments.portfolio_title')}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  minHeight: '300px',
                  paddingTop: theme.spacing(2),
                  paddingBottom: theme.spacing(2),
                  paddingLeft: { xs: theme.spacing(0.5), sm: theme.spacing(2) },
                  paddingRight: {
                    xs: theme.spacing(0.5),
                    sm: theme.spacing(2),
                  },
                }}
              >
                <PortfolioChartComponent />
              </Box>
            </TitledBoxComponent>
          </Box>
        </Box>

        <TitledBoxComponent title={t('investments.my_investments')}>
          <TableComponent
            data={getInvestments()}
            columns={columns}
            totalRows={getInvestments().length}
            loading={investmentsLoading}
            onPageChange={setPage}
            rowsPerPage={rowsPerPage}
            minWidth="1600px"
          />
        </TitledBoxComponent>
      </Box>
    </>
  );
};

export default InvestmentsComponent;
