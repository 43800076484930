import { isNil } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { ToastOptions } from '../../models/toast/toast-options.model';
import { ToastType } from '../../models/toast/toast-type.model';
import { ToastModel } from '../../models/toast/toast.model';
import { MakeId } from '../../utilities/id.utility';

const toastSubject = new Subject<ToastModel>();
const defaultId = 'default-toast';

export const ToastService = {
  onToast(): Observable<ToastModel> {
    return toastSubject.asObservable();
  },

  success(message: string, options?: ToastOptions) {
    options = ToastServicePrivate.getOptions(options);
    const toast = ToastServicePrivate.constructToast(
      message,
      options,
      ToastType.success
    );
    ToastServicePrivate.showToast(toast);
  },

  error(message: string, options?: ToastOptions) {
    options = ToastServicePrivate.getOptions(options);
    const toast = ToastServicePrivate.constructToast(
      message,
      options,
      ToastType.error
    );
    ToastServicePrivate.showToast(toast);
  },

  info(message: string, options?: ToastOptions) {
    options = ToastServicePrivate.getOptions(options);
    const toast = ToastServicePrivate.constructToast(
      message,
      options,
      ToastType.info
    );
    ToastServicePrivate.showToast(toast);
  },

  warning(message: string, options?: ToastOptions) {
    options = ToastServicePrivate.getOptions(options);
    const toast = ToastServicePrivate.constructToast(
      message,
      options,
      ToastType.warning
    );
    ToastServicePrivate.showToast(toast);
  },

  clearToast(id = defaultId) {
    const toast: ToastModel = {
      id,
      autoClose: true,
      keepAfterRouteChange: true,
      message: null,
      type: '',
    };
    toastSubject.next(toast);
  },
};

const ToastServicePrivate = {
  showToast(toast: ToastModel) {
    toast.id = MakeId(5);
    toastSubject.next(toast);
  },

  constructToast(
    message: string,
    options: ToastOptions,
    type: string
  ): ToastModel {
    return {
      id: defaultId,
      autoClose: options.autoClose,
      keepAfterRouteChange: options.keepAfterRouteChange,
      message,
      type,
    };
  },

  getOptions(options?: ToastOptions): ToastOptions {
    if (isNil(options)) {
      return { autoClose: true };
    }

    return options;
  },
};
