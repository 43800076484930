import { object, string } from 'zod';
import i18n from '../../translations/i18n';

const LoginSchema = object({
  email: string()
    .min(1, i18n.t('login.errors.email_min_length'))
    .email(i18n.t('login.errors.not_an_email')),
  password: string().min(1, i18n.t('login.errors.password_min_length')),
});

export default LoginSchema;
