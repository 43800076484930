import {
  Box,
  Button,
  DialogContent,
  Typography,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DepositType } from '../../constants/deposit.constants';
import { DialogProps } from '../../models/common/dialogs/dialog-props.model';
import DialogComponent from '../common/dialog/dialog.component';
import BankDepositComponent from './bank/bank-deposit.component';
import CardDepositComponent from './card/card-deposit.component';

const DepositDialogComponent = ({
  open,
  handleClose,
  customer,
}: DialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [depositType, setDepositType] = useState(DepositType.Bank);

  const isBankDepositSelected = () => {
    return depositType === DepositType.Bank;
  };

  const isCardDepositSeleced = () => {
    return depositType === DepositType.Card;
  };

  const getTabColor = (tabDepositType: DepositType): string => {
    if (
      (tabDepositType == DepositType.Bank && isBankDepositSelected()) ||
      (tabDepositType == DepositType.Card && isCardDepositSeleced())
    ) {
      return theme.palette.primary.main;
    } else {
      return theme.palette.grey[500];
    }
  };

  return (
    <DialogComponent
      open={open}
      handleClose={handleClose}
      dialogTitle={t('deposit.title')}
      width={{ sm: '400px', md: '500px' }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '0',
          paddingLeft: theme.spacing(6),
          paddingRight: theme.spacing(6),
        }}
      >
        <Button
          sx={{
            borderBottom: `2px solid ${getTabColor(DepositType.Bank)}`,
            borderRadius: '0px',
            transition: 'all .4s ease',
            flex: '1',
          }}
          onClick={() => setDepositType(DepositType.Bank)}
        >
          <Typography
            sx={{
              fontSize: { xs: '10px', sm: '14px', md: '16px' },
              fontWeight: isBankDepositSelected() ? 'bold' : 'normal',
              color: getTabColor(DepositType.Bank),
              transition: 'all .4s ease',
            }}
          >
            {t('deposit.bank_transfer')}
          </Typography>
        </Button>
        <Button
          sx={{
            borderBottom: `2px solid ${getTabColor(DepositType.Card)}`,
            borderRadius: '0px',
            transition: 'all .4s ease',
            flex: '1',
          }}
          onClick={() => setDepositType(DepositType.Card)}
        >
          <Typography
            sx={{
              fontSize: { xs: '10px', sm: '14px', md: '16px' },
              fontWeight: isCardDepositSeleced() ? 'bold' : 'normal',
              color: getTabColor(DepositType.Card),
              transition: 'all .4s ease',
            }}
          >
            {t('deposit.card_payment')}
          </Typography>
        </Button>
      </Box>

      <DialogContent
        sx={{
          paddingLeft: theme.spacing(6),
          paddingRight: theme.spacing(6),
        }}
      >
        {isBankDepositSelected() && !isNil(customer) && (
          <BankDepositComponent customer={customer} />
        )}
        {isCardDepositSeleced() && (
          <CardDepositComponent closeAction={handleClose} />
        )}
      </DialogContent>
    </DialogComponent>
  );
};

export default DepositDialogComponent;
