import { Box, Typography, useTheme } from '@mui/material';

const PreviewRowComponent = ({
  title,
  value,
  bottomBorderWidth = 1,
}: {
  title: string;
  value: string;
  bottomBorderWidth?: number;
}) => {
  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: { xs: 'center', sm: 'space-between' },
          alignItems: 'center',
          borderBottom: `${bottomBorderWidth}px solid ${theme.palette.secondary.dark}`,
          marginLeft: theme.spacing(1),
          marginRight: theme.spacing(1),
          height: { xs: '55px', sm: '45px' },
        }}
      >
        <Typography
          sx={{
            fontSize: '12px',
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: theme.spacing(1),
          }}
        >
          <Typography
            color="primary"
            sx={{
              fontSize: { xs: '14px', sm: '18px' },
              fontWeight: 'bold',
            }}
          >
            {value}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default PreviewRowComponent;
