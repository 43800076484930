import { TableProps } from '../../../models/table/table-props.model';
import TableHeaderComponent from './table-header.component';
import TableRowsComponent from './table-rows.component';
import LoadingComponent from '../loading-spinner/loading.component';
import { Table, TableContainer } from '@mui/material';
import TableEmptyRowsComponent from './table-empty-rows.component';

const TableComponent = <T, K extends keyof T>({
  data,
  columns,
  totalRows,
  loading,
  //onPageChange,
  //rowsPerPage = 10,
  //rowsPerPageOptions = [10],
  minWidth = '800px',
}: TableProps<T, K>) => {
  //const [currentPage, setCurrentPage] = useState(1);

  /*const onTablePageChange = (page: number) => {
    setCurrentPage(page);
    onPageChange(page);
  };*/

  return (
    <LoadingComponent isLoading={loading}>
      <TableContainer>
        <Table
          sx={{
            minWidth: { minWidth },
          }}
        >
          <TableHeaderComponent columns={columns} />
          {totalRows === 0 ? (
            <TableEmptyRowsComponent />
          ) : (
            <TableRowsComponent data={data} columns={columns} />
          )}
        </Table>
      </TableContainer>

      {/*
        Need some refactoring + it does not work currently 
        on the backend
      <TablePaginationComponent
        count={totalRows}
        page={currentPage}
        onPageChange={onTablePageChange}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
          />*/}
    </LoadingComponent>
  );
};

export default TableComponent;
