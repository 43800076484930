import { Box, Checkbox, FormHelperText, useTheme } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FormCheckboxProps } from '../../../models/common/form/form-checkbox-props.model';

const FormCheckboxComponent = ({ name, label }: FormCheckboxProps) => {
  const theme = useTheme();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: theme.spacing(0.2),
              }}
            >
              <Checkbox
                {...field}
                sx={{
                  paddingLeft: '0px',
                }}
              />
              {label}
            </Box>

            <FormHelperText
              id={name}
              sx={{
                color: (theme) => theme.palette.error.main,
                fontWeight: 580,
              }}
            >
              {errors[name] ? (errors[name]?.message as unknown as string) : ''}
            </FormHelperText>
          </Box>
        </>
      )}
    />
  );
};

export default FormCheckboxComponent;
