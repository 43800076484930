import { t } from 'i18next';
import {
  DepositTypeBank,
  DepositTypeCard,
} from '../models/deposit/deposit.model';

export const GetDepositType = (depositType: string) => {
  switch (depositType.toLowerCase()) {
    case DepositTypeBank.toLowerCase():
      return t('deposit.bank_transfer');
    case DepositTypeCard.toLowerCase():
      return t('deposit.card_payment');
    default:
      return '';
  }
};
