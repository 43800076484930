export enum ColumnType {
  Text,
  Number,
  Date,
  Currency,
  Button,
  Image,
  ProgressBar,
  RowHighlight,
  Icon,
}
