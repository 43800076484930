export const WithdrawalRequestStatus = {
  AwaitingApproval: 'AWAITING_APPROVAL',
  Approved: 'APPROVED',
  Rejected: 'REJECTED',
  SentForExecution: 'SENT_FOR_EXECUTION',
};

export type WithdrawalRequestStatusType =
  | 'AWAITING_APPROVAL'
  | 'APPROVED'
  | 'REJECTED'
  | 'SENT_FOR_EXECUTION';
