import { t } from 'i18next';
import { number, object, string } from 'zod';
import { IsValidNumber } from '../../utilities/number.utility';

const PropertySellShareSchema = object({
  availableShareValue: number(),
  saleAmount: string()
    .min(1, t('share_sale_dialog.input.errors.min_amount'))
    .refine(
      (value) => IsValidNumber(value),
      t('share_sale_dialog.input.errors.not_valid_number')
    )
    .refine(
      (value) => +value > 0,
      t('share_sale_dialog.input.errors.not_valid_amount')
    ),
}).superRefine(({ availableShareValue, saleAmount }, ctx) => {
  if (availableShareValue < +saleAmount) {
    ctx.addIssue({
      code: 'custom',
      message: t('share_sale_dialog.input.errors.not_enough_share'),
      path: ['saleAmount'],
    });
  }
});

export default PropertySellShareSchema;
