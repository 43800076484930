import { Button, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const ButtonComponent = styled(Button)<ButtonProps>(({ theme }) => ({
  borderWidth: '2px',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '8px',
  borderStyle: 'solid',
  color: '#FFFFFF',
  fontSize: '12px',
  fontWeight: 'bold',
  transition: 'all .2s ease',
  '&: hover': {
    borderColor: theme.palette.primary.main,
    backgroundColor: 'none',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
}));

export default ButtonComponent;
