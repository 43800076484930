import { Box } from '@mui/material';

const ImageGradientComponent = () => {
  return (
    <Box
      sx={{
        background:
          'linear-gradient(0deg, rgba(197,196,198,0.258140756302521) 0%, rgba(197,196,198,0.26) 100%)',
        height: '100%',
        width: '100%',
      }}
    ></Box>
  );
};

export default ImageGradientComponent;
