import { DepositModel } from '../../models/deposit/deposit.model';
import { Observable } from 'rxjs';
import ApiProvider from '../../providers/api/api.provider';

export const DepositService = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  GetDeposits(page: number, rowsPerPage: number): Observable<DepositModel[]> {
    return ApiProvider.get<DepositModel[]>('/deposits');
  },
};
