import { Box, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Subject, takeUntil } from 'rxjs';
import useObservable from '../../../hooks/use-observable.hook';
import { CommentSectionProps } from '../../../models/common/comments/comment-section-props.model';
import { CommentModel } from '../../../models/common/comments/comment.model';
import { PropertyComment } from '../../../models/property/property-comment.model';
import CustomerContext from '../../../providers/customer/customer.provider';
import SharedDataService from '../../../services/shared-data/shared-data.service';
import LoadingComponent from '../loading-spinner/loading.component';
import CommentTextareaComponent from './comment-textarea.component';
import CommentComponent from './comment.component';

const CommentSectionComponent = ({
  getComments,
  getReplies,
  onAddComment,
  onReply,
}: CommentSectionProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [refreshComments, setRefreshComments] = useState<boolean>(false);
  const refreshCommentsRef = useRef(refreshComments);

  const comments$ = useMemo(
    () => getComments(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshComments]
  );
  const [comments, , loadingComments] = useObservable(comments$);

  const destroy$ = new Subject<void>();

  const { customer } = useContext(CustomerContext);

  useEffect(() => {
    SharedDataService.GetUpdateCommentsEvent()
      .pipe(takeUntil(destroy$))
      .subscribe(() => {
        setRefreshComments(!refreshCommentsRef.current);
      });

    return () => {
      destroy$.next();
      destroy$.complete();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refreshCommentsRef.current = refreshComments;
  });

  const renderComments = (comments: PropertyComment[]): JSX.Element => {
    return (
      <>
        {comments.map((comment: CommentModel) => {
          return (
            <CommentComponent
              key={comment.id}
              comment={comment}
              getReplies={getReplies}
              onReply={onReply}
            ></CommentComponent>
          );
        })}
      </>
    );
  };

  return (
    <Box>
      <CommentTextareaComponent
        addComment={onAddComment}
        avatarAbbreviation={`${customer?.firstName[0]}${customer?.lastName[0]}`}
        isReply={false}
        maxLength={2048}
      />

      <Box
        sx={{
          paddingBottom: theme.spacing(1),
        }}
      >
        <Typography color="primary" fontSize="16px" fontWeight="bold">
          {(comments ?? []).length} {t('comment_section.comments_title')}
        </Typography>
      </Box>
      <LoadingComponent isLoading={loadingComments}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
          }}
        >
          {renderComments(comments ?? [])}
        </Box>
      </LoadingComponent>
    </Box>
  );
};

export default CommentSectionComponent;
