import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Container, Typography, useTheme } from '@mui/material';
import { isNil } from 'lodash';
import { useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { take } from 'rxjs';
import { TypeOf } from 'zod';
import useObservable from '../../hooks/use-observable.hook';
import { VerifyCustomerProps } from '../../models/customer-verification/verify-customer-props.model';
import { CustomerVerificationModel } from '../../models/customer/customer-verification.model';
import VerifyCustomerSchema from '../../schemas/customer-verification/verify-customer.schema';
import CustomerService from '../../services/customer/customer.service';
import MerchantCategoryCodeService from '../../services/merchant-category-code/merchant-category-code.service';
import ButtonOutlinedComponent from '../common/button/button-outlined.component';
import FormCalendarComponent from '../common/forms/form-calendar.component';
import FormInputTextComponent from '../common/forms/form-input-text.component';
import FormSelectComponent from '../common/forms/form-select.component';
import SharedDataService from '../../services/shared-data/shared-data.service';

type IVerifyCustomerForm = TypeOf<typeof VerifyCustomerSchema>;

const VerifyCustomerComponent = ({ onVerify }: VerifyCustomerProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [formErrorMessage, setFormErrorMessage] = useState('');

  const defaultVerifyCustomerFormValues: IVerifyCustomerForm = {
    address: '',
    city: '',
    postalCode: '',
    secondaryAddress: '',
    dateOfBirth: new Date(),
    industryDescription: '',
    merchantCategoryId: 1,
    phone: '+359',
  };

  const methods = useForm<IVerifyCustomerForm>({
    resolver: zodResolver(VerifyCustomerSchema),
    defaultValues: defaultVerifyCustomerFormValues,
  });

  const merchantCategoryCodes$ = useMemo(
    () => MerchantCategoryCodeService.GetAll(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [merchantCategoryCodes, ,] = useObservable(merchantCategoryCodes$);

  const verifyCustomer: SubmitHandler<IVerifyCustomerForm> = (
    verificationInformation: IVerifyCustomerForm
  ) => {
    const customerVerificationModel: CustomerVerificationModel = {
      addressLine: verificationInformation.address,
      city: verificationInformation.city,
      dateOfBirth: verificationInformation.dateOfBirth
        .toISOString()
        .split('T')[0],
      industryDescription: verificationInformation.industryDescription,
      merchantCategoryCodeId: verificationInformation.merchantCategoryId,
      phoneNumber: verificationInformation.phone,
      postalCode: verificationInformation.postalCode,
      secondAddressLine: verificationInformation.secondaryAddress,
    };

    CustomerService.Verify(customerVerificationModel)
      .pipe(take(1))
      .subscribe({
        next: () => {
          onVerify();
          SharedDataService.TriggerUpdateCustomerInformationEvent();
        },
        error: () => setFormErrorMessage('Failed verification of customer'),
      });
  };

  return (
    <>
      <Container sx={{ height: '100%' }}>
        <FormProvider {...methods}>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={methods.handleSubmit(verifyCustomer)}
            display="flex"
            flexDirection="column"
            gap={theme.spacing(2)}
            paddingTop={theme.spacing(2)}
          >
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: {
                  xs: '200px',
                  md: 'minmax(164px, 1fr) minmax(164px, 1fr)',
                },
                gap: theme.spacing(1),
                justifyContent: 'center',
              }}
            >
              <FormInputTextComponent
                label={t('customer_verification.verify.fields.address')}
                type="text"
                name="address"
                variant="filled"
              />

              <FormInputTextComponent
                label={t('customer_verification.verify.fields.city')}
                type="text"
                name="city"
                variant="filled"
              />

              <FormInputTextComponent
                label={t('customer_verification.verify.fields.postal_code')}
                type="text"
                name="postalCode"
                variant="filled"
              />

              <FormInputTextComponent
                label={t(
                  'customer_verification.verify.fields.secondary_address'
                )}
                type="text"
                name="secondaryAddress"
                variant="filled"
              />

              <FormCalendarComponent
                label={t('customer_verification.verify.fields.date_of_birth')}
                name="dateOfBirth"
              />

              <FormInputTextComponent
                label={t(
                  'customer_verification.verify.fields.industry_description'
                )}
                type="text"
                name="industryDescription"
                variant="filled"
              />

              <FormSelectComponent
                label={t(
                  'customer_verification.verify.fields.merchant_category'
                )}
                name="merchantCategoryId"
                type="filled"
                elements={merchantCategoryCodes ?? []}
                optionValue="id"
                optionDisplay="name"
              />

              <FormInputTextComponent
                label={t('customer_verification.verify.fields.phone')}
                type="text"
                name="phone"
                variant="filled"
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <ButtonOutlinedComponent
                sx={{
                  width: '200px',
                  height: '50px',
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
                type="submit"
              >
                {t('customer_verification.verify.verify_button')}
              </ButtonOutlinedComponent>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              {!isNil(formErrorMessage) && (
                <Typography color="error">{formErrorMessage}</Typography>
              )}
            </Box>
          </Box>
        </FormProvider>
      </Container>
    </>
  );
};

export default VerifyCustomerComponent;
