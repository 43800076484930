import { Box, TextField, Typography, useTheme } from '@mui/material';
import { FileRejection, useDropzone } from 'react-dropzone';
import CopyAllOutlinedIcon from '@mui/icons-material/CopyAllOutlined';
import { useTranslation } from 'react-i18next';
import { DropzoneProps } from '../../../models/common/dropzone/dropzone-props.model';
import { useCallback } from 'react';

const DropzoneComponent = ({
  maxFiles,
  maxFileSize,
  acceptedFileTypes,
  setFiles,
}: DropzoneProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const MAX_FILE_SIZE_DEFAULT = 1048576; // 1 MB
  const MAX_FILES_DEFAULT = 2;
  const ACCEPTED_FILE_TYPES_DEFAULT = {
    'image/jpeg': [],
    'image/png': [],
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFiles(acceptedFiles);
    },
    [setFiles]
  );

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      maxFiles: maxFiles ?? MAX_FILES_DEFAULT,
      accept: acceptedFileTypes ?? ACCEPTED_FILE_TYPES_DEFAULT,
      maxSize: maxFileSize ?? MAX_FILE_SIZE_DEFAULT,
      onDrop,
    });

  const selectedFiles = acceptedFiles.map((file) => (
    <Typography key={file.name} fontSize="14px">
      {file.name}
    </Typography>
  ));

  const getError = (errorCode: string) => {
    let errorMessage = t('dropzone.errors.default');
    if (errorCode === 'file-too-large') {
      errorMessage = t('dropzone.errors.file-too-large');
    }

    if (errorCode === 'file-invalid-type') {
      errorMessage = t('dropzone.errors.invalid-file-type');
    }

    if (errorCode === 'too-many-files') {
      errorMessage = t('dropzone.errors.too-many-files');
    }

    return (
      <Typography fontSize="14px" color="error" key={errorCode}>
        {errorMessage}
      </Typography>
    );
  };

  const rejectedFilesErrors = () => {
    return fileRejections.map((rejection: FileRejection) => {
      return (
        <Box key={rejection.file.name}>
          <Box>
            <Typography fontSize="14px">{rejection.file.name}</Typography>
          </Box>
          {rejection.errors.map((err) => {
            return getError(err.code);
          })}
        </Box>
      );
    });
  };

  return (
    <>
      <Box
        {...getRootProps()}
        sx={{
          border: `dashed 2px ${theme.palette.secondary.dark}`,
          borderRadius: '10px',
          backgroundColor: 'transparent',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: theme.spacing(2),
          height: '200px',
          cursor: 'pointer',
        }}
      >
        <CopyAllOutlinedIcon
          sx={{
            fontSize: '64px',
            color: theme.palette.secondary.dark,
          }}
        />
        <TextField inputProps={getInputProps()} sx={{ display: 'none' }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            color="primary"
            fontSize="14px"
            fontWeight="bold"
            textAlign="center"
          >
            {t('dropzone.text')}
          </Typography>
          <Typography
            fontSize="14px"
            fontWeight="400"
            fontStyle="italic"
            textAlign="center"
            sx={{
              color: theme.palette.secondary.dark,
            }}
          >
            {t('dropzone.accepted_file_type')}
          </Typography>
        </Box>
      </Box>
      <Box>
        {fileRejections.length == 0 && acceptedFiles.length > 0 && (
          <>
            <Typography fontSize="14px">
              {t('dropzone.selected_files')}
            </Typography>
            <Box>{selectedFiles}</Box>
          </>
        )}
        {fileRejections.length > 0 && rejectedFilesErrors()}
      </Box>
    </>
  );
};

export default DropzoneComponent;
