import { PropertyDetailsDividendTableModel } from '../../models/property-details/property-details-dividend-table.model';
import ColumnDefinitionModel from '../../models/table/column-definition.model';
import { ColumnType } from './column-type.constants';

export const PropertyDetailsDividendColumns: ColumnDefinitionModel<
  PropertyDetailsDividendTableModel,
  keyof PropertyDetailsDividendTableModel
>[] = [
  {
    key: 'allPropertyDividendsPaid',
    headerTranslationId:
      'property_details.dividends_tab.table_columns.property_dividends_paid',
    type: ColumnType.Currency,
  },
  {
    key: 'customerDividendsPaid',
    headerTranslationId:
      'property_details.dividends_tab.table_columns.customer_dividends_paid',
    type: ColumnType.Currency,
  },
  {
    key: 'receivedAt',
    headerTranslationId:
      'property_details.dividends_tab.table_columns.dividends_paid_at',
    type: ColumnType.Date,
  },
  {
    key: 'statusIcon',
    headerTranslationId: 'property_details.dividends_tab.table_columns.status',
    type: ColumnType.Icon,
  },
];
