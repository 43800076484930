import { TabModel } from '../models/common/tab.model';
import { HistoryType } from './history.constants';

export const HistoryTypeTabs: TabModel<string>[] = [
  {
    value: HistoryType.Orders,
    labelTranslationId: 'history_page.tabs.orders',
    icon: 'ni ni-building mr-2',
  },
  {
    value: HistoryType.Deposits,
    labelTranslationId: 'history_page.tabs.deposits',
    icon: 'ni ni-money-coins mr-2',
  },
  {
    value: HistoryType.Withdrawals,
    labelTranslationId: 'history_page.tabs.withdrawals',
    icon: 'ni ni-briefcase-24 mr-2',
  },
  {
    value: HistoryType.Transactions,
    labelTranslationId: 'history_page.tabs.transactions',
    icon: 'fas fa-arrows-alt-h mr-2',
  },
  {
    value: HistoryType.Dividends,
    labelTranslationId: 'history_page.tabs.dividends',
    icon: 'fas fa-hand-holding-usd mr-2',
  },
];
