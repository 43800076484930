import { isNil } from 'lodash';
import { Navigate } from 'react-router-dom';
import { RoutesConstants } from '../../constants/routes.constants';
import { PrivateRouteProps } from '../../models/router/private-route-props.model';
import { IsUserAuthenticated } from '../../utilities/authentication.utility';

const PrivateRouteComponent = ({
  redirectionPath,
  ...routeProps
}: PrivateRouteProps): JSX.Element => {
  if (IsUserAuthenticated()) {
    return (
      routeProps.element ?? <Navigate to={{ pathname: '/not-valid-path' }} />
    );
  } else {
    const path = isNil(redirectionPath)
      ? RoutesConstants.Login
      : redirectionPath;
    return <Navigate to={{ pathname: path }} />;
  }
};

export default PrivateRouteComponent;
