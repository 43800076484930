import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { ReactNode, useMemo } from 'react';
import useObservable from '../../../../hooks/use-observable.hook';
import ButtonOutlinedComponent from '../../../common/button/button-outlined.component';
import LoadingComponent from '../../../common/loading-spinner/loading.component';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import { useTranslation } from 'react-i18next';
import VisaLogo from '../../../../assets/img/icons/visa.svg';
import MastercardLogo from '../../../../assets/img/icons/mastercard.svg';
import MaestrocardLogo from '../../../../assets/img/icons/maestrocard.svg';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import ButtonComponent from '../../../common/button/button.component';
import PaymentMethodsService from '../../../../services/payment-methods/payment-methods.service';
import { PaymentMethodModel } from '../../../../models/payment-methods/payment-method.model';
import { FormatCardLastFour } from '../../../../utilities/payment-methods.utility';

const CardDepositCardSelectComponent = ({
  goToNewCardPayment,
  goToExistingCardPayment,
}: {
  goToNewCardPayment: () => void;
  goToExistingCardPayment: (
    paymentMethodId: string,
    lastFourDigits: string
  ) => void;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const customerStripeCards$ = useMemo(
    () => PaymentMethodsService.GetCardDepositMethods(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [customerStripeCards, , loadingPaymentMethods] =
    useObservable(customerStripeCards$);

  const renderDepositCard = (card: PaymentMethodModel): ReactNode => {
    return (
      <ButtonOutlinedComponent
        key={card.id}
        startIcon={<CreditCardOutlinedIcon />}
        color="primary"
        sx={{
          height: '50px',
        }}
        onClick={() => goToExistingCardPayment(card.id, card.description)}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '10px', sm: '12px' },
              textAlign: 'start',
            }}
          >
            {card.subMethod}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '12px', sm: '14px' },
              fontWeight: 'bold',
            }}
          >
            {FormatCardLastFour(card.description)}
          </Typography>
        </Box>
      </ButtonOutlinedComponent>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderCardLogo = (logo: any, logoTooltip: string): ReactNode => {
    return (
      <Tooltip title={logoTooltip}>
        <Box
          sx={{
            height: '35px',
            width: '60px',
            backgroundColor: theme.palette.secondary.main,
            borderRadius: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            src={logo}
            sx={{
              height: '25px',
              width: '60px',
              objectFit: 'contain',
            }}
          ></Box>
        </Box>
      </Tooltip>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: theme.spacing(2),
        }}
      >
        <LoadingComponent isLoading={loadingPaymentMethods}>
          {(customerStripeCards ?? []).length > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(1),
                paddingTop: theme.spacing(0.5),
              }}
            >
              {customerStripeCards?.map((customerStripeCard) =>
                renderDepositCard(customerStripeCard)
              )}
            </Box>
          )}
        </LoadingComponent>

        <ButtonComponent
          startIcon={<AddCardOutlinedIcon />}
          color="primary"
          sx={{
            height: '50px',
            marginTop:
              (customerStripeCards ?? []).length === 0 ? 0 : theme.spacing(5),
          }}
          onClick={goToNewCardPayment}
        >
          {t('card_deposit.card_select.new_card_payment')}
        </ButtonComponent>

        <Box
          sx={{
            display: 'flex',
            paddingTop: theme.spacing(1),
            gap: theme.spacing(1),
          }}
        >
          {renderCardLogo(VisaLogo, 'Visa')}
          {renderCardLogo(MastercardLogo, 'MasterCard')}
          {renderCardLogo(MaestrocardLogo, 'MaestroCard')}
        </Box>

        <Box
          sx={{
            paddingTop: theme.spacing(2),
          }}
        >
          <Typography
            color="primary"
            sx={{
              textAlign: 'center',
              fontSize: '12px',
              fontWeight: '600',
            }}
          >
            {t('card_deposit.card_select.deposit_warning')}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default CardDepositCardSelectComponent;
