import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Container, useTheme } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TypeOf } from 'zod';
import PropertyInvestmentSchema from '../../../../schemas/investments/property-investment.schema';
import ButtonComponent from '../../../common/button/button.component';
import FormCurrencyInputComponent from '../../../common/forms/form-currency-input.component';

type IPropertyInvestmentForm = TypeOf<typeof PropertyInvestmentSchema>;

const InvestDialogAmountInput = ({
  customerCash,
  setInvestmentAmount,
}: {
  customerCash: number;
  setInvestmentAmount: (investmentAmount: number) => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const defaultPropertyInvestmentFormValues: IPropertyInvestmentForm = {
    availableCustomerCash: customerCash,
    investmentAmount: '100',
  };

  const methods = useForm<IPropertyInvestmentForm>({
    resolver: zodResolver(PropertyInvestmentSchema),
    defaultValues: defaultPropertyInvestmentFormValues,
  });

  const submitForm: SubmitHandler<IPropertyInvestmentForm> = (
    propertyInvestmentForm: IPropertyInvestmentForm
  ) => {
    setInvestmentAmount(+propertyInvestmentForm.investmentAmount);
  };
  return (
    <Box>
      <Container sx={{ height: '100%' }}>
        <FormProvider {...methods}>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={methods.handleSubmit(submitForm)}
            display="flex"
            flexDirection="column"
            paddingTop={theme.spacing(2)}
          >
            <FormCurrencyInputComponent
              label={t('invest_dialog.input.fields.investment_amount')}
              type="text"
              name="investmentAmount"
              currency={'BGN'}
              variant="filled"
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: theme.spacing(2),
              }}
            >
              <ButtonComponent
                sx={{
                  width: '200px',
                  height: '50px',
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
                type="submit"
              >
                {t('invest_dialog.input.next_button')}
              </ButtonComponent>
            </Box>
          </Box>
        </FormProvider>
      </Container>
    </Box>
  );
};

export default InvestDialogAmountInput;
