import { StepModel } from '../../models/common/stepper/step.model';

export enum PaymentStatus {
  InputAmount,
  SelectCard,
  NewCardPayment,
  ExistingCardPayment,
  PaymentSuccessful,
  PaymentFailed,
}

export const DEPOSIT_STEPS: StepModel[] = [
  {
    id: PaymentStatus.InputAmount,
    labelId: 'card_deposit.steps.input_deposit_amount',
  },
  {
    id: PaymentStatus.SelectCard,
    labelId: 'card_deposit.steps.select_card',
  },
  {
    id: PaymentStatus.NewCardPayment,
    labelId: 'card_deposit.steps.finish_deposit',
  },
];

export const MAX_DEPOSIT_AMOUNT = 10000;
