import { useEffect, useMemo, useRef, useState } from 'react';
import { Route, Routes } from 'react-router';
import { RoutesConstants } from '../../constants/routes.constants';
import InvestmentsComponent from '../investments/investments.component';
import NavbarComponent from '../navbar/navbar.component';
import PropertiesComponent from '../properties/properties.component';
import HistoryComponent from '../history/history.component';
import PropertyDetailsComponent from '../property-details/property-details.component';
import PrivateRouteComponent from './private-route.component';
import GenericNotFoundComponent from '../not-found/generic-not-found-component';
import { Box } from '@mui/material';
import { CustomerModel } from '../../models/customer/customer.model';
import { CustomerProvider } from '../../providers/customer/customer.provider';
import CustomerService from '../../services/customer/customer.service';
import useObservable from '../../hooks/use-observable.hook';
import SharedDataService from '../../services/shared-data/shared-data.service';
import { Subject, of, takeUntil } from 'rxjs';
import CustomerProfileComponent from '../customer-profile/customer-profile.component';
import { PortfolioMetricsModel } from '../../models/portfolio-metrics/portfolio-metrics.model';
import { PortfolioMetricsProvider } from '../../providers/portfolio-metrics/portfolio-metrics.provider';
import { GetCustomerId } from '../../utilities/customer.utility';
import { isNil } from 'lodash';

const MainRoutesComponent = () => {
  const mainContent = useRef<HTMLHeadingElement | null>(null);

  const [refreshCustomer, setRefreshCustomer] = useState(false);
  const refreshCustomerRef = useRef(refreshCustomer);

  const [refreshPortfolioMetrics, setRefreshPortfolioMetrics] = useState(false);
  const refreshPortfolioMetricsRef = useRef(refreshPortfolioMetrics);

  const destroy$ = new Subject<void>();

  useEffect(() => {
    document.documentElement.scrollTop = 0;

    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }

    if (mainContent.current) {
      mainContent.current.scrollTop = 0;
    }
  });

  const [customer, setCustomer] = useState<CustomerModel | undefined>(
    undefined
  );
  const [isCustomerLoading, setIsCustomerLoading] = useState<boolean>(false);

  const customer$ = useMemo(
    () => {
      return CustomerService.GetCustomer();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshCustomer]
  );
  const [loadedCustomer, , customerLoading] = useObservable(customer$);

  useEffect(() => {
    refreshCustomerRef.current = refreshCustomer;
  });

  const [portfolioMetrics, setPortfolioMetrics] = useState<
    PortfolioMetricsModel | undefined
  >(undefined);
  const [arePortfolioMetricsLoading, setArePortfolioMetricsLoading] =
    useState<boolean>(false);

  const portfolioMetrics$ = useMemo(
    () => {
      if (isNil(customer)) {
        return of({
          investedCash: 0,
          averageAnnualPercentageRate: 0,
        });
      }

      return CustomerService.GetPortfolioMetrics(GetCustomerId(customer));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshPortfolioMetrics, customer]
  );
  const [loadedPortfolioMetrics, , areMetricsLoading] =
    useObservable(portfolioMetrics$);

  useEffect(() => {
    refreshPortfolioMetricsRef.current = refreshPortfolioMetrics;
  });

  useEffect(() => {
    SharedDataService.GetUpdateCustomerInformationEvent()
      .pipe(takeUntil(destroy$))
      .subscribe(() => setRefreshCustomer(!refreshCustomerRef.current));

    SharedDataService.GetUpdatePortfolioMetricsEvent()
      .pipe(takeUntil(destroy$))
      .subscribe(() =>
        setRefreshPortfolioMetrics(!refreshPortfolioMetricsRef.current)
      );

    return () => {
      destroy$.next();
      destroy$.complete();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCustomer(loadedCustomer);
    setIsCustomerLoading(customerLoading);
  }, [loadedCustomer, customerLoading]);

  useEffect(() => {
    setPortfolioMetrics(loadedPortfolioMetrics);
    setArePortfolioMetricsLoading(areMetricsLoading);
  }, [loadedPortfolioMetrics, areMetricsLoading]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRefreshPortfolioMetrics(!refreshPortfolioMetricsRef.current);
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <CustomerProvider value={{ customer, isCustomerLoading }}>
      <PortfolioMetricsProvider
        value={{ portfolioMetrics, arePortfolioMetricsLoading }}
      >
        <Box
          sx={{
            height: '100%',
            backgroundColor: (theme) => theme.palette.secondary.main,
          }}
        >
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.secondary.main,
            }}
            ref={mainContent}
          >
            <PrivateRouteComponent element={<NavbarComponent />} />
            <Routes>
              <Route
                path="*"
                element={
                  <PrivateRouteComponent
                    element={
                      <GenericNotFoundComponent isMainPlatformError={true} />
                    }
                  />
                }
              />

              <Route
                path={RoutesConstants.Properties}
                element={
                  <PrivateRouteComponent element={<PropertiesComponent />} />
                }
              />

              <Route
                path={RoutesConstants.PropertyDetails}
                element={
                  <PrivateRouteComponent
                    element={<PropertyDetailsComponent />}
                  />
                }
              />

              <Route
                path={RoutesConstants.Investments}
                element={
                  <PrivateRouteComponent element={<InvestmentsComponent />} />
                }
              />

              <Route
                path={RoutesConstants.Home}
                element={
                  <PrivateRouteComponent element={<PropertiesComponent />} />
                }
              />

              <Route
                path={RoutesConstants.History}
                element={
                  <PrivateRouteComponent element={<HistoryComponent />} />
                }
              />

              <Route
                path={RoutesConstants.CustomerProfile}
                element={
                  <PrivateRouteComponent
                    element={<CustomerProfileComponent />}
                  />
                }
              />
            </Routes>
          </Box>
        </Box>
      </PortfolioMetricsProvider>
    </CustomerProvider>
  );
};

export default MainRoutesComponent;
