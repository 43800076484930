import { Observable } from 'rxjs';
import { LocalStorageItems } from '../../constants/local-storage.constants';
import { AuthenticationModel } from '../../models/authentication/authentication.model';
import { SignInModel } from '../../models/authentication/signin.model';
import { SignUpResponseModel } from '../../models/authentication/signup-response.model';
import { SignUpModel } from '../../models/authentication/signup.model';
import { User } from '../../models/user/user.model';
import ApiProvider from '../../providers/api/api.provider';

const AuthenticationService = {
  SignIn(
    signInParameters: SignInModel
  ): Observable<AuthenticationModel | null> {
    return ApiProvider.post<AuthenticationModel>('/login', signInParameters);
  },

  OnSingInSuccess(token: string, tokenExpiryDate: number, user: User) {
    localStorage.setItem(LocalStorageItems.AuthenticationToken, token);
    localStorage.setItem(
      LocalStorageItems.AuthenticationTokenExpiryDate,
      tokenExpiryDate.toString()
    );
    localStorage.setItem(
      LocalStorageItems.AuthenticationUser,
      JSON.stringify(user)
    );
  },

  SignOut() {
    localStorage.clear();
  },

  SignUp(
    signUpParameters: SignUpModel
  ): Observable<SignUpResponseModel | null> {
    return ApiProvider.post<SignUpResponseModel>(
      '/customers',
      signUpParameters
    );
  },
};

export default AuthenticationService;
