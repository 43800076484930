import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GetPlatformRoute from '../../utilities/route.utility';
import ButtonComponent from '../common/button/button.component';

const GenericNotFoundComponent = ({
  isMainPlatformError,
}: {
  isMainPlatformError: boolean;
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();

  const goBackToPlatformClick = () => {
    navigate(GetPlatformRoute(''));
  };

  return (
    <Box
      sx={{
        height: isMainPlatformError ? 'calc(100vh - 60px)' : '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.secondary.main,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontSize: '64px',
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            lineHeight: '1',
          }}
        >
          {t('not_found_page.error_title')}
        </Typography>

        <Typography
          sx={{
            fontSize: '128px',
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            lineHeight: '1',
          }}
        >
          {t('not_found_page.404')}
        </Typography>

        <Typography
          sx={{
            fontSize: '16px',
            color: theme.palette.primary.main,
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
          }}
        >
          {t('not_found_page.error_description')}
        </Typography>

        <ButtonComponent
          sx={{
            height: '70px',
            width: { xs: '320px', sm: '420px' },
            fontSize: { xs: '20px', sm: '24px' },
            fontWeight: 'bold',
          }}
          onClick={goBackToPlatformClick}
        >
          {t('not_found_page.back_to_platform_button')}
        </ButtonComponent>
      </Box>
    </Box>
  );
};

export default GenericNotFoundComponent;
