import { Box, useTheme } from '@mui/material';
import { isNil } from 'lodash';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Subject, of, takeUntil } from 'rxjs';
import useObservable from '../../hooks/use-observable.hook';
import { InvestmentModel } from '../../models/investments/investment.model';
import { PropertyService } from '../../services/properties/property.service';
import SharedDataService from '../../services/shared-data/shared-data.service';
import LoadingComponent from '../common/loading-spinner/loading.component';
import PropertyDetailsMainComponent from './property-details-main.component';
import PropertyDetailsTabsComponent from './property-details-tabs.component';
import CustomerService from '../../services/customer/customer.service';
import CustomerContext from '../../providers/customer/customer.provider';
import { GetCustomerId } from '../../utilities/customer.utility';

const PropertyDetailsComponent = () => {
  const theme = useTheme();

  const { customer } = useContext(CustomerContext);

  const [searchParams] = useSearchParams();
  const propertyId = searchParams.get('id');
  const [refreshProperty, setRefreshPropery] = useState<boolean>(false);
  const refreshPropertyRef = useRef(refreshProperty);

  const destroy$ = new Subject<void>();

  const property$ = useMemo(
    () => PropertyService.GetProperty(parseInt(propertyId ?? '-1')),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [propertyId, refreshProperty]
  );

  const [property, , isPropertyLoading] = useObservable(property$);

  const propertyInvestments$ = useMemo(
    () => {
      if (isNil(property) || isNil(customer)) {
        return of(null);
      }

      return CustomerService.GetInvestmentsByProperty(
        GetCustomerId(customer),
        property.id
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [property, customer]
  );

  const [propertyInvestments, , arePropertyInvestmentsLoading] =
    useObservable(propertyInvestments$);

  useEffect(() => {
    refreshPropertyRef.current = refreshProperty;
  });

  useEffect(() => {
    SharedDataService.GetUpdatePropertyEvent()
      .pipe(takeUntil(destroy$))
      .subscribe(() => setRefreshPropery(!refreshPropertyRef.current));

    return () => {
      destroy$.next();
      destroy$.complete();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPropertyInvestments = (): InvestmentModel | null => {
    if (!arePropertyInvestmentsLoading && !isNil(propertyInvestments)) {
      return propertyInvestments.length > 0 ? propertyInvestments[0] : null;
    }

    return null;
  };

  const renderPropertyDetails = (): JSX.Element => {
    if (isNil(property)) {
      return <></>;
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',

          paddingLeft: {
            xs: theme.spacing(2),
            sm: theme.spacing(4),
            md: theme.spacing(8),
            lg: theme.spacing(16),
            xl: theme.spacing(16),
          },
          paddingRight: {
            xs: theme.spacing(2),
            sm: theme.spacing(4),
            md: theme.spacing(8),
            lg: theme.spacing(16),
            xl: theme.spacing(16),
          },
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
          gap: theme.spacing(2),
        }}
      >
        <Box>
          <PropertyDetailsMainComponent
            property={property}
            propertyInvestment={getPropertyInvestments()}
          />
        </Box>

        <Box>
          <PropertyDetailsTabsComponent property={property} />
        </Box>
      </Box>
    );
  };

  return (
    <LoadingComponent isLoading={isPropertyLoading}>
      {renderPropertyDetails()}
    </LoadingComponent>
  );
};

export default PropertyDetailsComponent;
