import { isNil } from 'lodash';
import moment from 'moment';
import { date, number, object, string } from 'zod';
import i18n from '../../translations/i18n';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

const VerifyCustomerSchema = object({
  address: string().min(
    1,
    i18n.t('customer_verification.verify.errors.address_min_length')
  ),
  city: string().min(
    1,
    i18n.t('customer_verification.verify.errors.city_min_length')
  ),
  postalCode: string().min(
    1,
    i18n.t('customer_verification.verify.errors.postal_code_min_length')
  ),
  secondaryAddress: string(),
  dateOfBirth: date()
    .nullable()
    .transform((pickedDate, ctx): Date => {
      if (isNil(pickedDate)) {
        ctx.addIssue({
          code: 'custom',
          message: i18n.t(
            'customer_verification.verify.errors.date_of_birth_required'
          ),
        });
      }

      return pickedDate ?? new Date();
    }),
  industryDescription: string().min(
    1,
    i18n.t(
      'customer_verification.verify.errors.industry_description_min_length'
    )
  ),
  merchantCategoryId: number().nonnegative({
    message: i18n.t(
      'customer_verification.verify.errors.merchant_category_min_length'
    ),
  }),
  phone: string().min(
    1,
    i18n.t('customer_verification.verify.errors.phone_min_length')
  ),
}).superRefine(({ dateOfBirth, phone }, ctx) => {
  const age = moment().diff(dateOfBirth, 'years');

  if (age < 18) {
    ctx.addIssue({
      code: 'custom',
      message: i18n.t('errors.underage'),
      path: ['dateOfBirth'],
    });
  }

  if (!isPossiblePhoneNumber(phone, 'BG')) {
    ctx.addIssue({
      code: 'custom',
      message: i18n.t('errors.invalid_phone_number'),
      path: ['phone'],
    });
  }

  if (!phone.startsWith('+359')) {
    ctx.addIssue({
      code: 'custom',
      message: i18n.t('errors.phone_number_does_not_start_with_359'),
      path: ['phone'],
    });
  }
});

export default VerifyCustomerSchema;
