import { t } from 'i18next';
import { OrderDirection } from '../constants/order-direction.constants';

export const GetOrderType = (orderType: string) => {
  switch (orderType) {
    case OrderDirection.Buy: {
      return t('orders.buy');
    }
    case OrderDirection.Sell: {
      return t('orders.sell');
    }
    default: {
      return '';
    }
  }
};
