import { ExecutionStrategyType } from '../../constants/execution-strategy.constants';
import { WithdrawalRequestStatusType } from '../../constants/withdrawal/withdrawal-request-status.constants';
import { PaymentMethodModel } from '../payment-methods/payment-method.model';
import { WithdrawalModel } from './withdrawal.model';

export type WithdrawalRequest = {
  amount: number;
  createdAt: Date;
  customerId: number;
  executionStrategy: ExecutionStrategyType;
  id: number;
  paymentMethod: PaymentMethodModel;
  status: WithdrawalRequestStatusType;
  updatedAt: Date;
};

export const MapToAwaitingApprovalWithdrawalRequest = (
  withdrawal: WithdrawalModel
): WithdrawalRequest => {
  return MapToWithdrawalRequest(withdrawal, 'AWAITING_APPROVAL');
};

const MapToWithdrawalRequest = (
  withdrawal: WithdrawalModel,
  status: WithdrawalRequestStatusType
): WithdrawalRequest => {
  const withdrawalRequest: WithdrawalRequest = {
    amount: withdrawal.amount,
    createdAt: withdrawal.createdAt,
    customerId: withdrawal.customerId,
    executionStrategy: withdrawal.executionStrategy,
    id: withdrawal.id,
    paymentMethod: withdrawal.paymentMethod,
    status: status,
    updatedAt: withdrawal.updatedAt,
  };

  return withdrawalRequest;
};
