import {
  Box,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ButtonOutlinedComponent from '../../../common/button/button-outlined.component';
import { PaymentMethodModel } from '../../../../models/payment-methods/payment-method.model';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import { CurrencyFormatter } from '../../../../utilities/currency.utility';
import { WithdrawalPaymentMethodModel } from '../../../../models/payment-methods/withdrawal-payment-method.model';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const WithdrawBankAccountElementComponent = ({
  withdrawalPaymentMethod,
  setPaymentMethod,
}: {
  withdrawalPaymentMethod: WithdrawalPaymentMethodModel;
  setPaymentMethod: (paymentMethod: PaymentMethodModel) => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isXsBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
  const [iban] = useState(withdrawalPaymentMethod.paymentMethod.description);

  return (
    <ButtonOutlinedComponent
      key={withdrawalPaymentMethod.paymentMethod.id}
      startIcon={<AccountBalanceOutlinedIcon />}
      color="primary"
      sx={{
        height: '50px',
      }}
      onClick={() => setPaymentMethod(withdrawalPaymentMethod.paymentMethod)}
    >
      <Box
        sx={{
          width: '80%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '10px', sm: '12px' },
              textAlign: 'start',
            }}
          >
            {withdrawalPaymentMethod.paymentMethod.subMethod}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: '12px', sm: '14px' },
              fontWeight: 'bold',
            }}
          >
            {isXsBreakpoint
              ? iban.substring(0, 4) +
                ' ... ' +
                iban.substring(iban.length - 6, iban.length)
              : iban}
          </Typography>
        </Box>

        <Tooltip
          title={t('withdraw_dialog.select_payment_method.withdraw_max_amount')}
        >
          <Typography
            sx={{
              fontSize: { xs: '12px', sm: '14px' },
              fontWeight: 'bold',
              textAlign: 'start',
            }}
          >
            {CurrencyFormatter(withdrawalPaymentMethod.balance)}
          </Typography>
        </Tooltip>
      </Box>
    </ButtonOutlinedComponent>
  );
};

export default WithdrawBankAccountElementComponent;
