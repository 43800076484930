import { Box, Typography, useTheme } from '@mui/material';
import { isNil } from 'lodash';
import { ReactNode, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CustomerService from '../../services/customer/customer.service';
import ImageGradientComponent from '../common/gradient/image-gradient.component';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';
import Logo from '../../assets/img/icons/landsera-blue-logo.png';
import RegistrationCover from '../../assets/img/brand/registration-cover.jpg';
import { useTranslation } from 'react-i18next';
import LoadingComponent from '../common/loading-spinner/loading.component';
import { take } from 'rxjs';
import { RoutesConstants } from '../../constants/routes.constants';
import ButtonComponent from '../common/button/button.component';

const CustomerActivationComponent = () => {
  const CODE_SEARCH_PARAM = 'code';
  const REDIRECT_TIME_IN_SECONDS = 5;

  const [activationCode, setActivationCode] = useState<string | null>(null);

  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const [isActivationDone, setIsActivationDone] = useState(false);
  const [isActivationSuccessful, setIsActivationSuccessful] = useState(false);
  const [redirectCounter, setRedirectCounter] = useState(0);
  const [isRedirectTimerStarted, setIsRedirectTimerStarted] = useState(false);

  const [supportEmail] = useState('support@landsera.com');

  // This is done so we hide the buttons for now. When we implement the resending of emails
  // we will show the buttons.
  const [isHidden] = useState(true);

  const successfulActivation = () => {
    setIsActivationDone(true);
    setIsActivationSuccessful(true);
    setRedirectCounter(REDIRECT_TIME_IN_SECONDS);
    setIsRedirectTimerStarted(true);
  };

  const failedActivation = () => {
    setIsActivationDone(true);
    setIsActivationSuccessful(false);
  };

  useEffect(() => {
    const _activationCode = searchParams.get(CODE_SEARCH_PARAM);

    setActivationCode(_activationCode);

    activateCustomer(_activationCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (redirectCounter > 0) {
      setTimeout(() => setRedirectCounter(redirectCounter - 1), 1000);
    }

    if (redirectCounter === 0 && isRedirectTimerStarted) {
      navigate(RoutesConstants.Login);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectCounter, isRedirectTimerStarted]);

  const onTryAgainClick = () => {
    activateCustomer(activationCode);
  };

  const onResendActivationEmailClick = () => {
    console.log('Resend activation email');
  };

  const activateCustomer = (activationCode: string | null) => {
    if (!isNil(activationCode)) {
      CustomerService.ActivateCustomer(activationCode)
        .pipe(take(1))
        .subscribe({
          next: () => {
            successfulActivation();
          },
          error: () => {
            failedActivation();
          },
          complete: () => {
            successfulActivation();
          },
        });
    }
  };

  const successfulActivationDisplay = (): ReactNode => {
    return (
      <>
        <Box
          sx={{
            marginTop: theme.spacing(8),
          }}
        >
          <CheckBoxIcon color="success" sx={{ fontSize: '128px' }} />
        </Box>

        <Box
          sx={{
            marginTop: theme.spacing(4),
            textAlign: 'center',
            width: '80%',
          }}
        >
          <Typography
            sx={{
              fontSize: '24px',
              color: theme.palette.success.main,
            }}
          >
            {t('customer_activation.success.title')}
          </Typography>
        </Box>

        <Box
          sx={{
            marginTop: theme.spacing(4),
            textAlign: 'center',
            width: '80%',
          }}
        >
          <Typography
            sx={{
              fontSize: '18px',
            }}
          >
            {`${t(
              'customer_activation.success.message'
            )} ${redirectCounter} ${t('customer_activation.seconds')}`}
          </Typography>
        </Box>
      </>
    );
  };

  const failedActivationDisplay = (): ReactNode => {
    return (
      <>
        <Box
          sx={{
            marginTop: theme.spacing(8),
          }}
        >
          <ErrorOutlineSharpIcon color="error" sx={{ fontSize: '128px' }} />
        </Box>

        <Box
          sx={{
            marginTop: theme.spacing(4),
            textAlign: 'center',
            width: '80%',
          }}
        >
          <Typography
            sx={{
              fontSize: '24px',
              color: theme.palette.error.main,
            }}
          >
            {t('customer_activation.failed.title')}
          </Typography>
        </Box>

        <Box
          sx={{
            marginTop: theme.spacing(4),
            textAlign: 'center',
            width: '80%',
          }}
        >
          <Typography
            sx={{
              fontSize: '18px',
            }}
          >
            {t('customer_activation.failed.message', {
              supportEmail,
            })}
          </Typography>
        </Box>

        {!isHidden && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              width: '80%',
              marginTop: theme.spacing(4),
              gap: theme.spacing(1),
            }}
          >
            <ButtonComponent sx={{ width: '180px' }} onClick={onTryAgainClick}>
              {t('customer_activation.failed.try_again')}
            </ButtonComponent>
            <ButtonComponent
              sx={{ width: '180px' }}
              onClick={onResendActivationEmailClick}
            >
              {t('customer_activation.failed.resend')}
            </ButtonComponent>
          </Box>
        )}
      </>
    );
  };

  return (
    <LoadingComponent isLoading={!isActivationDone}>
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        <Box
          sx={{
            flex: 1,
            minWidth: '370px',
            height: '100%',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: theme.spacing(10),
              }}
            >
              <Box
                component="img"
                src={Logo}
                sx={{
                  height: '70px',
                  width: '340px',
                  objectFit: 'contain',
                }}
              ></Box>
            </Box>

            {!isActivationSuccessful
              ? failedActivationDisplay()
              : successfulActivationDisplay()}
          </Box>
        </Box>

        <Box
          sx={{
            backgroundImage: `url(${RegistrationCover})`,
            backgroundSize: 'cover',
            flex: 1.8,
            minWidth: '340px',
            '@media (max-width: 800px)': {
              display: 'none',
            },
          }}
        >
          <ImageGradientComponent />
        </Box>
      </Box>
    </LoadingComponent>
  );
};

export default CustomerActivationComponent;
