import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Container, useTheme } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TypeOf } from 'zod';
import WithdrawSchema from '../../../schemas/withdrawals/withdraw.schema';
import ButtonComponent from '../../common/button/button.component';
import FormCurrencyInputComponent from '../../common/forms/form-currency-input.component';

type IWithdrawForm = TypeOf<typeof WithdrawSchema>;

const WithdrawInputAmountComponent = ({
  customerCash,
  submitWithdrawAmount,
}: {
  customerCash: number;
  submitWithdrawAmount: (amount: number) => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const defaultCardDepositFormValues: IWithdrawForm = {
    withdrawAmount: '100',
    availableCustomerCash: customerCash,
  };

  const methods = useForm<IWithdrawForm>({
    resolver: zodResolver(WithdrawSchema),
    defaultValues: defaultCardDepositFormValues,
  });

  const submitForm: SubmitHandler<IWithdrawForm> = (
    withdrawForm: IWithdrawForm
  ) => {
    submitWithdrawAmount(Number(withdrawForm.withdrawAmount));
  };

  return (
    <Box>
      <Container sx={{ height: '100%' }}>
        <FormProvider {...methods}>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={methods.handleSubmit(submitForm)}
            display="flex"
            flexDirection="column"
            paddingTop={theme.spacing(2)}
          >
            <FormCurrencyInputComponent
              label={t('withdraw_dialog.input.fields.withdraw_amount')}
              type="text"
              name="withdrawAmount"
              currency={'BGN'}
              variant="filled"
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: theme.spacing(2),
              }}
            >
              <ButtonComponent
                sx={{
                  width: '200px',
                  height: '50px',
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
                type="submit"
              >
                {t('withdraw_dialog.input.next_button')}
              </ButtonComponent>
            </Box>
          </Box>
        </FormProvider>
      </Container>
    </Box>
  );
};

export default WithdrawInputAmountComponent;
