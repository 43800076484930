import { AccordionSummary, AccordionSummaryProps, styled } from '@mui/material';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

const AccordionSummaryComponent = styled((props: AccordionSummaryProps) => (
  <AccordionSummary expandIcon={<ExpandMoreOutlinedIcon />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
}));

export default AccordionSummaryComponent;
