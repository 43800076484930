export type StripeIdentityDocumentsStatusCodeType =
  | 'DOCUMENT_ADDRESS_MISMATCH'
  | 'DOCUMENT_DOB_MISMATCH'
  | 'DOCUMENT_DUPLICATE_TYPE'
  | 'DOCUMENT_ID_NUMBER_MISMATCH'
  | 'DOCUMENT_NAME_MISMATCH'
  | 'DOCUMENT_NATIONALITY_MISMATCH'
  | 'FAILED_KEYED_IDENTITY'
  | 'FAILED_OTHER'
  | 'SUCCESS';

export const StripeIdentityDocumentsStatusCode = {
  DocumentAddressMismatch: 'DOCUMENT_ADDRESS_MISMATCH',
  DocumentDobMismatch: 'DOCUMENT_DOB_MISMATCH',
  DocumentDuplicateType: 'DOCUMENT_DUPLICATE_TYPE',
  DocumentIdNumberMismatch: 'DOCUMENT_ID_NUMBER_MISMATCH',
  DocumentNameMismatch: 'DOCUMENT_NAME_MISMATCH',
  DocumentNationalityMismatch: 'DOCUMENT_NATIONALITY_MISMATCH',
  FailedKeyedIdentity: 'FAILED_KEYED_IDENTITY',
  FailedOther: 'FAILED_OTHER',
  Success: 'SUCCESS',
};
