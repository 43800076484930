export const StripeAccountCapabilityConstants = {
  CardPayment: 'CARD_PAYMENTS',
  Transfers: 'TRANSFERS',
};

export const StripeAccountCapabilityStatusConstants = {
  Active: 'ACTIVE',
  Inactive: 'INACTIVE',
  Pending: 'PENDING',
  Requested: 'REQUESTED',
};
