import { object, string } from 'zod';

const CustomerProfileInformationSchema = object({
  firstName: string(),
  lastName: string(),
  email: string(),
  phoneNumber: string(),
  address: string(),
  city: string(),
  postalCode: string(),
  status: string(),
});

export default CustomerProfileInformationSchema;
