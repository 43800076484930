import { TransactionTableModel } from '../../models/transaction/transaction-table.model';
import ColumnDefinitionModel from '../../models/table/column-definition.model';
import { ColumnType } from './column-type.constants';

export const TransactionColumnsDefinition: ColumnDefinitionModel<
  TransactionTableModel,
  keyof TransactionTableModel
>[] = [
  {
    key: 'id',
    headerTranslationId: 'history_page.transactions_table.id',
    type: ColumnType.Text,
  },
  {
    key: 'transactionType',
    headerTranslationId: 'history_page.transactions_table.transaction_type',
    type: ColumnType.Text,
  },
  {
    key: 'amount',
    headerTranslationId: 'history_page.transactions_table.transaction_amount',
    type: ColumnType.Currency,
  },
  {
    key: 'createdAt',
    headerTranslationId: 'history_page.transactions_table.transaction_date',
    type: ColumnType.Date,
  },
];
