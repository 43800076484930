import 'react-horizontal-scrolling-menu/dist/styles.css';
import '../../../assets/css/horizontal-menu-flex-item.css';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { ReactNode } from 'react';
import {
  HorizontalMenuLeftArrowComponent,
  HorizontalMenuRightArrowComponent,
} from './horizontal-menu-arrows.component';
import useDrag from '../../../hooks/use-drag.hook';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type ScrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

const HorizontalMenuComponent = <TItem,>({
  items,
  renderItem,
  onItemClick,
  isLeftArrowVisible = false,
  isRightArrowVisible = false,
  defaultNoRecordsMessage,
}: {
  items: TItem[];
  renderItem: (item: TItem, itemIndex: number) => ReactNode;
  onItemClick?: (item: TItem) => void;
  isLeftArrowVisible?: boolean;
  isRightArrowVisible?: boolean;
  defaultNoRecordsMessage?: string;
}) => {
  const { t } = useTranslation();

  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const handleDrag =
    ({ scrollContainer }: ScrollVisibilityApiType) =>
    (ev: React.MouseEvent) =>
      dragMove(ev, (posDiff) => {
        if (scrollContainer.current) {
          scrollContainer.current.scrollLeft += posDiff;
        }
      });

  const handleItemClick = (item: TItem) => () => {
    if (dragging) {
      return false;
    }

    onItemClick && onItemClick(item);
  };

  return (
    <>
      {items.length === 0 && (
        <Typography>
          {defaultNoRecordsMessage ?? t('information.no_records')}
        </Typography>
      )}
      <ScrollMenu
        LeftArrow={
          isLeftArrowVisible &&
          items.length > 0 &&
          HorizontalMenuLeftArrowComponent
        }
        RightArrow={
          isRightArrowVisible &&
          items.length > 0 &&
          HorizontalMenuRightArrowComponent
        }
        onMouseDown={() => dragStart}
        onMouseUp={() => dragStop}
        onMouseMove={handleDrag}
      >
        {items.map((item, itemIndex) => (
          <Box key={itemIndex} onClick={handleItemClick(item)} sx={{ flex: 1 }}>
            {renderItem(item, itemIndex)}
          </Box>
        ))}
      </ScrollMenu>
    </>
  );
};

export default HorizontalMenuComponent;
