import { DepositTableModel } from '../../models/deposit/deposit-table.model';
import ColumnDefinitionModel from '../../models/table/column-definition.model';
import { ColumnType } from './column-type.constants';

export const DepositColumnsDefinition: ColumnDefinitionModel<
  DepositTableModel,
  keyof DepositTableModel
>[] = [
  {
    key: 'depositType',
    headerTranslationId: 'history_page.deposits_table.deposit_type',
    type: ColumnType.Text,
  },
  {
    key: 'depositMethod',
    headerTranslationId: 'history_page.deposits_table.deposit_method',
    type: ColumnType.Text,
    width: 240,
  },
  {
    key: 'amount',
    headerTranslationId: 'history_page.deposits_table.deposit_amount',
    type: ColumnType.Currency,
  },
  {
    key: 'createdAt',
    headerTranslationId: 'history_page.deposits_table.deposit_date',
    type: ColumnType.Date,
  },
];
