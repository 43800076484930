import { Box, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Subject, take, takeUntil } from 'rxjs';
import { CommentProps } from '../../../models/common/comments/comment-props.model';
import { CommentModel } from '../../../models/common/comments/comment.model';
import SharedDataService from '../../../services/shared-data/shared-data.service';
import { timeAgo } from '../../../utilities/time';
import { useTranslation } from 'react-i18next';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import CommentAvatarComponent from './comment-avatar.component';
import CommentTextareaComponent from './comment-textarea.component';
import CustomerContext from '../../../providers/customer/customer.provider';

const CommentComponent = ({ comment, getReplies, onReply }: CommentProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [replies, setReplies] = useState<CommentModel[]>([]);

  const [areRepliesOpened, setAreRepliesOpened] = useState(false);

  const destroy$ = new Subject<void>();

  const { customer } = useContext(CustomerContext);
  useEffect(() => {
    SharedDataService.GetUpdateCommentRepliesEvent()
      .pipe(takeUntil(destroy$))
      .subscribe((commentId: number) => {
        if (commentId === comment.id) {
          updateReplies();
        }
      });

    SharedDataService.GetUpdateCommentsEvent()
      .pipe(takeUntil(destroy$))
      .subscribe(() => {
        setAreRepliesOpened(false);
      });

    return () => {
      destroy$.next();
      destroy$.complete();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCommentTime = (comment: CommentModel): number => {
    return new Date().getTime() - new Date(comment.createdAt).getTime();
  };

  const updateReplies = () => {
    getReplies(comment.id)
      .pipe(take(1))
      .subscribe((commentReplies) => {
        setReplies(commentReplies);
      });
  };

  const toggleReplies = (areRepliesOpened: boolean) => {
    if (areRepliesOpened) {
      hideReplies();
    } else {
      showReplies();
    }
  };
  const showReplies = () => {
    setAreRepliesOpened(true);
    updateReplies();
  };

  const hideReplies = () => {
    setAreRepliesOpened(false);
  };

  const addReply = (replyContent: string) => {
    onReply(comment.id, replyContent);
  };

  const renderCommentRepliesMenu = (areRepliesOpened: boolean): JSX.Element => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: '-0.3rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            cursor: 'pointer',
          }}
          onClick={() => toggleReplies(areRepliesOpened)}
        >
          {areRepliesOpened ? (
            <ArrowDropUpOutlinedIcon color="primary" />
          ) : (
            <ArrowDropDownOutlinedIcon color="primary" />
          )}

          <Typography
            color="primary"
            sx={{
              fontSize: '14px',
            }}
          >
            {t('comment_section.comments.view_replies')}
          </Typography>
        </Box>
      </Box>
    );
  };

  const renderComment = (
    comment: CommentModel,
    isReply = false
  ): JSX.Element => {
    return (
      <Box
        key={comment.id}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(0.2),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: theme.spacing(1),
          }}
        >
          <CommentAvatarComponent
            abbreviation={`${comment.author.firstName[0]}${comment.author.lastName[0]}`}
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: theme.spacing(1),
                alignItems: 'center',
              }}
            >
              <Typography
                color="primary"
                sx={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
              >
                {`${comment.author.firstName} ${comment.author.lastName}`}
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                }}
              >{`${t('comment_section.comments.before')}${timeAgo(
                getCommentTime(comment)
              )}${t('comment_section.comments.time_ago')}`}</Typography>
            </Box>
            <Box>
              <Typography>{comment.content}</Typography>
            </Box>

            {!isReply && (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: theme.spacing(1),
                  }}
                >
                  {renderCommentRepliesMenu(areRepliesOpened)}
                </Box>

                {areRepliesOpened && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingTop: theme.spacing(1),
                      gap: theme.spacing(2),
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: theme.spacing(2),
                      }}
                    >
                      {replies.map((reply) => {
                        return renderComment(reply, true);
                      })}
                    </Box>

                    <CommentTextareaComponent
                      addComment={addReply}
                      avatarAbbreviation={`${customer?.firstName[0]}${customer?.lastName[0]}`}
                      isReply={true}
                      maxLength={2048}
                    />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  return <>{renderComment(comment)}</>;
};

export default CommentComponent;
