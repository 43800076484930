import { createContext } from 'react';
import { PortfolioMetricsModel } from '../../models/portfolio-metrics/portfolio-metrics.model';

const PortfolioMetricsContext = createContext<{
  portfolioMetrics: PortfolioMetricsModel | undefined;
  arePortfolioMetricsLoading: boolean;
}>({ portfolioMetrics: undefined, arePortfolioMetricsLoading: false });

export const PortfolioMetricsProvider = PortfolioMetricsContext.Provider;
export default PortfolioMetricsContext;
