import DOMPurify from 'dompurify';

if (window.trustedTypes && window.trustedTypes.createPolicy) {
  window.trustedTypes.createPolicy('default', {
    createHTML: (string) =>
      DOMPurify.sanitize(string, { RETURN_TRUSTED_TYPE: true }),
    createScriptURL: (input) => {
      if (new URL(input).origin === 'https://js.stripe.com') {
        return input;
      }
      return undefined;
    },
  });
}
