import { Observable, Subject } from 'rxjs';

const toggleNavbarCustomerInformationEventSubject$ = new Subject<boolean>();
const toggleNavbarCustomerInformationEvent$ =
  toggleNavbarCustomerInformationEventSubject$.asObservable();

const updateCustomerInformationEventSubject$ = new Subject<void>();
const updateCustomerInformationEvent$ =
  updateCustomerInformationEventSubject$.asObservable();

const updatePendingWithdrawalsRequestsEventSubject$ = new Subject<void>();
const updatePendingWithdrawalsRequestsEvent$ =
  updatePendingWithdrawalsRequestsEventSubject$.asObservable();

const updateCommentsEventSubject$ = new Subject<void>();
const updateCommentsEvent$ = updateCommentsEventSubject$.asObservable();

const updateCommentRepliesEventSubject$ = new Subject<number>();
const updateCommentRepliesEvent$ =
  updateCommentRepliesEventSubject$.asObservable();

const updatePropertyEventSubject$ = new Subject<void>();
const updatePropertyEvent$ = updatePropertyEventSubject$.asObservable();

const updatePortfolioMetricsEventSubject$ = new Subject<void>();
const updatePortfolioMetricsEvent$ =
  updatePortfolioMetricsEventSubject$.asObservable();

const SharedDataService = {
  TriggerToggleNavbarCustomerInformationEvent(isHidden: boolean) {
    toggleNavbarCustomerInformationEventSubject$.next(isHidden);
  },

  GetToggleNavbarCustomerInformationEvent(): Observable<boolean> {
    return toggleNavbarCustomerInformationEvent$;
  },

  TriggerUpdateCustomerInformationEvent() {
    updateCustomerInformationEventSubject$.next();
  },

  GetUpdateCustomerInformationEvent(): Observable<void> {
    return updateCustomerInformationEvent$;
  },

  TriggerUpdatePendingWithdrawalsRequestsEvent() {
    updatePendingWithdrawalsRequestsEventSubject$.next();
  },

  GetUpdatePendingWithdrawalsRequestsEvent(): Observable<void> {
    return updatePendingWithdrawalsRequestsEvent$;
  },

  TriggerUpdateCommentsEvent() {
    updateCommentsEventSubject$.next();
  },

  GetUpdateCommentsEvent(): Observable<void> {
    return updateCommentsEvent$;
  },

  TriggerUpdateCommentRepliesEvent(commentId: number) {
    updateCommentRepliesEventSubject$.next(commentId);
  },

  GetUpdateCommentRepliesEvent(): Observable<number> {
    return updateCommentRepliesEvent$;
  },

  TriggerUpdatePropertyEvent() {
    updatePropertyEventSubject$.next();
  },

  GetUpdatePropertyEvent(): Observable<void> {
    return updatePropertyEvent$;
  },

  TriggerUpdatePortfolioMetricsEvent() {
    updatePortfolioMetricsEventSubject$.next();
  },

  GetUpdatePortfolioMetricsEvent(): Observable<void> {
    return updatePortfolioMetricsEvent$;
  },
};

export default SharedDataService;
