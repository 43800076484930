import { InvestmentsTableModel } from '../../models/investments/investments-table.model';
import ColumnDefinitionModel from '../../models/table/column-definition.model';
import { ColumnType } from './column-type.constants';

export const InvestmentsColumnsDefinition: ColumnDefinitionModel<
  InvestmentsTableModel,
  keyof InvestmentsTableModel
>[] = [
  {
    key: 'propertyImage',
    headerTranslationId: 'investments.table_columns.property_image',
    type: ColumnType.Image,
  },
  {
    key: 'propertyName',
    headerTranslationId: 'investments.table_columns.property_name',
    type: ColumnType.Text,
  },
  {
    key: 'propertyType',
    headerTranslationId: 'investments.table_columns.property_type',
    type: ColumnType.Text,
  },
  {
    key: 'propertyPrice',
    headerTranslationId: 'investments.table_columns.property_price',
    type: ColumnType.Currency,
  },
  {
    key: 'quantity',
    headerTranslationId: 'investments.table_columns.investment_quantity',
    type: ColumnType.Number,
  },
  {
    key: 'investmentValue',
    headerTranslationId: 'investments.table_columns.investment_value',
    type: ColumnType.Currency,
  },
  {
    key: 'details',
    headerTranslationId: 'investments.table_columns.property_details',
    type: ColumnType.Button,
  },
];
