import { Badge, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../assets/img/icons/landsera-blue-logo.png';
import { RoutesConstants } from '../../constants/routes.constants';
import ButtonComponent from '../common/button/button.component';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import GetPlatformRoute from '../../utilities/route.utility';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import NavbarDropdownMenuComponent from './navbar-dropdown-menu.component';
import NavbarBalanceMenu from './navbar-balance-menu.component';
import { Subject, takeUntil } from 'rxjs';
import useObservable from '../../hooks/use-observable.hook';
import SharedDataService from '../../services/shared-data/shared-data.service';
import { isNil } from 'lodash';
import { CurrencyFormatter } from '../../utilities/currency.utility';
import LoadingComponent from '../common/loading-spinner/loading.component';
import { DialogType } from '../../services/dialog-manager/dialog-manager.service';
import withDialogs from '../../providers/dialog/with-dialog';
import DepositDialogComponent from '../deposits/deposit-dialog.component';
import { DialogManagerProps } from '../../models/common/dialogs/dialog-manager-props.model';
import CustomerContext from '../../providers/customer/customer.provider';
import NavbarNotificationMenuComponent from './navbar-notification-menu.component';
import { NotificationService } from '../../services/notifications/notification.service';
import { NotificationStatus } from '../../constants/notifications/notification-status.constants';
import PortfolioMetricsContext from '../../providers/portfolio-metrics/portfolio-metrics.provider';

const NavbarComponent = ({ dialogManagerService }: DialogManagerProps) => {
  const [areAllNotificationsRead, setAreAllNotificationRead] = useState(true);

  const [menuAnchor, setMenuAnchor] = useState<null | Element>(null);
  const [balanceMenuAnchor, setBalanceMenuAnchor] = useState<null | Element>(
    null
  );
  const [notificationMenuAnchor, setNotificationMenuAnchor] =
    useState<null | Element>(null);

  const [refreshNotifications, setRefreshNotifications] =
    useState<boolean>(false);

  const refreshNotificationsRef = useRef(refreshNotifications);

  const destroy$ = new Subject<void>();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const redirectToHome = () => {
    removeMenuAnchor();
    navigate(GetPlatformRoute(RoutesConstants.Home));
  };

  const redirectToMyInvestments = () => {
    removeMenuAnchor();
    navigate(GetPlatformRoute(RoutesConstants.Investments));
  };

  const redirectToProperties = () => {
    removeMenuAnchor();
    navigate(GetPlatformRoute(RoutesConstants.Properties));
  };

  const openDepositDialog = () => {
    const dialog: DialogType = {
      component: DepositDialogComponent,
      props: {
        customer: customer,
      },
    };
    dialogManagerService.Open(dialog);
  };

  const openBalanceMenu = (event?: React.MouseEvent<Element>) => {
    if (isNil(event)) {
      setBalanceMenuAnchor(menuAnchor);
    } else {
      setBalanceMenuAnchor(event.currentTarget);
    }

    setMenuAnchor(null);
  };

  const openNotificationsMenu = (event: React.MouseEvent<Element>) => {
    setMenuAnchor(null);
    setBalanceMenuAnchor(null);
    setNotificationMenuAnchor(event.currentTarget);
    setAreAllNotificationRead(true);
  };

  const openMenu = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    setMenuAnchor(event.currentTarget);
  };

  const removeMenuAnchor = () => setMenuAnchor(null);
  const removeBalanceMenuAnchor = () => setBalanceMenuAnchor(null);
  const removeNotificationMenuAnchor = () => setNotificationMenuAnchor(null);

  useEffect(() => {
    refreshNotificationsRef.current = refreshNotifications;
  });

  useEffect(() => {
    SharedDataService.GetUpdateCustomerInformationEvent()
      .pipe(takeUntil(destroy$))
      .subscribe(() => SharedDataService.TriggerUpdatePortfolioMetricsEvent());

    return () => {
      destroy$.next();
      destroy$.complete();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { customer, isCustomerLoading } = useContext(CustomerContext);
  const { portfolioMetrics } = useContext(PortfolioMetricsContext);

  const notifications$ = useMemo(
    () => NotificationService.GetNotifications(),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshNotifications]
  );
  const [notifications, ,] = useObservable(notifications$);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRefreshNotifications(!refreshNotificationsRef.current);
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!isNil(notifications)) {
      const unreadNotifications = notifications.filter(
        (notification) => notification.status === NotificationStatus.New
      );

      setAreAllNotificationRead(unreadNotifications.length === 0);
    }
  }, [notifications]);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '60px',
          backgroundColor: '#ffffff',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={(theme) => ({
            paddingLeft: {
              xs: theme.spacing(2),
              sm: theme.spacing(4),
              md: theme.spacing(8),
            },
            height: '100%',
            display: 'flex',
            alignItems: 'center',
          })}
        >
          <Box
            component="img"
            src={Logo}
            sx={{
              height: '30px',
              width: '150px',
              objectFit: 'contain',
              cursor: 'pointer',
              paddingBottom: '6px',
            }}
            onClick={redirectToHome}
          ></Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            alignItems: 'center',
            gap: (theme) => theme.spacing(2),
          }}
        >
          <Box
            sx={{
              display: { xs: 'none', sm: 'none', md: 'flex' },
              flexDirection: 'row',
              height: '100%',
              alignItems: 'center',
              paddingTop: '12px',
              gap: (theme) => theme.spacing(2),
              paddingRight: (theme) => theme.spacing(4),
            }}
          >
            <Button
              sx={{
                fontSize: '18px',
                fontWeight:
                  location.pathname ===
                  GetPlatformRoute(RoutesConstants.Investments)
                    ? 'bold'
                    : 'normal',
                height: '30px',
                textTransform: 'none',
                transition: 'all .2s ease',
                '&:hover': {
                  backgroundColor: 'transparent',
                  fontWeight: 'bold',
                },
              }}
              onClick={redirectToMyInvestments}
            >
              {t('navbar.my_investments')}
            </Button>

            <Button
              sx={{
                fontSize: '18px',
                height: '30px',
                textTransform: 'none',
                transition: 'all .2s ease',
                fontWeight:
                  location.pathname ===
                    GetPlatformRoute(RoutesConstants.Home) ||
                  location.pathname ===
                    GetPlatformRoute(RoutesConstants.Properties)
                    ? 'bold'
                    : 'normal',
                '&:hover': {
                  backgroundColor: 'transparent',
                  fontWeight: 'bold',
                },
              }}
              onClick={redirectToProperties}
            >
              {t('navbar.properties')}
            </Button>
          </Box>

          <Box
            sx={{
              paddingTop: '6px',
              display: { xs: 'none', sm: 'block' },
            }}
          >
            <ButtonComponent
              sx={{
                textTransform: 'none',
                height: '30px',
                width: '160px',
                fontSize: '14px',
              }}
              onClick={openDepositDialog}
            >
              {t('navbar.deposit')}
            </ButtonComponent>
          </Box>

          <Box
            sx={{
              display: { xs: 'none', sm: 'none', md: 'flex' },
              flexDirection: 'row',
              alignItems: 'center',
              paddingTop: '6px',
            }}
          >
            <AccountBalanceWalletOutlinedIcon
              sx={{
                fontSize: '24px',
                color: (theme) => theme.palette.secondary.dark,
                paddingRight: (theme) => theme.spacing(0.4),
              }}
            />
            <ButtonComponent
              sx={{
                width: '100px',
                height: '30px',
                fontSize: '14px',
                textTransform: 'none',
                backgroundColor: (theme) => theme.palette.secondary.dark,
                color: '#FFFFFF',
                '&:hover': {
                  backgroundColor: 'transparent',
                  border: (theme) =>
                    `2px solid ${theme.palette.secondary.dark}`,
                  color: (theme) => theme.palette.secondary.dark,
                },
              }}
              onClick={openBalanceMenu}
            >
              <LoadingComponent isLoading={isCustomerLoading}>
                {CurrencyFormatter(customer?.cash ?? 0)}
              </LoadingComponent>
            </ButtonComponent>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingTop: '6px',
              paddingRight: (theme) => theme.spacing(2),
              gap: (theme) => theme.spacing(0.4),
            }}
          >
            <Badge
              color="error"
              variant="dot"
              invisible={areAllNotificationsRead}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <NotificationsNoneIcon
                sx={{
                  fontSize: '24px',
                  cursor: 'pointer',
                }}
                color="primary"
                onClick={openNotificationsMenu}
              />
            </Badge>

            <AccountCircleOutlinedIcon
              sx={{
                display: { xs: 'none', sm: 'none', md: 'block' },
                fontSize: '24px',
                cursor: 'pointer',
              }}
              color="primary"
              onClick={openMenu}
            />

            <MenuIcon
              sx={{
                display: { xs: 'block', sm: 'block', md: 'none' },
                fontSize: '24px',
                cursor: 'pointer',
              }}
              color="primary"
              onClick={openMenu}
            />
          </Box>
        </Box>
      </Box>
      <NavbarDropdownMenuComponent
        balance={(customer?.cash ?? 0) + (portfolioMetrics?.investedCash ?? 0)}
        menuAnchor={menuAnchor}
        removeMenuAnchor={removeMenuAnchor}
        openDepositDialog={openDepositDialog}
        openBalanceMenu={openBalanceMenu}
        redirectToMyInvestments={redirectToMyInvestments}
        redirectToProperties={redirectToProperties}
      />
      <NavbarBalanceMenu
        balanceMenuAnchor={balanceMenuAnchor}
        removeBalanceMenuAnchor={removeBalanceMenuAnchor}
      />
      <NavbarNotificationMenuComponent
        notificationMenuAnchor={notificationMenuAnchor}
        removeNotificationMenuAnchor={removeNotificationMenuAnchor}
      />
    </>
  );
};

const wrappedNavbarComponent = withDialogs(NavbarComponent);
export default wrappedNavbarComponent;
