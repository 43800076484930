import { object, string } from 'zod';
import i18n from '../../translations/i18n';

const ForgotPasswordSchema = object({
  email: string()
    .min(1, i18n.t('forgot_password.errors.email_min_length'))
    .email(i18n.t('forgot_password.errors.not_an_email')),
});

export default ForgotPasswordSchema;
