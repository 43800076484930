import { Box, Container, Typography, useTheme } from '@mui/material';
import ImageGradientComponent from '../common/gradient/image-gradient.component';
import Logo from '../../assets/img/icons/landsera-blue-logo.png';
import ForgotPasswordCover from '../../assets/img/brand/forgot-password-cover.jpg';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import FormInputTextComponent from '../common/forms/form-input-text.component';
import ButtonComponent from '../common/button/button.component';
import { isNil } from 'lodash';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import ForgotPasswordSchema from '../../schemas/authentication/forgot-password.schema';
import { TypeOf } from 'zod';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../constants/routes.constants';
import { useState } from 'react';
import CustomerService from '../../services/customer/customer.service';
import { take } from 'rxjs';
import { ApiErrorResponse } from '../../models/common/errors/api-error-response.model';
import { ConstructMessage } from '../../utilities/errors.utility';

type IForgotPasswordForm = TypeOf<typeof ForgotPasswordSchema>;

const ForgotPasswordComponent = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [formErrorMessage, setFormErrorMessage] = useState('');

  const defaultForgotPasswordFormValues: IForgotPasswordForm = {
    email: '',
  };

  const methods = useForm<IForgotPasswordForm>({
    resolver: zodResolver(ForgotPasswordSchema),
    defaultValues: defaultForgotPasswordFormValues,
  });

  const onRegistrationRedirectClick = () => {
    navigate(RoutesConstants.Registration);
  };

  const handleForgotPasswordErrors = (err: ApiErrorResponse<null>) => {
    const data = err.response.data;

    if (!isNil(data.code)) {
      setFormErrorMessage(ConstructMessage('', data.code));
    }
  };

  const onResetPassowrdClick: SubmitHandler<IForgotPasswordForm> = (
    forgotPasswordParameters: IForgotPasswordForm
  ) => {
    CustomerService.SendForgottenPasswordEmail(forgotPasswordParameters.email)
      .pipe(take(1))
      .subscribe({
        complete: () => {
          setFormErrorMessage('');
          navigate(RoutesConstants.Login);
        },
        error: (err: ApiErrorResponse<null>) => {
          handleForgotPasswordErrors(err);
        },
      });
  };

  return (
    <>
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        <Box
          sx={{
            flex: 1,
            minWidth: '370px',
            height: '100%',
          }}
        >
          <Container sx={{ height: '100%' }}>
            <FormProvider {...methods}>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: theme.spacing(10),
                  }}
                >
                  <Box
                    component="img"
                    src={Logo}
                    sx={{
                      height: '70px',
                      width: '340px',
                      objectFit: 'contain',
                    }}
                  ></Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    component="form"
                    noValidate
                    autoComplete="off"
                    onSubmit={methods.handleSubmit(onResetPassowrdClick)}
                    sx={{
                      width: '75%',
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          width: '100%',
                          height: '70px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Typography
                          color="primary"
                          sx={{ fontSize: '24px', fontWeight: 'bold' }}
                        >
                          {t('forgot_password.title')}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textAlign: 'center',
                        }}
                      >
                        <Typography
                          color="primary"
                          sx={{ fontSize: '14px', fontWeight: 'bold' }}
                        >
                          {t('forgot_password.message')}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          marginTop: theme.spacing(3),
                          marginBottom: theme.spacing(3),
                        }}
                      >
                        <FormInputTextComponent
                          label={t('forgot_password.fields.email')}
                          type="text"
                          name="email"
                          variant="filled"
                        />
                      </Box>

                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <ButtonComponent
                          sx={{
                            width: '200px',
                            height: '50px',
                            fontSize: '18px',
                          }}
                          type="submit"
                        >
                          {t('forgot_password.reset_password_button')}
                        </ButtonComponent>
                      </Box>

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          width: '100%',
                          marginTop: theme.spacing(2),
                        }}
                      >
                        {!isNil(formErrorMessage) && (
                          <Typography color="error">
                            {formErrorMessage}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: theme.spacing(5),
                  }}
                >
                  <Typography>{t('forgot_password.not_an_account')}</Typography>
                  <Typography
                    color="primary"
                    sx={{
                      fontWeight: 'bold',
                      cursor: 'pointer',
                      marginLeft: theme.spacing(0.5),
                    }}
                    onClick={onRegistrationRedirectClick}
                  >
                    {t('forgot_password.registration_redirect')}
                  </Typography>
                </Box>
              </Box>
            </FormProvider>
          </Container>
        </Box>

        <Box
          sx={{
            backgroundImage: `url(${ForgotPasswordCover})`,
            backgroundSize: 'cover',
            backgroundPosition: 'right',
            flex: 1.8,
            minWidth: '340px',
            '@media (max-width: 800px)': {
              display: 'none',
            },
          }}
        >
          <ImageGradientComponent />
        </Box>
      </Box>
    </>
  );
};

export default ForgotPasswordComponent;
