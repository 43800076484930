const VerificationDocuments = {
  Passport: 'Passport',
  IdentityCard: 'Identity card',
  DriverLicense: "Driver's license",
  ResidencePermit: 'Residence permit',
  UtilityBill: 'Utility Bill',
  BankStatement: 'Bank statement',
  MortgageStatement: 'Mortgage statement',
};

export const GetDocumentLabelTranslationId = (documentName: string): string => {
  switch (documentName) {
    case VerificationDocuments.Passport:
      return 'stripe_verification.document_verification.passport';
    case VerificationDocuments.IdentityCard:
      return 'stripe_verification.document_verification.identity_card';
    case VerificationDocuments.DriverLicense:
      return 'stripe_verification.document_verification.driver_license';
    case VerificationDocuments.ResidencePermit:
      return 'stripe_verification.document_verification.residence_permit';
    case VerificationDocuments.UtilityBill:
      return 'stripe_verification.document_verification.utility_bill';
    case VerificationDocuments.BankStatement:
      return 'stripe_verification.document_verification.bank_statement';
    case VerificationDocuments.MortgageStatement:
      return 'stripe_verification.document_verification.mortgage_statement';
    default:
      return '';
  }
};
