import {
  Box,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  useTheme,
} from '@mui/material';
import { StepModel } from '../../../models/common/stepper/step.model';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const StepperComponent = ({
  steps,
  nextTrigger,
  returnToStep,
}: {
  steps: StepModel[];
  nextTrigger: number;
  returnToStep: (step: StepModel) => void;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const completeStep = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const removeCompletionFromIndexToEnd = (index: number) => {
    const newCompleted = completed;

    for (let i = index; i < steps.length; i++) {
      newCompleted[i] = false;
    }

    setCompleted(newCompleted);
  };

  useEffect(() => {
    if (nextTrigger > activeStep) {
      completeStep();
      handleNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextTrigger]);

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const returnToPreviousStep = (stepIndex: number) => {
    removeCompletionFromIndexToEnd(stepIndex);

    const step = steps[stepIndex];

    setActiveStep(step.id);
    returnToStep(step);
  };

  const isStepDisabled = (stepIndex: number): boolean => {
    return stepIndex > activeStep;
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
      }}
    >
      <Stepper nonLinear activeStep={activeStep} alternativeLabel>
        {steps.map((step, index) => (
          <Step key={step.id} completed={completed[index]}>
            <StepButton
              color="inherit"
              onClick={() => returnToPreviousStep(index)}
              disabled={isStepDisabled(index)}
            >
              <StepLabel
                sx={{
                  '& .css-puq664-MuiStepLabel-label.MuiStepLabel-alternativeLabel':
                    {
                      marginTop: theme.spacing(1),
                    },
                }}
              >
                {t(step.labelId)}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default StepperComponent;
