import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ImageGradientComponent from '../common/gradient/image-gradient.component';

import LoginCover from '../../assets/img/brand/login-cover.jpg';
import Logo from '../../assets/img/icons/landsera-blue-logo.png';
import FormInputTextComponent from '../common/forms/form-input-text.component';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import LoginSchema from '../../schemas/authentication/login.schema';
import { TypeOf } from 'zod';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useEffect, useState } from 'react';
import { isNil } from 'lodash';
import { AuthenticationModel } from '../../models/authentication/authentication.model';
import GetPlatformRoute from '../../utilities/route.utility';
import { RoutesConstants } from '../../constants/routes.constants';
import { take } from 'rxjs';
import { useNavigate } from 'react-router-dom';
import AuthenticationService from '../../services/authentication/authentication.service';
import ButtonComponent from '../common/button/button.component';
import withDialogs from '../../providers/dialog/with-dialog';
import { DialogManagerProps } from '../../models/common/dialogs/dialog-manager-props.model';

type ILoginForm = TypeOf<typeof LoginSchema>;

const LoginComponent = ({ dialogManagerService }: DialogManagerProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState('');

  const navigate = useNavigate();

  const defaultLoginFormValues: ILoginForm = {
    email: '',
    password: '',
  };

  const methods = useForm<ILoginForm>({
    resolver: zodResolver(LoginSchema),
    defaultValues: defaultLoginFormValues,
  });

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onResetPasswordClick = () => {
    navigate(RoutesConstants.ForgotPassowrd);
  };

  const onRegisterRedirectClick = () => {
    navigate(RoutesConstants.Registration);
  };

  const onLogin: SubmitHandler<ILoginForm> = (signInParameters: ILoginForm) => {
    AuthenticationService.SignIn(signInParameters)
      .pipe(take(1))
      .subscribe({
        next: (authResult: AuthenticationModel | null) => {
          if (
            !isNil(authResult) &&
            !isNil(authResult.token) &&
            !isNil(authResult.tokenExpiryDate)
          ) {
            AuthenticationService.OnSingInSuccess(
              authResult.token,
              authResult.tokenExpiryDate,
              authResult.user
            );

            navigate(GetPlatformRoute(RoutesConstants.Home));
          }
        },
        error: () => {
          // TODO Handle Errors from backend
          setFormErrorMessage(t('login.errors.wrong_email_and_passowrd'));
        },
      });
  };

  useEffect(() => {
    dialogManagerService.CloseAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${LoginCover})`,
          backgroundSize: 'cover',
          flex: 1.8,
          minWidth: '340px',
          '@media (max-width: 800px)': {
            display: 'none',
          },
        }}
      >
        <ImageGradientComponent />
      </Box>
      <Box
        sx={{
          flex: 1,
          minWidth: '370px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Container sx={{ height: '100%' }}>
          <FormProvider {...methods}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: theme.spacing(10),
                }}
              >
                <Box
                  component="img"
                  src={Logo}
                  sx={{
                    height: '70px',
                    width: '340px',
                    objectFit: 'contain',
                  }}
                ></Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  component="form"
                  noValidate
                  autoComplete="off"
                  onSubmit={methods.handleSubmit(onLogin)}
                  sx={{
                    width: '75%',
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        width: '100%',
                        height: '70px',
                        display: 'flex',
                        alignItems: 'end',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        color="primary"
                        sx={{ fontSize: '24px', fontWeight: 'bold' }}
                      >
                        {t('login.title')}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        marginTop: theme.spacing(3),
                        marginBottom: theme.spacing(3),
                      }}
                    >
                      <FormInputTextComponent
                        label={t('login.fields.email')}
                        type="text"
                        name="email"
                        variant="filled"
                      />
                    </Box>

                    <Box sx={{ marginBottom: theme.spacing(0.5) }}>
                      <FormInputTextComponent
                        label={t('login.fields.password')}
                        type={isPasswordVisible ? 'text' : 'password'}
                        name="password"
                        variant="filled"
                        autoComplete="on"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setIsPasswordVisible(!isPasswordVisible)
                              }
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {isPasswordVisible ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        sx={{
                          width: '50px',
                        }}
                      />
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingTop: theme.spacing(0.5),
                        marginBottom: theme.spacing(3),
                      }}
                    >
                      <Box>
                        <Typography
                          color="primary"
                          sx={{
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            fontSize: '16px',
                          }}
                          onClick={onResetPasswordClick}
                        >
                          {t('login.reset_password')}
                        </Typography>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <ButtonComponent
                        sx={{
                          width: '120px',
                          height: '50px',
                          fontSize: '18px',
                        }}
                        type="submit"
                      >
                        {t('login.sign_in_button')}
                      </ButtonComponent>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        marginTop: theme.spacing(2),
                      }}
                    >
                      {!isNil(formErrorMessage) && (
                        <Typography color="error">
                          {formErrorMessage}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: theme.spacing(5),
                }}
              >
                <Typography>{t('login.not_a_profile')}</Typography>
                <Typography
                  color="primary"
                  sx={{
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    marginLeft: theme.spacing(0.5),
                  }}
                  onClick={onRegisterRedirectClick}
                >
                  {t('login.register_redirect')}
                </Typography>
              </Box>
            </Box>
          </FormProvider>
        </Container>
      </Box>
    </Box>
  );
};

const wrappedLoginComponent = withDialogs(LoginComponent);
export default wrappedLoginComponent;
