import { Box, Menu, useTheme } from '@mui/material';
import { NavbarBalanceMenuProps } from '../../models/navbar/navbar-balance-menu-props.model';
import CustomerBalanceComponent from '../customer-balance/customer-balance.component';

const NavbarBalanceMenu = ({
  balanceMenuAnchor,
  removeBalanceMenuAnchor,
}: NavbarBalanceMenuProps) => {
  const theme = useTheme();

  const isBalanceMenuOpen = Boolean(balanceMenuAnchor);

  const handleBalanceMenuClose = () => {
    removeBalanceMenuAnchor();
  };

  return (
    <>
      <Menu
        anchorEl={balanceMenuAnchor}
        open={isBalanceMenuOpen}
        onClose={handleBalanceMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            backgroundColor: '#FFFFFF',
            mt: 3,
            width: { xs: '100%', sm: '100%', md: '550px' },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        disableAutoFocusItem
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            minHeight: '60px',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
          }}
        >
          <CustomerBalanceComponent />
        </Box>
      </Menu>
    </>
  );
};

export default NavbarBalanceMenu;
