import { Observable } from 'rxjs';
import { StripeAcceptableVerificationDocument } from '../../../models/payments/stripe/stripe-acceptable-verification-document.model';
import { StripeConfigModel } from '../../../models/payments/stripe/stripe-config.model';
import { StripeConnectedAccountModel } from '../../../models/payments/stripe/stripe-connected-account.model';
import ApiProvider from '../../../providers/api/api.provider';

const StripeService = {
  GetConfig(): Observable<StripeConfigModel> {
    return ApiProvider.get<StripeConfigModel>(`/stripe/config`);
  },

  GetConnectedAccount(): Observable<StripeConnectedAccountModel | null> {
    return ApiProvider.get<StripeConnectedAccountModel | null>(
      `/stripe/connected-accounts/by-customer`
    );
  },

  CreateConnectedAccount(): Observable<string | null> {
    return ApiProvider.post<string | null>(`/stripe/connected-accounts`, {});
  },

  VerifyConnectedAccount(
    connectedAccountId: string,
    documents: FormData
  ): Observable<never | null> {
    return ApiProvider.post<never | null>(
      `/stripe/connected-accounts/${connectedAccountId}/verify`,
      documents
    );
  },

  GetAllAcceptableVerificationDocuments(): Observable<
    StripeAcceptableVerificationDocument[]
  > {
    return ApiProvider.get<StripeAcceptableVerificationDocument[]>(
      `/stripe/verification/acceptable-documents`
    );
  },
};

export default StripeService;
