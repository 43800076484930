import { DialogTypeModel } from '../../models/common/dialogs/dialog-type.model';
import { MakeId } from '../../utilities/id.utility';

// There is a problem with the types.
// We should use DialogProps as a type for the props
// And this type should be a union of all dialog props types
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DialogType = DialogTypeModel<(props?: any) => JSX.Element, any>;
type DialogArrayType = DialogType[];

export class DialogManagerService {
  dialogs: DialogArrayType = [];
  onDialogChangeEvent: (() => void)[] = [];

  Open(dialog: DialogType) {
    dialog.id = MakeId(13);

    dialog.open = true;

    this.dialogs.push(dialog);
    this.CallDialogsChangeEvents();
  }

  Close(closingDialog: DialogType) {
    this.dialogs = this.dialogs.filter(
      (dialog) => dialog.id !== closingDialog.id
    );
    this.CallDialogsChangeEvents();
  }

  CloseAll() {
    this.dialogs = [];
    this.CallDialogsChangeEvents();
  }

  IsVisible(dialogId: string): boolean {
    const dialogIndex = this.getDialogIndex(dialogId);

    if (dialogIndex < 0) {
      return false;
    }

    return this.dialogs[dialogIndex].open ?? false;
  }

  Show(dialogId: string) {
    this.toggleDialogVisibility(dialogId, true);
    this.CallDialogsChangeEvents();
  }

  Hide(dialogId: string) {
    this.toggleDialogVisibility(dialogId, false);
    this.CallDialogsChangeEvents();
  }

  CallDialogsChangeEvents() {
    this.onDialogChangeEvent.forEach((callback) => callback());
  }

  SubscribeToDialogsChange(callback: () => void) {
    this.onDialogChangeEvent.push(callback);
  }

  private getDialogIndex(dialogId: string): number {
    return this.dialogs.findIndex((dialog) => dialog.id === dialogId);
  }

  private toggleDialogVisibility(dialogId: string, isOpen: boolean) {
    const dialogIndex = this.getDialogIndex(dialogId);

    if (dialogIndex < 0) {
      return;
    }

    this.dialogs[dialogIndex] = { ...this.dialogs[dialogIndex], open: isOpen };
  }
}
