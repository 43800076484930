import { Box, Dialog, Typography, useTheme } from '@mui/material';
import { isNil } from 'lodash';
import { DialogProps } from '../../../models/common/dialogs/dialog-props.model';
import DialogCloseButtonComponent from './dialog-close-button.component';

const DialogComponent = (
  props: DialogProps & {
    dialogTitle?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    width?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    maxHeight?: any;
    backgroundColor?: string;
  }
) => {
  const theme = useTheme();

  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.handleClose}
      PaperProps={{
        sx: {
          maxWidth: { xs: '550px', sm: '850px', md: '1100px', lg: '1400px' },
          maxHeight: props.maxHeight ?? 'auto',
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: props.backgroundColor ?? '#FFFFFF',
          paddingBottom: theme.spacing(2),
          width: props.width ?? { sm: '400px', md: '500px' },
        }}
      >
        <DialogCloseButtonComponent closeDialog={props.handleClose} />

        <Box
          sx={{
            paddingBottom: theme.spacing(2),
            paddingTop: theme.spacing(2),
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5),
          }}
        >
          {!isNil(props.dialogTitle) && (
            <Typography
              color="primary"
              fontSize="16px"
              fontWeight="bold"
              textAlign="center"
            >
              {props.dialogTitle}
            </Typography>
          )}
        </Box>

        <Box>{props.children}</Box>
      </Box>
    </Dialog>
  );
};

export default DialogComponent;
