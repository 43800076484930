import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Container, useTheme } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TypeOf } from 'zod';
import PropertySellShareSchema from '../../../../schemas/investments/property-sell-share.schema';
import ButtonComponent from '../../../common/button/button.component';
import FormCurrencyInputComponent from '../../../common/forms/form-currency-input.component';

type IPropertySellShareForm = TypeOf<typeof PropertySellShareSchema>;

const ShareSaleDialogAmountInput = ({
  availableShareValue,
  setSaleAmount,
}: {
  availableShareValue: number;
  setSaleAmount: (saleAmount: number) => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const defaultPropertySellShareFormValues: IPropertySellShareForm = {
    availableShareValue: availableShareValue,
    saleAmount: '100',
  };

  const methods = useForm<IPropertySellShareForm>({
    resolver: zodResolver(PropertySellShareSchema),
    defaultValues: defaultPropertySellShareFormValues,
  });

  const submitForm: SubmitHandler<IPropertySellShareForm> = (
    propertyShareSellForm: IPropertySellShareForm
  ) => {
    setSaleAmount(+propertyShareSellForm.saleAmount);
  };
  return (
    <Box>
      <Container sx={{ height: '100%' }}>
        <FormProvider {...methods}>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={methods.handleSubmit(submitForm)}
            display="flex"
            flexDirection="column"
            paddingTop={theme.spacing(2)}
          >
            <FormCurrencyInputComponent
              label={t('share_sale_dialog.input.fields.share_sale_amount')}
              type="text"
              name="saleAmount"
              currency={'BGN'}
              variant="filled"
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: theme.spacing(2),
              }}
            >
              <ButtonComponent
                sx={{
                  width: '200px',
                  height: '50px',
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
                type="submit"
              >
                {t('share_sale_dialog.input.next_button')}
              </ButtonComponent>
            </Box>
          </Box>
        </FormProvider>
      </Container>
    </Box>
  );
};

export default ShareSaleDialogAmountInput;
