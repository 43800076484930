import { Box, Button, Paper, Typography, useTheme } from '@mui/material';
import { isNil } from 'lodash';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { PropertyStatusType } from '../../constants/property.constants';
import { DialogManagerProps } from '../../models/common/dialogs/dialog-manager-props.model';
import { InvestmentModel } from '../../models/investments/investment.model';
import { PropertyModel } from '../../models/property/property.model';
import CustomerContext from '../../providers/customer/customer.provider';
import withDialogs from '../../providers/dialog/with-dialog';
import { DialogType } from '../../services/dialog-manager/dialog-manager.service';
import { CurrencyFormatter } from '../../utilities/currency.utility';
import { RoundDecimal } from '../../utilities/number.utility';
import {
  GetPropertyStatusTranslationId,
  GetPropertyStatusColor,
} from '../../utilities/property.utility';
import ProgressBarComponent from '../common/progress-bar/progress-bar.component';
import InvestDialogComponent from '../investments/invest-dialog/invest-dialog.component';
import ShareSaleDialogComponent from '../investments/sell-dialog/share-sale-dialog.component';

const PropertyDetailsMainInformationComponent = ({
  property,
  propertyInvestment,
  dialogManagerService,
}: {
  property: PropertyModel;
  propertyInvestment: InvestmentModel | null;
} & DialogManagerProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { customer } = useContext(CustomerContext);

  const isSellAvailable = (): boolean => {
    return !isNil(propertyInvestment) && propertyInvestment.quantity > 0;
  };

  const isInvestAvailable = (): boolean => {
    return property.status === PropertyStatusType.Financing.key;
  };

  const openInvestmentDialog = () => {
    const dialog: DialogType = {
      component: InvestDialogComponent,
      props: {
        customer: customer,
        propertyId: property.id,
      },
    };
    dialogManagerService.Open(dialog);
  };

  const openShareSaleDialog = () => {
    const dialog: DialogType = {
      component: ShareSaleDialogComponent,
      props: {
        customer: customer,
        propertyId: property.id,
        availableShareValue: RoundDecimal(propertyInvestment?.value ?? 0),
      },
    };
    dialogManagerService.Open(dialog);
  };

  const renderInformationRow = (
    leftFieldLabel: string,
    leftFieldValue: string,
    rightFieldLabel: string,
    rightFieldValue: string
  ): JSX.Element => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <Typography
            sx={{
              color: '#FFFFFF',
              fontSize: { xs: '12px', md: '14px', lg: '16px' },
            }}
          >
            {leftFieldLabel}
          </Typography>
          <Typography
            sx={{
              color: '#FFFFFF',
              fontSize: { xs: '18px', md: '20px', lg: '22px' },
              fontWeight: 'bold',
            }}
          >
            {leftFieldValue}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
        >
          <Typography
            sx={{
              color: '#FFFFFF',
              fontSize: { xs: '12px', md: '14px', lg: '16px' },
              textAlign: 'end',
            }}
          >
            {rightFieldLabel}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'end',
            }}
          >
            <Typography
              sx={{
                color: '#FFFFFF',
                fontSize: { xs: '18px', md: '20px', lg: '22px' },
                fontWeight: 'bold',
                paddingRight: theme.spacing(0.2),
              }}
            >
              {rightFieldValue}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: '13px', md: '15px', lg: '17px' },
                  fontWeight: 'bold',
                  color: '#FFFFFF',
                }}
              >
                %
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Paper
      elevation={2}
      sx={{
        borderRadius: '8px',
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          height: '60px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: 'max-content',
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px',
            backgroundColor: GetPropertyStatusColor(property.status, theme),
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '18px', md: '20px', lg: '22px' },
              color: 'white',
              fontWeight: '500',
              paddingLeft: theme.spacing(4),
              paddingRight: theme.spacing(2),
            }}
          >
            {t(GetPropertyStatusTranslationId(property.status))}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: theme.spacing(2),
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(1),
          paddingLeft: {
            xs: theme.spacing(1),
            md: theme.spacing(2),
            lg: theme.spacing(4),
          },
          paddingRight: {
            xs: theme.spacing(1),
            md: theme.spacing(2),
            lg: theme.spacing(4),
          },
          minWidth: {
            xs: '300px',
            sm: '360px',
            md: '420px',
            lg: '480px',
            xl: '600px',
          },
          width: '100%',
          alignSelf: 'center',
        }}
      >
        {renderInformationRow(
          t('property_details.main_information.property_amount'),
          CurrencyFormatter(property.value),
          t('property_details.main_information.annual_yield'),
          property.annualPercentageYield.toString()
        )}

        {!isNil(propertyInvestment) && (
          <Box
            sx={{
              height: '2px',
              backgroundColor: '#FFFFFF',
            }}
          ></Box>
        )}

        {!isNil(propertyInvestment) &&
          renderInformationRow(
            t('property_details.main_information.invested'),
            CurrencyFormatter(RoundDecimal(propertyInvestment.value)),
            t('property_details.main_information.share'),
            RoundDecimal(
              (propertyInvestment.quantity / property.shares) * 100
            ).toString()
          )}

        <Box
          sx={{
            height: '50px',
          }}
        >
          <ProgressBarComponent
            min={0}
            max={property.value}
            value={RoundDecimal(property.retailValue)}
            percentageLabel={t(
              'property_details.main_information.percentage_financed_label'
            )}
            remainingLabel={t(
              'property_details.main_information.remaining_value_label'
            )}
            color={GetPropertyStatusColor(property.status, theme)}
            labelColor={'#FFFFFF'}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            gap: theme.spacing(2),
            paddingTop: theme.spacing(4),
          }}
        >
          <Button
            sx={{
              height: '50px',
              width: '150px',
              backgroundColor: theme.palette.success.main,
              color: '#FFFFFF',
              fontSize: '18px',
              fontWeight: 'bold',
              '&: hover': {
                backgroundColor: '#2e8b57',
              },
              '&: disabled': {
                backgroundColor: theme.palette.secondary.dark,
                color: '#C0C0C0',
              },
            }}
            disabled={!isInvestAvailable()}
            onClick={openInvestmentDialog}
          >
            {t('property_details.main_information.invest_button')}
          </Button>

          <Button
            sx={{
              height: '50px',
              width: '150px',
              color: 'primary',
              backgroundColor: '#FFFFFF',
              fontSize: '18px',
              fontWeight: 'bold',
              '&: hover': {
                backgroundColor: '#C0C0C0',
              },
              '&: disabled': {
                backgroundColor: theme.palette.secondary.dark,
                color: '#C0C0C0',
              },
            }}
            disabled={!isSellAvailable()}
            onClick={openShareSaleDialog}
          >
            {t('property_details.main_information.sell_button')}
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          height: '50px',
        }}
      ></Box>
    </Paper>
  );
};

const wrappedPropertyDetailsMainInformationComponent = withDialogs(
  PropertyDetailsMainInformationComponent
);
export default wrappedPropertyDetailsMainInformationComponent;
