export type PaymentMethodType = 'CARD' | 'BANK';

export type PaymentMethodModel = {
  description: string;
  id: string;
  subMethod: string;
  type: PaymentMethodType;
};

export const PaymentMethodType = {
  Bank: 'BANK',
  Card: 'CARD',
};
