import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translation_en from './en/translation.json';
import translation_bg from './bg/translation.json';

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },
    fallbackLng: 'en',
    supportedLngs: ['en', 'bg'],
    resources: {
      en: {
        translation: translation_en,
      },
      bg: {
        translation: translation_bg,
      },
    },
  });

export default i18n;
