import { Box } from '@mui/material';
import { ImageOptionsModel } from '../../../../models/table/image-options.model';

const TableImageComponent = ({
  options,
}: {
  options: ImageOptionsModel;
}): JSX.Element => {
  return (
    <Box
      component="img"
      alt={options.alt ?? ''}
      src={
        options.src ?? require('../../../../assets/img/brand/logo-default.png')
      }
      sx={{
        width: options.width ?? '120px',
        height: options.height ?? '80px',
        objectFit: 'cover',
        borderRadius: '8px',
      }}
    ></Box>
  );
};

export default TableImageComponent;
