import { Box, Typography, useTheme } from '@mui/material';

const TabComponent = <TTabType,>({
  name,
  tabType,
  index,
  selectedTabType,
}: {
  name: string;
  tabType: TTabType;
  index: number;
  selectedTabType: TTabType;
}) => {
  const theme = useTheme();
  const isSelected = selectedTabType === tabType;

  return (
    <Box
      key={index}
      sx={{
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        cursor: 'pointer',
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50px',
        minWidth: '160px',
        backgroundColor: isSelected ? '#FFFFFF' : theme.palette.primary.main,
      }}
    >
      <Typography
        sx={{
          color: isSelected ? theme.palette.primary.main : '#FFFFFF',
          fontSize: '16px',
        }}
      >
        {name}
      </Typography>
    </Box>
  );
};

export default TabComponent;
