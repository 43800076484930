import { Box } from '@mui/material';
import { RouteProps } from 'react-router-dom';

const SelectedGradientComponent = ({ children }: RouteProps) => {
  return (
    <Box
      sx={{
        background:
          'linear-gradient(0deg, rgba(66,63,150,0.5) 0%, rgba(66,63,150,0.5) 100%)',
        height: '100%',
        width: '100%',
        borderRadius: 'inherit',
      }}
    >
      {children}
    </Box>
  );
};

export default SelectedGradientComponent;
