import { Box, useTheme } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HistoryType } from '../../constants/history.constants';
import { HistoryTypeTabs } from '../../constants/tabs.constants';
import { ChipModel } from '../../models/common/chips/chip.model';
import ChipsComponent from '../common/chips/chips.component';
import TitledBoxComponent from '../common/titled-box/titled-box.component';
import HistoryDepositsTabComponent from './history-tabs/history-deposits-tab.component';
import HistoryDividendsTabComponent from './history-tabs/history-dividends-tab.component';
import HistoryOrdersTabComponent from './history-tabs/history-orders-tab.component';
import HistoryTransactionsTabComponent from './history-tabs/history-transactions-tab.component';
import HistoryWithdrawalsTabComponent from './history-tabs/history-withdrawals-tab.component';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TabModel } from '../../models/common/tab.model';
import GetPlatformRoute from '../../utilities/route.utility';
import { RoutesConstants } from '../../constants/routes.constants';

const HistoryComponent = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const historyTabId = searchParams.get('tab');

  const [historyTab] = useState<TabModel<string> | undefined>(
    HistoryTypeTabs.find(
      (tab) =>
        tab.value.toLocaleLowerCase().toString() ===
        historyTabId?.toLocaleLowerCase()
    )
  );
  const [selectedHistotyTab, setSelectedHistoryTab] = useState<ChipModel>({
    id: historyTab?.value.toString() ?? 0,
    labelTranslationId: historyTab?.labelTranslationId ?? '',
  } as ChipModel);

  const [historyTabsChips] = useState<ChipModel[]>(
    HistoryTypeTabs.map<ChipModel>(
      (historyTab) =>
        ({
          id: historyTab.value.toString(),
          labelTranslationId: historyTab.labelTranslationId,
        } as ChipModel)
    )
  );

  const changeSelectedTab = (tab: ChipModel) => {
    navigate(GetPlatformRoute(`${RoutesConstants.History}?tab=${tab.id}`));
    setSelectedHistoryTab(tab);
  };

  const renderHistoryTab = () => {
    switch (selectedHistotyTab.id) {
      case HistoryType.Orders:
        return <HistoryOrdersTabComponent />;
      case HistoryType.Deposits:
        return <HistoryDepositsTabComponent />;
      case HistoryType.Withdrawals:
        return <HistoryWithdrawalsTabComponent />;
      case HistoryType.Transactions:
        return <HistoryTransactionsTabComponent />;
      case HistoryType.Dividends:
        return <HistoryDividendsTabComponent />;
      default:
        return <></>;
    }
  };

  return (
    <Box
      sx={{
        padding: theme.spacing(2),
      }}
    >
      <TitledBoxComponent title={t('history_page.title')}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Box
            sx={{
              padding: theme.spacing(2),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ChipsComponent
              chips={historyTabsChips}
              selectedChip={selectedHistotyTab}
              onChipClick={changeSelectedTab}
              wrapChips={false}
              chipsGap={theme.spacing(2)}
              centerChips={true}
            />
          </Box>

          <Box>{renderHistoryTab()}</Box>
        </Box>
      </TitledBoxComponent>
    </Box>
  );
};

export default HistoryComponent;
