import { t } from 'i18next';
import { ExecutionStrategy } from '../constants/execution-strategy.constants';
import { WithdrawalRequestStatus } from '../constants/withdrawal/withdrawal-request-status.constants';
import { WithdrawalStatus } from '../constants/withdrawal/withdrawal-status.constants';
import { PaymentMethodType } from '../models/payment-methods/payment-method.model';

export const GetExecutionStrategy = (executionStrategy: string) => {
  switch (executionStrategy) {
    case ExecutionStrategy.Bank:
      return t('withdrawal.execution_strategy.bank');
    case ExecutionStrategy.StripeCard:
      return t('withdrawal.execution_strategy.card');
    default:
      return '';
  }
};

export const GetExecutionStrategyId = (
  paymentMethodType?: PaymentMethodType
): string => {
  switch (paymentMethodType) {
    case PaymentMethodType.Bank:
      return ExecutionStrategy.Bank;
    case PaymentMethodType.Card:
      return ExecutionStrategy.StripeCard;
    default:
      return ExecutionStrategy.Unknown;
  }
};

export const GetWithdrawalStatus = (status: string) => {
  switch (status) {
    case WithdrawalStatus.Executed:
      return t('withdrawal.status.executed');
    case WithdrawalStatus.Failed:
      return t('withdrawal.status.failed');
    case WithdrawalStatus.Pending:
      return t('withdrawal.status.pending');
    case WithdrawalStatus.SentForExecution:
      return t('withdrawal.status.sent_for_execution');
    default:
      return '';
  }
};

export const GetWithdrawalRequestStatus = (status: string) => {
  switch (status) {
    case WithdrawalRequestStatus.Approved:
      return t('withdrawal.request.status.approved');
    case WithdrawalRequestStatus.Rejected:
      return t('withdrawal.request.status.rejected');
    case WithdrawalRequestStatus.AwaitingApproval:
      return t('withdrawal.request.status.awaiting_approval');
    default:
      return '';
  }
};
