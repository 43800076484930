import { object, string } from 'zod';
import i18n from '../../translations/i18n';

const CommentSchema = object({
  content: string()
    .min(1, i18n.t('comment_section.comment.errors.content_min_length'))
    .max(2048, i18n.t('comment_section.comment.errors.content_max_length'))
    .regex(
      new RegExp('^[^\\"\\\'\\0\\b\\r\\t\\\\]*$'),
      i18n.t('comment_section.comment.errors.content_not_allowed_symbols')
    ),
});

export default CommentSchema;
