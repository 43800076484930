import {
  NotifiableEvent,
  NotifiableEventConstants,
} from '../constants/notifications/notifiable-event.constants';
import AddHomeOutlinedIcon from '@mui/icons-material/AddHomeOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
// eslint-disable-next-line import/no-unresolved
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';
import { t } from 'i18next';
import { CurrencyFormatter } from './currency.utility';
import { NotifiableEventParamsModel } from '../models/notification/param-models/notifiable-event-params.model';
import { BoughtPropertySharesNotificationParamsModel } from '../models/notification/param-models/bought-property-shares-notification-params.model';
import { SoldPropertySharesNotificationParamsModel } from '../models/notification/param-models/sold-property-shares-notification-params.model';
import { DividentRecievedNotificationParamsModel } from '../models/notification/param-models/divident-recieved-notification-params.model';
import { CardFundSuccessNotificationParamsModel } from '../models/notification/param-models/card-fund-success-notification-params.model';
import { BankFundSuccessNotificationParamsModel } from '../models/notification/param-models/bank-fund-success-notification-params.model';
import { CardWithdrawSuccessNotificationParamsModel } from '../models/notification/param-models/card-withdraw-success-notification-params.model';
import { BankWithdrawSuccessNotificationParamsModel } from '../models/notification/param-models/bank-withdraw-success-notification-params.model';
export const GetNotificationIcon = (
  notificationType: NotifiableEvent
  // eslint-disable-next-line @typescript-eslint/ban-types
): OverridableComponent<SvgIconTypeMap<{}, 'svg'>> => {
  switch (notificationType) {
    case NotifiableEventConstants.BoughtPropertyShares:
      return AddHomeOutlinedIcon;
    case NotifiableEventConstants.SoldPropertyShares:
      return PaidOutlinedIcon;
    case NotifiableEventConstants.DividendReceived:
      return PaymentsOutlinedIcon;
    case NotifiableEventConstants.CardFundSuccess:
      return CreditCardOutlinedIcon;
    case NotifiableEventConstants.BankFundSuccess:
      return AccountBalanceOutlinedIcon;
    case NotifiableEventConstants.CardWithdrawSuccess:
      return CreditCardOutlinedIcon;
    case NotifiableEventConstants.BankWithdrawSuccess:
      return AccountBalanceOutlinedIcon;
    default:
      return AddHomeOutlinedIcon;
  }
};

export const GetNotificationTitle = (
  notificationType: NotifiableEvent
): string => {
  switch (notificationType) {
    case NotifiableEventConstants.BoughtPropertyShares:
      return t('notifications.bought_property_shares.title');
    case NotifiableEventConstants.SoldPropertyShares:
      return t('notifications.sold_property_shares.title');
    case NotifiableEventConstants.DividendReceived:
      return t('notifications.dividend_received.title');
    case NotifiableEventConstants.CardFundSuccess:
      return t('notifications.card_fund_success.title');
    case NotifiableEventConstants.BankFundSuccess:
      return t('notifications.bank_fund_success.title');
    case NotifiableEventConstants.CardWithdrawSuccess:
      return t('notifications.card_withdraw_success.title');
    case NotifiableEventConstants.BankWithdrawSuccess:
      return t('notifications.bank_withdraw_success.title');
    default:
      return '';
  }
};

const GetNotificationParams = (
  notificationType: NotifiableEvent,
  notificationParams: NotifiableEventParamsModel
): NotifiableEventParamsModel => {
  switch (notificationType as NotifiableEvent) {
    case 'BOUGHT_PROPERTY_SHARES':
      return {
        value: notificationParams.value,
        propertyName: notificationParams.propertyName,
      } as BoughtPropertySharesNotificationParamsModel;
    case 'SOLD_PROPERTY_SHARES':
      return {
        value: notificationParams.value,
        propertyName: notificationParams.propertyName,
      } as SoldPropertySharesNotificationParamsModel;
    case 'DIVIDEND_RECEIVED':
      return {
        amount: notificationParams.amount,
        propertyName: notificationParams.propertyName,
      } as DividentRecievedNotificationParamsModel;
    case 'CARD_FUND_SUCCESS':
      return {
        amount: notificationParams.amount,
      } as CardFundSuccessNotificationParamsModel;
    case 'BANK_FUND_SUCCESS':
      return {
        amount: notificationParams.amount,
      } as BankFundSuccessNotificationParamsModel;
    case 'CARD_WITHDRAW_SUCCESS':
      return {
        amount: notificationParams.amount,
      } as CardWithdrawSuccessNotificationParamsModel;
    case 'BANK_WITHDRAW_SUCCESS':
      return {
        amount: notificationParams.amount,
      } as BankWithdrawSuccessNotificationParamsModel;
    default:
      return {};
  }
};

export const GetNotificationDescription = (
  notificationType: NotifiableEvent,
  notificationParams: NotifiableEventParamsModel
): string => {
  const paramsByType = GetNotificationParams(
    notificationType,
    notificationParams
  );
  switch (notificationType) {
    case NotifiableEventConstants.BoughtPropertyShares:
      return t('notifications.bought_property_shares.description', {
        propertyName: paramsByType.propertyName?.value,
        sharesValue: CurrencyFormatter(<number>paramsByType.value?.value),
      });
    case NotifiableEventConstants.SoldPropertyShares:
      return t('notifications.sold_property_shares.description', {
        propertyName: paramsByType.propertyName?.value,
        sharesValue: CurrencyFormatter(<number>paramsByType.value?.value),
      });
    case NotifiableEventConstants.DividendReceived:
      return t('notifications.dividend_received.description', {
        propertyName: paramsByType.propertyName?.value,
        dividendValue: CurrencyFormatter(<number>paramsByType?.amount?.value),
      });
    case NotifiableEventConstants.CardFundSuccess:
      return t('notifications.card_fund_success.description', {
        depositAmount: CurrencyFormatter(<number>paramsByType?.amount?.value),
      });
    case NotifiableEventConstants.BankFundSuccess:
      return t('notifications.bank_fund_success.description', {
        depositAmount: CurrencyFormatter(<number>paramsByType?.amount?.value),
      });
    case NotifiableEventConstants.CardWithdrawSuccess:
      return t('notifications.card_withdraw_success.description', {
        withdrawAmount: CurrencyFormatter(<number>paramsByType?.amount?.value),
      });
    case NotifiableEventConstants.BankWithdrawSuccess:
      return t('notifications.bank_withdraw_success.description', {
        withdrawAmount: CurrencyFormatter(<number>paramsByType?.amount?.value),
      });
    default:
      return '';
  }
};

export const GetNotifyViaEmailSettingLabel = (
  notifiableEventType: string
): string => {
  switch (notifiableEventType) {
    case NotifiableEventConstants.BoughtPropertyShares:
      return t('settings_dialog.email_notifications_settings.bought_share');
    case NotifiableEventConstants.SoldPropertyShares:
      return t('settings_dialog.email_notifications_settings.sold_share');
    case NotifiableEventConstants.DividendReceived:
      return t(
        'settings_dialog.email_notifications_settings.dividend_received'
      );
    case NotifiableEventConstants.CardFundSuccess:
      return t(
        'settings_dialog.email_notifications_settings.card_fund_success'
      );
    case NotifiableEventConstants.BankFundSuccess:
      return t(
        'settings_dialog.email_notifications_settings.bank_fund_success'
      );
    case NotifiableEventConstants.CardWithdrawSuccess:
      return t(
        'settings_dialog.email_notifications_settings.card_withdraw_success'
      );
    case NotifiableEventConstants.BankWithdrawSuccess:
      return t(
        'settings_dialog.email_notifications_settings.bank_withdraw_success'
      );
    default:
      return '';
  }
};
