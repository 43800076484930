/* eslint-disable @typescript-eslint/no-explicit-any */
import DialogContext from './dialog.context';

const withDialogs = (
  WrappedComponent: (props: any) => JSX.Element
): ((props: any) => JSX.Element) => {
  const component = (props: any) => (
    <DialogContext.Consumer>
      {(context) => (
        <WrappedComponent
          {...props}
          dialogManagerService={context.dialogManagerService}
        />
      )}
    </DialogContext.Consumer>
  );

  return component;
};

export default withDialogs;
