import { Box } from '@mui/material';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { MapProps } from '../../../models/common/map-props.model';
import { divIcon } from 'leaflet';
import { MapMarkerModel } from '../../../models/common/map-marker.model';
import { isNil } from 'lodash';

const MapComponent = ({
  centerPosition,
  zoomLevel,
  schrollWheelZoom,
  markerLabel,
  mapHeight,
  additionalMarkers,
}: MapProps) => {
  const renderMarker = (marker: MapMarkerModel): JSX.Element => {
    if (isNil(marker.iconMarkup)) {
      return (
        <Marker position={marker.position}>
          <Popup>{marker.label ?? ''}</Popup>
        </Marker>
      );
    }

    const customMarkerIcon = divIcon({
      html: marker.iconMarkup,
      className: 'removeDefault',
    });

    return (
      <Marker position={marker.position} icon={customMarkerIcon}>
        <Popup>{marker.label ?? ''}</Popup>
      </Marker>
    );
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: mapHeight ?? '500px',
      }}
    >
      <MapContainer
        style={{ height: '100%' }}
        center={centerPosition}
        zoom={zoomLevel}
        scrollWheelZoom={schrollWheelZoom ?? false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={centerPosition}>
          <Popup>{markerLabel}</Popup>
        </Marker>
        {additionalMarkers?.map((marker) => renderMarker(marker))}
      </MapContainer>
    </Box>
  );
};

export default MapComponent;
