export const WithdrawalStatus = {
  Pending: 'PENDING',
  Executed: 'EXECUTED',
  Failed: 'FAILED',
  SentForExecution: 'SENT_FOR_EXECUTION',
};

export type WithdrawalStatusType =
  | 'PENDING'
  | 'EXECUTED'
  | 'FAILED'
  | 'SENT_FOR_EXECUTION';
