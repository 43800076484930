import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import LoadingComponent from '../common/loading-spinner/loading.component';
import PortfolioChartTooltipComponent from './portfolio-chart-tooltip.component';
import { useTranslation } from 'react-i18next';
import { FormatDate } from '../../utilities/date.utility';
import { Typography } from '@mui/material';
import { useContext, useMemo } from 'react';
import CustomerService from '../../services/customer/customer.service';
import { GetCustomerId } from '../../utilities/customer.utility';
import useObservable from '../../hooks/use-observable.hook';
import CustomerContext from '../../providers/customer/customer.provider';
import { ChartPortfolioData } from '../../models/portfolio-metrics/portfolio-value.model';
import { map, of } from 'rxjs';
import { isNil } from 'lodash';

const PortfolioChartComponent = () => {
  const { t } = useTranslation();

  const { customer } = useContext(CustomerContext);

  const portfolioValuesByDate$ = useMemo(
    () => {
      if (isNil(customer)) {
        return of([]);
      }

      return CustomerService.GetPortfolioValuesByDate(
        GetCustomerId(customer)
      ).pipe(
        map((portfolioValues) =>
          portfolioValues.map(
            (el) =>
              new ChartPortfolioData(
                FormatDate(el.date, { showDay: true }),
                el.value
              )
          )
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customer]
  );

  const [portfolioValuesByDate, , arePortfolioValuesByDateLoading] =
    useObservable(portfolioValuesByDate$);

  const getPortfolioValuesByDate = (): ChartPortfolioData[] => {
    return portfolioValuesByDate ?? [];
  };

  return (
    <>
      <LoadingComponent isLoading={arePortfolioValuesByDateLoading}>
        {getPortfolioValuesByDate().length === 0 && (
          <Typography
            sx={{
              textAlign: 'center',
            }}
          >
            {t('investments.charts.portfolio_empty')}
          </Typography>
        )}
        {getPortfolioValuesByDate().length > 0 && (
          <ResponsiveContainer width="100%" maxHeight={400}>
            <LineChart
              data={getPortfolioValuesByDate()}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="snapshotDate" />
              <YAxis />
              <Tooltip content={<PortfolioChartTooltipComponent />} />

              <Legend />
              <Line
                name={t('investments.charts.portfolio_value')}
                type="monotone"
                dataKey="portfolioValue"
                stroke="#423f96"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </LoadingComponent>
    </>
  );
};

export default PortfolioChartComponent;
