import {
  Box,
  FormHelperText,
  InputAdornment,
  TextFieldProps,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { GetCurrencyDisplay } from '../../../utilities/currency.utility';
import { IsValidNumber } from '../../../utilities/number.utility';
import TextFieldComponent from '../text-field/text-field.component';

const FormCurrencyInputComponent = ({
  name,
  currency,
  ...props
}: { name: string; currency: string } & TextFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <>
          <Box>
            <TextFieldComponent
              id={name}
              {...field}
              {...props}
              error={!!errors[name]}
              onChange={(event) => {
                if (IsValidNumber(event.target.value)) {
                  field.onChange(event);
                }
              }}
              InputProps={{
                ...props?.InputProps,

                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{
                      fontWeight: 'bold',
                    }}
                  >
                    {GetCurrencyDisplay(currency)}
                  </InputAdornment>
                ),
                sx: {
                  color: (theme) => theme.palette.text.primary,
                  fontWeight: 580,
                },
              }}
              InputLabelProps={{
                sx: {
                  color: (theme) => theme.palette.text.primary,
                  fontWeight: 580,
                },
              }}
            />
            <FormHelperText
              id={name}
              sx={{
                color: (theme) => theme.palette.error.main,
                fontWeight: 580,
              }}
            >
              {errors[name] ? (errors[name]?.message as unknown as string) : ''}
            </FormHelperText>
          </Box>
        </>
      )}
    />
  );
};

export default FormCurrencyInputComponent;
