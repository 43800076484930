import moment from 'moment';
import { DateOptionsModel } from '../models/table/date-options.model';
import i18n from '../translations/i18n';

export const FormatDate = (
  date: Date,
  dateOptions?: DateOptionsModel
): string => {
  const locale = dateOptions?.locale ?? GetDateLocale();
  const year = dateOptions?.year ?? 'numeric';
  const month = dateOptions?.month ?? 'long';
  const day = dateOptions?.day ?? '2-digit';

  const localDate = moment(date).local(true);

  return new Intl.DateTimeFormat(locale, {
    year: year,
    month: month,
    day: dateOptions?.showDay ? day : undefined,
  }).format(localDate.toDate());
};

export const GetDateLocale = () => {
  const applicationLanguage = i18n.language;

  if (applicationLanguage === 'en') {
    return 'en-GB';
  }

  if (applicationLanguage === 'bg') {
    return 'bg-BG';
  }

  return '';
};
