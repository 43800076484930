import { DividendTableModel } from '../../models/dividend/dividend-table.model';
import ColumnDefinitionModel from '../../models/table/column-definition.model';
import { ColumnType } from './column-type.constants';

export const DividendColumnsDefinition: ColumnDefinitionModel<
  DividendTableModel,
  keyof DividendTableModel
>[] = [
  {
    key: 'id',
    headerTranslationId: 'history_page.dividends_table.id',
    type: ColumnType.Text,
  },
  {
    key: 'propertyName',
    headerTranslationId: 'history_page.dividends_table.property_name',
    type: ColumnType.Text,
  },
  {
    key: 'amount',
    headerTranslationId: 'history_page.dividends_table.dividend_amount',
    type: ColumnType.Currency,
  },
  {
    key: 'createdAt',
    headerTranslationId: 'history_page.dividends_table.dividend_date',
    type: ColumnType.Date,
  },
];
