import { Box, Typography, useTheme } from '@mui/material';
import { isNil } from 'lodash';
import { ReactNode, useEffect, useMemo } from 'react';
import useObservable from '../../../hooks/use-observable.hook';
import CustomerService from '../../../services/customer/customer.service';
import { NotificationService } from '../../../services/notifications/notification.service';
import { GetNotifyViaEmailSettingLabel } from '../../../utilities/notification.utility';
import LoadingComponent from '../../common/loading-spinner/loading.component';
import SwitchComponent from '../../common/switch/switch.component';

const NotificationsViaEmailSectionComponent = ({
  notifyViaEmailSettings,
  setNotifyViaEmailSettings,
}: {
  notifyViaEmailSettings: Map<number, boolean>;
  setNotifyViaEmailSettings: (settings: Map<number, boolean>) => void;
}) => {
  const theme = useTheme();

  const notifiableEvents$ = useMemo(
    () => NotificationService.GetNotifiableEvents(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [notifiableEvents, , areNotifiableEventsLoading] =
    useObservable(notifiableEvents$);

  const customerNotifiableEventSettings$ = useMemo(
    () => CustomerService.GetCustomerNotifiableEventSettings(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [
    customerNotifiableEventSettings,
    ,
    areCustomerNotifiableEventSettingsLoading,
  ] = useObservable(customerNotifiableEventSettings$);

  useEffect(() => {
    const customerNotifyViaEmailSettings = new Map<number, boolean>();

    customerNotifiableEventSettings?.forEach((customerSetting) => {
      customerNotifyViaEmailSettings.set(
        customerSetting.notifiableEventId,
        customerSetting.notifyViaEmail
      );
    });

    setNotifyViaEmailSettings(customerNotifyViaEmailSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerNotifiableEventSettings]);

  const changeNotifyViaEmailSetting = (
    notifiableEventId: number,
    notifyViaEmail: boolean
  ) => {
    setNotifyViaEmailSettings(
      new Map(notifyViaEmailSettings.set(notifiableEventId, notifyViaEmail))
    );
  };

  const renderNotifyViaEmailSetting = (
    notifiableEventId: number,
    notifyViaEmail: boolean
  ): ReactNode => {
    const notifiableEvent = notifiableEvents?.find(
      (notifiableEvent) => notifiableEvent.id === notifiableEventId
    );

    if (isNil(notifiableEvent)) {
      return <></>;
    }

    const notifyViaEmailLabel = GetNotifyViaEmailSettingLabel(
      notifiableEvent.name
    );
    return (
      <Box
        key={notifiableEventId}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: theme.spacing(1),
          gap: theme.spacing(1),
        }}
      >
        <SwitchComponent
          onChange={(event) =>
            changeNotifyViaEmailSetting(notifiableEventId, event.target.checked)
          }
          checked={notifyViaEmail}
        />
        <Typography>{notifyViaEmailLabel}</Typography>
      </Box>
    );
  };

  const renderNotificationsViaEmailSettings = (): ReactNode => {
    const settingsDisplay: ReactNode[] = [];

    notifyViaEmailSettings.forEach((notifyViaEmail, notifiableEventId) => {
      const notifyViaEmailSetting = renderNotifyViaEmailSetting(
        notifiableEventId,
        notifyViaEmail
      );

      settingsDisplay.push(notifyViaEmailSetting);
    });

    return <>{settingsDisplay}</>;
  };

  return (
    <LoadingComponent
      isLoading={
        areCustomerNotifiableEventSettingsLoading || areNotifiableEventsLoading
      }
    >
      {renderNotificationsViaEmailSettings()}
    </LoadingComponent>
  );
};

export default NotificationsViaEmailSectionComponent;
