import CloseIcon from '@mui/icons-material/Close';
import { IconButton, useTheme } from '@mui/material';

const DialogCloseButtonComponent = ({
  closeDialog,
}: {
  closeDialog: () => void;
}) => {
  const theme = useTheme();

  return (
    <>
      <IconButton
        sx={{
          position: 'absolute',
          right: theme.spacing(0.5),
          top: theme.spacing(0.5),
          color: theme.palette.grey[500],
        }}
        onClick={closeDialog}
      >
        <CloseIcon />
      </IconButton>
    </>
  );
};

export default DialogCloseButtonComponent;
