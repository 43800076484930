import { Observable } from 'rxjs';
import ApiProvider from '../../../providers/api/api.provider';
import { StripeDepositResponse } from '../../../models/payments/stripe/stripe-deposit-response.model';
import { StripeInitialDepositResponse } from '../../../models/payments/stripe/stripe-initial-deposit-response.model';
import { StripeDepositFeeModel } from '../../../models/payments/stripe/stripe-deposit-fee.model';

export const StripeDepositsService = {
  Deposit(
    amount: number,
    currencyCode: string,
    paymentMethodId: string
  ): Observable<StripeDepositResponse | null> {
    const depositInformation = {
      amount: amount,
      currencyCode: currencyCode,
      paymentMethodId: paymentMethodId,
    };

    return ApiProvider.post<StripeDepositResponse | null>(
      `/deposits/stripe`,
      depositInformation
    );
  },

  CreatePaymentIntent(
    amount: number,
    currencyCode: string
  ): Observable<StripeInitialDepositResponse | null> {
    const paymentIntentInformation = {
      amount: amount,
      currencyCode: currencyCode,
    };

    return ApiProvider.post<StripeInitialDepositResponse | null>(
      `/deposits/stripe/initial`,
      paymentIntentInformation
    );
  },

  GetDepositFee(amount: number): Observable<StripeDepositFeeModel> {
    return ApiProvider.get<StripeDepositFeeModel>(
      `/deposits/stripe/fees?depositAmount=${amount}`
    );
  },
};
