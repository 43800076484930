import { z } from 'zod';
import i18n from '../translations/i18n';

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.invalid_type) {
    if (issue.expected === 'date') {
      return {
        message: i18n.t('errors.invalid_date'),
      };
    }
  }

  if (issue.code === z.ZodIssueCode.invalid_date) {
    return {
      message: i18n.t('errors.invalid_date'),
    };
  }

  return { message: ctx.defaultError };
};

z.setErrorMap(customErrorMap);
