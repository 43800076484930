import { isNil } from 'lodash';
import { ReactNode, useEffect, useState } from 'react';
import { take } from 'rxjs';
import {
  StripeAccountCapabilityConstants,
  StripeAccountCapabilityStatusConstants,
} from '../../../constants/stripe/stripe-account-capability.constants';
import { StripeConnectedAccountStatus } from '../../../constants/stripe/stripe-account-document-status.constants';
import { CardDepositAvailabilityStatus } from '../../../enums/card-deposit/card-deposit-availability-status.enum';
import { StripeConnectedAccountModel } from '../../../models/payments/stripe/stripe-connected-account.model';
import { StripeIdentityDocumentsStatusCode } from '../../../models/payments/stripe/stripe-identity-documents-status-code.model';
import { StripeProofOfAddressDocumentsVerificationStatusCode } from '../../../models/payments/stripe/stripe-proof-of-address-documents-status-code.model';
import CustomerService from '../../../services/customer/customer.service';
import StripeService from '../../../services/payments/stripe/stripe.service';
import LoadingSpinnerComponent from '../../common/loading-spinner/loading-spinner.component';
import CustomerStripeVerificationComponent from '../../customer-stripe-verification/customer-stripe-verification.component';
import CustomerVerificationComponent from '../../customer-verification/customer-verification.component';
import CardPaymentComponent from './card-payment.component';

const CardDepositComponent = ({ closeAction }: { closeAction: () => void }) => {
  const [connectedAccountId, setConnectedAccountId] = useState('');
  const [
    isStripeDocumentsVerificationPending,
    setIsStripeDocumentsVerificationPending,
  ] = useState(false);
  const [
    isIdentityDocumentVerificationFailed,
    setIsIdentityDocumentVerificationFailed,
  ] = useState(false);
  const [
    isAddressDocumentVerificationFailed,
    setIsAddressDocumentVerificationFailed,
  ] = useState(false);

  const [cardDepositAvailabilityStatus, setCardDepositAvailabilityStatus] =
    useState(CardDepositAvailabilityStatus.NotVerifiedUser);

  useEffect(() => {
    setCardDepositAvailabilityStatus(CardDepositAvailabilityStatus.Loading);

    CustomerService.IsCustomerVerified()
      .pipe(take(1))
      .subscribe((isVerified: boolean) => {
        if (!isVerified) {
          setCardDepositAvailabilityStatus(
            CardDepositAvailabilityStatus.NotVerifiedUser
          );
          return;
        }

        checkStripeConnectedAccount();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkStripeConnectedAccount = () => {
    StripeService.GetConnectedAccount()
      .pipe(take(1))
      .subscribe((connectedAccount: StripeConnectedAccountModel | null) => {
        if (isNil(connectedAccount)) {
          // Create Connected Account
          createStripeConnectedAccount();
          return;
        }
        // The user has a Stripe Connected Account

        // Check this Connected Account capabilities
        const connectedAccountCardPaymentCapability =
          connectedAccount.capabilities.find(
            (capability) =>
              capability.name === StripeAccountCapabilityConstants.CardPayment
          );

        const connectedAccountTransfersCapability =
          connectedAccount.capabilities.find(
            (capability) =>
              capability.name === StripeAccountCapabilityConstants.Transfers
          );

        if (
          isNil(connectedAccountCardPaymentCapability) ||
          isNil(connectedAccountTransfersCapability) ||
          connectedAccountCardPaymentCapability.status !==
            StripeAccountCapabilityStatusConstants.Active ||
          connectedAccountTransfersCapability.status !==
            StripeAccountCapabilityStatusConstants.Active
        ) {
          // TODO: Check if the user is waiting for the documents to
          // be verified. How are we doing this?
          if (
            connectedAccount.verificationStatus ===
            StripeConnectedAccountStatus.PendingVerification
          ) {
            // The documents are still looked by Stripe
            setIsStripeDocumentsVerificationPending(true);
          } else if (
            connectedAccount.verificationStatus ===
            StripeConnectedAccountStatus.Verified
          ) {
            // Documents are verified but we still don't have capability.
            // TODO: discuss what to do in this situation. Maybe a message to contact support
            return;
          } else {
            // Documents are not verified
            setIsAddressDocumentVerificationFailed(
              !isNil(connectedAccount.proofOfAddressStatusCode) &&
                connectedAccount.proofOfAddressStatusCode !==
                  StripeProofOfAddressDocumentsVerificationStatusCode.Success
            );
            setIsIdentityDocumentVerificationFailed(
              !isNil(connectedAccount.identityDocumentsStatusCode) &&
                connectedAccount.identityDocumentsStatusCode !==
                  StripeIdentityDocumentsStatusCode.Success
            );
          }

          // If Card Payment capability and Transfers capability is not available
          // then the user should either verify the documents or check if an error
          // occured during the last verification of documents
          setConnectedAccountId(connectedAccount.id);
          setCardDepositAvailabilityStatus(
            CardDepositAvailabilityStatus.NotVerifiedStripeConnectedAccount
          );

          return;
        }

        // Everything with the account is setup.
        // The user can proceed with payment
        setConnectedAccountId(connectedAccount.id);
        goToCardDeposit();
      });
  };

  const createStripeConnectedAccount = () => {
    setCardDepositAvailabilityStatus(CardDepositAvailabilityStatus.Loading);

    StripeService.CreateConnectedAccount()
      .pipe(take(1))
      .subscribe((connectedAccountId: string | null) => {
        if (!isNil(connectedAccountId)) {
          setConnectedAccountId(connectedAccountId);
          setCardDepositAvailabilityStatus(
            CardDepositAvailabilityStatus.NotVerifiedStripeConnectedAccount
          );
        }
      });
  };

  const goToCardDeposit = () => {
    setCardDepositAvailabilityStatus(
      CardDepositAvailabilityStatus.CardDepositAvailable
    );
  };

  const getCardDepositStep = (): ReactNode => {
    switch (cardDepositAvailabilityStatus) {
      case CardDepositAvailabilityStatus.NotVerifiedUser: {
        return (
          <CustomerVerificationComponent
            onSuccessfulVerification={createStripeConnectedAccount}
          />
        );
      }
      case CardDepositAvailabilityStatus.NotVerifiedStripeConnectedAccount: {
        return (
          <CustomerStripeVerificationComponent
            connectedAccountId={connectedAccountId}
            isVerificationPending={isStripeDocumentsVerificationPending}
            isAddressDocumentVerificationFailed={
              isAddressDocumentVerificationFailed
            }
            isIdentityDocumentVerificationFailed={
              isIdentityDocumentVerificationFailed
            }
            pendingStatusAction={closeAction}
          />
        );
      }
      case CardDepositAvailabilityStatus.CardDepositAvailable: {
        return (
          <CardPaymentComponent
            connectedAccountId={connectedAccountId}
            handleClose={closeAction}
          />
        );
      }
      case CardDepositAvailabilityStatus.Loading: {
        return <LoadingSpinnerComponent />;
      }
    }
  };

  return <>{getCardDepositStep()}</>;
};

export default CardDepositComponent;
