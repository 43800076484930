import { Box, Menu, Typography, useTheme } from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { take } from 'rxjs';
import { NotificationStatus } from '../../constants/notifications/notification-status.constants';
import useObservable from '../../hooks/use-observable.hook';
import { NotificationService } from '../../services/notifications/notification.service';
import LoadingComponent from '../common/loading-spinner/loading.component';
import NotificationComponent from '../notifications/notification.component';

const NavbarNotificationMenuComponent = ({
  notificationMenuAnchor,
  removeNotificationMenuAnchor,
}: {
  notificationMenuAnchor: null | Element;
  removeNotificationMenuAnchor: () => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isNotificationMenuOpen = Boolean(notificationMenuAnchor);
  const [refreshNotifications, setRefreshNotifications] = useState(true);
  const refreshNotificationsRef = useRef(refreshNotifications);

  const notifications$ = useMemo(
    () => {
      return NotificationService.GetNotifications();
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshNotifications]
  );
  const [notifications, , areNotificationsLoading] =
    useObservable(notifications$);

  useEffect(() => {
    refreshNotificationsRef.current = refreshNotifications;
  });

  useEffect(() => {
    if (isNotificationMenuOpen) {
      setRefreshNotifications(!refreshNotificationsRef.current);
    }
  }, [isNotificationMenuOpen]);

  useEffect(() => {
    if (
      isNotificationMenuOpen &&
      !isNil(notifications) &&
      notifications.length > 0
    ) {
      const newNotifications = notifications.filter(
        (notification) => notification.status === NotificationStatus.New
      );

      newNotifications.forEach((notification) =>
        NotificationService.SetNotificationAsRead(notification.id)
          .pipe(take(1))
          .subscribe()
      );
    }
  }, [isNotificationMenuOpen, notifications]);

  return (
    <Menu
      anchorEl={notificationMenuAnchor}
      open={isNotificationMenuOpen}
      onClose={removeNotificationMenuAnchor}
      PaperProps={{
        elevation: 0,
        sx: {
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          backgroundColor: '#FFFFFF',
          mt: 3,
          width: { xs: '100%', sm: '300px' },
        },
      }}
      MenuListProps={{
        sx: {
          paddingTop: '0px !important',
          paddingBottom: '0px',
          maxHeight: '80vh',
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      disableAutoFocusItem
    >
      <LoadingComponent isLoading={areNotificationsLoading}>
        <Typography
          color="primary"
          sx={{
            fontSize: '18px',
            fontWeight: 'bold',
            paddingTop: theme.spacing(1),
            paddingLeft: theme.spacing(2),
          }}
        >
          {t('notifications.title')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {notifications?.map((notification) => (
            <NotificationComponent
              key={notification.id}
              notification={notification}
            />
          ))}
        </Box>

        {notifications?.length === 0 && (
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 'bold',
              paddingTop: theme.spacing(1),
              paddingLeft: theme.spacing(2),
              paddingBottom: theme.spacing(1),
              color: theme.palette.secondary.dark,
            }}
          >
            {t('notifications.no_notifications_title')}
          </Typography>
        )}
      </LoadingComponent>
    </Menu>
  );
};

export default NavbarNotificationMenuComponent;
