import { t } from 'i18next';
import { number, object, string } from 'zod';
import { IsValidNumber } from '../../utilities/number.utility';

const WithdrawSchema = object({
  availableCustomerCash: number(),
  withdrawAmount: string()
    .min(1, t('withdraw_dialog.input.errors.min_amount'))
    .refine(
      (value) => IsValidNumber(value),
      t('withdraw_dialog.input.errors.not_valid_number')
    )
    .refine(
      (value) => +value > 0,
      t('withdraw_dialog.input.errors.not_valid_amount')
    ),
}).superRefine(({ availableCustomerCash, withdrawAmount }, ctx) => {
  if (availableCustomerCash < +withdrawAmount) {
    ctx.addIssue({
      code: 'custom',
      message: t('withdraw_dialog.input.errors.not_enough_cash'),
      path: ['withdrawAmount'],
    });
  }
});

export default WithdrawSchema;
