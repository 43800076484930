export const RoutesConstants = {
  Home: '/',
  Login: '/login',
  Registration: '/registration',
  CustomerActivation: '/customers/activate',
  ForgotPassowrd: '/customers/forgot-password',
  ResetPassword: '/customers/update-password-with-token',
  Properties: '/properties',
  PropertyDetails: '/property',
  Investments: '/investments',
  History: '/history',
  HealthCheck: '/health',
  CustomerProfile: '/customer_profile',
};

export const RoutesPrefix = '/platform';
