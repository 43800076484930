import { TextFieldProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FormSelectProps } from '../../../models/common/form/form-select-props.model';
import SelectComponent from '../select/select.component';

const FormSelectComponent = <TElements, TKey extends keyof TElements>({
  name,
  elements,
  optionValue,
  optionDisplay,
  ...props
}: FormSelectProps<TElements, TKey> & TextFieldProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { onChange } }) => (
        <SelectComponent
          name={name}
          elements={elements}
          optionValue={optionValue}
          optionDisplay={optionDisplay}
          selectErrors={errors}
          onSelectChange={onChange}
          {...props}
        />
      )}
    />
  );
};

export default FormSelectComponent;
