import { Box, Chip, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChipsMultiSelectProps } from '../../../models/common/chips/chips-multi-select-props.model';

const ChipsMultiSelectComponent = ({
  chips,
  selectedChips,
  onChipClick,
}: ChipsMultiSelectProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: '100%',
        overflowY: 'auto',
        display: 'flex',
        gap: theme.spacing(1),
      }}
    >
      {chips.map((chip, index) => (
        <Chip
          key={index}
          label={t(chip.labelTranslationId)}
          onClick={() => onChipClick(chip)}
          variant={
            selectedChips.findIndex(
              (selectedChip) => selectedChip.id === chip.id
            ) > -1
              ? 'filled'
              : 'outlined'
          }
        />
      ))}
    </Box>
  );
};

export default ChipsMultiSelectComponent;
