import { Box, useTheme } from '@mui/material';
import { useStripe } from '@stripe/react-stripe-js';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { take } from 'rxjs';
import { StripeDepositsService } from '../../../../services/payments/stripe/stripe-deposit.service';
import SharedDataService from '../../../../services/shared-data/shared-data.service';
import ButtonComponent from '../../../common/button/button.component';
import LoadingComponent from '../../../common/loading-spinner/loading.component';
import { FormatCardLastFour } from '../../../../utilities/payment-methods.utility';
import LoadingSpinnerComponent from '../../../common/loading-spinner/loading-spinner.component';
import useObservable from '../../../../hooks/use-observable.hook';
import { CurrencyFormatter } from '../../../../utilities/currency.utility';
import PreviewBoxComponent from '../../../common/preview-box/preview-box.component';
import PreviewRowComponent from '../../../common/preview-box/preview-row.component';

const CardDepositExistingCardPaymentComponent = ({
  depositAmount,
  depositCurrency,
  paymentMethodId,
  lastFourDigits,
  onPaymentFail,
  onPaymentSuccess,
}: {
  depositAmount: number;
  depositCurrency: string;
  paymentMethodId: string;
  lastFourDigits: string;
  onPaymentFail: () => void;
  onPaymentSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const stripe = useStripe();

  const [isDepositAttempted, setIsDepositAttempted] = useState(false);
  const [depositButtonSpinnerColor, setDepositButtonSpinnerColor] =
    useState('#FFFFFF');

  const depositFee$ = useMemo(
    () => StripeDepositsService.GetDepositFee(depositAmount),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [depositAmount]
  );

  const [depositFee, , loadingDepositFee] = useObservable(depositFee$);

  const depositFunds = () => {
    if (!stripe) {
      return;
    }

    setIsDepositAttempted(true);

    StripeDepositsService.Deposit(
      depositAmount,
      depositCurrency,
      paymentMethodId
    )
      .pipe(take(1))
      .subscribe(async (result) => {
        if (!result) {
          setIsDepositAttempted(false);
          return;
        }

        // Confirm the payment on the client
        const clientSecret = result.clientSecret;

        const paymentResults = await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: result.paymentMethodId,
          },
          {}
        );

        if (paymentResults.paymentIntent?.status === 'succeeded') {
          setTimeout(
            () => SharedDataService.TriggerUpdateCustomerInformationEvent(),
            5000
          );

          onPaymentSuccess();
          return;
        }

        onPaymentFail();
      });
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: theme.spacing(2),
          paddingTop: theme.spacing(2),
        }}
      >
        <LoadingComponent isLoading={loadingDepositFee}>
          <PreviewBoxComponent>
            <PreviewRowComponent
              title={t('card_deposit.existing_card_payment.card')}
              value={FormatCardLastFour(lastFourDigits)}
            />

            <PreviewRowComponent
              title={t(
                'card_deposit.existing_card_payment.deposit_amount_label'
              )}
              value={CurrencyFormatter(depositAmount)}
            />

            <PreviewRowComponent
              title={t('card_deposit.existing_card_payment.deposit_fee_label')}
              value={CurrencyFormatter(depositFee?.stripeFee ?? 0)}
              bottomBorderWidth={0}
            />
          </PreviewBoxComponent>
        </LoadingComponent>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ButtonComponent
            onClick={depositFunds}
            sx={{
              width: '200px',
              height: '50px',
              fontSize: '12px',
              fontWeight: 'bold',
            }}
            onMouseEnter={() =>
              setDepositButtonSpinnerColor(theme.palette.primary.main)
            }
            onMouseLeave={() => setDepositButtonSpinnerColor('#FFFFFF')}
          >
            {isDepositAttempted ? (
              <LoadingSpinnerComponent
                spinnerColor={depositButtonSpinnerColor}
              />
            ) : (
              <>{`${t(
                'card_deposit.existing_card_payment.deposit_button'
              )} ${CurrencyFormatter(
                depositFee?.amountAfterFeeApplied ?? 0
              )}`}</>
            )}
          </ButtonComponent>
        </Box>
      </Box>
    </>
  );
};

export default CardDepositExistingCardPaymentComponent;
