import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { isNil } from 'lodash';

const ApiInitialization = (
  config: AxiosRequestConfig,
  authorization: string
): AxiosInstance => {
  if (isNil(config.headers) || authorization === '') {
    return axios.create(config);
  }

  config.headers.Authorization = authorization;
  return axios.create(config);
};

export default ApiInitialization;
