import { Avatar, Box, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GetPropertyHistoryEventTypeTranslationId,
  GetPropertyHistoryEventTypeIcon,
} from '../../../constants/property-details/property-history-event-type.constants';
import useObservable from '../../../hooks/use-observable.hook';
import { PropertyHistoryModel } from '../../../models/property-details/property-history.model';
import { PropertyModel } from '../../../models/property/property.model';
import { PropertyService } from '../../../services/properties/property.service';
import { FormatDate } from '../../../utilities/date.utility';
import LoadingComponent from '../../common/loading-spinner/loading.component';

const PropertyDetailsHistoryTabComponent = ({
  property,
}: {
  property: PropertyModel;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const rowsPerPage = 10;
  const page = 1;

  const propertyHistoryEvents$ = useMemo(
    () => PropertyService.GetPropertyHistory(property.id, page, rowsPerPage),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, property.id]
  );

  const [propertyHistoryEvents, , propertyHistoryEventsLoading] = useObservable(
    propertyHistoryEvents$
  );

  const getPropertyHistoryEvents = (): PropertyHistoryModel[] => {
    return propertyHistoryEvents ?? [];
  };

  const renderPropertyHistoryEventCard = (
    propertyHistoryEvent: PropertyHistoryModel,
    historyEventIndex: number
  ): JSX.Element => {
    const HistoryEventIcon = GetPropertyHistoryEventTypeIcon(
      propertyHistoryEvent.type
    );
    return (
      <Box
        key={historyEventIndex}
        sx={{
          border: `2px solid ${theme.palette.primary.main}`,
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          padding: theme.spacing(1),
          gap: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            gap: { xs: theme.spacing(1), sm: theme.spacing(0) },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Avatar
              sx={{
                backgroundColor: theme.palette.primary.main,
              }}
            >
              <HistoryEventIcon sx={{ color: '#FFFFFF' }} />
            </Avatar>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                paddingLeft: theme.spacing(1),
              }}
            >
              <Typography fontSize="18px" fontWeight="bold" color="primary">
                {t(
                  GetPropertyHistoryEventTypeTranslationId(
                    propertyHistoryEvent.type
                  )
                )}
              </Typography>
              <Typography fontSize="14px">Name: {property.name}</Typography>
            </Box>
          </Box>

          <Box>
            <Typography fontSize="14px">
              {FormatDate(new Date(propertyHistoryEvent.createdAt))}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Typography
            sx={{
              fontSize: '14px',
            }}
          >
            {propertyHistoryEvent.details}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        gap: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          color="primary"
          sx={{
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          {t('property_details.history_tab.title')}
        </Typography>
      </Box>

      <LoadingComponent isLoading={propertyHistoryEventsLoading}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
          }}
        >
          {getPropertyHistoryEvents().map(
            (propertyHistoryEvent, propertyHistoryIndex) =>
              renderPropertyHistoryEventCard(
                propertyHistoryEvent,
                propertyHistoryIndex
              )
          )}

          {getPropertyHistoryEvents().length === 0 && (
            <Box>
              <Typography textAlign="center" fontSize="14px">
                {t('property_details.history_tab.no_history')}
              </Typography>
            </Box>
          )}
        </Box>
      </LoadingComponent>
    </Box>
  );
};

export default PropertyDetailsHistoryTabComponent;
