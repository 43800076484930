import { Box, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PaymentMethodModel,
  PaymentMethodType,
} from '../../../models/payment-methods/payment-method.model';
import PaymentMethodsService from '../../../services/payment-methods/payment-methods.service';
import useObservable from '../../../hooks/use-observable.hook';
import LoadingComponent from '../../common/loading-spinner/loading.component';
import WithdrawBankAccountElementComponent from './payment-methods-elements/withdraw-bank-account-element.component';
import WithdrawCardElementComponent from './payment-methods-elements/withdraw-card-element.component';

const WithdrawSelectPaymentMethodComponent = ({
  withdrawalAmount,
  setPaymentMethod,
}: {
  withdrawalAmount: number;
  setPaymentMethod: (paymentMethod: PaymentMethodModel) => void;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const paymentMethods$ = useMemo(
    () => PaymentMethodsService.GetPaymentMethodsForCustomer(withdrawalAmount),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [paymentMethods, , arePaymentMethodsLoading] =
    useObservable(paymentMethods$);

  const getPaymentMethods = () => {
    return paymentMethods ?? [];
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <LoadingComponent isLoading={arePaymentMethodsLoading}>
        {getPaymentMethods().length === 0 && (
          <Typography textAlign="center">
            {t(
              'withdraw_dialog.select_payment_method.payment_methods_not_available'
            )}
          </Typography>
        )}
        {getPaymentMethods().length > 0 && (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(1),
                paddingTop: theme.spacing(0.5),
                paddingBottom: theme.spacing(0.5),
              }}
            >
              {getPaymentMethods()
                .filter(
                  (withdrawalPaymentMethod) =>
                    withdrawalPaymentMethod.paymentMethod.type ===
                    PaymentMethodType.Bank
                )
                .map((withdrawalPaymentMethod) => (
                  <WithdrawBankAccountElementComponent
                    key={withdrawalPaymentMethod.paymentMethod.id}
                    withdrawalPaymentMethod={withdrawalPaymentMethod}
                    setPaymentMethod={setPaymentMethod}
                  />
                ))}
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(1),
                paddingTop: theme.spacing(0.5),
              }}
            >
              {getPaymentMethods()
                .filter(
                  (withdrawalPaymentMethod) =>
                    withdrawalPaymentMethod.paymentMethod.type ===
                    PaymentMethodType.Card
                )
                .map((withdrawalPaymentMethod) => (
                  <WithdrawCardElementComponent
                    key={withdrawalPaymentMethod.paymentMethod.id}
                    withdrawalPaymentMethod={withdrawalPaymentMethod}
                    setPaymentMethod={setPaymentMethod}
                  />
                ))}
            </Box>
          </>
        )}

        <Box
          sx={{
            paddingTop: theme.spacing(2),
          }}
        >
          <Typography
            color="primary"
            sx={{
              textAlign: 'center',
              fontSize: { xs: '10px', sm: '12px' },
              fontWeight: '600',
            }}
          >
            {t('withdraw_dialog.select_payment_method.withdraw_warning')}
          </Typography>
        </Box>
      </LoadingComponent>
    </Box>
  );
};

export default WithdrawSelectPaymentMethodComponent;
