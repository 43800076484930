import { ProfileTabType } from '../../enums/profile/profile-tab-type.enum';

const PROFILE_TABS: { translationId: string; type: ProfileTabType }[] = [
  {
    translationId: 'customer_profile.tabs.profile_information',
    type: ProfileTabType.ProfileInformation,
  },
  {
    translationId: 'customer_profile.tabs.change_password',
    type: ProfileTabType.ChangePassword,
  },
];

export default PROFILE_TABS;
