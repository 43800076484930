/* eslint-disable @typescript-eslint/no-unused-vars */
import { PropertyModel } from '../../models/property/property.model';
import { Observable } from 'rxjs';
import { PropertyHistoryModel } from '../../models/property-details/property-history.model';
import { PropertyComment } from '../../models/property/property-comment.model';
import { PropertyAmenityModel } from '../../models/property/property-amenity.model';
import ApiProvider from '../../providers/api/api.provider';

export const PropertyService = {
  GetProperties(
    page: number,
    rowsPerPage: number
  ): Observable<PropertyModel[]> {
    return ApiProvider.get<PropertyModel[]>('/properties');
  },

  GetProperty(propertyId: number): Observable<PropertyModel> {
    return ApiProvider.get<PropertyModel>(`/properties/${propertyId}`);
  },

  GetPropertyHistory(
    propertyId: number,
    page: number,
    rowsPerPage: number
  ): Observable<PropertyHistoryModel[]> {
    return ApiProvider.get<PropertyHistoryModel[]>(
      `/properties/${propertyId}/history`
    );
  },

  GetPropertyComments(propertyId: number): Observable<PropertyComment[]> {
    return ApiProvider.get<PropertyComment[]>(
      `/properties/${propertyId}/comments`
    );
  },

  GetPropertyCommentsReplies(
    propertyId: number,
    commentId: number
  ): Observable<PropertyComment[]> {
    return ApiProvider.get<PropertyComment[]>(
      `/properties/${propertyId}/comments/${commentId}/replies`
    );
  },

  AddPropertyComment(
    propertyId: number,
    content: string,
    commentId?: number
  ): Observable<PropertyComment | null> {
    const comment = {
      content: content,
      parentId: commentId,
    };
    return ApiProvider.post<PropertyComment | null>(
      `/properties/${propertyId}/comments`,
      comment
    );
  },

  GetPropertyClosestAmenities(
    propertyId: number
  ): Observable<PropertyAmenityModel[]> {
    return ApiProvider.get<PropertyAmenityModel[]>(
      `/properties/${propertyId}/amenities`
    );
  },
};
