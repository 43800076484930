import {
  Autocomplete,
  Box,
  FormHelperText,
  TextFieldProps,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import { FormSelectProps } from '../../../models/common/form/form-select-props.model';
import TextFieldComponent from '../text-field/text-field.component';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const SelectComponent = <TElements, TKey extends keyof TElements>({
  name,
  elements,
  optionValue,
  optionDisplay,
  selectErrors,
  selectedValue,
  onSelectChange,
  ...props
}: FormSelectProps<TElements, TKey> &
  TextFieldProps & {
    selectErrors?: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [x: string]: any;
    };
    selectedValue?: TElements;
    onSelectChange: (value: string) => void;
  }) => {
  const theme = useTheme();

  return (
    <Box>
      <Autocomplete
        openOnFocus
        options={elements}
        getOptionLabel={(option) => option[optionDisplay] as unknown as string}
        onChange={(_, newValue) =>
          onSelectChange(
            isNil(newValue) ? '' : (newValue[optionValue] as unknown as string)
          )
        }
        componentsProps={{
          paper: {
            sx: {
              width: { xs: 'auto', sm: '300px' },
            },
          },
        }}
        value={selectedValue}
        renderInput={(params) => (
          <TextFieldComponent
            {...params}
            {...props}
            id={name}
            value={elements.length === 0 ? '' : selectedValue}
            fullWidth
            onChange={(event) => onSelectChange(event.target.value)}
            variant="filled"
          />
        )}
        renderOption={(props, option, { inputValue }) => {
          const matches = match(
            option[optionDisplay] as unknown as string,
            inputValue,
            {
              insideWords: true,
            }
          );
          const parts = parse(
            option[optionDisplay] as unknown as string,
            matches
          );

          return (
            <li {...props}>
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                      color: part.highlight
                        ? theme.palette.primary.main
                        : theme.palette.text.primary,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          );
        }}
      />
      <FormHelperText
        id={name}
        sx={{
          color: (theme) => theme.palette.error.main,
          fontWeight: 580,
        }}
      >
        {selectErrors && selectErrors[name]
          ? (selectErrors[name]?.message as unknown as string)
          : ''}
      </FormHelperText>
    </Box>
  );
};

export default SelectComponent;
