import { object, string } from 'zod';
import i18n from '../../translations/i18n';

const ResetPasswordSchema = object({
  password: string()
    .regex(
      new RegExp('.*[A-Z].*'),
      i18n.t('reset_password.errors.password_uppercase_letter')
    )
    .regex(
      new RegExp('.*[a-z].*'),
      i18n.t('reset_password.errors.password_lowercase_letter')
    )
    .regex(
      new RegExp('.*\\d.*'),
      i18n.t('reset_password.errors.password_number')
    )
    .min(8, i18n.t('reset_password.errors.password_min_length')),
  confirmPassword: string().min(
    8,
    i18n.t('reset_password.errors.confirm_password_min_length')
  ),
}).superRefine(({ password, confirmPassword }, ctx) => {
  if (password !== confirmPassword) {
    ctx.addIssue({
      code: 'custom',
      message: i18n.t('reset_password.errors.confirm_password_not_match'),
      path: ['confirmPassword'],
    });
  }
});

export default ResetPasswordSchema;
