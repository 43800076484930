import { DialogContent } from '@mui/material';
import DialogComponent from '../common/dialog/dialog.component';
import { DialogProps } from '../../models/common/dialogs/dialog-props.model';
import { TERMS_AND_CONDITIONS } from '../../constants/terms-and-conditions/terms-and-conditions.constants';
import { useTranslation } from 'react-i18next';

const TermsAndConditionsDialogComponent = ({ handleClose }: DialogProps) => {
  const { t } = useTranslation();

  return (
    <DialogComponent
      open={true}
      handleClose={handleClose}
      width={{ xs: '300px', sm: '400px', md: '550px', lg: '700px' }}
      maxHeight={'500px'}
      dialogTitle={t('registration.fields.terms_and_condition')}
    >
      <DialogContent>{TERMS_AND_CONDITIONS}</DialogContent>
    </DialogComponent>
  );
};

export default TermsAndConditionsDialogComponent;
