import { AxiosInstance } from 'axios';
import { defer, map, Observable } from 'rxjs';
import { AxiosRequestConfiguration } from '../../constants/axios.constants';
import ApiInitialization from '../../utilities/api.utility';
import { AuthenticationHeader } from '../../utilities/authentication.utility';

const ApiProvider = {
  get<T>(url: string, queryParams?: object): Observable<T> {
    return defer(() =>
      this.ApiInstance().get<T>(url, { params: queryParams })
    ).pipe(map((result) => result.data));
  },

  post<T>(
    url: string,
    body: object,
    queryParams?: object
  ): Observable<T | null> {
    return defer(() =>
      this.ApiInstance().post<T>(url, body, { params: queryParams })
    ).pipe(map((result) => result.data));
  },

  put<T>(
    url: string,
    body: object,
    queryParams?: object
  ): Observable<T | null> {
    return defer(() =>
      this.ApiInstance().put<T>(url, body, { params: queryParams })
    ).pipe(map((result) => result.data));
  },

  patch<T>(
    url: string,
    body: object,
    queryParams?: object
  ): Observable<T | null> {
    return defer(() =>
      this.ApiInstance().patch<T>(url, body, { params: queryParams })
    ).pipe(map((result) => result.data));
  },

  delete<T>(url: string, id: number): Observable<T | null> {
    return defer(() => this.ApiInstance().delete(`${url}/${id}`)).pipe(
      map((result) => result.data)
    );
  },

  ApiInstance(): AxiosInstance {
    const authenticationHeader = AuthenticationHeader();
    return ApiInitialization(
      AxiosRequestConfiguration,
      authenticationHeader.Authorization ?? ''
    );
  },
};

export default ApiProvider;
