import NotListedLocationOutlinedIcon from '@mui/icons-material/NotListedLocationOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import TransferWithinAStationOutlinedIcon from '@mui/icons-material/TransferWithinAStationOutlined';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined';
// eslint-disable-next-line import/no-unresolved
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';

const PropertyHistoryEventType = {
  ReceivedPayment: 'RECEIVED_PAYMENT',
  TenantSigned: 'TENANT_SIGNED',
  TenantLeft: 'TENANT_LEFT',
  PropertyBought: 'PROPERTY_BOUGHT',
  PropertySold: 'PROPERTY_SOLD',
  MaintenanceCost: 'MAINTENANCE_COST',
  TaxPaid: 'TAX_PAID',
};

export type PropertyHistoryEventType =
  | 'RECEIVED_PAYMENT'
  | 'TENANT_SIGNED'
  | 'TENANT_LEFT'
  | 'PROPERTY_BOUGHT'
  | 'PROPERTY_SOLD'
  | 'MAINTENANCE_COST'
  | 'TAX_PAID';

export const GetPropertyHistoryEventTypeTranslationId = (
  type: PropertyHistoryEventType
): string => {
  switch (type) {
    case PropertyHistoryEventType.MaintenanceCost:
      return 'property_details.history_tab.events.maintenance_cost';
    case PropertyHistoryEventType.PropertyBought:
      return 'property_details.history_tab.events.property_bought';
    case PropertyHistoryEventType.PropertySold:
      return 'property_details.history_tab.events.property_sold';
    case PropertyHistoryEventType.ReceivedPayment:
      return 'property_details.history_tab.events.received_payment';
    case PropertyHistoryEventType.TaxPaid:
      return 'property_details.history_tab.events.tax_paid';
    case PropertyHistoryEventType.TenantLeft:
      return 'property_details.history_tab.events.tenant_left';
    case PropertyHistoryEventType.TenantSigned:
      return 'property_details.history_tab.events.tenant_signed';
    default:
      return '';
  }
};

export const GetPropertyHistoryEventTypeIcon = (
  type: PropertyHistoryEventType
  // eslint-disable-next-line @typescript-eslint/ban-types
): OverridableComponent<SvgIconTypeMap<{}, 'svg'>> => {
  switch (type) {
    case PropertyHistoryEventType.MaintenanceCost:
      return AttachMoneyOutlinedIcon;
    case PropertyHistoryEventType.PropertyBought:
      return HouseOutlinedIcon;
    case PropertyHistoryEventType.PropertySold:
      return HouseOutlinedIcon;
    case PropertyHistoryEventType.ReceivedPayment:
      return MoneyOutlinedIcon;
    case PropertyHistoryEventType.TaxPaid:
      return AttachMoneyOutlinedIcon;
    case PropertyHistoryEventType.TenantLeft:
      return TransferWithinAStationOutlinedIcon;
    case PropertyHistoryEventType.TenantSigned:
      return TransferWithinAStationOutlinedIcon;
    default:
      return NotListedLocationOutlinedIcon;
  }
};
