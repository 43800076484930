import { useTranslation } from 'react-i18next';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import VerificationCommonComponent from '../verification-helpers/verification-common.component';
import StripeService from '../../services/payments/stripe/stripe.service';
import useObservable from '../../hooks/use-observable.hook';
import { StripeVerificationDocumentType } from '../../constants/stripe/stripe-verification-document-type.constants';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import CustomerStripeFailedVerificationComponent from './customer-stripe-failed-verification.component';
import { take } from 'rxjs';
import VerificationFileSelectionComponent from '../verification-helpers/verification-file-selection.component';
import { VerificationDocumentsExpandedPanelContants } from '../../constants/verification-documents/verification-documents-expanded-panel.constants';
import { isNil } from 'lodash';
import { ToastService } from '../../services/toasts/toast.service';

enum CustomerVerificationStep {
  Initial,
  IdentityVerification,
  AddressVerification,
  PendingVerification,
  FailedVerification,
}

const CustomerStripeVerificationComponent = ({
  connectedAccountId,
  isVerificationPending,
  isIdentityDocumentVerificationFailed,
  isAddressDocumentVerificationFailed,
  pendingStatusAction,
}: {
  connectedAccountId: string;
  isVerificationPending: boolean;
  isIdentityDocumentVerificationFailed: boolean;
  isAddressDocumentVerificationFailed: boolean;
  pendingStatusAction: () => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const getInitialState = (): CustomerVerificationStep => {
    if (
      isIdentityDocumentVerificationFailed ||
      isAddressDocumentVerificationFailed
    ) {
      return CustomerVerificationStep.FailedVerification;
    }

    return isVerificationPending
      ? CustomerVerificationStep.PendingVerification
      : CustomerVerificationStep.Initial;
  };

  const [customerVerificationStep, setCustomerVerificationStep] =
    useState<CustomerVerificationStep>(getInitialState());

  const [addressFileFront, setAddressFileFront] = useState<File>();
  const [addressFileBack, setAddressFileBack] = useState<File>();

  const [identityFileFront, setIdentityFileFront] = useState<File>();
  const [identityFileBack, setIdentityFileBack] = useState<File>();

  const [identityErrorMessage, setIdentityErrorMessage] = useState('');
  const [addressErrorMessage, setAddressErrorMessage] = useState('');

  const [expandedPanel, setExpandedPanel] = useState(
    VerificationDocumentsExpandedPanelContants.IdentityDocumentFront
  );

  const [isIdentityValidationEnabled, setIsIdentityValidationEnabled] =
    useState(false);
  const [isAddressValidationEnabled, setIsAddressValidationEnabled] =
    useState(false);

  const [isVerificationFromFailed, setIsVerificationFromFailed] = useState(
    isIdentityDocumentVerificationFailed || isAddressDocumentVerificationFailed
  );
  const [
    areAddressDocumentsUpdatedAfterFail,
    setAreAddressDocumentsUpdatedAfterFail,
  ] = useState(false);
  const [
    areIdentityDocumentsUpdatedAfterFail,
    setAreIdentityDocumentsUpdatedAfterFail,
  ] = useState(false);

  const verificationAcceptableDocuments$ = useMemo(
    () => StripeService.GetAllAcceptableVerificationDocuments(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [verificationAcceptableDocuments, ,] = useObservable(
    verificationAcceptableDocuments$
  );

  useEffect(() => {
    if (isNil(identityFileFront) || isNil(identityFileBack)) {
      setIdentityErrorMessage(
        t('stripe_verification.errors.at_least_one_file')
      );
    } else {
      setIdentityErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityFileFront, identityFileBack]);

  useEffect(() => {
    if (isNil(addressFileFront) || isNil(addressFileBack)) {
      setAddressErrorMessage(t('stripe_verification.errors.at_least_one_file'));
    } else {
      setAddressErrorMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressFileFront, addressFileBack]);

  const goToAddressVerification = () => {
    setExpandedPanel(
      VerificationDocumentsExpandedPanelContants.AddressDocumentFront
    );
    setCustomerVerificationStep(CustomerVerificationStep.AddressVerification);
  };

  const goToAddressVerificationFromFailed = () => {
    setIsVerificationFromFailed(true);
    goToAddressVerification();
  };

  const goToIdentityVerification = () => {
    setCustomerVerificationStep(CustomerVerificationStep.IdentityVerification);
  };

  const goToIdentityVerificationFromFailed = () => {
    setIsVerificationFromFailed(true);
    goToIdentityVerification();
  };

  const goToPendingVerification = () => {
    setCustomerVerificationStep(CustomerVerificationStep.PendingVerification);
  };

  const goToFailedVerification = () => {
    setCustomerVerificationStep(CustomerVerificationStep.FailedVerification);
  };

  const verifyDocuments = () => {
    // Send pictures to stripe
    const documentsFormData = new FormData();

    if (isNil(identityFileFront)) {
      ToastService.error(
        t('stripe_verification.errors.identity_file_front_not_set')
      );
      return;
    }

    documentsFormData.append('identityDocumentFront', identityFileFront);

    if (!isNil(identityFileBack)) {
      documentsFormData.append('identityDocumentBack', identityFileBack);
    }

    if (!isNil(addressFileFront)) {
      documentsFormData.append('addressDocumentFront', addressFileFront);
    }

    if (!isNil(addressFileBack)) {
      documentsFormData.append('addressDocumentBack', addressFileBack);
    }

    goToPendingVerification();
    StripeService.VerifyConnectedAccount(connectedAccountId, documentsFormData)
      .pipe(take(1))
      .subscribe({
        next: () =>
          setCustomerVerificationStep(
            CustomerVerificationStep.PendingVerification
          ),
        error: () =>
          setCustomerVerificationStep(
            CustomerVerificationStep.FailedVerification
          ),
      });
  };

  const VerificationStep = (): ReactNode => {
    switch (customerVerificationStep) {
      case CustomerVerificationStep.Initial:
        return (
          <VerificationCommonComponent
            description={t('stripe_verification.initial.description')}
            buttonText={t('stripe_verification.initial.button_text')}
            buttonAction={goToIdentityVerification}
            Icon={HowToRegOutlinedIcon}
          />
        );
      case CustomerVerificationStep.IdentityVerification:
        const identityAcceptableDocuments =
          verificationAcceptableDocuments?.filter(
            (verificationDocument) =>
              verificationDocument.type ===
              StripeVerificationDocumentType.Identity
          ) ?? [];

        const onIdentityNext = () => {
          setIsIdentityValidationEnabled(true);

          if (isNil(identityFileFront) || isNil(identityFileBack)) {
            return;
          }

          if (isVerificationFromFailed) {
            setIsVerificationFromFailed(false);
            setAreIdentityDocumentsUpdatedAfterFail(true);
            goToFailedVerification();
          } else {
            goToAddressVerification();
          }
        };

        return (
          <VerificationFileSelectionComponent
            setDocumentBack={setIdentityFileBack}
            setDocumentFront={setIdentityFileFront}
            acceptableDocuments={identityAcceptableDocuments}
            acceptableDocumentsDescription={t(
              'stripe_verification.document_verification.acceptable_identity_documents'
            )}
            key={'identity'}
            nextButtonAction={onIdentityNext}
            nextButtonLabel={t('stripe_verification.button_next')}
            expandedPanel={expandedPanel}
            expandedPanelFront={
              VerificationDocumentsExpandedPanelContants.IdentityDocumentFront
            }
            expandedPanelBack={
              VerificationDocumentsExpandedPanelContants.IdentityDocumentBack
            }
            setExpandedPanel={setExpandedPanel}
            documentFrontName={identityFileFront?.name ?? ''}
            documentBackName={identityFileBack?.name ?? ''}
            documentFrontLabel={`${t(
              'stripe_verification.files.identity_front'
            )}`}
            documentBackLabel={t('stripe_verification.files.identity_back')}
          />
        );
      case CustomerVerificationStep.AddressVerification:
        const addressAcceptableDocuments =
          verificationAcceptableDocuments?.filter(
            (verificationDocument) =>
              verificationDocument.type ===
              StripeVerificationDocumentType.Address
          ) ?? [];

        const onAddressNext = () => {
          setIsAddressValidationEnabled(true);

          if (isNil(addressFileFront) || isNil(addressFileBack)) {
            return;
          }

          if (isVerificationFromFailed) {
            setIsVerificationFromFailed(false);
            setAreAddressDocumentsUpdatedAfterFail(true);
            goToFailedVerification();
          } else {
            verifyDocuments();
          }
        };

        return (
          <VerificationFileSelectionComponent
            setDocumentBack={setAddressFileBack}
            setDocumentFront={setAddressFileFront}
            acceptableDocuments={addressAcceptableDocuments}
            acceptableDocumentsDescription={t(
              'stripe_verification.document_verification.acceptable_address_documents'
            )}
            key={'address'}
            nextButtonAction={onAddressNext}
            nextButtonLabel={t('stripe_verification.button_next')}
            expandedPanel={expandedPanel}
            expandedPanelFront={
              VerificationDocumentsExpandedPanelContants.AddressDocumentFront
            }
            expandedPanelBack={
              VerificationDocumentsExpandedPanelContants.AddressDocumentBack
            }
            setExpandedPanel={setExpandedPanel}
            documentFrontName={addressFileFront?.name ?? ''}
            documentBackName={addressFileBack?.name ?? ''}
            documentFrontLabel={t('stripe_verification.files.address_front')}
            documentBackLabel={t('stripe_verification.files.address_back')}
          />
        );
      case CustomerVerificationStep.PendingVerification:
        return (
          <VerificationCommonComponent
            description={t('stripe_verification.pending.description')}
            buttonText={t('stripe_verification.pending.button_text')}
            buttonAction={() => pendingStatusAction()}
            Icon={HourglassEmptyOutlinedIcon}
          />
        );
      case CustomerVerificationStep.FailedVerification:
        return (
          <CustomerStripeFailedVerificationComponent
            areAddressDocumentsValid={!isAddressDocumentVerificationFailed}
            areAddressDocumentsUpdated={areAddressDocumentsUpdatedAfterFail}
            goToAddressVerification={goToAddressVerificationFromFailed}
            areIdentityDocumentsValid={!isIdentityDocumentVerificationFailed}
            areIdentityDocumentsUpdated={areIdentityDocumentsUpdatedAfterFail}
            goToIdentityVerification={goToIdentityVerificationFromFailed}
            verifyDocuments={verifyDocuments}
          />
        );
    }
  };

  const renderErrorMessage = (
    isValidationEnabled: boolean,
    errorMessage: string
  ): ReactNode => {
    if (!isValidationEnabled || errorMessage === '') {
      return <Box></Box>;
    }
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: theme.spacing(1),
        }}
      >
        <Typography fontSize="14px" color="error">
          {errorMessage}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Box>
        <Typography
          color="primary"
          fontSize="16px"
          fontWeight="bold"
          textAlign="center"
        >
          {t('stripe_verification.title')}
        </Typography>
      </Box>
      {VerificationStep()}
      {renderErrorMessage(isIdentityValidationEnabled, identityErrorMessage)}
      {renderErrorMessage(isAddressValidationEnabled, addressErrorMessage)}
    </>
  );
};

export default CustomerStripeVerificationComponent;
