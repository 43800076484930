import { Box, Typography, useTheme } from '@mui/material';
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { isNil } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { take } from 'rxjs';
import { StripeDepositsService } from '../../../../services/payments/stripe/stripe-deposit.service';
import SharedDataService from '../../../../services/shared-data/shared-data.service';
import { GetStripeErrorMessage } from '../../../../utilities/stripe.utility';
import ButtonComponent from '../../../common/button/button.component';
import StripeCardNumberComponent from '../../../common/stripe/stripe-card-number.component';
import StripeCvcComponent from '../../../common/stripe/stripe-cvc.component';
import StripeExpiryComponent from '../../../common/stripe/stripe-expiry.component';
import LoadingSpinnerComponent from '../../../common/loading-spinner/loading-spinner.component';
import { CurrencyFormatter } from '../../../../utilities/currency.utility';
import useObservable from '../../../../hooks/use-observable.hook';

const CardDepositNewCardPaymentComponent = ({
  depositAmount,
  depositCurrency,
  onPaymentFail,
  onPaymentSuccess,
}: {
  depositAmount: number;
  depositCurrency: string;
  onPaymentFail: () => void;
  onPaymentSuccess: () => void;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const elements = useElements();
  const stripe = useStripe();

  const [depositButtonSpinnerColor, setDepositButtonSpinnerColor] =
    useState('#FFFFFF');
  const [isDepositAttempted, setIsDepositAttempted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [state, setState] = React.useState({
    cardNumberComplete: false,
    expiredComplete: false,
    cvcComplete: false,
    cardNumberError: null,
    expiredError: null,
    cvcError: null,
  });

  const onElementChange =
    (field: string, errorField: string) =>
    ({
      complete,
      error,
    }: {
      complete: boolean;
      error: { code: string; message: string; type: string } | undefined;
    }) => {
      const errorMessage = !isNil(error)
        ? GetStripeErrorMessage(error.code)
        : '';
      setState({ ...state, [field]: complete, [errorField]: errorMessage });
    };

  const { cardNumberError, expiredError, cvcError } = state;
  const { cardNumberComplete, expiredComplete, cvcComplete } = state;

  useEffect(() => {
    if (cardNumberComplete && expiredComplete && cvcComplete) {
      setErrorMessage('');
    }
  }, [cardNumberComplete, expiredComplete, cvcComplete]);

  const depositFee$ = useMemo(
    () => StripeDepositsService.GetDepositFee(depositAmount),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [depositAmount]
  );

  const [depositFee, , isDepositFeeLoading] = useObservable(depositFee$);

  const createPaymentIntent = () => {
    if (!stripe || !elements) {
      setIsDepositAttempted(false);
      return;
    }

    // Create payment intent on the server
    StripeDepositsService.CreatePaymentIntent(depositAmount, depositCurrency)
      .pipe(take(1))
      .subscribe(async (paymentIntent) => {
        if (!paymentIntent) {
          setIsDepositAttempted(false);
          return;
        }

        // Confirm the payment on the client
        const clientSecret = paymentIntent.clientSecret;

        const cardNumberElement = elements.getElement(CardNumberElement);
        if (!cardNumberElement) {
          setIsDepositAttempted(false);
          return;
        }

        const paymentResults = await stripe.confirmCardPayment(
          clientSecret,
          {
            payment_method: {
              card: cardNumberElement,
            },
          },
          {}
        );

        if (paymentResults.paymentIntent?.status === 'succeeded') {
          setTimeout(
            () => SharedDataService.TriggerUpdateCustomerInformationEvent(),
            5000
          );

          onPaymentSuccess();
          return;
        }

        onPaymentFail();
      });
  };

  const depositFunds = () => {
    if (!cardNumberComplete || !expiredComplete || !cvcComplete) {
      setErrorMessage(t('card_deposit.new_card_payment.errors.invalid_fields'));
      return;
    }

    setIsDepositAttempted(true);

    createPaymentIntent();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        paddingTop: theme.spacing(2),
      }}
    >
      <Box>
        <StripeCardNumberComponent
          error={Boolean(cardNumberError)}
          labelErrorMessage={cardNumberError ?? ''}
          variant="filled"
          onChange={onElementChange('cardNumberComplete', 'cardNumberError')}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'column', md: 'row' },
          gap: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            flex: 2,
          }}
        >
          <StripeExpiryComponent
            error={Boolean(expiredError)}
            labelErrorMessage={expiredError ?? ''}
            variant="filled"
            onChange={onElementChange('expiredComplete', 'expiredError')}
          />
        </Box>

        <Box
          sx={{
            flex: 1,
          }}
        >
          <StripeCvcComponent
            error={Boolean(cvcError)}
            labelErrorMessage={cvcError ?? ''}
            variant="filled"
            onChange={onElementChange('cvcComplete', 'cvcError')}
          />
        </Box>
      </Box>

      <Box>
        <Typography
          color="primary"
          fontSize="12px"
          textAlign="center"
          fontWeight="600"
          sx={{
            paddingTop: theme.spacing(2),
          }}
        >
          {t('card_deposit.new_card_payment.tax_warning')}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <ButtonComponent
          onClick={depositFunds}
          sx={{
            width: '200px',
            height: '50px',
            fontSize: '12px',
            fontWeight: 'bold',
          }}
          onMouseEnter={() =>
            setDepositButtonSpinnerColor(theme.palette.primary.main)
          }
          onMouseLeave={() => setDepositButtonSpinnerColor('#FFFFFF')}
        >
          {isDepositAttempted || isDepositFeeLoading ? (
            <LoadingSpinnerComponent spinnerColor={depositButtonSpinnerColor} />
          ) : (
            <>{`${t(
              'card_deposit.new_card_payment.deposit_button'
            )} ${CurrencyFormatter(depositFee?.amountAfterFeeApplied ?? 0)}`}</>
          )}
        </ButtonComponent>
      </Box>

      {errorMessage !== '' && (
        <Box>
          <Typography fontSize="14px" color="error" textAlign="center">
            {errorMessage}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default CardDepositNewCardPaymentComponent;
