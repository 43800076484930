import { WithdrawalTableModel } from '../../models/withdrawal/withdrawal-table.model';
import ColumnDefinitionModel from '../../models/table/column-definition.model';
import { ColumnType } from './column-type.constants';

export const WithdrawalColumnsDefinition: ColumnDefinitionModel<
  WithdrawalTableModel,
  keyof WithdrawalTableModel
>[] = [
  {
    key: 'id',
    headerTranslationId: 'history_page.withdrawals_table.id',
    type: ColumnType.Text,
  },
  {
    key: 'status',
    headerTranslationId: 'history_page.withdrawals_table.withdrawal_status',
    type: ColumnType.Text,
  },
  {
    key: 'amount',
    headerTranslationId: 'history_page.withdrawals_table.withdrawal_amount',
    type: ColumnType.Currency,
  },
  {
    key: 'executionStrategy',
    headerTranslationId:
      'history_page.withdrawals_table.withdrawal_execution_strategy',
    type: ColumnType.Text,
  },
  {
    key: 'paymentMethodSubMethod',
    headerTranslationId:
      'history_page.withdrawals_table.withdrawal_payment_method_sub_method',
    type: ColumnType.Text,
    width: 150,
  },
  {
    key: 'paymentMethodDescription',
    headerTranslationId:
      'history_page.withdrawals_table.withdrawal_payment_method_description',
    type: ColumnType.Text,
  },
  {
    key: 'createdAt',
    headerTranslationId: 'history_page.withdrawals_table.withdrawal_date',
    type: ColumnType.Date,
    width: 140,
  },
];
