export const StripeConnectedAccountStatus = {
  Verified: 'VERIFIED',
  PendingVerification: 'PENDING_VERIFICATION',
  Unverified: 'UNVERIFIED',
};

export type StripeConnectedAccountStatusType =
  | 'VERIFIED'
  | 'PENDING_VERIFICATION'
  | 'UNVERIFIED';
