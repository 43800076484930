import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined';
import { useTranslation } from 'react-i18next';
import { ReactNode, useState } from 'react';
import VerifyCustomerComponent from './verify-customer.component';
import { Box, Typography, useTheme } from '@mui/material';
import VerificationCommonComponent from '../verification-helpers/verification-common.component';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

enum CustomerVerificationStep {
  Initial,
  Verification,
  VerificationSuccess,
}

const CustomerVerificationComponent = ({
  onSuccessfulVerification,
}: {
  onSuccessfulVerification: () => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [customerVerificationStep, setCustomerVerificationStep] = useState(
    CustomerVerificationStep.Initial
  );

  const goToVerificationStep = () => {
    setCustomerVerificationStep(CustomerVerificationStep.Verification);
  };

  const goToSuccessfullVerificationStep = () => {
    setCustomerVerificationStep(CustomerVerificationStep.VerificationSuccess);
  };

  const VerificationStep = (): ReactNode => {
    switch (customerVerificationStep) {
      case CustomerVerificationStep.Initial:
        return (
          <VerificationCommonComponent
            description={t('customer_verification.initial.description')}
            buttonText={t('customer_verification.initial.button_text')}
            buttonAction={goToVerificationStep}
            Icon={FingerprintOutlinedIcon}
          />
        );
      case CustomerVerificationStep.Verification:
        return (
          <VerifyCustomerComponent onVerify={goToSuccessfullVerificationStep} />
        );
      case CustomerVerificationStep.VerificationSuccess:
        return (
          <VerificationCommonComponent
            description={t('customer_verification.success.description')}
            buttonText={t('customer_verification.success.button_text')}
            buttonAction={onSuccessfulVerification}
            Icon={CheckBoxIcon}
            iconColor={theme.palette.success.main}
          />
        );
    }
  };

  return (
    <>
      <Box>
        <Typography
          color="primary"
          fontSize="16px"
          fontWeight="bold"
          textAlign="center"
        >
          {t('customer_verification.title')}
        </Typography>
      </Box>
      {VerificationStep()}
    </>
  );
};

export default CustomerVerificationComponent;
