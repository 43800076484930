import { zodResolver } from '@hookform/resolvers/zod';
import { Box, useTheme } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TypeOf } from 'zod';
import { CustomerModel } from '../../models/customer/customer.model';
import CustomerProfileInformationSchema from '../../schemas/customer-profile/customer-profile-information.schema';
import FormInputTextComponent from '../common/forms/form-input-text.component';

type ICustomerProfileInformationForm = TypeOf<
  typeof CustomerProfileInformationSchema
>;

const CustomerProfileInformationTabComponent = ({
  customer,
  isCustomerVerified,
}: {
  customer: CustomerModel;
  isCustomerVerified: boolean;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const defaultCustomerProfileInformationFormValues: ICustomerProfileInformationForm =
    {
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email,
      phoneNumber: customer.phoneNumber ?? '',
      status: isCustomerVerified
        ? t('customer_profile_information.fields.status.verified')
        : t('customer_profile_information.fields.status.not_verified'),
      address: customer.addressLine ?? '',
      city: customer.city ?? '',
      postalCode: customer.postalCode ?? '',
    };

  const methods = useForm<ICustomerProfileInformationForm>({
    resolver: zodResolver(CustomerProfileInformationSchema),
    defaultValues: defaultCustomerProfileInformationFormValues,
  });

  const updateCustomerInformation: SubmitHandler<ICustomerProfileInformationForm> =
    (customerProfileInformation: ICustomerProfileInformationForm) => {
      console.log(customerProfileInformation);
    };

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={methods.handleSubmit(updateCustomerInformation)}
        display="flex"
        flexDirection="column"
        gap={theme.spacing(2)}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: '200px',
              md: 'minmax(164px, 1fr) minmax(164px, 1fr)',
            },
            gap: theme.spacing(2),
            justifyContent: 'center',
            padding: theme.spacing(2),
          }}
        >
          <FormInputTextComponent
            label={t('customer_profile_information.fields.first_name')}
            type="text"
            name="firstName"
            variant="filled"
            disabled
            inputProps={{
              sx: {
                fontSize: '18px',
                fontWeight: 'bold',
              },
            }}
          />

          <FormInputTextComponent
            label={t('customer_profile_information.fields.last_name')}
            type="text"
            name="lastName"
            variant="filled"
            disabled
            inputProps={{
              sx: {
                fontSize: '18px',
                fontWeight: 'bold',
              },
            }}
          />

          <FormInputTextComponent
            label={t('customer_profile_information.fields.email')}
            type="text"
            name="email"
            variant="filled"
            disabled
            inputProps={{
              sx: {
                fontSize: '18px',
                fontWeight: 'bold',
              },
            }}
          />

          <FormInputTextComponent
            label={t('customer_profile_information.fields.status.label')}
            type="text"
            name="status"
            variant="filled"
            disabled
            inputProps={{
              sx: {
                fontSize: '18px',
                fontWeight: 'bold',
              },
            }}
          />

          <FormInputTextComponent
            label={t('customer_profile_information.fields.phone_number')}
            type="text"
            name="phoneNumber"
            variant="filled"
            disabled
            inputProps={{
              sx: {
                fontSize: '18px',
                fontWeight: 'bold',
              },
            }}
          />

          <FormInputTextComponent
            label={t('customer_profile_information.fields.address')}
            type="text"
            name="address"
            variant="filled"
            disabled
            inputProps={{
              sx: {
                fontSize: '18px',
                fontWeight: 'bold',
              },
            }}
          />

          <FormInputTextComponent
            label={t('customer_profile_information.fields.city')}
            type="city"
            name="address"
            variant="filled"
            disabled
            inputProps={{
              sx: {
                fontSize: '18px',
                fontWeight: 'bold',
              },
            }}
          />

          <FormInputTextComponent
            label={t('customer_profile_information.fields.postal_code')}
            type="postalCode"
            name="address"
            variant="filled"
            disabled
            inputProps={{
              sx: {
                fontSize: '18px',
                fontWeight: 'bold',
              },
            }}
          />
        </Box>
      </Box>
    </FormProvider>
  );
};

export default CustomerProfileInformationTabComponent;
