import { PropertyDetailsTabType } from '../../enums/property-details/property-details-tab-type.enum';

const PROPERTY_DETAILS_TABS: {
  nameTranslationId: string;
  type: PropertyDetailsTabType;
}[] = [
  {
    nameTranslationId: 'property_details.tabs.information',
    type: PropertyDetailsTabType.Information,
  },
  {
    nameTranslationId: 'property_details.tabs.comments',
    type: PropertyDetailsTabType.Comments,
  },
  {
    nameTranslationId: 'property_details.tabs.location',
    type: PropertyDetailsTabType.Location,
  },
  {
    nameTranslationId: 'property_details.tabs.amenities',
    type: PropertyDetailsTabType.Amenities,
  },
  {
    nameTranslationId: 'property_details.tabs.dividends',
    type: PropertyDetailsTabType.Dividends,
  },
  {
    nameTranslationId: 'property_details.tabs.history',
    type: PropertyDetailsTabType.History,
  },
];

export default PROPERTY_DETAILS_TABS;
