import { OrderModel } from '../../models/order/order.model';
import { Observable } from 'rxjs';
import { ValueOrderModel } from '../../models/order/place-order.model';
import { OrderDirection } from '../../constants/order-direction.constants';
import ApiProvider from '../../providers/api/api.provider';
import { QuantityOrderModel } from '../../models/order/quantity-order.model';

export const OrderService = {
  PlaceValueOrder(
    propertyId: number,
    amount: number,
    direction: OrderDirection.Buy | OrderDirection.Sell
  ): Observable<OrderModel | null> {
    const placeOrderParams: ValueOrderModel = {
      direction: direction,
      propertyId: propertyId,
      value: amount,
    };

    return ApiProvider.post<OrderModel>('/orders/value', placeOrderParams);
  },

  PlaceQuantityOrder(
    propertyId: number,
    quantity: number,
    direction: OrderDirection.Buy | OrderDirection.Sell
  ): Observable<OrderModel | null> {
    const placeOrderParams: QuantityOrderModel = {
      direction: direction,
      propertyId: propertyId,
      quantity: quantity,
    };

    return ApiProvider.post<OrderModel>('/orders/quantity', placeOrderParams);
  },
};
