import { SvgIconTypeMap } from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { KeyValueModel } from '../../models/common/key-value.model';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import FitnessCenterOutlinedIcon from '@mui/icons-material/FitnessCenterOutlined';
import ParkOutlinedIcon from '@mui/icons-material/ParkOutlined';
import DirectionsBusOutlinedIcon from '@mui/icons-material/DirectionsBusOutlined';
import NotListedLocationOutlinedIcon from '@mui/icons-material/NotListedLocationOutlined';

export type PropertyAmenityType =
  | 'RESTAURANT'
  | 'HOSPITAL'
  | 'SCHOOL'
  | 'SUPERMARKET'
  | 'GYM'
  | 'PARK'
  | 'PUBLIC_TRANSPORT_STATION';

export const RESTAURANT_TYPE: PropertyAmenityType = 'RESTAURANT';
export const HOSPITAL_TYPE: PropertyAmenityType = 'HOSPITAL';
export const SCHOOL_TYPE: PropertyAmenityType = 'SCHOOL';
export const SUPERMARKET_TYPE: PropertyAmenityType = 'SUPERMARKET';
export const GYM_TYPE: PropertyAmenityType = 'GYM';
export const PARK_TYPE: PropertyAmenityType = 'PARK';
export const PUBLIC_TRANSPORT_STATION_TYPE: PropertyAmenityType =
  'PUBLIC_TRANSPORT_STATION';

export const AllPropertyAmenityTypes = [
  RESTAURANT_TYPE,
  HOSPITAL_TYPE,
  SCHOOL_TYPE,
  SUPERMARKET_TYPE,
  GYM_TYPE,
  PARK_TYPE,
  PUBLIC_TRANSPORT_STATION_TYPE,
];

const Restaurant: KeyValueModel<string, string> = {
  key: RESTAURANT_TYPE,
  value: 'property_details.amenities_tab.restaurant',
};

const Hospital: KeyValueModel<string, string> = {
  key: HOSPITAL_TYPE,
  value: 'property_details.amenities_tab.hospital',
};

const School: KeyValueModel<string, string> = {
  key: SCHOOL_TYPE,
  value: 'property_details.amenities_tab.school',
};

const Supermarket: KeyValueModel<string, string> = {
  key: SUPERMARKET_TYPE,
  value: 'property_details.amenities_tab.supermarket',
};

const Gym: KeyValueModel<string, string> = {
  key: GYM_TYPE,
  value: 'property_details.amenities_tab.gym',
};

const Park: KeyValueModel<string, string> = {
  key: PARK_TYPE,
  value: 'property_details.amenities_tab.park',
};

const PublicTransportStation: KeyValueModel<string, string> = {
  key: PUBLIC_TRANSPORT_STATION_TYPE,
  value: 'property_details.amenities_tab.public_transport',
};

export const Amenities = [
  Restaurant,
  Hospital,
  School,
  Supermarket,
  Gym,
  Park,
  PublicTransportStation,
];

export const GetPropertyAmenityType = (
  propertyAmenityType: PropertyAmenityType
): string => {
  switch (propertyAmenityType) {
    case Restaurant.key:
      return Restaurant.value;
    case Hospital.key:
      return Hospital.value;
    case School.key:
      return School.value;
    case Supermarket.key:
      return Supermarket.value;
    case Gym.key:
      return Gym.value;
    case Park.key:
      return Park.value;
    case PublicTransportStation.key:
      return PublicTransportStation.value;
    default:
      return '';
  }
};

export const GetPropertyAmenityIcon = (
  propertyAmenityType: PropertyAmenityType
  // eslint-disable-next-line @typescript-eslint/ban-types
): OverridableComponent<SvgIconTypeMap<{}, 'svg'>> => {
  switch (propertyAmenityType) {
    case Restaurant.key:
      return RestaurantOutlinedIcon;
    case Hospital.key:
      return LocalHospitalOutlinedIcon;
    case School.key:
      return SchoolOutlinedIcon;
    case Supermarket.key:
      return StoreOutlinedIcon;
    case Gym.key:
      return FitnessCenterOutlinedIcon;
    case Park.key:
      return ParkOutlinedIcon;
    case PublicTransportStation.key:
      return DirectionsBusOutlinedIcon;
    default:
      return NotListedLocationOutlinedIcon;
  }
};
