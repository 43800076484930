/* eslint-disable @typescript-eslint/no-unused-vars */
import { Observable, take, map } from 'rxjs';
import { IsCustomerVerified } from '../../enums/customer-verification-status.enum';
import { CustomerNotifiableEventSettingsUpdateModel } from '../../models/customer/customer-notifiable-event-settings-update.model';
import { CustomerNotifiableEventSettings } from '../../models/customer/customer-notifiable-event-settings.model';
import { CustomerVerificationModel } from '../../models/customer/customer-verification.model';
import { CustomerModel } from '../../models/customer/customer.model';
import { DividendModel } from '../../models/dividend/dividend.model';
import ApiProvider from '../../providers/api/api.provider';
import { InvestmentModel } from '../../models/investments/investment.model';
import { OrderModel } from '../../models/order/order.model';
import { PortfolioMetricsModel } from '../../models/portfolio-metrics/portfolio-metrics.model';
import { TransactionModel } from '../../models/transaction/transaction.model';
import { PortfolioValue } from '../../models/portfolio-metrics/portfolio-value.model';

const CustomerService = {
  GetCustomer(): Observable<CustomerModel> {
    return ApiProvider.get<CustomerModel>('/customers/me');
  },

  GetCustomerNotifiableEventSettings(): Observable<
    CustomerNotifiableEventSettings[]
  > {
    return ApiProvider.get<CustomerNotifiableEventSettings[]>(
      '/customers/notifiable-events/settings'
    );
  },

  UpdateCustomerNotifiableEventSettings(
    settings: CustomerNotifiableEventSettingsUpdateModel[]
  ): Observable<Record<string, never> | null> {
    return ApiProvider.put<Record<string, never>>(
      '/customers/notifiable-events/settings',
      settings
    );
  },

  ActivateCustomer(activationCode: string): Observable<unknown> {
    return ApiProvider.put<unknown>(
      `/customers/activate?activationCode=${activationCode}`,
      {}
    );
  },

  Verify(
    customerVerficiationModel: CustomerVerificationModel
  ): Observable<void | null> {
    return ApiProvider.put<void | null>(
      '/customers/me/verify',
      customerVerficiationModel
    );
  },

  IsCustomerVerified(): Observable<boolean> {
    return this.GetCustomer().pipe(
      take(1),
      map((customer: CustomerModel) => IsCustomerVerified(customer))
    );
  },

  SendForgottenPasswordEmail(email: string): Observable<unknown> {
    const sendForgottenPasswordEmailParams = {
      email: email,
    };

    return ApiProvider.post<unknown>(
      `/customers/forgotten-password/send-email`,
      {},
      sendForgottenPasswordEmailParams
    );
  },

  UpdatePasswordWithToken(
    newPassword: string,
    token: string
  ): Observable<boolean | null> {
    const changePasswordWithTokenParams = {
      newPassword: newPassword,
      token: token,
    };

    return ApiProvider.put<boolean>(
      `customers/settings/password/with-token`,
      changePasswordWithTokenParams
    );
  },

  ChangePassword(
    oldPassword: string,
    newPassword: string
  ): Observable<boolean | null> {
    const customerChangePasswordParams = {
      oldPassword,
      newPassword,
    };

    return ApiProvider.put<boolean>(`customers/settings/password`, {
      ...customerChangePasswordParams,
    });
  },

  GetDividends(
    customerId: number,
    page: number,
    rowsPerPage: number
  ): Observable<DividendModel[]> {
    return ApiProvider.get<DividendModel[]>(
      `customers/${customerId}/dividends/history`
    );
  },

  GetDividendsByProperty(
    customerId: number,
    propertyId: number
  ): Observable<DividendModel[]> {
    return ApiProvider.get<DividendModel[]>(
      `/customers/${customerId}/dividends?propertyId=${propertyId}`
    );
  },

  GetInvestments(
    customerId: number,
    page: number,
    rowsPerPage: number
  ): Observable<InvestmentModel[]> {
    return ApiProvider.get<InvestmentModel[]>(
      `customers/${customerId}/investments`
    );
  },

  GetInvestmentsByProperty(
    customerId: number,
    propertyId: number
  ): Observable<InvestmentModel[]> {
    return ApiProvider.get<InvestmentModel[]>(
      `/customers/${customerId}/investments?propertyId=${propertyId}`
    );
  },

  GetOrders(
    customerId: number,
    page: number,
    rowsPerPage: number
  ): Observable<OrderModel[]> {
    // TODO: send those parameters to the back-end when the time comes
    return ApiProvider.get<OrderModel[]>(
      `/customers/${customerId}/orders/history`
    );
  },

  GetPortfolioMetrics(customerId: number): Observable<PortfolioMetricsModel> {
    return ApiProvider.get<PortfolioMetricsModel>(
      `/customers/${customerId}/portfolio-metrics`
    );
  },

  GetPortfolioValuesByDate(customerId: number): Observable<PortfolioValue[]> {
    return ApiProvider.get<PortfolioValue[]>(
      `/customers/${customerId}/portfolio-value`
    );
  },

  GetTransactions(
    customerId: number,
    page: number,
    rowsPerPage: number
  ): Observable<TransactionModel[]> {
    // TODO: send those parameters to the back-end when the time comes
    return ApiProvider.get<TransactionModel[]>(
      `customers/${customerId}/transactions`
    );
  },
};

export default CustomerService;
