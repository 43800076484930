import { Box, useTheme } from '@mui/material';
import { t } from 'i18next';
import { isNil } from 'lodash';
import { useState } from 'react';
import { DialogManagerProps } from '../../../models/common/dialogs/dialog-manager-props.model';
import { CustomerModel } from '../../../models/customer/customer.model';
import {
  PaymentMethodModel,
  PaymentMethodType,
} from '../../../models/payment-methods/payment-method.model';
import withDialogs from '../../../providers/dialog/with-dialog';
import { CurrencyFormatter } from '../../../utilities/currency.utility';
import ButtonComponent from '../../common/button/button.component';
import LoadingSpinnerComponent from '../../common/loading-spinner/loading-spinner.component';
import PreviewBoxComponent from '../../common/preview-box/preview-box.component';
import PreviewRowComponent from '../../common/preview-box/preview-row.component';

const WithdrawPreviewComponent = ({
  withdrawAmount,
  paymentMethod,
  makeWithdrawRequest,
}: {
  withdrawAmount: number;
  paymentMethod: PaymentMethodModel | null;
  customer: CustomerModel;
  makeWithdrawRequest: () => void;
} & DialogManagerProps) => {
  const theme = useTheme();

  const [isWithdrawAttempted, setIsWithdrawAttempted] = useState(false);

  const getSubMethodLabel = (): string => {
    if (paymentMethod?.type === PaymentMethodType.Bank) {
      return t('withdraw_dialog.preview.bank_name');
    }

    if (paymentMethod?.type === PaymentMethodType.Card) {
      return t('withdraw_dialog.preview.card_type');
    }

    return '';
  };

  const getDescriptionLabel = (): string => {
    if (paymentMethod?.type === PaymentMethodType.Bank) {
      return t('withdraw_dialog.preview.iban');
    }

    if (paymentMethod?.type === PaymentMethodType.Card) {
      return t('withdraw_dialog.preview.card_number');
    }

    return '';
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: theme.spacing(2),
      }}
    >
      <PreviewBoxComponent>
        <PreviewRowComponent
          title={t('withdraw_dialog.preview.withdraw_amount')}
          value={CurrencyFormatter(withdrawAmount)}
          bottomBorderWidth={isNil(paymentMethod) ? 0 : 1}
        />
        {!isNil(paymentMethod) && (
          <>
            <PreviewRowComponent
              title={getSubMethodLabel()}
              value={paymentMethod.subMethod}
              bottomBorderWidth={1}
            />
            <PreviewRowComponent
              title={getDescriptionLabel()}
              value={paymentMethod.description}
              bottomBorderWidth={0}
            />
          </>
        )}
      </PreviewBoxComponent>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: theme.spacing(2),
        }}
      >
        <ButtonComponent
          sx={{
            width: { xs: '180px', sm: '200px' },
            height: '50px',
            fontSize: '12px',
            fontWeight: 'bold',
          }}
          onClick={() => {
            setIsWithdrawAttempted(true);
            makeWithdrawRequest();
          }}
        >
          {isWithdrawAttempted ? (
            <LoadingSpinnerComponent spinnerColor="#FFFFFF" />
          ) : (
            <>{t('withdraw_dialog.preview.withdraw_button')}</>
          )}
        </ButtonComponent>
      </Box>
    </Box>
  );
};

const wrappedWithdrawPreviewComponent = withDialogs(WithdrawPreviewComponent);
export default wrappedWithdrawPreviewComponent;
