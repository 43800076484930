import { Box, FormHelperText } from '@mui/material';
import { isNil } from 'lodash';
import { ReactNode } from 'react';
import {
  FormStripeInputProps,
  StripeElement,
} from '../../../models/common/form/form-stripe-input-props.model';
import StripeInputComponent from '../stripe/stripe-input.component';
import TextFieldComponent from '../text-field/text-field.component';

const FormStripeInputComponent = <T extends StripeElement>({
  helperText,
  InputLabelProps,
  InputProps = {},
  inputProps,
  error,
  labelErrorMessage,
  stripeElement,
  endAdornment,
  ...other
}: FormStripeInputProps<T> & { endAdornment?: ReactNode }) => {
  return (
    <Box>
      <TextFieldComponent
        InputLabelProps={{
          ...InputLabelProps,
          shrink: true,
        }}
        error={error}
        InputProps={{
          ...InputProps,
          inputProps: {
            ...inputProps,
            ...InputProps.inputProps,
            component: stripeElement,
          },
          endAdornment: isNil(endAdornment)
            ? InputProps?.endAdornment
            : endAdornment,
          inputComponent: StripeInputComponent,
        }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...(other as any)}
      />

      <FormHelperText
        sx={{
          color: (theme) => theme.palette.error.main,
          fontWeight: 580,
        }}
      >
        {error ? labelErrorMessage : helperText}
      </FormHelperText>
    </Box>
  );
};

export default FormStripeInputComponent;
