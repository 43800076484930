import { Box, Typography, useTheme } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ButtonComponent from '../button/button.component';
import { useTranslation } from 'react-i18next';

const SuccessDialogContentComponent = ({
  message,
  confirmButtonLabel,
  close,
}: {
  message: string;
  confirmButtonLabel: string;
  close: () => void;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Typography
            color="primary"
            sx={{
              fontSize: '26px',
            }}
          >
            {t('success_dialog.title')}
          </Typography>
        </Box>
        <Box
          sx={{
            marginTp: theme.spacing(4),
            marginBottom: theme.spacing(4),
          }}
        >
          <CheckBoxIcon color="primary" sx={{ fontSize: '128px' }} />
        </Box>

        <Box
          sx={{
            marginBottom: theme.spacing(4),
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: '16px',
            }}
          >
            {message}
          </Typography>
        </Box>

        <Box
          sx={{
            width: '100%',
            marginBottom: theme.spacing(4),
          }}
        >
          <ButtonComponent
            sx={{
              fontSize: '26px',
              width: '100%',
            }}
            onClick={close}
          >
            {confirmButtonLabel}
          </ButtonComponent>
        </Box>
      </Box>
    </Box>
  );
};

export default SuccessDialogContentComponent;
