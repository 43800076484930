import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PropertyModel } from '../../../models/property/property.model';
import { CurrencyFormatter } from '../../../utilities/currency.utility';
import InformationFieldComponent from '../../common/information-field/information-field.component';

const PropertyDetailsInformationTabComponent = ({
  property,
}: {
  property: PropertyModel;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        gap: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          color="primary"
          sx={{
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          {t('property_details.information_tab.title')}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          gap: theme.spacing(2),
        }}
      >
        <InformationFieldComponent
          fieldLabel={t('property_details.information_tab.property_price')}
          fieldValue={CurrencyFormatter(property.shares)}
        />
        <InformationFieldComponent
          fieldLabel={t('property_details.information_tab.property_type')}
          fieldValue={property.type.name}
        />
        <InformationFieldComponent
          fieldLabel={t('property_details.information_tab.construction')}
          fieldValue={property.constructionType.name}
        />
        <InformationFieldComponent
          fieldLabel={t('property_details.information_tab.build_year')}
          fieldValue={property.yearOfConstruction.toString()}
        />
        <InformationFieldComponent
          fieldLabel={t('property_details.information_tab.floors')}
          fieldValue={property.floors.toString()}
        />
        <InformationFieldComponent
          fieldLabel={t('property_details.information_tab.area')}
          fieldValue={`${property.area} ${t('square_meters')}`}
        />
      </Box>

      <Box
        sx={{
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        }}
      >
        <Typography
          sx={{
            fontSize: '18px',
          }}
        >
          {property.description}
        </Typography>
      </Box>
    </Box>
  );
};

export default PropertyDetailsInformationTabComponent;
