import { createTheme, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { themeOptions } from '../../assets/theme';
import {
  RoutesConstants,
  RoutesPrefix,
} from '../../constants/routes.constants';
import DialogProvider from '../../providers/dialog/dialog.provider';
import LoadingSpinnerComponent from '../common/loading-spinner/loading-spinner.component';
import ToastComponent from '../common/toast/toast.component';
import HealthCheckComponent from '../health-check/health-check.component';
import CustomerActivationComponent from '../login/customer-activation.component';
import ForgotPasswordComponent from '../login/forgot-password.component';
import LoginComponent from '../login/login.component';
import RegistrationComponent from '../login/registration.component';
import ResetPasswordComponent from '../login/reset-password.component';
import GenericNotFoundComponent from '../not-found/generic-not-found-component';
import MainRoutesComponent from './main-routes.component';

const RouterComponent = (): JSX.Element => {
  const theme = createTheme(themeOptions);

  return (
    <>
      <Suspense fallback={<LoadingSpinnerComponent />}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <DialogProvider>
            <ToastComponent />
            <BrowserRouter>
              <Routes>
                <Route
                  path="*"
                  element={
                    <GenericNotFoundComponent isMainPlatformError={false} />
                  }
                />
                <Route
                  path={RoutesConstants.Login}
                  element={<LoginComponent />}
                />
                <Route
                  path={RoutesConstants.Registration}
                  element={<RegistrationComponent />}
                />
                <Route
                  path={RoutesConstants.CustomerActivation}
                  element={<CustomerActivationComponent />}
                />
                <Route
                  path={RoutesConstants.ForgotPassowrd}
                  element={<ForgotPasswordComponent />}
                />
                <Route
                  path={RoutesConstants.ResetPassword}
                  element={<ResetPasswordComponent />}
                />
                <Route
                  path={RoutesConstants.HealthCheck}
                  element={<HealthCheckComponent />}
                />
                <Route path="/" element={<Navigate to={RoutesPrefix} />} />
                <Route
                  path={`${RoutesPrefix}/*`}
                  element={<MainRoutesComponent />}
                />
              </Routes>
            </BrowserRouter>
          </DialogProvider>
        </ThemeProvider>
      </Suspense>
    </>
  );
};

export default RouterComponent;
