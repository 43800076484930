import { Typography } from '@mui/material';
import moment from 'moment';
import { DateOptionsModel } from '../../../../models/table/date-options.model';
import { GetDateLocale } from '../../../../utilities/date.utility';

const TableDateComponent = ({ options }: { options: DateOptionsModel }) => {
  const locale = options.locale ?? GetDateLocale();
  const year = options.year ?? 'numeric';
  const month = options.month ?? 'long';
  const day = options.day ?? '2-digit';

  const localDate = moment(options.value).local(true);
  return (
    <Typography>
      {new Intl.DateTimeFormat(locale, {
        year: year,
        month: month,
        day: day,
      }).format(localDate.toDate())}
    </Typography>
  );
};

export default TableDateComponent;
