import { useTranslation } from 'react-i18next';
import { SUPPORTED_LANGUAGES } from '../../../constants/translation/translation.constants';
import SelectComponent from '../../common/select/select.component';

const LanguageSectionComponent = ({
  selectedLanguageId,
  setSelectedLanguage,
}: {
  selectedLanguageId: string;
  setSelectedLanguage: (selectedLanguageId: string) => void;
}) => {
  const { t } = useTranslation();

  return (
    <SelectComponent
      label={t('settings_dialog.language_settings.select_label')}
      selectedValue={SUPPORTED_LANGUAGES.find(
        (language) => language.id === selectedLanguageId
      )}
      value={selectedLanguageId}
      name=""
      onSelectChange={setSelectedLanguage}
      type="filled"
      elements={SUPPORTED_LANGUAGES}
      optionValue="id"
      optionDisplay="display"
    />
  );
};

export default LanguageSectionComponent;
