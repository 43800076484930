import { TextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const TextFieldComponent = styled(TextField)<TextFieldProps>(({ theme }) => ({
  boxShadow:
    '2px 2px 15px -10px black inset, -2px 2px 15px -10px #9090a6 inset',
  backgroundColor: '#e1e2e6',
  borderRadius: '7px',
  width: '100% !important',
  '& :before': {
    borderBottom: '0px !important',
  },
  '& .MuiFilledInput-root:after': {
    borderBottom: `4px solid ${theme.palette.primary.main} !important`,
  },
  '& .Mui-error:after': {
    borderBottom: `4px solid ${theme.palette.error.main} !important`,
  },
}));

export default TextFieldComponent;
