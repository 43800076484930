import { Box, Paper, Typography, useTheme } from '@mui/material';
import { RouteProps } from 'react-router-dom';

const TitledBoxComponent = ({
  title,
  children,
}: { title: string } & RouteProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          height: '40px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#FFFFFF',
          }}
        >
          {title.toLocaleUpperCase()}
        </Typography>
      </Box>

      <Box sx={{ height: '100%' }}>
        <Paper elevation={2} sx={{ height: '100%' }}>
          {children}
        </Paper>
      </Box>
    </Box>
  );
};

export default TitledBoxComponent;
