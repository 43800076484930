import { Observable } from 'rxjs';
import { PaymentMethodModel } from '../../models/payment-methods/payment-method.model';
import ApiProvider from '../../providers/api/api.provider';
import { WithdrawalPaymentMethodModel } from '../../models/payment-methods/withdrawal-payment-method.model';

const PaymentMethodsService = {
  GetPaymentMethodsForCustomer(
    withdrawAmount: number
  ): Observable<WithdrawalPaymentMethodModel[]> {
    return ApiProvider.get<WithdrawalPaymentMethodModel[]>(
      `/payment-methods/withdraw?withdrawAmount=${withdrawAmount}`
    );
  },

  GetAllPaymentMethodsForCustomer(): Observable<PaymentMethodModel[]> {
    return ApiProvider.get<PaymentMethodModel[]>('/payment-methods');
  },

  GetCardDepositMethods(): Observable<PaymentMethodModel[]> {
    return ApiProvider.get<PaymentMethodModel[]>(
      '/payment-methods/deposit/cards'
    );
  },
};

export default PaymentMethodsService;
