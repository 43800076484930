import { CardNumberElement } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { FormStripeInputProps } from '../../../models/common/form/form-stripe-input-props.model';
import FormStripeInputComponent from '../forms/form-stripe-input.component';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import { InputAdornment } from '@mui/material';

const StripeCardNumberComponent = (
  props: FormStripeInputProps<typeof CardNumberElement>
) => {
  const { t } = useTranslation();

  return (
    <FormStripeInputComponent
      label={t('stripe.card_number')}
      stripeElement={CardNumberElement}
      endAdornment={
        <InputAdornment position="end">
          <CreditCardOutlinedIcon sx={{ width: '24px', height: '24px' }} />
        </InputAdornment>
      }
      inputProps={{
        options: {
          showIcon: true,
        },
      }}
      {...props}
    />
  );
};

export default StripeCardNumberComponent;
