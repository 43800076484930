import { TableBody, TableRow } from '@mui/material';
import _, { isNil } from 'lodash';
import { ColumnType } from '../../../constants/table/column-type.constants';
import ColumnDefinitionModel, {
  ColumnOptions,
} from '../../../models/table/column-definition.model';
import { TableRowsProps } from '../../../models/table/table-rows-props.model';
import { TableColumnProvider } from '../../../providers/table/table-column.provider';
import TableCellComponent from './table-cell.component';

const TableRowsComponent = <T, K extends keyof T>({
  data,
  columns,
}: TableRowsProps<T, K>) => {
  const areOptionsValid = (
    options: ColumnOptions | undefined
  ): options is ColumnOptions => !_.isNil(options);

  const isCustom = (type: ColumnType): boolean =>
    type === ColumnType.Button ||
    type === ColumnType.Image ||
    type === ColumnType.ProgressBar ||
    type === ColumnType.Date ||
    type === ColumnType.Currency ||
    type === ColumnType.Icon;

  const getCell = (
    row: T,
    column: ColumnDefinitionModel<T, K>
  ): JSX.Element => {
    if (column.type === ColumnType.Text || column.type === ColumnType.Number) {
      return <>{row[column.key]}</>;
    }

    if (isNil(row[column.key])) {
      return <></>;
    }

    if (isCustom(column.type)) {
      let options: ColumnOptions | undefined = undefined;

      if (areOptionsValid(row[column.key] as unknown as ColumnOptions)) {
        options = row[column.key] as unknown as ColumnOptions;
      }

      if (!areOptionsValid(options) && areOptionsValid(column.options)) {
        options = column.options;
      }

      if (!areOptionsValid(options)) {
        return <></>;
      }

      return TableColumnProvider.renderCustomElement(options, column.type);
    }

    return <></>;
  };

  const isColumnVisible = (columnType: ColumnType): boolean => {
    return columnType !== ColumnType.RowHighlight;
  };

  const isRowHighlighted = (row: T): boolean => {
    let isHighlighted = false;
    columns.forEach((column) => {
      if (column.type === ColumnType.RowHighlight) {
        isHighlighted = row[column.key] as unknown as boolean;
        return;
      }
    });
    return isHighlighted;
  };

  const rows = data.map((row, rowindex) => {
    return (
      <TableRow
        key={`row-${rowindex}`}
        sx={{
          backgroundColor: isRowHighlighted(row) ? 'red' : 'inherit',
        }}
      >
        {columns.map((column, columnIndex) => {
          return (
            isColumnVisible(column.type) && (
              <TableCellComponent key={`cell-${columnIndex}`}>
                {getCell(row, column)}
              </TableCellComponent>
            )
          );
        })}
      </TableRow>
    );
  });

  return <TableBody>{rows}</TableBody>;
};

export default TableRowsComponent;
