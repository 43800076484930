import {
  Box,
  CircularProgress,
  circularProgressClasses,
  CircularProgressProps,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';

const LoadingSpinnerComponent = ({
  spinnerColor,
  ...props
}: CircularProgressProps & {
  spinnerColor?: string;
}) => {
  const theme = useTheme();

  const getSpinnerColor = (): string => {
    if (isNil(spinnerColor)) {
      return theme.palette.mode === 'light'
        ? theme.palette.primary.main
        : theme.palette.primary.dark;
    }

    return spinnerColor;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: getSpinnerColor(),
          animationDuration: '550ms',
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={20}
        thickness={4}
        {...props}
      />
    </Box>
  );
};

export default LoadingSpinnerComponent;
