import {
  Box,
  Container,
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import ImageGradientComponent from '../common/gradient/image-gradient.component';
import Logo from '../../assets/img/icons/landsera-blue-logo.png';
import RegistrationCover from '../../assets/img/brand/registration-cover.jpg';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';
import { TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import RegistrationSchema from '../../schemas/authentication/registration.schema';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import FormInputTextComponent from '../common/forms/form-input-text.component';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { RoutesConstants } from '../../constants/routes.constants';
import AuthenticationService from '../../services/authentication/authentication.service';
import { take } from 'rxjs';
import { ApiErrorResponse } from '../../models/common/errors/api-error-response.model';
import { RegistrationErrorPayloadModel } from '../../models/common/errors/payload/authentication/registration-error-payload.model';
import { ConstructMessage } from '../../utilities/errors.utility';
import withDialogs from '../../providers/dialog/with-dialog';
import { DialogManagerProps } from '../../models/common/dialogs/dialog-manager-props.model';
import { DialogType } from '../../services/dialog-manager/dialog-manager.service';
import ButtonComponent from '../common/button/button.component';
import TooltipComponent from '../common/tooltip/tooltip.component';
import PasswordTooltipComponent from './password-tooltip.component';
import SuccessDialogComponent from '../common/dialog/success-dialog.component';
import FormCheckboxComponent from '../common/forms/form-checkbox.component';
import TermsAndConditionsDialogComponent from './terms-and-conditions-dialog.component';

type IRegistrationForm = TypeOf<typeof RegistrationSchema>;

const RegistrationComponent = ({
  dialogManagerService,
}: DialogManagerProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] =
    useState(false);

  const navigate = useNavigate();

  const isBiggerThanSmBreakpoint = useMediaQuery(theme.breakpoints.up('md'));

  const defaultRegistrationFormValues: IRegistrationForm = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    termsAndConditions: false,
  };

  const methods = useForm<IRegistrationForm>({
    resolver: zodResolver(RegistrationSchema),
    defaultValues: defaultRegistrationFormValues,
  });

  const handleRegistrationErrors = (
    err: ApiErrorResponse<RegistrationErrorPayloadModel>
  ) => {
    const data = err.response.data;

    if (!isNil(data.payload.email)) {
      methods.setError('email', {
        message: ConstructMessage(
          t('registration.error_fields.email'),
          data.payload.email
        ),
      });
    }

    if (!isNil(data.payload.firstName)) {
      methods.setError('firstName', {
        message: ConstructMessage(
          t('registration.error_fields.first_name'),
          data.payload.firstName
        ),
      });
    }

    if (!isNil(data.payload.lastName)) {
      methods.setError('lastName', {
        message: ConstructMessage(
          t('registration.error_fields.last_name'),
          data.payload.lastName
        ),
      });
    }

    if (!isNil(data.payload.password)) {
      methods.setError('password', {
        message: ConstructMessage(
          t('registration.error_fields.password'),
          data.payload.password
        ),
      });
    }
  };

  const onLoginRedirectClick = () => {
    navigate(RoutesConstants.Login);
  };

  const openRegistrationSuccessfulDialog = () => {
    const message = t('registration_successful.message');
    const confirmButtonLabel = t('registration_successful.confirmation');

    const registrationSuccessfulDialog: DialogType = {
      component: SuccessDialogComponent,
      props: {
        handleClose: () => navigate(RoutesConstants.Login),
        message,
        confirmButtonLabel,
      },
    };

    dialogManagerService.Open(registrationSuccessfulDialog);
  };

  const openTermsAndConditionsDialog = () => {
    const termsAndConditionsDialog: DialogType = {
      component: TermsAndConditionsDialogComponent,
      props: {},
    };

    dialogManagerService.Open(termsAndConditionsDialog);
  };

  const onRegistration: SubmitHandler<IRegistrationForm> = (
    signUpParameters: IRegistrationForm
  ) => {
    AuthenticationService.SignUp(signUpParameters)
      .pipe(take(1))
      .subscribe({
        next: (signUpResult) => {
          if (!isNil(signUpResult)) {
            methods.reset();
            openRegistrationSuccessfulDialog();
          } else {
            setFormErrorMessage(t('registration.errors.something_went_wrong'));
          }
        },
        error: (err: ApiErrorResponse<RegistrationErrorPayloadModel>) => {
          handleRegistrationErrors(err);
        },
      });
  };

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}
    >
      <Box
        sx={{
          flex: 1,
          minWidth: '370px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Container sx={{ height: '100%' }}>
          <FormProvider {...methods}>
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: theme.spacing(10),
                }}
              >
                <Box
                  component="img"
                  src={Logo}
                  sx={{
                    height: '70px',
                    width: '340px',
                    objectFit: 'contain',
                  }}
                ></Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  component="form"
                  noValidate
                  autoComplete="off"
                  onSubmit={methods.handleSubmit(onRegistration)}
                  sx={{
                    width: '75%',
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        width: '100%',
                        height: '70px',
                        display: 'flex',
                        alignItems: 'end',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        color="primary"
                        sx={{ fontSize: '24px', fontWeight: 'bold' }}
                      >
                        {t('registration.title')}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginTop: theme.spacing(3),
                        marginBottom: theme.spacing(2),
                      }}
                    >
                      <Box sx={{ marginRight: theme.spacing(2) }}>
                        <FormInputTextComponent
                          label={t('registration.fields.first_name')}
                          type="text"
                          name="firstName"
                          variant="filled"
                        />
                      </Box>

                      <Box>
                        <FormInputTextComponent
                          label={t('registration.fields.last_name')}
                          type="text"
                          name="lastName"
                          variant="filled"
                        />
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        marginBottom: theme.spacing(2),
                      }}
                    >
                      <FormInputTextComponent
                        label={t('registration.fields.email')}
                        type="text"
                        name="email"
                        variant="filled"
                      />
                    </Box>

                    <TooltipComponent
                      title={<PasswordTooltipComponent />}
                      placement={
                        isBiggerThanSmBreakpoint ? 'right' : 'top-start'
                      }
                      arrow={isBiggerThanSmBreakpoint}
                    >
                      <Box
                        sx={{
                          marginBottom: theme.spacing(2),
                        }}
                      >
                        <FormInputTextComponent
                          label={t('registration.fields.password')}
                          type={isPasswordVisible ? 'text' : 'password'}
                          name="password"
                          variant="filled"
                          autoComplete="on"
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onMouseDown={(event) => {
                                  event.preventDefault();
                                }}
                                onClick={() =>
                                  setIsPasswordVisible(!isPasswordVisible)
                                }
                                edge="end"
                                tabIndex={-1}
                              >
                                {isPasswordVisible ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          sx={{
                            width: '50px',
                          }}
                        />
                      </Box>
                    </TooltipComponent>

                    <Box
                      sx={{
                        marginBottom: theme.spacing(2),
                      }}
                    >
                      <FormInputTextComponent
                        label={t('registration.fields.confirm_password')}
                        type={isConfirmPasswordVisible ? 'text' : 'password'}
                        name="confirmPassword"
                        variant="filled"
                        autoComplete="on"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onMouseDown={(event) => {
                                event.preventDefault();
                              }}
                              onClick={() =>
                                setIsConfirmPasswordVisible(
                                  !isConfirmPasswordVisible
                                )
                              }
                              edge="end"
                              tabIndex={-1}
                            >
                              {isConfirmPasswordVisible ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        sx={{
                          width: '50px',
                        }}
                      />
                    </Box>

                    <Box
                      sx={{
                        marginBottom: theme.spacing(2),
                      }}
                    >
                      <FormCheckboxComponent
                        name="termsAndConditions"
                        label={
                          <>
                            <Typography
                              sx={{
                                fontSize: '14px',
                              }}
                            >
                              {t(
                                'registration.fields.accept_terms_and_conditions'
                              )}
                            </Typography>
                            <Typography
                              sx={{
                                color: theme.palette.primary.main,
                                fontSize: '14px',
                                fontWeight: 'bold',
                                cursor: 'pointer',
                              }}
                              onClick={() => openTermsAndConditionsDialog()}
                            >
                              {t('registration.fields.terms_and_condition')}
                            </Typography>
                          </>
                        }
                      />
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <ButtonComponent
                        sx={{
                          width: '160px',
                          height: '50px',
                          fontSize: '18px',
                          fontWeight: 'bold',
                        }}
                        type="submit"
                      >
                        {t('registration.sign_up_button')}
                      </ButtonComponent>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        marginTop: theme.spacing(2),
                      }}
                    >
                      {!isNil(formErrorMessage) && (
                        <Typography color="error">
                          {formErrorMessage}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: theme.spacing(5),
                }}
              >
                <Typography>{t('registration.already_registered')}</Typography>
                <Typography
                  color="primary"
                  sx={{
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    marginLeft: theme.spacing(0.5),
                  }}
                  onClick={onLoginRedirectClick}
                >
                  {t('registration.login_redirect')}
                </Typography>
              </Box>
            </Box>
          </FormProvider>
        </Container>
      </Box>

      <Box
        sx={{
          backgroundImage: `url(${RegistrationCover})`,
          backgroundSize: 'cover',
          flex: 1.8,
          minWidth: '340px',
          '@media (max-width: 800px)': {
            display: 'none',
          },
        }}
      >
        <ImageGradientComponent />
      </Box>
    </Box>
  );
};

const WrappedRegistrationComponent = withDialogs(RegistrationComponent);
export default WrappedRegistrationComponent;
