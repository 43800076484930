import { Box, Divider, Menu, MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../common/button/button.component';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons';
import AuthenticationService from '../../services/authentication/authentication.service';
import { RoutesConstants } from '../../constants/routes.constants';
import { useNavigate } from 'react-router-dom';
import { NavbarDropdownMenuProps } from '../../models/navbar/navbar-dropdown-menu-props.model';
import { CurrencyFormatter } from '../../utilities/currency.utility';
import GetPlatformRoute from '../../utilities/route.utility';
import { DialogManagerProps } from '../../models/common/dialogs/dialog-manager-props.model';
import ManageFundsDialogComponent from '../manage-funds/manage-funds-dialog.component';
import { DialogType } from '../../services/dialog-manager/dialog-manager.service';
import withDialogs from '../../providers/dialog/with-dialog';
import { useContext } from 'react';
import CustomerContext from '../../providers/customer/customer.provider';
import SettingsDialogComponent from '../settings/settings-dialog.component';
import { HistoryType } from '../../constants/history.constants';

const NavbarDropdownMenuComponent = ({
  balance,
  menuAnchor,
  removeMenuAnchor,
  openDepositDialog,
  openBalanceMenu,
  redirectToMyInvestments,
  redirectToProperties,
  dialogManagerService,
}: NavbarDropdownMenuProps & DialogManagerProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isMenuOpen = Boolean(menuAnchor);
  const { customer } = useContext(CustomerContext);

  const handleMenuClose = () => {
    removeMenuAnchor();
  };

  const signOut = () => {
    AuthenticationService.SignOut();
    navigate(RoutesConstants.Login);
  };

  const redirectToHistoryPage = () => {
    navigate(
      GetPlatformRoute(`${RoutesConstants.History}?tab=${HistoryType.Orders}`)
    );
    handleMenuClose();
  };

  const redirectToCustomerProfile = () => {
    navigate(GetPlatformRoute(RoutesConstants.CustomerProfile));
    handleMenuClose();
  };

  const openManageFundsDialog = () => {
    const dialog: DialogType = {
      component: ManageFundsDialogComponent,
      props: {
        customer: customer,
      },
    };
    dialogManagerService.Open(dialog);
    handleMenuClose();
  };

  const openSettingsDialog = () => {
    const dialog: DialogType = {
      component: SettingsDialogComponent,
    };
    dialogManagerService.Open(dialog);
    handleMenuClose();
  };

  return (
    <>
      <Menu
        anchorEl={menuAnchor}
        open={isMenuOpen}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            backgroundColor: '#FFFFFF',
            mt: 3,
            width: { xs: '100%', sm: '100%', md: '270px' },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        disableAutoFocusItem
      >
        <MenuItem
          disableGutters
          disableRipple
          disableTouchRipple
          sx={{
            display: { xs: 'flex', sm: 'flex', md: 'none' },
          }}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              height: '100%',
              alignItems: 'center',
              gap: theme.spacing(2),
              paddingLeft: theme.spacing(2),
              paddingRight: theme.spacing(2),
            })}
          >
            <ButtonComponent
              sx={{
                display: { xs: 'inline-flex', sm: 'none' },
                textTransform: 'none',
                width: '100%',
                height: '30px',
                fontSize: '14px',
                flex: 1,
              }}
              onClick={openDepositDialog}
            >
              {t('navbar.deposit')}
            </ButtonComponent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flex: 1,
                width: '100%',
              }}
              onClick={() => openBalanceMenu()}
            >
              <AccountBalanceWalletOutlinedIcon
                sx={{
                  fontSize: '24px',
                  color: (theme) => theme.palette.secondary.dark,
                  paddingRight: (theme) => theme.spacing(0.4),
                }}
              />
              <ButtonComponent
                sx={{
                  height: '30px',
                  width: '100%',
                  fontSize: '14px',
                  backgroundColor: (theme) => theme.palette.secondary.dark,
                  color: '#FFFFFF',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    border: (theme) =>
                      `2px solid ${theme.palette.secondary.dark}`,
                    color: (theme) => theme.palette.secondary.dark,
                  },
                }}
              >
                {CurrencyFormatter(balance)}
              </ButtonComponent>
            </Box>
          </Box>
        </MenuItem>

        <Divider
          sx={{
            display: { xs: 'flex', sm: 'flex', md: 'none' },
          }}
        />

        <MenuItem
          sx={{
            flexDirection: 'row',
            display: { xs: 'flex', sm: 'flex', md: 'none' },
          }}
          onClick={redirectToMyInvestments}
        >
          <FontAwesomeIcon
            icon={faHandHoldingDollar}
            style={{
              width: '24px',
              height: '24px',
              paddingRight: '8px',
              color: '#423f96',
            }}
          />
          <Typography
            color="primary"
            sx={{
              fontSize: '18px',
            }}
          >
            {t('navbar.menu.investments')}
          </Typography>
        </MenuItem>

        <MenuItem
          sx={{
            flexDirection: 'row',
            display: { xs: 'flex', sm: 'flex', md: 'none' },
          }}
          onClick={redirectToProperties}
        >
          <ApartmentOutlinedIcon
            color="primary"
            sx={{
              fontSize: '24px',
              paddingRight: (theme) => theme.spacing(1),
            }}
          />
          <Typography
            color="primary"
            sx={{
              fontSize: '18px',
            }}
          >
            {t('navbar.menu.properties')}
          </Typography>
        </MenuItem>

        <MenuItem
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
          onClick={redirectToCustomerProfile}
        >
          <AccountCircleOutlinedIcon
            color="primary"
            sx={{
              fontSize: '24px',
              paddingRight: (theme) => theme.spacing(1),
            }}
          />
          <Typography
            color="primary"
            sx={{
              fontSize: '18px',
            }}
          >
            {t('navbar.menu.profile')}
          </Typography>
        </MenuItem>

        <MenuItem
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
          onClick={openManageFundsDialog}
        >
          <SavingsOutlinedIcon
            color="primary"
            sx={{
              fontSize: '24px',
              paddingRight: (theme) => theme.spacing(1),
            }}
          />
          <Typography
            color="primary"
            sx={{
              fontSize: '18px',
            }}
          >
            {t('navbar.menu.funds_management')}
          </Typography>
        </MenuItem>

        <MenuItem
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
          onClick={redirectToHistoryPage}
        >
          <HistoryOutlinedIcon
            color="primary"
            sx={{
              fontSize: '24px',
              paddingRight: (theme) => theme.spacing(1),
            }}
          />
          <Typography
            color="primary"
            sx={{
              fontSize: '18px',
            }}
          >
            {t('navbar.menu.history')}
          </Typography>
        </MenuItem>

        <MenuItem
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
          onClick={openSettingsDialog}
        >
          <SettingsOutlinedIcon
            color="primary"
            sx={{
              fontSize: '24px',
              paddingRight: (theme) => theme.spacing(1),
            }}
          />
          <Typography
            color="primary"
            sx={{
              fontSize: '18px',
            }}
          >
            {t('navbar.menu.settings')}
          </Typography>
        </MenuItem>

        <MenuItem
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
          onClick={signOut}
        >
          <LogoutOutlinedIcon
            color="primary"
            sx={{
              fontSize: '24px',
              paddingRight: (theme) => theme.spacing(1),
            }}
          />
          <Typography
            color="primary"
            sx={{
              fontSize: '18px',
            }}
          >
            {t('navbar.menu.logout')}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

const wrappedNavbarDropdownMenuComponent = withDialogs(
  NavbarDropdownMenuComponent
);
export default wrappedNavbarDropdownMenuComponent;
