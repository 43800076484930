import { Box, Typography, useTheme } from '@mui/material';
import { VerificationCommonProps } from '../../models/verification/verification-common-props.model';
import ButtonComponent from '../common/button/button.component';

const VerificationCommonComponent = ({
  description,
  buttonText,
  buttonAction,
  Icon,
  iconColor,
}: VerificationCommonProps) => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: theme.spacing(2),
          paddingTop: theme.spacing(2),
        }}
      >
        <Box>
          <Icon
            sx={{
              fontSize: '100px',
              color: iconColor ?? theme.palette.secondary.dark,
            }}
          />
        </Box>

        <Box>
          <Typography color="primary" fontSize="14px" textAlign="center">
            {description}
          </Typography>
        </Box>

        <ButtonComponent
          sx={{
            width: '200px',
            height: '50px',
          }}
          onClick={buttonAction}
        >
          {buttonText}
        </ButtonComponent>
      </Box>
    </>
  );
};

export default VerificationCommonComponent;
