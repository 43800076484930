import { ErrorCodes } from '../constants/errors/error-codes.constants';
import i18n from '../translations/i18n';

export const MapErrorCodeToMessage = (errorCode: string): string => {
  switch (errorCode) {
    case ErrorCodes.AlreadyExists:
      return i18n.t('error_codes.already_exist');
    case ErrorCodes.NotPresent:
      return i18n.t('error_codes.not_present');
    case ErrorCodes.ContainsNonCharacters:
      return i18n.t('error_codes.contains_non_characters');
    case ErrorCodes.ExceedsLength:
      return i18n.t('error_codes.exceeds_length');
    case ErrorCodes.PasswordMissmatch:
      return i18n.t('error_codes.password_missmatch');
    case ErrorCodes.NoSuchCustomer:
      return i18n.t('error_codes.no_such_customer');
    default:
      return i18n.t('error_codes.default_error');
  }
};

export const ConstructMessage = (field: string, errorCode: string): string => {
  return `${field} ${MapErrorCodeToMessage(errorCode)}`;
};
