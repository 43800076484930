/* eslint-disable @typescript-eslint/no-unused-vars */
import { WithdrawalModel } from '../../models/withdrawal/withdrawal.model';
import { Observable } from 'rxjs';
import { WithdrawalRequestBody } from '../../models/withdrawal/withdrawal-request-body.model';
import ApiProvider from '../../providers/api/api.provider';
import { WithdrawalRequest } from '../../models/withdrawal/withdrawal-request.model';
import { WithdrawalRequestStatus } from '../../constants/withdrawal/withdrawal-request-status.constants';
import { WithdrawalStatus } from '../../constants/withdrawal/withdrawal-status.constants';

export const WithdrawalService = {
  GetExecutedWithdrawals(
    customerId: number,
    page: number,
    rowsPerPage: number
  ): Observable<WithdrawalModel[]> {
    return ApiProvider.get<WithdrawalModel[]>(
      `/withdrawals?customerId=${customerId}&withdrawalStatus=${WithdrawalStatus.Executed}`
    );
  },

  GetFailedWithdrawals(
    customerId: number,
    page: number,
    rowsPerPage: number
  ): Observable<WithdrawalModel[]> {
    return ApiProvider.get<WithdrawalModel[]>(
      `/withdrawals?customerId=${customerId}&withdrawalStatus=${WithdrawalStatus.Failed}`
    );
  },

  GetPendingWithdrawals(
    customerId: number,
    page: number,
    rowsPerPage: number
  ): Observable<WithdrawalModel[]> {
    return ApiProvider.get<WithdrawalModel[]>(
      `/withdrawals?customerId=${customerId}&withdrawalStatus=${WithdrawalStatus.Pending}`
    );
  },

  MakeWithdrawalRequest(
    amount: number,
    paymentMethodId: string,
    executionStrategy: string
  ): Observable<WithdrawalModel | null> {
    const MakeWithdrawalRequestBody: WithdrawalRequestBody = {
      amount: amount,
      paymentMethodId: paymentMethodId,
      executionStrategy: executionStrategy,
    };

    return ApiProvider.post<WithdrawalModel>(
      '/withdrawal-requests',
      MakeWithdrawalRequestBody
    );
  },

  GetPendingWithdrawalRequests(
    customerId: number,
    page: number,
    rowsPerPage: number
  ): Observable<WithdrawalRequest[]> {
    return this.GetWithdrawalRequestsByCriteria(
      customerId,
      WithdrawalRequestStatus.AwaitingApproval
    );
  },

  GetWithdrawalRequestsByCriteria(
    customerId: number,
    status: string
  ): Observable<WithdrawalRequest[]> {
    return ApiProvider.get<WithdrawalRequest[]>(
      `/withdrawal-requests?customerId=${customerId}&status=${status}`
    );
  },
};
