import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Observable, take } from 'rxjs';
import { PropertyComment } from '../../../models/property/property-comment.model';
import { PropertyModel } from '../../../models/property/property.model';
import { PropertyService } from '../../../services/properties/property.service';
import SharedDataService from '../../../services/shared-data/shared-data.service';
import CommentSectionComponent from '../../common/comments/comment-section.component';

const PropertyDetailsCommentsTabComponent = ({
  property,
}: {
  property: PropertyModel;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const AddComment = (propertyId: number, commentContent: string) => {
    PropertyService.AddPropertyComment(propertyId, commentContent)
      .pipe(take(1))
      .subscribe(() => {
        SharedDataService.TriggerUpdateCommentsEvent();
      });
  };

  const AddReplyToComment = (
    propertyId: number,
    commentId: number,
    replyContent: string
  ) => {
    PropertyService.AddPropertyComment(propertyId, replyContent, commentId)
      .pipe(take(1))
      .subscribe(() => {
        SharedDataService.TriggerUpdateCommentRepliesEvent(commentId);
      });
  };

  const GetComments = (propertyId: number): Observable<PropertyComment[]> => {
    return PropertyService.GetPropertyComments(propertyId);
  };

  const GetReplies = (
    propertyId: number,
    commentId: number
  ): Observable<PropertyComment[]> => {
    return PropertyService.GetPropertyCommentsReplies(propertyId, commentId);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        gap: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          color="primary"
          sx={{
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          {t('property_details.comments_tab.title')}
        </Typography>
      </Box>

      <Box>
        <CommentSectionComponent
          getComments={() => GetComments(property.id)}
          getReplies={(commentId: number) => GetReplies(property.id, commentId)}
          onAddComment={(content: string) => AddComment(property.id, content)}
          onReply={(commentId, replyContent) =>
            AddReplyToComment(property.id, commentId, replyContent)
          }
        />
      </Box>
    </Box>
  );
};

export default PropertyDetailsCommentsTabComponent;
