import { DialogContent, useTheme } from '@mui/material';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogProps } from '../../models/common/dialogs/dialog-props.model';
import { PaymentMethodModel } from '../../models/payment-methods/payment-method.model';
import { WithdrawalService } from '../../services/withdrawals/withdrawal.service';
import { GetExecutionStrategyId } from '../../utilities/withdrawal.utility';
import DialogComponent from '../common/dialog/dialog.component';
import WithdrawInputAmountComponent from './withdraw-steps/withdraw-input-amount.component';
import WithdrawPreviewComponent from './withdraw-steps/withdraw-preview.component';
import WithdrawSelectPaymentMethodComponent from './withdraw-steps/withdraw-select-payment-method.component';
import StepperComponent from '../common/stepper/stepper.component';
import {
  WITHDRAWAL_STEPS,
  WithdrawSteps,
} from '../../constants/withdrawal/withdrawal-steps.constants';
import { StepModel } from '../../models/common/stepper/step.model';
import SuccessDialogContentComponent from '../common/dialog/success-dialog-content.component';
import { CurrencyFormatter } from '../../utilities/currency.utility';
import FailureDialogContentComponent from '../common/dialog/failure-dialog-content.component';

const WithdrawDialogComponent = ({
  open,
  handleClose,
  customer,
}: DialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [withdrawStep, setWithdrawStep] = useState(
    WithdrawSteps.InputWithdrawAmount
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethodModel | null>(null);
  const [withdrawAmount, setWithdrawAmount] = useState(0);

  const goToSelectPaymentMethod = () => {
    setWithdrawStep(WithdrawSteps.SelectPaymentMethod);
  };

  const goToPreviewWithdrawRequest = () => {
    setWithdrawStep(WithdrawSteps.PreviewWithdrawRequest);
  };

  const goToSuccessfulWithdrawRequest = () => {
    setWithdrawStep(WithdrawSteps.SuccessfulWithdrawRequest);
  };

  const goToFailedWithdrawRequest = () => {
    setWithdrawStep(WithdrawSteps.FailedWithdrawRequest);
  };

  const setPaymentMethod = (paymentMethod: PaymentMethodModel) => {
    setSelectedPaymentMethod(paymentMethod);
    goToPreviewWithdrawRequest();
  };

  const submitWithdrawAmount = (withdrawAmount: number) => {
    setWithdrawAmount(withdrawAmount);
    goToSelectPaymentMethod();
  };

  const makeWithdrawRequest = () => {
    WithdrawalService.MakeWithdrawalRequest(
      withdrawAmount,
      selectedPaymentMethod?.id ?? '',
      GetExecutionStrategyId(selectedPaymentMethod?.type)
    ).subscribe({
      complete: () => {
        goToSuccessfulWithdrawRequest();
      },
      error: () => {
        goToFailedWithdrawRequest();
      },
    });
  };

  const returnToStep = (step: StepModel) => {
    setWithdrawStep(step.id);
  };

  const renderWithdrawalRequestStep = (): ReactNode => {
    switch (withdrawStep) {
      case WithdrawSteps.InputWithdrawAmount:
        return (
          <WithdrawInputAmountComponent
            customerCash={customer?.cash ?? 0}
            submitWithdrawAmount={submitWithdrawAmount}
          />
        );
      case WithdrawSteps.SelectPaymentMethod:
        return (
          <WithdrawSelectPaymentMethodComponent
            withdrawalAmount={withdrawAmount}
            setPaymentMethod={setPaymentMethod}
          />
        );
      case WithdrawSteps.PreviewWithdrawRequest:
        return (
          <WithdrawPreviewComponent
            withdrawAmount={withdrawAmount}
            makeWithdrawRequest={makeWithdrawRequest}
            customer={customer}
            paymentMethod={selectedPaymentMethod}
          />
        );
      case WithdrawSteps.SuccessfulWithdrawRequest:
        const message = `${t(
          'withdraw_dialog.success.description'
        )} ${CurrencyFormatter(withdrawAmount)}`;
        const confirmButtonLabel = t('withdraw_dialog.success.close_button');
        return (
          <SuccessDialogContentComponent
            message={message}
            confirmButtonLabel={confirmButtonLabel}
            close={handleClose}
          />
        );
      case WithdrawSteps.FailedWithdrawRequest:
        const failedWithdrawMessage = t('withdraw_dialog.failed.description');
        const failedWithdrawlConfirmButtonLabel = t(
          'withdraw_dialog.success.close_button'
        );
        return (
          <FailureDialogContentComponent
            message={failedWithdrawMessage}
            confirmButtonLabel={failedWithdrawlConfirmButtonLabel}
            close={handleClose}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <DialogComponent
      open={open}
      handleClose={handleClose}
      dialogTitle={t('withdraw_dialog.title')}
      width={{ sm: '400px', md: '500px' }}
    >
      <DialogContent
        sx={{
          paddingLeft: theme.spacing(6),
          paddingRight: theme.spacing(6),
        }}
      >
        <StepperComponent
          steps={WITHDRAWAL_STEPS}
          nextTrigger={withdrawStep}
          returnToStep={returnToStep}
        />
        {renderWithdrawalRequestStep()}
      </DialogContent>
    </DialogComponent>
  );
};

export default WithdrawDialogComponent;
