import { boolean, object, string } from 'zod';
import i18n from '../../translations/i18n';

const RegistrationSchema = object({
  firstName: string()
    .min(1, i18n.t('registration.errors.first_name_min_length'))
    .regex(
      /^[a-zA-Z\u0400-\u04FF]+[-'s]?[a-zA-Z\u0400-\u04FF ]+$/,
      i18n.t('registration.errors.name_only_alphabet')
    ),
  lastName: string()
    .min(1, i18n.t('registration.errors.last_name_min_length'))
    .regex(
      /^[a-zA-Z\u0400-\u04FF]+[-'s]?[a-zA-Z\u0400-\u04FF ]+$/,
      i18n.t('registration.errors.name_only_alphabet')
    ),
  email: string()
    .min(1, i18n.t('registration.errors.email_min_length'))
    .email(i18n.t('registration.errors.not_an_email')),
  password: string()
    .regex(
      new RegExp('.*[A-Z].*'),
      i18n.t('registration.errors.password_uppercase_letter')
    )
    .regex(
      new RegExp('.*[a-z].*'),
      i18n.t('registration.errors.password_lowercase_letter')
    )
    .regex(new RegExp('.*\\d.*'), i18n.t('registration.errors.password_number'))
    .min(8, i18n.t('registration.errors.password_min_length')),
  confirmPassword: string().min(
    8,
    i18n.t('registration.errors.confirm_password_min_length')
  ),
  termsAndConditions: boolean(),
}).superRefine(({ password, confirmPassword, termsAndConditions }, ctx) => {
  if (password !== confirmPassword) {
    ctx.addIssue({
      code: 'custom',
      message: i18n.t('registration.errors.confirm_password_not_match'),
      path: ['confirmPassword'],
    });
  }

  if (termsAndConditions !== true) {
    ctx.addIssue({
      code: 'custom',
      message: i18n.t('registration.errors.terms_and_conditions_not_accepted'),
      path: ['termsAndConditions'],
    });
  }
});

export default RegistrationSchema;
