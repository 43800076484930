import { Box, Paper, Typography, useTheme } from '@mui/material';
import { ReactCountryFlag } from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../../constants/routes.constants';
import { CardPropertyProps } from '../../../models/common/card-property-props.model';
import { CurrencyFormatter } from '../../../utilities/currency.utility';
import {
  GetPropertyStatusTranslationId,
  GetPropertyStatusColor,
} from '../../../utilities/property.utility';
import GetPlatformRoute from '../../../utilities/route.utility';
import ButtonOutlinedComponent from '../button/button-outlined.component';
import ProgressBarComponent from '../progress-bar/progress-bar.component';
import { RoundDecimal } from '../../../utilities/number.utility';

const CardPropertyComponent = ({ property }: CardPropertyProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const openPropertyMoreDetails = () => {
    const params = new URLSearchParams({
      id: property.id.toString(),
    });
    navigate(`${GetPlatformRoute(RoutesConstants.PropertyDetails)}?${params}`);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            minWidth: '350px',
            maxWidth: '435px',
            width: '100%',
          }}
        >
          <Paper
            elevation={3}
            sx={{
              width: '100%',
              backgroundColor: '#FFFFFF',
              borderRadius: '10px',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  height: '240px',
                }}
              >
                <Box
                  component="img"
                  src={property.imageUrls[0]}
                  sx={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                    borderTopLeftRadius: '10px',
                    borderTopRightRadius: '10px',
                  }}
                ></Box>

                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Box
                    sx={{
                      display: 'inline-block',
                      position: 'relative',
                      bottom: '230px',
                      right: '10px',
                      width: '24px',
                      height: '24px',
                      borderRadius: '24px',
                    }}
                  >
                    <ReactCountryFlag
                      countryCode="BG"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: '50%',
                      }}
                      svg
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    position: 'relative',
                    bottom: '70px',
                    left: '0px',
                    borderTopRightRadius: '10px',
                    borderBottomRightRadius: '10px',
                    backgroundColor: GetPropertyStatusColor(
                      property.status,
                      theme
                    ),
                    display: 'inline-block',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '18px',
                      color: 'white',
                      fontWeight: '500',
                      paddingLeft: theme.spacing(2),
                      paddingRight: theme.spacing(2),
                    }}
                  >
                    {t(GetPropertyStatusTranslationId(property.status))}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  height: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  sx={{
                    textAling: 'center',
                    color: '#FFFFFF',
                    fontSize: '14px',
                    fontWeight: '600',
                  }}
                >
                  {property.address}
                </Typography>
              </Box>

              <Box
                sx={{
                  paddingTop: theme.spacing(2),
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: theme.spacing(1),
                    }}
                  >
                    <Typography
                      fontSize="14px"
                      fontWeight="600"
                      lineHeight="1.3"
                      sx={{
                        color: theme.palette.secondary.dark,
                      }}
                    >
                      {t('property_card.amount')}
                    </Typography>
                    <Typography
                      fontWeight="bold"
                      color="primary"
                      fontSize="28px"
                      lineHeight="1"
                    >
                      {CurrencyFormatter(property.value)}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingRight: theme.spacing(1),
                      textAlign: 'end',
                    }}
                  >
                    <Typography
                      fontSize="14px"
                      fontWeight="600"
                      lineHeight="1.3"
                      sx={{
                        color: theme.palette.secondary.dark,
                      }}
                    >
                      {t('property_card.annual_percentage_yield')}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Typography
                        fontWeight="bold"
                        color="primary"
                        fontSize="28px"
                        lineHeight="1"
                      >
                        {property.annualPercentageYield}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Typography
                          fontSize="14px"
                          fontWeight="bold"
                          color={theme.palette.primary.main}
                        >
                          %
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    paddingTop: theme.spacing(2),
                    paddingLeft: theme.spacing(1),
                    paddingRight: theme.spacing(1),
                  }}
                >
                  <ProgressBarComponent
                    min={0}
                    max={property.value}
                    value={RoundDecimal(property.retailValue)}
                    percentageLabel={t(
                      'property_card.percentage_financed_label'
                    )}
                    remainingLabel={t('property_card.remaining_value_label')}
                    color={GetPropertyStatusColor(property.status, theme)}
                  />
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: theme.spacing(3),
                    paddingBottom: theme.spacing(3),
                  }}
                >
                  <ButtonOutlinedComponent
                    sx={{
                      width: '140px',
                      height: '42px',
                      fontSize: '17px',
                      textTransform: 'none',
                    }}
                    onClick={openPropertyMoreDetails}
                  >
                    {t('property_card.details')}
                  </ButtonOutlinedComponent>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default CardPropertyComponent;
