import { useContext, useMemo, useState } from 'react';
import { map, of } from 'rxjs';
import { OrderColumnsDefinition } from '../../../constants/table/order-columns.constants';
import useObservable from '../../../hooks/use-observable.hook';
import { OrderTableModel } from '../../../models/order/order-table.model';
import { OrderModel } from '../../../models/order/order.model';
import { GetOrderType } from '../../../utilities/orders.utility';
import TableComponent from '../../common/table/table.component';
import CustomerService from '../../../services/customer/customer.service';
import CustomerContext from '../../../providers/customer/customer.provider';
import { GetCustomerId } from '../../../utilities/customer.utility';
import { isNil } from 'lodash';

const HistoryOrdersTabComponent = () => {
  const columns = OrderColumnsDefinition;

  const { customer } = useContext(CustomerContext);

  const rowsPerPage = 10;
  const [page, setPage] = useState(1);

  const getTableOrder = (order: OrderModel): OrderTableModel => {
    const tableModel: OrderTableModel = {
      id: order.id,
      propertyName: order.propertyName,
      value: {
        value: order.value,
      },
      quantity: order.quantity,
      direction: GetOrderType(order.direction),
      createdAt: {
        value: new Date(order.createdAt),
      },
    };
    return tableModel;
  };

  const orders$ = useMemo(
    () => {
      if (isNil(customer)) {
        return of([]);
      }

      return CustomerService.GetOrders(
        GetCustomerId(customer),
        page,
        rowsPerPage
      ).pipe(
        map<OrderModel[], OrderTableModel[]>((orders: OrderModel[]) => {
          return orders.map((order) => {
            return getTableOrder(order);
          });
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, customer]
  );

  const [orders, , loading] = useObservable(orders$);

  const getOrders = (): OrderTableModel[] => {
    return orders ?? [];
  };

  return (
    <TableComponent
      data={getOrders()}
      columns={columns}
      totalRows={getOrders().length}
      loading={loading}
      onPageChange={setPage}
      rowsPerPage={rowsPerPage}
    />
  );
};

export default HistoryOrdersTabComponent;
