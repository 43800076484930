import { Box, Typography, useTheme } from '@mui/material';
import { CurrencyFormatter } from '../../utilities/currency.utility';
import { useTranslation } from 'react-i18next';

const PortfolioChartTooltipComponent = ({
  active,
  payload,
  label,
}: {
  active?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
  label?: string;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  if (!(active && payload && payload.length)) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        color: '#FFFFFF',
        padding: theme.spacing(1),
        borderRadius: '10px',
        border: '0px',
        width: { xs: '240px', sm: '340px' },
      }}
    >
      <Typography
        sx={{
          fontSize: '12px',
        }}
      >{`${label}`}</Typography>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 'bold',
        }}
      >{`${t('investments.charts.portfolio_value')}: ${CurrencyFormatter(
        payload[0].value
      )}`}</Typography>
    </Box>
  );
};

export default PortfolioChartTooltipComponent;
