import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import ButtonComponent from '../common/button/button.component';

const CustomerStripeFailedVerificationComponent = ({
  areAddressDocumentsValid,
  areAddressDocumentsUpdated,
  goToAddressVerification,
  areIdentityDocumentsValid,
  goToIdentityVerification,
  areIdentityDocumentsUpdated,
  verifyDocuments,
}: {
  areAddressDocumentsValid: boolean;
  areAddressDocumentsUpdated: boolean;
  goToAddressVerification: () => void;
  areIdentityDocumentsValid: boolean;
  areIdentityDocumentsUpdated: boolean;
  goToIdentityVerification: () => void;
  verifyDocuments: () => void;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const renderDocumentsStatus = (
    areDocumentsValid: boolean,
    documentsDescription: string,
    areDocumentsUpdated: boolean,
    uploadDocuments: () => void
  ): ReactNode => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: theme.spacing(2),
          height: '100%',
          alignItems: 'center',
        }}
      >
        {areDocumentsValid ? (
          <Tooltip
            title={t('stripe_verification.failed.successful_verification')}
            placement="top"
          >
            <CheckCircleOutlinedIcon
              color="success"
              sx={{
                fontSize: { sm: '24px', md: '32px' },
              }}
            />
          </Tooltip>
        ) : (
          <Tooltip
            title={
              areDocumentsUpdated
                ? t('stripe_verification.failed.ready_to_submit')
                : t('stripe_verification.failed.failed_verification')
            }
            placement="top"
          >
            {areDocumentsUpdated ? (
              <CheckCircleOutlinedIcon
                color="inherit"
                sx={{
                  fontSize: { sm: '24px', md: '32px' },
                }}
              />
            ) : (
              <CloseOutlinedIcon
                color="error"
                sx={{
                  fontSize: { sm: '24px', md: '32px' },
                }}
              />
            )}
          </Tooltip>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography
            color={
              !areDocumentsValid && !areDocumentsUpdated ? 'error' : 'inherit'
            }
            sx={{
              fontSize: { sm: '14px', md: '18px' },
              lineHeight: { sm: '24px', md: '32px' },
            }}
          >
            {documentsDescription}
          </Typography>

          {!areDocumentsValid && (
            <Tooltip
              title={t('stripe_verification.failed.file_upload_tooltip')}
              placement="top"
            >
              <FileUploadIcon
                color="primary"
                sx={{
                  fontSize: { sm: '24px', md: '32px' },
                  cursor: 'pointer',
                }}
                onClick={uploadDocuments}
              />
            </Tooltip>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <DangerousOutlinedIcon
          color="error"
          sx={{
            fontSize: '100px',
          }}
        />
      </Box>

      <Box>
        <Typography textAlign="center" fontSize="14px" color="error">
          {t('stripe_verification.failed.description')}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: theme.spacing(2),
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '10px',
        }}
      >
        <Box
          sx={{
            borderBottom: `1px solid ${theme.palette.primary.main}`,
            height: '50px',
          }}
        >
          {renderDocumentsStatus(
            areAddressDocumentsValid,
            t('stripe_verification.failed.address'),
            areAddressDocumentsUpdated,
            goToAddressVerification
          )}
        </Box>

        <Box
          sx={{
            height: '50px',
          }}
        >
          {renderDocumentsStatus(
            areIdentityDocumentsValid,
            t('stripe_verification.failed.identity'),
            areIdentityDocumentsUpdated,
            goToIdentityVerification
          )}
        </Box>
      </Box>

      <Box
        sx={{
          paddingTop: theme.spacing(4),
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <ButtonComponent
          sx={{
            width: '200px',
            height: '50px',
          }}
          onClick={verifyDocuments}
        >
          {t('stripe_verification.failed.verify_button')}
        </ButtonComponent>
      </Box>
    </Box>
  );
};

export default CustomerStripeFailedVerificationComponent;
