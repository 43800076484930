import { Box, Typography, useTheme } from '@mui/material';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { take } from 'rxjs';
import { DialogProps } from '../../models/common/dialogs/dialog-props.model';
import { CustomerNotifiableEventSettingsUpdateModel } from '../../models/customer/customer-notifiable-event-settings-update.model';
import CustomerService from '../../services/customer/customer.service';
import ButtonComponent from '../common/button/button.component';
import DialogComponent from '../common/dialog/dialog.component';
import LanguageSectionComponent from './sections/language-section.component';
import NotificationsViaEmailSectionComponent from './sections/notifications-via-email-section.component';

const SettingsDialogComponent = ({ open, handleClose }: DialogProps) => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const theme = useTheme();

  const [notifyViaEmailSettings, setNotifyViaEmailSettings] = useState<
    Map<number, boolean>
  >(new Map<number, boolean>());

  const saveEmailNotificationSettings = () => {
    const settings: CustomerNotifiableEventSettingsUpdateModel[] = [];

    notifyViaEmailSettings.forEach((notifyViaEmail, notifiableEventId) => {
      const notifyViaEmailSetting = {
        notifiableEventId,
        notifyViaEmail,
      } as CustomerNotifiableEventSettingsUpdateModel;
      settings.push(notifyViaEmailSetting);
    });

    CustomerService.UpdateCustomerNotifiableEventSettings(settings)
      .pipe(take(1))
      .subscribe();
  };

  const saveLanguageSettings = () => {
    i18n.changeLanguage(language);
  };

  const saveSettings = () => {
    saveEmailNotificationSettings();
    saveLanguageSettings();
    handleClose();
  };

  const renderSettingSection = (
    sectionTitle: string,
    sectionBody: ReactNode
  ) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1),
        }}
      >
        <Typography fontWeight="bold" color="primary">
          {sectionTitle}
        </Typography>
        {sectionBody}
      </Box>
    );
  };

  return (
    <DialogComponent
      open={open}
      handleClose={handleClose}
      dialogTitle={t('settings_dialog.title')}
      width={{ sm: '400px', md: '500px' }}
    >
      <Box
        sx={{
          padding: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
        }}
      >
        {renderSettingSection(
          t('settings_dialog.email_notifications_settings.title'),
          <NotificationsViaEmailSectionComponent
            notifyViaEmailSettings={notifyViaEmailSettings}
            setNotifyViaEmailSettings={setNotifyViaEmailSettings}
          />
        )}

        {renderSettingSection(
          t('settings_dialog.language_settings.title'),
          <LanguageSectionComponent
            selectedLanguageId={language}
            setSelectedLanguage={setLanguage}
          />
        )}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ButtonComponent
            sx={{
              width: '200px',
              height: '50px',
              fontSize: '14px',
              fontWeight: 'bold',
            }}
            onClick={saveSettings}
          >
            {t('settings_dialog.save_settings_button')}
          </ButtonComponent>
        </Box>
      </Box>
    </DialogComponent>
  );
};

export default SettingsDialogComponent;
