import { useEffect, useRef, useState } from 'react';
import {
  DialogManagerService,
  DialogType,
} from '../../services/dialog-manager/dialog-manager.service';

const DialogWrapper = (props: {
  dialogManagerService: DialogManagerService;
}) => {
  const [refreshDialogs, setRefreshDialogs] = useState(0);
  const refreshDialogsRef = useRef(refreshDialogs);

  useEffect(() => {
    refreshDialogsRef.current = refreshDialogs;
  });

  useEffect(() => {
    props.dialogManagerService.SubscribeToDialogsChange(() => {
      setRefreshDialogs(refreshDialogsRef.current + 1);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeDialog = (dialog: DialogType) => {
    props.dialogManagerService.Close(dialog);

    dialog.props && dialog.props.handleClose && dialog.props.handleClose();
  };

  return (
    <>
      {props.dialogManagerService.dialogs.map((dialog) => (
        <dialog.component
          key={dialog.id}
          {...dialog.props}
          open={dialog.open}
          handleClose={() => closeDialog(dialog)}
          id={dialog.id}
        />
      ))}
    </>
  );
};

export default DialogWrapper;
