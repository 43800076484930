import { Box, Typography, useTheme } from '@mui/material';
import { isNil } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropertyDetailsDividendColumns } from '../../../constants/table/property-details-dividends-columns.constants';
import useObservable from '../../../hooks/use-observable.hook';
import { DividendModel } from '../../../models/dividend/dividend.model';
import { PropertyDetailsDividendTableModel } from '../../../models/property-details/property-details-dividend-table.model';
import { PropertyModel } from '../../../models/property/property.model';
import DividendsService from '../../../services/dividends/dividends.service';
import TableComponent from '../../common/table/table.component';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CustomerService from '../../../services/customer/customer.service';
import CustomerContext from '../../../providers/customer/customer.provider';
import { GetCustomerId } from '../../../utilities/customer.utility';
import { of } from 'rxjs';

const PropertyDetailsDividendsTabComponent = ({
  property,
}: {
  property: PropertyModel;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { customer } = useContext(CustomerContext);

  const columns = PropertyDetailsDividendColumns;

  const [areDividendsLoading, setAreDividendsLoading] = useState<boolean>(true);
  const rowsPerPage = 10;
  const [, setPage] = useState(1);
  const [allDividends, setAllDividends] = useState<
    PropertyDetailsDividendTableModel[]
  >([]);

  const propertyDividends$ = useMemo(
    () => DividendsService.GetDividendDistributionEventsByProperty(property.id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [propertyDividends, , propertyDividendsLoading] =
    useObservable(propertyDividends$);

  const customerPropertyDividends$ = useMemo(
    () => {
      if (isNil(customer)) {
        return of([]);
      }

      return CustomerService.GetDividendsByProperty(
        GetCustomerId(customer),
        property.id
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [customer]
  );
  const [customerPropertyDividends, , customerPropertyDividendsLoading] =
    useObservable(customerPropertyDividends$);

  useEffect(() => {
    if (!propertyDividendsLoading && !customerPropertyDividendsLoading) {
      getDividends();
      setAreDividendsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    propertyDividends,
    customerPropertyDividends,
    propertyDividendsLoading,
    customerPropertyDividendsLoading,
  ]);

  const getDividends = () => {
    const customerDividends = new Map<number, DividendModel>();
    customerPropertyDividends?.forEach((customerDividend) => {
      customerDividends.set(
        customerDividend.dividendDistributionEventId,
        customerDividend
      );
    });

    const dividends: PropertyDetailsDividendTableModel[] = [];

    propertyDividends?.forEach((propertyDividend) => {
      const customerDividend = customerDividends.get(propertyDividend.id);
      const dividend: PropertyDetailsDividendTableModel = {
        allPropertyDividendsPaid: {
          value: propertyDividend.amount,
        },
        customerDividendsPaid: {
          value: customerDividend?.amount ?? 0,
        },
        receivedAt: {
          value: new Date(propertyDividend.createdAt),
        },
        statusIcon: isNil(customerDividend)
          ? undefined
          : {
              Icon: CheckCircleOutlineOutlinedIcon,
              tooltipLabel: t(
                'property_details.dividends_tab.status.received_dividend'
              ),
              color: theme.palette.success.main,
            },
      };
      dividends.push(dividend);
    });

    setAllDividends(dividends);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        gap: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          color="primary"
          sx={{
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          {t('property_details.dividends_tab.title')}
        </Typography>
      </Box>

      <TableComponent
        data={allDividends}
        columns={columns}
        totalRows={allDividends.length}
        loading={areDividendsLoading}
        onPageChange={setPage}
        rowsPerPage={rowsPerPage}
        minWidth="900px"
      />
    </Box>
  );
};

export default PropertyDetailsDividendsTabComponent;
