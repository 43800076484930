import { Observable } from 'rxjs';
import { NotificationStatus } from '../../constants/notifications/notification-status.constants';
import { NotifiableEventModel } from '../../models/notification/notifiable-event.model';
import { NotificationModel } from '../../models/notification/notification.model';
import ApiProvider from '../../providers/api/api.provider';

export const NotificationService = {
  GetNotifiableEvents(): Observable<NotifiableEventModel[]> {
    return ApiProvider.get<NotifiableEventModel[]>('/notifiable-events');
  },

  GetNotifications(): Observable<NotificationModel[]> {
    return ApiProvider.get<NotificationModel[]>('/notifications');
  },

  SetNotificationAsRead(notificationId: number): Observable<null> {
    return ApiProvider.put(`/notifications/${notificationId}`, {
      status: NotificationStatus.Read,
    });
  },
};
