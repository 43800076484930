import { Box, useTheme } from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { combineLatest, map, of, Subject, takeUntil } from 'rxjs';
import { PendingWithdrawalRequestsColumnsDefinition } from '../../../constants/table/pending-withdrawal-requests-columns.constants';
import useObservable from '../../../hooks/use-observable.hook';
import { DialogProps } from '../../../models/common/dialogs/dialog-props.model';
import { WithdrawalRequestTableModel } from '../../../models/withdrawal/withdrawal-request-table.model';
import {
  MapToAwaitingApprovalWithdrawalRequest,
  WithdrawalRequest,
} from '../../../models/withdrawal/withdrawal-request.model';
import SharedDataService from '../../../services/shared-data/shared-data.service';
import { WithdrawalService } from '../../../services/withdrawals/withdrawal.service';
import {
  GetExecutionStrategy,
  GetWithdrawalRequestStatus,
} from '../../../utilities/withdrawal.utility';
import DialogComponent from '../../common/dialog/dialog.component';
import TableComponent from '../../common/table/table.component';
import TitledBoxComponent from '../../common/titled-box/titled-box.component';
import { GetCustomerId } from '../../../utilities/customer.utility';
import { PaymentMethodType } from '../../../models/payment-methods/payment-method.model';
import { FormatCardLastFour } from '../../../utilities/payment-methods.utility';

const WithdrawalRequestsDialogComponent = ({
  open,
  handleClose,
  customer,
}: DialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const destroy$ = new Subject<void>();

  const columns = PendingWithdrawalRequestsColumnsDefinition;
  const rowsPerPage = 10;
  const [page, setPage] = useState(1);

  const [
    refreshPendingWithdrawalsRequests,
    setRefreshPendingWithdrawalsRequests,
  ] = useState<boolean>(false);
  const refreshPendingWithdrawalsRequestsRef = useRef(
    refreshPendingWithdrawalsRequests
  );

  useEffect(() => {
    refreshPendingWithdrawalsRequestsRef.current =
      refreshPendingWithdrawalsRequests;
  });

  useEffect(() => {
    SharedDataService.GetUpdatePendingWithdrawalsRequestsEvent()
      .pipe(takeUntil(destroy$))
      .subscribe(() => {
        setRefreshPendingWithdrawalsRequests(
          !refreshPendingWithdrawalsRequestsRef.current
        );
      });

    return () => {
      destroy$.next();
      destroy$.complete();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTablePendingWithdrawalRequest = (
    pendingWithdrawalRequest: WithdrawalRequest
  ): WithdrawalRequestTableModel => {
    const tableModel: WithdrawalRequestTableModel = {
      id: pendingWithdrawalRequest.id,
      paymentMethodSubMethod: pendingWithdrawalRequest.paymentMethod.subMethod,
      paymentMethodDescription:
        pendingWithdrawalRequest.paymentMethod.type === PaymentMethodType.Card
          ? FormatCardLastFour(
              pendingWithdrawalRequest.paymentMethod.description
            )
          : pendingWithdrawalRequest.paymentMethod.description,
      amount: {
        value: pendingWithdrawalRequest.amount,
      },
      executionStrategy: GetExecutionStrategy(
        pendingWithdrawalRequest.executionStrategy
      ),
      status: GetWithdrawalRequestStatus(pendingWithdrawalRequest.status),
      updatedAt: {
        value: new Date(pendingWithdrawalRequest.updatedAt),
      },
    };

    return tableModel;
  };

  const pendingWithdrawalRequests$ = useMemo(
    () => {
      if (isNil(customer)) {
        return of([]);
      }

      const customerId = GetCustomerId(customer);

      const pendingWithdrawals = WithdrawalService.GetPendingWithdrawals(
        customerId,
        page,
        rowsPerPage
      ).pipe(
        map((withdrawals) =>
          withdrawals.map((withdrawal) =>
            MapToAwaitingApprovalWithdrawalRequest(withdrawal)
          )
        )
      );

      const pendingWithdrawalRequests =
        WithdrawalService.GetPendingWithdrawalRequests(
          customerId,
          page,
          rowsPerPage
        );

      return combineLatest([
        pendingWithdrawals,
        pendingWithdrawalRequests,
      ]).pipe(
        map((lists) => {
          const arrayToReturn = [];
          for (const list of lists) {
            arrayToReturn.push(...list);
          }

          return arrayToReturn;
        }),
        map((pendingWithdrawalRequests) =>
          pendingWithdrawalRequests.map((pendingWithdrawalRequest) =>
            getTablePendingWithdrawalRequest(pendingWithdrawalRequest)
          )
        ),
        map((pendingWithdrawalRequests) =>
          pendingWithdrawalRequests
            .sort(
              (leftWithdrawalRequest, rightWithdrawalRequest) =>
                (leftWithdrawalRequest.updatedAt.value?.getTime() ?? 0) -
                (rightWithdrawalRequest.updatedAt.value?.getTime() ?? 0)
            )
            .reverse()
        )
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, refreshPendingWithdrawalsRequests, customer]
  );

  const [pendingWithdrawalRequests, , arePendingWithdrawalRequestsLoading] =
    useObservable(pendingWithdrawalRequests$);

  const getPendingWithdrawalRequests = (): WithdrawalRequestTableModel[] => {
    return pendingWithdrawalRequests ?? [];
  };

  return (
    <DialogComponent
      open={open}
      handleClose={handleClose}
      dialogTitle={t('withdrawal_requests_dialog.dialog_title')}
      width={'100%'}
    >
      <Box
        sx={{
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        }}
      >
        <TitledBoxComponent title={t('withdrawal_requests_dialog.title')}>
          <TableComponent
            data={getPendingWithdrawalRequests()}
            columns={columns}
            totalRows={getPendingWithdrawalRequests().length}
            loading={arePendingWithdrawalRequestsLoading}
            onPageChange={setPage}
            rowsPerPage={rowsPerPage}
          />
        </TitledBoxComponent>
      </Box>
    </DialogComponent>
  );
};

export default WithdrawalRequestsDialogComponent;
