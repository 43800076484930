import { Box, Typography, useTheme } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useTranslation } from 'react-i18next';
import { CurrencyFormatter } from '../../../../utilities/currency.utility';
import ButtonComponent from '../../../common/button/button.component';

const CardDepositSuccessfulPaymentComponent = ({
  paymentAmount,
  close,
}: {
  paymentAmount: number;
  close: () => void;
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: theme.spacing(2),
        paddingTop: theme.spacing(2),
      }}
    >
      <Box>
        <CheckBoxIcon
          sx={{
            fontSize: '100px',
            color: theme.palette.success.main,
          }}
        />
      </Box>

      <Box>
        <Typography fontSize="14px" textAlign="center">
          {`${t(
            'card_deposit.payment_successful.description'
          )} ${CurrencyFormatter(paymentAmount)}`}
        </Typography>
      </Box>

      <ButtonComponent
        sx={{
          width: '200px',
          height: '50px',
        }}
        onClick={close}
      >
        {t('card_deposit.payment_successful.close_button')}
      </ButtonComponent>
    </Box>
  );
};

export default CardDepositSuccessfulPaymentComponent;
