import { useContext, useMemo, useState } from 'react';
import { map, of } from 'rxjs';
import { DividendColumnsDefinition } from '../../../constants/table/dividend-columns.constants';
import useObservable from '../../../hooks/use-observable.hook';
import { DividendTableModel } from '../../../models/dividend/dividend-table.model';
import { DividendModel } from '../../../models/dividend/dividend.model';
import TableComponent from '../../common/table/table.component';
import CustomerService from '../../../services/customer/customer.service';
import CustomerContext from '../../../providers/customer/customer.provider';
import { GetCustomerId } from '../../../utilities/customer.utility';
import { isNil } from 'lodash';

const HistoryDividendsTabComponent = () => {
  const columns = DividendColumnsDefinition;

  const rowsPerPage = 10;
  const [page, setPage] = useState(1);

  const { customer } = useContext(CustomerContext);

  const getTableDividend = (dividend: DividendModel): DividendTableModel => {
    const tableModel: DividendTableModel = {
      id: dividend.id,
      amount: {
        value: dividend.amount,
      },
      createdAt: {
        value: new Date(dividend.createdAt),
      },
      propertyName: dividend.propertyName,
    };

    return tableModel;
  };

  const dividends$ = useMemo(
    () => {
      if (isNil(customer)) {
        return of([]);
      }

      return CustomerService.GetDividends(
        GetCustomerId(customer),
        page,
        rowsPerPage
      ).pipe(
        map<DividendModel[], DividendTableModel[]>(
          (dividends: DividendModel[]) => {
            return dividends.map((dividend) => {
              return getTableDividend(dividend);
            });
          }
        )
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, customer]
  );

  const [dividends, , loading] = useObservable(dividends$);

  const getDividends = (): DividendTableModel[] => {
    return dividends ?? [];
  };

  return (
    <TableComponent
      data={getDividends()}
      columns={columns}
      totalRows={getDividends().length}
      loading={loading}
      onPageChange={setPage}
      rowsPerPage={rowsPerPage}
    />
  );
};

export default HistoryDividendsTabComponent;
