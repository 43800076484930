import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { InvestmentModel } from '../../models/investments/investment.model';
import { PropertyModel } from '../../models/property/property.model';
import ImageBottomTextGradientComponent from '../common/gradient/image-bottom-text-gradient.component';
import PropertyDetailsMainInformationComponent from './property-details-main-information.component';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import HorizontalMenuComponent from '../common/horizontal-menu/horizontal-menu.component';

const PropertyDetailsMainComponent = ({
  property,
  propertyInvestment,
}: {
  property: PropertyModel;
  propertyInvestment: InvestmentModel | null;
}) => {
  const theme = useTheme();
  const isMdBreakpoint = useMediaQuery(theme.breakpoints.up('md'));

  const [mainImageUrl, setMainImageUrl] = useState(property.imageUrls[0]);

  const onImageClick = (imageUrl: string) => {
    setMainImageUrl(imageUrl);
  };
  const renderPropertyImage = (
    imageUrl: string,
    imageIndex: number
  ): JSX.Element => {
    const isSelected = imageUrl === mainImageUrl;

    return (
      <Box
        key={imageIndex}
        sx={{
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: 'cover',
          objectFit: 'cover',
          borderRadius: '8px',
          height: '90px',
          width: '120px',
          marginRight: theme.spacing(1),
          border: isSelected ? `3px solid ${theme.palette.primary.main}` : '',
          cursor: 'pointer',
        }}
      ></Box>
    );
  };

  const renderMainInformation = (): JSX.Element => {
    return (
      <Box
        sx={{
          flex: 1.2,
        }}
      >
        <PropertyDetailsMainInformationComponent
          property={property}
          propertyInvestment={propertyInvestment}
        />
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'column', md: 'row' },
          gap: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            flex: 2,
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${mainImageUrl})`,
              backgroundSize: 'cover',
              objectFit: 'cover',
              borderRadius: '8px',
              width: '100%',
              height: {
                xs: '400px',
                sm: '400px',
                md: '400px',
                lg: '500px',
                xl: '500px',
              },
            }}
          >
            <ImageBottomTextGradientComponent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'end',
                  height: '100%',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '24px',
                    fontWeight: 'bold',
                    color: '#FFFFFF',
                    paddingBottom: theme.spacing(1),
                    paddingLeft: theme.spacing(4),
                    paddingRight: theme.spacing(4),
                  }}
                >
                  {property.name}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    paddingBottom: theme.spacing(4),
                    paddingLeft: theme.spacing(4),
                    paddingRight: theme.spacing(4),
                  }}
                >
                  <PlaceOutlinedIcon
                    sx={{
                      fontSize: '28px',
                      color: '#FFFFFF',
                      height: '100%',
                      paddingRight: theme.spacing(1),
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: '18px',
                      color: '#FFFFFF',
                    }}
                  >
                    {property.address}
                  </Typography>
                </Box>
              </Box>
            </ImageBottomTextGradientComponent>
          </Box>
        </Box>

        {isMdBreakpoint && renderMainInformation()}
      </Box>

      <HorizontalMenuComponent
        items={property.imageUrls}
        renderItem={renderPropertyImage}
        onItemClick={(image) => onImageClick(image)}
        isLeftArrowVisible
        isRightArrowVisible
      />

      {!isMdBreakpoint && renderMainInformation()}
    </Box>
  );
};

export default PropertyDetailsMainComponent;
