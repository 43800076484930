import {
  Badge,
  Box,
  SvgIconTypeMap,
  Typography,
  useTheme,
} from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useState } from 'react';
import { NotificationStatus } from '../../constants/notifications/notification-status.constants';
import { NotificationModel } from '../../models/notification/notification.model';
import {
  GetNotificationDescription,
  GetNotificationIcon,
  GetNotificationTitle,
} from '../../utilities/notification.utility';
import { timeAgo } from '../../utilities/time';
import { toZonedTime } from 'date-fns-tz';
const NotificationComponent = ({
  notification,
}: {
  notification: NotificationModel;
}) => {
  const theme = useTheme();

  const [isNotificationRead] = useState(
    notification.status === NotificationStatus.Read
  );
  const [NotificationIcon] = useState<
    // eslint-disable-next-line @typescript-eslint/ban-types
    OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
  >(GetNotificationIcon(notification.eventType));

  const getNotificationTime = (
    notificationModel: NotificationModel
  ): number => {
    const utcDate = toZonedTime(new Date(), 'UTC');
    return utcDate.getTime() - new Date(notificationModel.createdAt).getTime();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
        padding: theme.spacing(2),
        borderBottom: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: '#FFFFFF',
      }}
    >
      <Box>
        <Badge
          color="error"
          variant="dot"
          invisible={isNotificationRead}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <NotificationIcon
            sx={{
              width: '24px',
              height: '24px',
            }}
          />
        </Badge>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(0.5),
        }}
      >
        <Typography
          color="primary"
          sx={{
            fontSize: '14px',
            fontWeight: 'bold',
          }}
        >
          {GetNotificationTitle(notification.eventType)}
        </Typography>

        <Typography
          sx={{
            fontSize: '12px',
          }}
        >
          {GetNotificationDescription(
            notification.eventType,
            notification.eventParams
          )}
        </Typography>

        <Box>
          <Typography
            color="primary"
            sx={{
              fontSize: '12px',
            }}
          >
            {timeAgo(getNotificationTime(notification))}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationComponent;
