import { useMemo, useState } from 'react';
import { map } from 'rxjs';
import { DepositColumnsDefinition } from '../../../constants/table/deposit-columns.constants';
import useObservable from '../../../hooks/use-observable.hook';
import { DepositTableModel } from '../../../models/deposit/deposit-table.model';
import { DepositModel } from '../../../models/deposit/deposit.model';
import { DepositService } from '../../../services/deposits/deposit.service';
import TableComponent from '../../common/table/table.component';
import { GetDepositType } from '../../../utilities/deposit.utility';
import { PaymentMethodType } from '../../../models/payment-methods/payment-method.model';
import { FormatCardLastFour } from '../../../utilities/payment-methods.utility';

const HistoryDepositsTabComponent = () => {
  const columns = DepositColumnsDefinition;

  const rowsPerPage = 10;
  const [page, setPage] = useState(1);

  const getTableDeposit = (deposit: DepositModel): DepositTableModel => {
    const tableModel: DepositTableModel = {
      amount: {
        value: deposit.amount,
      },
      depositType: GetDepositType(deposit.paymentMethod.type),
      depositMethod:
        deposit.paymentMethod.type === PaymentMethodType.Card
          ? FormatCardLastFour(deposit.paymentMethod.description)
          : deposit.paymentMethod.description,
      createdAt: {
        value: new Date(deposit.createdAt),
      },
    };

    return tableModel;
  };

  const deposits$ = useMemo(
    () =>
      DepositService.GetDeposits(page, rowsPerPage).pipe(
        map<DepositModel[], DepositTableModel[]>((deposits: DepositModel[]) => {
          return deposits.map((deposit) => {
            return getTableDeposit(deposit);
          });
        })
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page]
  );

  const [deposits, , loading] = useObservable(deposits$);

  const getDeposits = (): DepositTableModel[] => {
    return deposits ?? [];
  };

  return (
    <TableComponent
      data={getDeposits()}
      columns={columns}
      totalRows={getDeposits().length}
      loading={loading}
      onPageChange={setPage}
      rowsPerPage={rowsPerPage}
    />
  );
};

export default HistoryDepositsTabComponent;
