import { Box, Paper, useTheme } from '@mui/material';
import { isNil } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { IsCustomerVerified } from '../../enums/customer-verification-status.enum';
import CustomerContext from '../../providers/customer/customer.provider';
import LoadingComponent from '../common/loading-spinner/loading.component';
import CustomerProfileSummaryComponent from './customer-profile-summary.component';
import PROFILE_TABS from '../../constants/profile/profile-tabs.constants';
import { ProfileTabType } from '../../enums/profile/profile-tab-type.enum';
import CustomerProfileInformationTabComponent from './customer-profile-information-tab.component';
import TabComponent from '../common/tabs/tab.component';
import CustomerProfileChangePasswordTabComponent from './customer-profile-change-password-tab.component';
import { useTranslation } from 'react-i18next';
import HorizontalMenuComponent from '../common/horizontal-menu/horizontal-menu.component';

const CustomerProfileComponent = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const { customer, isCustomerLoading } = useContext(CustomerContext);

  const [isCustomerVerified, setIsCustomerVerified] = useState(false);

  const [selectedTab, setSelectedTab] = useState(
    ProfileTabType.ProfileInformation
  );

  useEffect(() => {
    if (!isNil(customer)) {
      setIsCustomerVerified(IsCustomerVerified(customer));
    }
  }, [customer]);

  const renderTab = (): JSX.Element => {
    if (isNil(customer)) {
      return <></>;
    }

    switch (selectedTab) {
      case ProfileTabType.ProfileInformation:
        return (
          <CustomerProfileInformationTabComponent
            customer={customer}
            isCustomerVerified={isCustomerVerified}
          />
        );
      case ProfileTabType.ChangePassword:
        return <CustomerProfileChangePasswordTabComponent />;
      default:
        return <></>;
    }
  };

  const renderTabMenuItem = (
    tab: {
      translationId: string;
      type: ProfileTabType;
    },
    tabIndex: number
  ): JSX.Element => {
    return (
      <TabComponent
        name={t(tab.translationId)}
        tabType={tab.type}
        index={tabIndex}
        selectedTabType={selectedTab}
      />
    );
  };

  return (
    <LoadingComponent isLoading={isCustomerLoading}>
      <Box
        sx={{
          paddingTop: theme.spacing(4),
          paddingLeft: {
            xs: theme.spacing(4),
            md: theme.spacing(8),
          },
          paddingRight: {
            xs: theme.spacing(4),
            md: theme.spacing(8),
          },
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: theme.spacing(2),
        }}
      >
        <Box>
          <Paper
            elevation={2}
            sx={{
              padding: theme.spacing(2),
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: theme.spacing(2),
            }}
          >
            {!isNil(customer) && (
              <CustomerProfileSummaryComponent customer={customer} />
            )}
          </Paper>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <HorizontalMenuComponent
            items={PROFILE_TABS}
            renderItem={renderTabMenuItem}
            onItemClick={(item) => setSelectedTab(item.type)}
          />

          <Paper
            elevation={2}
            sx={{
              width: '100%',
              padding: theme.spacing(2),
            }}
          >
            {renderTab()}
          </Paper>
        </Box>
      </Box>
    </LoadingComponent>
  );
};

export default CustomerProfileComponent;
