import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Typography, useTheme } from '@mui/material';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { take } from 'rxjs';
import { TypeOf } from 'zod';
import CustomerProfileChangePasswordSchema from '../../schemas/customer-profile/customer-profile-change-password.schema';
import CustomerService from '../../services/customer/customer.service';
import ButtonComponent from '../common/button/button.component';
import FormInputTextComponent from '../common/forms/form-input-text.component';
import { ApiErrorResponse } from '../../models/common/errors/api-error-response.model';
import { isNil } from 'lodash';
import { ConstructMessage } from '../../utilities/errors.utility';
import { useState } from 'react';
import { DialogType } from '../../services/dialog-manager/dialog-manager.service';
import SuccessDialogComponent from '../common/dialog/success-dialog.component';
import { DialogManagerProps } from '../../models/common/dialogs/dialog-manager-props.model';
import withDialogs from '../../providers/dialog/with-dialog';

type ICustomerProfileChangePasswordForm = TypeOf<
  typeof CustomerProfileChangePasswordSchema
>;

const CustomerProfileChangePasswordTabComponent = ({
  dialogManagerService,
}: DialogManagerProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [formErrorMessage, setFormErrorMessage] = useState('');

  const defaultCustomerProfileChangePasswordFormValues: ICustomerProfileChangePasswordForm =
    {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    };

  const methods = useForm<ICustomerProfileChangePasswordForm>({
    resolver: zodResolver(CustomerProfileChangePasswordSchema),
    defaultValues: defaultCustomerProfileChangePasswordFormValues,
  });

  const handleChangePasswordErrors = (err: ApiErrorResponse<null>) => {
    const data = err.response.data;

    if (!isNil(data.code)) {
      setFormErrorMessage(ConstructMessage('', data.code));
    }
  };

  const showPasswordChangeSuccessfullyDialog = () => {
    const message = t(
      'customer_profile_change_password.success_dialog.message'
    );
    const confirmButtonLabel = t(
      'customer_profile_change_password.success_dialog.confirmation'
    );

    const resetPasswordSuccessfulDialog: DialogType = {
      component: SuccessDialogComponent,
      props: {
        message,
        confirmButtonLabel,
      },
    };

    dialogManagerService.Open(resetPasswordSuccessfulDialog);
  };

  const updateCustomerPassword: SubmitHandler<ICustomerProfileChangePasswordForm> =
    (customerProfileChangePassword: ICustomerProfileChangePasswordForm) => {
      CustomerService.ChangePassword(
        customerProfileChangePassword.oldPassword,
        customerProfileChangePassword.newPassword
      )
        .pipe(take(1))
        .subscribe({
          next: () => {
            methods.reset();
            setFormErrorMessage('');
            showPasswordChangeSuccessfullyDialog();
          },
          error: (error: ApiErrorResponse<null>) => {
            handleChangePasswordErrors(error);
          },
        });
    };

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={methods.handleSubmit(updateCustomerPassword)}
        display="flex"
        flexDirection="column"
        gap={theme.spacing(2)}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: '200px',
              md: 'minmax(164px, 1fr) minmax(164px, 1fr) minmax(164px, 1fr)',
            },
            gap: theme.spacing(2),
            justifyContent: 'center',
            padding: theme.spacing(2),
          }}
        >
          <FormInputTextComponent
            label={t('customer_profile_change_password.fields.old_password')}
            type="password"
            name="oldPassword"
            variant="filled"
            inputProps={{
              sx: {
                color: theme.palette.primary.main,
                fontSize: '18px',
                fontWeight: 'bold',
              },
            }}
          />

          <FormInputTextComponent
            label={t('customer_profile_change_password.fields.new_password')}
            type="password"
            name="newPassword"
            variant="filled"
            inputProps={{
              sx: {
                color: theme.palette.primary.main,
                fontSize: '18px',
                fontWeight: 'bold',
              },
            }}
          />

          <FormInputTextComponent
            label={t(
              'customer_profile_change_password.fields.confirm_new_password'
            )}
            type="password"
            name="confirmNewPassword"
            variant="filled"
            inputProps={{
              sx: {
                color: theme.palette.primary.main,
                fontSize: '18px',
                fontWeight: 'bold',
              },
            }}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ButtonComponent
            sx={{
              width: '220px',
              height: '50px',
              fontSize: '18px',
              fontWeight: 'bold',
            }}
            type="submit"
          >
            {t('customer_profile_change_password.change_password_button')}
          </ButtonComponent>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginTop: theme.spacing(2),
          }}
        >
          {!isNil(formErrorMessage) && (
            <Typography color="error">{formErrorMessage}</Typography>
          )}
        </Box>
      </Box>
    </FormProvider>
  );
};

const WrappedCustomerProfileChangePasswordTabComponent = withDialogs(
  CustomerProfileChangePasswordTabComponent
);
export default WrappedCustomerProfileChangePasswordTabComponent;
