import { Box, Typography, useTheme } from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChipModel } from '../../models/common/chips/chip.model';
import { StripeAcceptableVerificationDocument } from '../../models/payments/stripe/stripe-acceptable-verification-document.model';
import { GetDocumentLabelTranslationId } from '../../utilities/verification.utility';
import ChipsComponent from '../common/chips/chips.component';

const VerificationAcceptableDocuments = ({
  acceptableDocuments,
  acceptableDocumentsDescription,
}: {
  acceptableDocuments: StripeAcceptableVerificationDocument[];
  acceptableDocumentsDescription: string;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [selectedDocument, setSelectedDocument] =
    useState<StripeAcceptableVerificationDocument | null>(
      acceptableDocuments[0]
    );
  const [acceptableDocumentsChips, setAcceptableDocumentsChips] = useState<
    ChipModel[]
  >([]);

  const changeSelectedDocument = (documentChip: ChipModel) => {
    const newSelectedDocument = acceptableDocuments.find(
      (acceptableDocument) => acceptableDocument.name === documentChip.id
    );

    isNil(newSelectedDocument)
      ? setSelectedDocument(null)
      : setSelectedDocument(newSelectedDocument);
  };

  const setChips = () => {
    const chips = acceptableDocuments.map<ChipModel>(
      (document) =>
        ({
          id: document.name,
          labelTranslationId: GetDocumentLabelTranslationId(document.name),
        } as ChipModel)
    );

    setAcceptableDocumentsChips(chips);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setChips(), []);

  return (
    <Box>
      <Box
        sx={{
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        }}
      >
        <ChipsComponent
          chips={acceptableDocumentsChips}
          selectedChip={
            {
              id: selectedDocument?.name ?? '',
              labelTranslationId: '',
            } as ChipModel
          }
          chipsDescription={acceptableDocumentsDescription}
          onChipClick={changeSelectedDocument}
        />
      </Box>

      <Box>
        <Typography fontSize="14px">
          {selectedDocument?.requiresBack
            ? t('stripe_verification.document_verification.requires_back')
            : t('stripe_verification.document_verification.only_front')}
        </Typography>
      </Box>
    </Box>
  );
};

export default VerificationAcceptableDocuments;
