import './static-initializers/trusted-security-policies';

import ReactDOM from 'react-dom';
import { App } from './App';

// Load Translations
import './translations/i18n';

// Load Font
import './assets/fonts/manrope-font.css';

// Load Zod utilities
import './utilities/zod.utility';

ReactDOM.render(<App />, document.getElementById('root'));
