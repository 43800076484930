import { CustomerModel } from '../models/customer/customer.model';

export type CustomerVerificationStatusType = 'UNVERIFIED' | 'VERIFIED';

const VERIFIED_STATUS: CustomerVerificationStatusType = 'VERIFIED';
const UNVERIFIED_STATUS: CustomerVerificationStatusType = 'UNVERIFIED';

export const CustomerVerificationStatus = {
  Verified: VERIFIED_STATUS,
  Unverified: UNVERIFIED_STATUS,
};

export const IsCustomerVerified = (customer: CustomerModel): boolean => {
  return customer.verificationStatus === CustomerVerificationStatus.Verified;
};
