import { isNil } from 'lodash';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { take } from 'rxjs';
import { OrderDirection } from '../../../constants/order-direction.constants';
import { DialogProps } from '../../../models/common/dialogs/dialog-props.model';
import { OrderModel } from '../../../models/order/order.model';
import { OrderService } from '../../../services/orders/order.service';
import SharedDataService from '../../../services/shared-data/shared-data.service';
import DialogComponent from '../../common/dialog/dialog.component';
import ShareSaleDialogAmountInput from './sell-dialog-steps/share-sale-dialog-amount-input.component';
import ShareSaleDialogPreviewComponent from './sell-dialog-steps/share-sale-dialog-preview.component';
import { CurrencyFormatter } from '../../../utilities/currency.utility';
import SuccessDialogContentComponent from '../../common/dialog/success-dialog-content.component';
import FailureDialogContentComponent from '../../common/dialog/failure-dialog-content.component';

enum SaleStatus {
  InputAmount,
  PreviewSale,
  SuccessfulSale,
  FailedSale,
}

const ShareSaleDialogComponent = ({
  open,
  customer,
  handleClose,
  propertyId,
  availableShareValue,
}: DialogProps & { propertyId: number; availableShareValue: number }) => {
  const { t } = useTranslation();

  const [shareSaleStatus, setShareSaleStatus] = useState(
    SaleStatus.InputAmount
  );
  const [shareSaleAmount, setShareSaleAmount] = useState(0);

  const goToPreview = () => {
    setShareSaleStatus(SaleStatus.PreviewSale);
  };

  const goToSuccessfulSale = () => {
    setShareSaleStatus(SaleStatus.SuccessfulSale);
  };

  const goToFailedSale = () => {
    setShareSaleStatus(SaleStatus.FailedSale);
  };

  const onShareSaleSet = (shareSaleAmount: number) => {
    setShareSaleAmount(shareSaleAmount);
    goToPreview();
  };

  const createShareSellOrder = () => {
    if (isNil(customer)) {
      return;
    }

    OrderService.PlaceValueOrder(
      propertyId,
      shareSaleAmount,
      OrderDirection.Sell
    )
      .pipe(take(1))
      .subscribe(
        (order: OrderModel | null) => {
          if (isNil(order)) {
            goToFailedSale();
          } else {
            SharedDataService.TriggerUpdatePortfolioMetricsEvent();
            SharedDataService.TriggerUpdateCustomerInformationEvent();
            SharedDataService.TriggerUpdatePropertyEvent();
            goToSuccessfulSale();
          }
        },
        () => goToFailedSale()
      );
  };

  const getTitle = (): string => {
    switch (shareSaleStatus) {
      case SaleStatus.InputAmount:
        return t('share_sale_dialog.input.title');
      case SaleStatus.PreviewSale:
        return t('share_sale_dialog.preview.title');
      case SaleStatus.SuccessfulSale:
      case SaleStatus.FailedSale:
      default:
        return '';
    }
  };

  const renderShareSaleView = (): ReactNode => {
    switch (shareSaleStatus) {
      case SaleStatus.InputAmount:
        return (
          <ShareSaleDialogAmountInput
            availableShareValue={availableShareValue}
            setSaleAmount={onShareSaleSet}
          />
        );
      case SaleStatus.PreviewSale:
        return (
          <ShareSaleDialogPreviewComponent
            shareSaleAmount={shareSaleAmount}
            sell={createShareSellOrder}
          />
        );
      case SaleStatus.SuccessfulSale:
        const message = `${t(
          'share_sale_dialog.successful_sale.description'
        )} ${CurrencyFormatter(shareSaleAmount)}`;
        const confirmButtonLabel = t(
          'share_sale_dialog.successful_sale.close_button'
        );
        return (
          <SuccessDialogContentComponent
            message={message}
            confirmButtonLabel={confirmButtonLabel}
            close={handleClose}
          />
        );
      case SaleStatus.FailedSale:
        const failedSaleMessage = t(
          'share_sale_dialog.failed_sale.description'
        );
        const failedSaleConfirmButtonLabel = t(
          'share_sale_dialog.failed_sale.close_button'
        );
        return (
          <FailureDialogContentComponent
            message={failedSaleMessage}
            confirmButtonLabel={failedSaleConfirmButtonLabel}
            close={handleClose}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <DialogComponent
      open={open}
      handleClose={handleClose}
      dialogTitle={getTitle()}
      width={{ sm: '400px', md: '500px' }}
    >
      {renderShareSaleView()}
    </DialogComponent>
  );
};

export default ShareSaleDialogComponent;
