import { List, ListItem, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PasswordTooltipComponent = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <List
        sx={{
          listStyleType: 'disc',
          paddingLeft: 2,
          '& .MuiListItem-root': {
            display: 'list-item',
          },
        }}
      >
        <ListItem
          disablePadding
          sx={{
            paddingBottom: theme.spacing(0.3),
            paddingTop: theme.spacing(0.3),
          }}
        >
          <Typography fontSize="13px">
            {t('registration.password_tooltip.lowercase')}
          </Typography>
        </ListItem>
        <ListItem
          disablePadding
          sx={{
            paddingBottom: theme.spacing(0.3),
            paddingTop: theme.spacing(0.3),
          }}
        >
          <Typography fontSize="13px">
            {t('registration.password_tooltip.uppercase')}
          </Typography>
        </ListItem>
        <ListItem
          disablePadding
          sx={{
            paddingBottom: theme.spacing(0.3),
            paddingTop: theme.spacing(0.3),
          }}
        >
          <Typography fontSize="13px">
            {t('registration.password_tooltip.number')}
          </Typography>
        </ListItem>
        <ListItem
          disablePadding
          sx={{
            paddingBottom: theme.spacing(0.3),
            paddingTop: theme.spacing(0.3),
          }}
        >
          <Typography fontSize="13px">
            {t('registration.password_tooltip.length')}
          </Typography>
        </ListItem>
      </List>
    </>
  );
};

export default PasswordTooltipComponent;
