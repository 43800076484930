import {
  Box,
  Button,
  DialogContent,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DialogProps } from '../../models/common/dialogs/dialog-props.model';
import ButtonComponent from '../common/button/button.component';
import DialogComponent from '../common/dialog/dialog.component';
import { DialogType } from '../../services/dialog-manager/dialog-manager.service';
import DepositDialogComponent from '../deposits/deposit-dialog.component';
import { DialogManagerProps } from '../../models/common/dialogs/dialog-manager-props.model';
import withDialogs from '../../providers/dialog/with-dialog';
import WithdrawalRequestsDialogComponent from '../withdraw/withdrawal-requests-dialog/withdrawal-requests-dialog.component';
import { useState } from 'react';
import WithdrawDialogComponent from '../withdraw/withdraw-dialog.component';

enum ManageFundsType {
  Deposit,
  Withdraw,
}

const ManageFundsDialogComponent = ({
  open,
  customer,
  handleClose,
  dialogManagerService,
}: DialogProps & DialogManagerProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [manageFundsType, setManageFundsType] = useState(
    ManageFundsType.Deposit
  );

  const isDepositSelected = () => {
    return manageFundsType === ManageFundsType.Deposit;
  };

  const isWithdrawSeleced = () => {
    return manageFundsType === ManageFundsType.Withdraw;
  };

  const getTabColor = (tabType: ManageFundsType): string => {
    if (
      (tabType == ManageFundsType.Deposit && isDepositSelected()) ||
      (tabType == ManageFundsType.Withdraw && isWithdrawSeleced())
    ) {
      return theme.palette.primary.main;
    } else {
      return theme.palette.grey[500];
    }
  };

  const openDepositDialog = () => {
    const dialog: DialogType = {
      component: DepositDialogComponent,
      props: {
        customer: customer,
      },
    };
    dialogManagerService.Open(dialog);
    handleClose();
  };

  const openWithdrawDialog = () => {
    const dialog: DialogType = {
      component: WithdrawDialogComponent,
      props: {
        customer: customer,
      },
    };
    dialogManagerService.Open(dialog);
    handleClose();
  };

  const openWithdrawalRequestsDialog = () => {
    const dialog: DialogType = {
      component: WithdrawalRequestsDialogComponent,
      props: {
        customer: customer,
      },
    };
    dialogManagerService.Open(dialog);
    handleClose();
  };

  return (
    <DialogComponent
      open={open}
      handleClose={handleClose}
      dialogTitle={t('manage_funds.title')}
      width={{ xs: '326px', sm: '400px', md: '500px' }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '0',
          paddingLeft: theme.spacing(6),
          paddingRight: theme.spacing(6),
        }}
      >
        <Button
          sx={{
            borderBottom: `2px solid ${getTabColor(ManageFundsType.Deposit)}`,
            borderRadius: '0px',
            transition: 'all .4s ease',
            flex: '1',
          }}
          onClick={() => setManageFundsType(ManageFundsType.Deposit)}
        >
          <Typography
            sx={{
              fontSize: { xs: '10px', sm: '14px', md: '16px' },
              fontWeight: isDepositSelected() ? 'bold' : 'normal',
              color: getTabColor(ManageFundsType.Deposit),
              transition: 'all .4s ease',
            }}
          >
            {t('manage_funds.deposit_title')}
          </Typography>
        </Button>
        <Button
          sx={{
            borderBottom: `2px solid ${getTabColor(ManageFundsType.Withdraw)}`,
            borderRadius: '0px',
            transition: 'all .4s ease',
            flex: '1',
          }}
          onClick={() => setManageFundsType(ManageFundsType.Withdraw)}
        >
          <Typography
            sx={{
              fontSize: { xs: '10px', sm: '14px', md: '16px' },
              fontWeight: isWithdrawSeleced() ? 'bold' : 'normal',
              color: getTabColor(ManageFundsType.Withdraw),
              transition: 'all .4s ease',
            }}
          >
            {t('manage_funds.withdraw_title')}
          </Typography>
        </Button>
      </Box>

      <DialogContent
        sx={{
          paddingLeft: theme.spacing(6),
          paddingRight: theme.spacing(6),
        }}
      >
        {isDepositSelected() && (
          <Box>
            <ButtonComponent
              sx={{
                fontSize: { xs: '12px', sm: '16px' },
                fontWeight: 'normal',
                height: '40px',
                width: '100%',
              }}
              onClick={openDepositDialog}
            >
              {t('manage_funds.deposit_funds_button')}
            </ButtonComponent>
          </Box>
        )}
        {isWithdrawSeleced() && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(2.5),
            }}
          >
            <Box>
              <ButtonComponent
                sx={{
                  fontSize: { xs: '12px', sm: '16px' },
                  fontWeight: 'normal',
                  height: '40px',
                  width: '100%',
                }}
                onClick={openWithdrawDialog}
              >
                {t('manage_funds.withdraw_funds_button')}
              </ButtonComponent>
            </Box>

            <Box>
              <ButtonComponent
                sx={{
                  fontSize: { xs: '12px', sm: '16px' },
                  fontWeight: 'normal',
                  height: '40px',
                  width: '100%',
                }}
                onClick={openWithdrawalRequestsDialog}
              >
                {t('manage_funds.withdrawal_requests_button')}
              </ButtonComponent>
            </Box>
          </Box>
        )}
      </DialogContent>
    </DialogComponent>
  );
};

const wrappedManageFundsDialogComponent = withDialogs(
  ManageFundsDialogComponent
);
export default wrappedManageFundsDialogComponent;
